import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField, Typography } from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { TICKET_STATUS } from '../../../Config/constant';
import { useAuthStore } from '../../../Stores/AuthStore';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import { width } from '../../../styles/variables/width';

const SUBMISSION_STATE = {
  REJECT: 'reject',
  APPROVE: 'approve',
  HIDDEN: 'hidden',
};

const ApproveSection = ({
  departmentOptions = [],
  priorityOptions = [],
  onSubmit,
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedPriority, setSelectedPriority] = useState();
  const isDisabled =
    selectedDepartment === undefined || selectedPriority === undefined;

  const handleSubmit = useCallback(() => {
    onSubmit &&
      onSubmit({ department: selectedDepartment, priority: selectedPriority });
  }, [onSubmit, selectedDepartment, selectedPriority]);

  return (
    <>
      <Typography fontWeight="bold" variant="h4" className="pb-4">
        Έγκριση αιτήματος
      </Typography>
      <section className="flex items-center mb-5">
        <Autocomplete
          className="mr-5 w-[300px]"
          options={priorityOptions}
          getOptionLabel={(option) => option?.description}
          onChange={(event, newOption) => {
            setSelectedPriority(newOption?.value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className="bg-[#FFFF] "
              label={'Ορισμός Προτεραιότητας'}
            />
          )}
        />
        <Autocomplete
          className="w-[300px]"
          options={departmentOptions}
          getOptionLabel={(option) => option?.name}
          onChange={(event, newOption) => {
            setSelectedDepartment(newOption?.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className="bg-[#FFFF]"
              label={'Ορισμός Τμήματος'}
            />
          )}
        />
      </section>
      <section className="flex justify-end">
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            disabled={isDisabled}
            onClick={handleSubmit}
            title={'Υποβολή'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </section>
    </>
  );
};

const RejectSection = ({ onSubmit }) => {
  const [comments, setComments] = useState('');

  const onChange = useCallback((event) => {
    setComments(event?.target?.value || '');
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit({ comments });
  }, [comments, onSubmit]);

  return (
    <>
      <section className="pb-5">
        <Typography fontWeight="bold" variant="h4" className="pb-4">
          Απόρριψη αίτησης
        </Typography>
        <TextField
          fullWidth
          multiline
          placeholder="Αιτία απόρριψης..."
          onChange={onChange}
          rows={4}
          value={comments}
        />
      </section>
      <section className="flex justify-end">
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: palette.info.dark,
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: palette.info.dark,
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            disabled={!comments}
            onClick={handleSubmit}
            title={'Υποβολή'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </section>
    </>
  );
};

const TicketModeratorActions = ({ onApprove, onReject, ticket }) => {
  const formSectionRef = useRef();
  const navigate = useNavigate();
  const [submissionState, setSubmissionState] = useState(
    SUBMISSION_STATE.HIDDEN
  );
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const isUnderReview = ticket?.status === TICKET_STATUS.UNDER_REVIEW.id;
  const { isModerator } = useAuthStore();

  const getActionForm = useCallback(
    (state) => {
      if (state === SUBMISSION_STATE.APPROVE) {
        return (
          <ApproveSection
            departmentOptions={departmentOptions}
            priorityOptions={priorityOptions}
            onSubmit={onApprove}
          />
        );
      }

      return <RejectSection onSubmit={onReject} />;
    },
    [departmentOptions, priorityOptions, onApprove, onReject]
  );

  const resolveActionSection = useCallback(
    (state) => {
      if (state === SUBMISSION_STATE.HIDDEN) {
        return null;
      }

      return (
        <ExpandableSectionContainer ref={formSectionRef}>
          {getActionForm(state)}
        </ExpandableSectionContainer>
      );
    },
    [getActionForm]
  );

  useEffect(() => {
    const intitializeOptions = async () => {
      await AuthSetAgent.tickets.getSearchLists().then((response) => {
        setDepartmentOptions(response?.result?.departments || []);
        setPriorityOptions(response?.result?.priorities || []);
      });
    };

    isUnderReview && intitializeOptions();
  }, [isUnderReview]);

  useEffect(() => {
    if (submissionState !== SUBMISSION_STATE.HIDDEN) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [submissionState]);

  if (!ticket || !isUnderReview || !isModerator) {
    return null;
  }

  return (
    <section>
      <section className="flex items-center justify-end pb-10">
        <div className="mr-4">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#003375',
                color: '#f9f9f9',
                width: width.button.md,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              className="capitalize text-sm"
              title={'Ακύρωση'}
              onClick={() => {
                if (submissionState === SUBMISSION_STATE.HIDDEN) {
                  navigate(-1);
                } else {
                  setSubmissionState(SUBMISSION_STATE.HIDDEN);
                }
              }}
              variant="contained"
              type="submit"
            />
          </AnimateButton>
        </div>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: palette.error.dark,
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              marginRight: '15px',
              '&:hover': {
                backgroundColor: palette.error.dark,
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            onClick={() => setSubmissionState(SUBMISSION_STATE.REJECT)}
            title={'Απόρριψη'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: palette.success.main,
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: palette.success.main,
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            onClick={() => setSubmissionState(SUBMISSION_STATE.APPROVE)}
            title={'Έγκριση'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </section>
      {resolveActionSection(submissionState)}
    </section>
  );
};

export default TicketModeratorActions;
