import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import { usePoisStore } from '../../../Stores/PoisStore';
import Topbar from '../../../Components/Topbar/Topbar';
import { error, success } from '../../../Common/Toast/Toast';
import EditPoiCategoryForm from '../EditPoiCategoryForm/EditPoiCategoryForm';

const PoiCategoriesViewEdit = () => {
  const poisStore = usePoisStore();
  const [poiCategory, setPoiCategory] = useState(null);
  const { id } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (id) {
      poisStore
        .getPoiCategoryById(id)
        .then((response) => {
          setPoiCategory(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const onDelete = () => {
    setDeleteModalOpen(true);
  };

  const deletePoiCategory = () => {
    poisStore
      .deletePoiCategory(id)
      .then(() => {
        success('Η κατηγορία διαγράφηκε');
        navigate(-1);
      })
      .catch((err) => {
        error('Κάτι πήγε στραβά');
        console.error(err);
      });
  };

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Typography
        className="pt-6 pb-5 pl-4"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigate('/pois')}
          style={{ color: '#003375' }}
        >
          Οι κατηγορίες σημείων ενδιαφέροντος
        </span>
        <span style={{ color: '#003375' }}> / Προβολή - Επεξεργασία</span>
      </Typography>
      {poiCategory ? (
        <Box className="flex flex-col w-full justify-right items-end pt-6 pb-5 pl-4 pr-4">
          <Box className="flex w-full justify-between items-center gap-4 pb-6">
            <div className="flex justify-center items-center gap-4">
              {isEditMode && (
                <>
                  <Button
                    variant="contained"
                    onClick={toggleEditMode}
                    sx={{
                      backgroundColor: '#003375',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#003375',
                        color: 'white',
                        opacity: 0.9,
                      },
                      textTransform: 'none',
                      fontSize: '1rem',
                    }}
                  >
                    {isEditMode ? 'Ακύρωση' : 'Επεξεργασία'}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={onDelete}
                    sx={{
                      backgroundColor: '#f9f9f9',
                      color: '#B60202',
                      border: '1px solid #B60202',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#B60202',
                        color: '#f9f9f9',
                      },
                      fontSize: '1rem',
                    }}
                  >
                    Διαγραφή
                  </Button>
                </>
              )}
            </div>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: '#f9f9f9',
                color: '#003375',
                border: '1px solid #003375',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
            >
              Επιστροφή
            </Button>
          </Box>

          <EditPoiCategoryForm
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            poiCategory={poiCategory}
          />
        </Box>
      ) : (
        <div className="text-center py-10">
          <CircularProgress
            sx={{
              color: '#003375',
            }}
            size="3rem"
          />
        </div>
      )}

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε σίγουροι;
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Η κατηγορία θα διαγραφεί οριστικά.
          </Typography>
          <Box className="flex justify-end gap-4 mt-4" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={deletePoiCategory}
              sx={{
                backgroundColor: '#f44336',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#f44336',
                  color: 'white',
                  opacity: 0.9,
                },
                textTransform: 'none',
              }}
            >
              Ναι
            </Button>
            <Button
              variant="contained"
              onClick={() => setDeleteModalOpen(false)}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: 'white',
                  opacity: 0.9,
                },
                textTransform: 'none',
              }}
            >
              Όχι
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default observer(PoiCategoriesViewEdit);
