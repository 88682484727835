import AuthCardWrapper from "../../../Components/Theme/AuthCardWrapper";
import {
  Box,
  TextField,
  FormControl,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { themeColors } from "../../../Config/Themes/theme";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AnimateButton from "../../../Components/Theme/Extended/AnimateButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { authStore } from "../../../Stores/AuthStore";
import { useState } from "react";
import SubmitButton from "../../../Components/Theme/Extended/SubmitButton";
import PasswordInput from "../../../Components/Theme/Inputs/PasswordInput";
import { passwordRegex } from "../../../Common/Regex";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Καταχωρήστε έγκυρο email")
      .trim()
      .strict(true)
      .required("Το email είναι υποχρεωτικό πεδίο"),
    newPassword: yup
      .string()
      .trim()
      .strict(true)
      .matches(passwordRegex, t("validation.InvalidPassword")),
    confirmNewPassword: yup
      .string()
      .required("Η επιβεβαίωση κωδικού είναι υποχρεωτική")
      .oneOf([yup.ref("newPassword"), null], t("forms.passwordsMustMatch")),
  });

  const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);

    authStore.resetPassword(data, token).finally(() => {
      setLoading(false);
      navigate("/");
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthCardWrapper className="min-w-full" pageName="resetPassword">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item container paddingBottom="1rem" xs={12}>
            <FormControl sx={{ width: "150%" }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Email"
                    variant="filled"
                    type="email"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message) : null}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ width: "150%", marginTop: "1.5rem" }}>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <PasswordInput
                    label={t("forms.Password")}
                    onChange={onChange}
                    value={value}
                    error={error}
                    variant="filled"
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ width: "150%", marginTop: "1.5rem" }}>
              <Controller
                name="confirmNewPassword"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <PasswordInput
                    label={t("forms.confirmPassword")}
                    onChange={onChange}
                    value={value}
                    error={error}
                    variant="filled"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <SubmitButton
                className="text-lg"
                buttonTitle="resetPassword"
                sx={{ backgroundColor: "#003375" }}
                disableElevation
                loading={loading}
                type="submit"
              />
            </AnimateButton>
          </Box>
        </Grid>
      </AuthCardWrapper>
    </form>
  );
};

export default observer(ResetPassword);
