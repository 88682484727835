// material-ui
import { Button, Grid, Stack, TextField } from '@mui/material';
// project imports
import SubCard from '../../../Components/Theme/Cards/SubCard';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import { gridSpacing } from '../../../Config/constant';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useAuthStore } from '../../../Stores/AuthStore';
import { phoneRegex } from '../../../Common/Regex';
import { useEffect, useState } from 'react';

const Profile = () => {
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authStore.authUserIsNotLoaded) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [authStore.authUserIsNotLoaded, authStore]);

  const profileSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(phoneRegex, t('validation.InvalidPhone'))
      .required('Το πεδίο είναι υποχρεωτικό'),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(profileSchema),
  });

  const onSubmit = ({ phoneNumber }) => {
    authStore.updateProfile(phoneNumber);
  };

  return loading ? null : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridSpacing}>
        <Grid item sm={6} md={8}>
          {/* <SubCard title={t("profile.editAccountDetailsTitle")}> */}
          <Grid className="pt-4" container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TextField
                defaultValue={authStore.authUser?.name}
                disabled
                fullWidth
                label={t('forms.FirstName')}
                id="outlined-basic1"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={authStore.authUser?.email}
                disabled
                fullWidth
                label="Email"
                id="outlined-basic6"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                defaultValue={authStore.authUser?.surname}
                disabled
                fullWidth
                label={t('forms.LastName')}
                id="outlined-basic6"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                defaultValue={authStore.authUser?.username}
                disabled
                fullWidth
                label={t('forms.UserName')}
                id="outlined-basic4"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={authStore.authUser?.phoneNumber}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    variant="standard"
                    label={t('forms.PhoneNumber')}
                    id="outlined-basic7"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message) : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row">
                <AnimateButton>
                  <Button
                    className="capitalize text-sm"
                    sx={{ backgroundColor: '#003375' }}
                    type="submit"
                    variant="contained"
                  >
                    Ενημερωση
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
          {/* </SubCard> */}
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(Profile);
