import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/el';

const ShowOnlyDatePicker = ({
  value,
  onChange,
  format = 'DD-MM-YYYY',
  className,
  error,
  helperText,
  disabled,
  disableFuture = false,
  disabledDates = null,
  size = 'medium',
  placeholder = 'Επιλογή Ημερομηνίας',
}) => {
  dayjs.locale('el');

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
      <DatePicker
        disabled={disabled}
        selectedDate={value}
        value={value}
        onChange={onChange}
        label={placeholder}
        format={format}
        className={className}
        disableFuture={disableFuture}
        shouldDisableDate={(day) => {
          if (disabledDates) {
            return disabledDates.some((date) => dayjs(date).isSame(day, 'day'));
          }
          return false;
        }}
        slotProps={{
          textField: {
            size: size,
          },
          actionBar: {
            actions: ['clear'],
          },
        }}
      />
      {error && (
        <div
          style={{
            color: '#D32F2F',
            fontSize: '10px',
            marginTop: '6px',
            marginLeft: '17px',
          }}
        >
          {helperText}
        </div>
      )}
    </LocalizationProvider>
  );
};

export default ShowOnlyDatePicker;
