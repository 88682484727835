import { Box, Grid } from '@mui/material';
import { gridSpacing } from '../../../Config/constant';
import UsersTable from '../UsersTable/UsersTable';
import Topbar from '../../../Components/Topbar/Topbar';
import { ORG_NAME } from '../../../Config/constant';
import { useNavigate } from 'react-router-dom';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';

const UsersList = () => {
  const navigate = useNavigate();
  return (
    <>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      {
        <Grid
          className="pt-16"
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={gridSpacing}
        >
          <Grid item />
          <Grid item className="flex"></Grid>
        </Grid>
      }
      <UsersTable />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: 'white',
              '&:hover': {
                backgroundColor: '#003375',
                color: 'white',
                opacity: 0.9,
              },
              textTransform: 'none',
            }}
            className="capitalize text-sm"
            title={'Προσθήκη Νέου'}
            onClick={() => navigate('/user-create')}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </Box>
    </>
  );
};

export default UsersList;
