import { Navigate, Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import DashBoard from '../../Pages/Authenticated/DashBoard/DashBoard';
import NoAccessCard from '../../Components/NoAccessCard/NoAccessCard';
import ProfileTabs from '../../Pages/Authenticated/ProfileTabs/ProfileTabs';
import UserCreate from '../../Pages/Authenticated/UserCreate/UserCreate';
import UsersEdit from '../../Pages/Authenticated/UsersEdit/UsersEdit';
import UsersList from '../../Pages/Authenticated/UsersList/UsersList';
import CreateSchedule from '../../Pages/Authenticated/Schedule/CreateSchedule';
import Rantevou from '../../Pages/Authenticated/Rantevou/Rantevou';
import ActiveSchedules from '../../Pages/Authenticated/Schedule/ActiveSchedulesSelection';
import RantevouViewEdit from '../../Pages/Authenticated/RantevouViewEdit/RantevouViewEdit';
import RantevouSearch from '../../Pages/Authenticated/RantevouSearch/RantevouSearch';
import ScheduleHistory from '../../Pages/Authenticated/ScheduleHistory/ScheduleHistory';
import ScheduleView from '../../Pages/Authenticated/ScheduleHistory/ScheduleView';
import CreateRantevouService from '../../Pages/Authenticated/RantevouService/CreateRantevouService';
import RantevouServiceViewEdit from '../../Pages/Authenticated/RantevouService/RantevouServiceViewEdit';
import RantevouService from '../../Pages/Authenticated/RantevouService/RantevouService';
import Certificates from '../../Pages/Authenticated/Certificates/Certificates';
import CreateCertificateForm from '../../Pages/Authenticated/CreateCertificateForm/CreateCertificateForm';
import CertificateViewEdit from '../../Pages/Authenticated/CertificateViewEdit/CertificateViewEdit';
import CertificatesSearch from '../../Pages/Authenticated/CertificatesSearch/CertificatesSearch';
import RantevouOverview from '../../Pages/Authenticated/RantevouOverview/RantevouOverview';
import CertificateSubmissionView from '../../Pages/Authenticated/CertificatesSubmissions/CertificateSubmissionView';
import CertificateSubmissionEdit from '../../Pages/Authenticated/CertificatesSubmissions/CertificateSubmissionEdit';
import CertificatesOverview from '../../Pages/Authenticated/CertificatesOverview/CertificatesOverview';
import Notifications from '../../Pages/Authenticated/Notifications/Notifications';
import TicketsOverview from '../../Pages/Authenticated/TicketsOverview/TicketsOverview';
import TicketsSearch from '../../Pages/Authenticated/TicketsSearch/TicketsSearch';
import TicketDetail from '../../Pages/Authenticated/TicketDetail/TicketDetail';
import HomePage from '../../Pages/Authenticated/HomePage/HomePage';
import { useAuthStore } from '../../Stores/AuthStore';
import Sectors from '../../Pages/Authenticated/Sectors/Sectors';
import Departments from '../../Pages/Authenticated/Departments/Departments';
import SubOrganizations from '../../Pages/Authenticated/SubOrganizations/SubOrganizations';
import ApplicationCategories from '../../Pages/Authenticated/ApplicationCategories/ApplicationCategories';
import OrganizationChart from '../../Pages/Authenticated/OrganizationChart/OrganizationChart';
import SubDepartments from '../../Pages/Authenticated/SubDepartments/SubDepartments';
// import CertificateSubmissionApproval from '../../Pages/CertificateSubmissionApproval/CertificateSubmissionApproval';
// import CertificateSubmissionSign from '../../Pages/Authenticated/CertificateSubmissionSign/CertificateSubmissionSign';
import ImpersonationAccess from '../../Pages/Authenticated/ImpersonationAccess/ImpersonationAccess';
import OrganizationDefinition from '../../Pages/Authenticated/Organization/OrganizationDefinition';
import SignedCertificateUpload from '../../Pages/Authenticated/SignedCertificateUpload/SignedCertificateUpload';
import Organizations from '../../Pages/Authenticated/Organizations/Organizations';
import Users from '../../Pages/Authenticated/UsersSuperAdmin/Users';
import AnnouncementsOverview from '../../Pages/Authenticated/AnnouncementsOverview/AnnouncementsOverview';
import AnnouncementsViewEdit from '../../Pages/Authenticated/AnnouncementsViewEdit/AnnouncementsViewEdit';
import AnnouncementsCreate from '../../Pages/Authenticated/AnnouncementsCreate/AnnouncementsCreate';
import Statistics from '../../Pages/Authenticated/Statistics/Statistics';
import EventsOverview from '../../Pages/Authenticated/EventsOverview/EventsOverview';
import EventsViewEdit from '../../Pages/Authenticated/EventsViewEdit/EventsViewEdit';
import EventsCreate from '../../Pages/Authenticated/EventsCreate/EventsCreate';
import PoisOverview from '../../Pages/Authenticated/PoisOverview/PoisOverview';
import PoisViewEdit from '../../Pages/Authenticated/PoisViewEdit/PoisViewEdit';
import PoisCreate from '../../Pages/Authenticated/PoisCreate/PoisCreate';
import PoiCategoriesOverview from '../../Pages/Authenticated/PoiCategoriesOverview/PoiCategoriesOverview';
import PoiCategoriesCreate from '../../Pages/Authenticated/PoiCategoriesCreate/PoiCategoriesCreate';
import PoiCategoriesViewEdit from '../../Pages/Authenticated/PoiCategoriesViewEdit/PoiCategoriesViewEdit';
import AnnouncementCategoriesOverview from '../../Pages/Authenticated/AnnouncementCategoriesOverview/AnnouncementCategoriesOverview';
import AnnouncementCategoriesCreate from '../../Pages/Authenticated/AnnouncementCategoriesCreate/AnnouncementCategoriesCreate';
import AnnouncementCategoriesViewEdit from '../../Pages/Authenticated/AnnouncementCategoriesViewEdit/AnnouncementCategoriesViewEdit';
import TicketCreate from '../../Pages/Authenticated/TicketCreate/TicketCreate';

const AuthenticatedRoutes = () => {
  const {
    isAdmin,
    isSuperAdmin,
    isUser,
    isModerator,
    isOrganizationSupervisor,
    isSubOrganizationSupervisor,
    isSectorSupervisor,
    authUserIsNotLoaded,
    isContentManager,
  } = useAuthStore();

  const defaultRoutes = (
    <>
      <Route path="/noAccessCard" element={<NoAccessCard />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </>
  );

  const getRoleBasedRoutes = () => {
    if (
      isOrganizationSupervisor ||
      isSubOrganizationSupervisor ||
      isSectorSupervisor
    ) {
      return (
        <>
          <Route path="/" element={<Statistics />} />
          <Route path="/profile" element={<ProfileTabs />} />
          {defaultRoutes}
        </>
      );
    }

    if (isSuperAdmin) {
      return (
        <>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/" element={<Organizations />} />
          <Route path="/profile" element={<ProfileTabs />} />
          <Route path="/org-definition" element={<OrganizationDefinition />} />
          {/* <Route path="/org-list" element={<Organizations />} /> */}
          <Route path="/org-chart" element={<OrganizationChart />} />
          <Route path="/org-general" element={<SubOrganizations />} />
          <Route path="/org-sectors" element={<Sectors />} />
          <Route path="/org-departments" element={<Departments />} />
          <Route path="/org-subdepartments" element={<SubDepartments />} />
          <Route
            path="/application-categories"
            element={<ApplicationCategories />}
          />
          <Route path="/users-list" element={<Users />} />
          <Route path="/access" element={<ImpersonationAccess />} />
          <Route path="/announcements" element={<AnnouncementsOverview />} />
          <Route
            path="/announcements/:id"
            element={<AnnouncementsViewEdit />}
          />
          <Route
            path="/announcement-create"
            element={<AnnouncementsCreate />}
          />
          <Route
            path="/announcement-categories"
            element={<AnnouncementCategoriesOverview />}
          />
          <Route
            path="/announcement-category-create"
            element={<AnnouncementCategoriesCreate />}
          />
          <Route
            path="/announcement-categories/:id"
            element={<AnnouncementCategoriesViewEdit />}
          />
          <Route path="/events" element={<EventsOverview />} />
          <Route path="/events/:id" element={<EventsViewEdit />} />
          <Route path="/event-create" element={<EventsCreate />} />
          <Route path="/pois" element={<PoisOverview />} />
          <Route path="/pois/:id" element={<PoisViewEdit />} />
          <Route path="/poi-create" element={<PoisCreate />} />
          <Route path="/poi-categories" element={<PoiCategoriesOverview />} />
          <Route
            path="/poi-category-create"
            element={<PoiCategoriesCreate />}
          />
          <Route
            path="/poi-categories/:id"
            element={<PoiCategoriesViewEdit />}
          />
          {defaultRoutes}
        </>
      );
    }

    if (isAdmin) {
      return (
        <>
          <Route path="/" element={<Notifications />} />
          <Route path="/profile" element={<ProfileTabs />} />
          <Route path="/users" element={<UsersList />} />
          <Route path="/user-create" element={<UserCreate />} />
          <Route path="/user/:id/edit" element={<UsersEdit />} />
          <Route path="/active-schedules" element={<ActiveSchedules />} />
          <Route
            path="/active-schedules/:id"
            element={<ScheduleView active={true} />}
          />
          <Route path="/new-schedule" element={<CreateSchedule />} />
          <Route
            path="/new-rantevou-service"
            element={<CreateRantevouService />}
          />
          <Route
            path="/rantevou-service/:id"
            element={<RantevouServiceViewEdit />}
          />
          <Route path="/rantevou-service" element={<RantevouService />} />
          <Route path="/rantevou" element={<Rantevou />} />
          <Route path="/rantevou/:id" element={<RantevouViewEdit />} />
          <Route path="/rantevou-search" element={<RantevouSearch />} />
          <Route path="/rantevou-overview" element={<RantevouOverview />} />
          <Route path="/schedule-history" element={<ScheduleHistory />} />
          <Route path="/schedule-history/:id" element={<ScheduleView />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route
            path="/certificates-create"
            element={<CreateCertificateForm />}
          />
          <Route
            path="/certificates-view/:certificateId"
            element={<CertificateViewEdit />}
          />
          <Route
            path="/certificates-submissions-view/:id"
            element={<CertificateSubmissionView />}
          />
          <Route
            path="/certificates-submissions-edit/:id"
            element={<CertificateSubmissionEdit />}
          />
          {/* <Route
            path="/certificates-submissions-approval/:id"
            element={<CertificateSubmissionApproval />}
          />{' '} */}
          <Route
            path="/signed-certificate-upload/:id"
            element={<SignedCertificateUpload />}
          />{' '}
          {/* <Route
            path="/certificates-submissions-sign/:id"
            element={<CertificateSubmissionSign />}
          /> */}
          <Route path="/certificates-search" element={<CertificatesSearch />} />
          <Route
            path="/certificates-overview"
            element={<CertificatesOverview />}
          />
          <Route path="/tickets-overview" element={<TicketsOverview />} />
          <Route path="/tickets-search" element={<TicketsSearch />} />
          <Route path="/ticket/:ticketId" element={<TicketDetail />} />
          <Route path="/dashboard" element={<DashBoard />} />
          {defaultRoutes}
        </>
      );
    }

    if (isUser) {
      return (
        <>
          <Route path="/" element={<Notifications />} />
          <Route path="/profile" element={<ProfileTabs />} />
          <Route path="/active-schedules" element={<ActiveSchedules />} />
          <Route
            path="/active-schedules/:id"
            element={<ScheduleView active={true} />}
          />
          <Route path="/rantevou-search" element={<RantevouSearch />} />
          <Route path="/rantevou/:id" element={<RantevouViewEdit />} />
          <Route path="/rantevou-overview" element={<RantevouOverview />} />
          <Route path="/schedule-history" element={<ScheduleHistory />} />
          <Route path="/schedule-history/:id" element={<ScheduleView />} />
          <Route
            path="/certificates-submissions-view/:id"
            element={<CertificateSubmissionView />}
          />
          <Route
            path="/certificates-submissions-edit/:id"
            element={<CertificateSubmissionEdit />}
          />
          {/* <Route
            path="/certificates-submissions-approval/:id"
            element={<CertificateSubmissionApproval />}
          /> */}
          {/* <Route
            path="/certificates-submissions-sign/:id"
            element={<CertificateSubmissionSign />}
          /> */}
          <Route
            path="/signed-certificate-upload/:id"
            element={<SignedCertificateUpload />}
          />{' '}
          <Route path="/certificates-search" element={<CertificatesSearch />} />
          <Route
            path="/certificates-overview"
            element={<CertificatesOverview />}
          />
          <Route path="/tickets-overview" element={<TicketsOverview />} />
          <Route path="/tickets-search" element={<TicketsSearch />} />
          <Route path="/ticket-create" element={<TicketCreate />} />
          <Route path="/ticket/:ticketId" element={<TicketDetail />} />
          <Route path="/dashboard" element={<DashBoard />} />
          {defaultRoutes}
        </>
      );
    }

    if (isModerator) {
      return (
        <>
          <Route path="/" element={<TicketsOverview />} />
          <Route path="/profile" element={<ProfileTabs />} />
          <Route path="/tickets-search" element={<TicketsSearch />} />
          <Route path="/ticket/:ticketId" element={<TicketDetail />} />
          {defaultRoutes}
        </>
      );
    }

    if (isContentManager) {
      return (
        <>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<ProfileTabs />} />
          <Route path="/announcements" element={<AnnouncementsOverview />} />
          <Route
            path="/announcements/:id"
            element={<AnnouncementsViewEdit />}
          />
          <Route
            path="/announcement-create"
            element={<AnnouncementsCreate />}
          />
          <Route path="/events" element={<EventsOverview />} />
          <Route path="/events/:id" element={<EventsViewEdit />} />
          <Route path="/event-create" element={<EventsCreate />} />
          <Route path="/pois" element={<PoisOverview />} />
          <Route path="/pois/:id" element={<PoisViewEdit />} />
          <Route path="/poi-create" element={<PoisCreate />} />
        </>
      );
    }

    return defaultRoutes;
  };

  return <Routes>{!authUserIsNotLoaded && getRoleBasedRoutes()}</Routes>;
};

export default observer(AuthenticatedRoutes);
