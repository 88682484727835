import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import TicketsSearchTable from '../TicketsSearch/TicketsSearchTable';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MyDatePicker from '../../../Components/MyDatePicker/MyDatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { TICKET_FORM_IDS } from '../../../Config/constant';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { width } from '../../../styles/variables/width';
import { formatDateInDateMonthYearHour } from '../../../Common/formatDate/formatDate';

const ticketResultsSchema = yup.object().shape({
  [TICKET_FORM_IDS.Id]: yup.string().notRequired(),
  [TICKET_FORM_IDS.SubmissionDate]: yup.date().notRequired(),
  [TICKET_FORM_IDS.CompletionDate]: yup.date().notRequired(),
  [TICKET_FORM_IDS.CategoryId]: yup.string().notRequired(),
  [TICKET_FORM_IDS.ServiceId]: yup.string().notRequired(),
  [TICKET_FORM_IDS.Status]: yup.string().notRequired(),
  [TICKET_FORM_IDS.Department]: yup.string().notRequired(),
  [TICKET_FORM_IDS.Priority]: yup.string().notRequired(),
  [TICKET_FORM_IDS.Afm]: yup
    .string()
    .trim()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)) // allow empty string
    .min(9)
    .max(9)
    .notRequired(),
});

const defaultValues = {
  [TICKET_FORM_IDS.Id]: null,
  [TICKET_FORM_IDS.SubmissionDate]: null,
  [TICKET_FORM_IDS.CompletionDate]: null,
  [TICKET_FORM_IDS.CategoryId]: null,
  [TICKET_FORM_IDS.ServiceId]: null,
  [TICKET_FORM_IDS.Status]: null,
  [TICKET_FORM_IDS.Department]: null,
  [TICKET_FORM_IDS.Priority]: null,
  [TICKET_FORM_IDS.Afm]: null,
};

const getServiceOptionsMap = (options) => {
  if (!options) {
    return {};
  }

  let output = {};
  options?.ticketCategories?.forEach((option) => {
    output[option?.id] = option?.ticketServices?.length
      ? option.ticketServices
      : null;
  });
  return output;
};

const TicketsSearch = () => {
  const [searchResults, setSearchResults] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const tableRef = useRef(null);
  const [options, setOptions] = useState(null);
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues,
    resolver: yupResolver(ticketResultsSchema),
  });
  const categoryIdValue = watch(TICKET_FORM_IDS.CategoryId);

  const serviceOptions = useMemo(
    () => getServiceOptionsMap(options)[categoryIdValue] || [],
    [categoryIdValue, options]
  );

  const scrollPageToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSubmit = useCallback(async () => {
    const body = watch();
    const formattedBody = {
      ...body,
      [TICKET_FORM_IDS.SubmissionDate]: formatDateInDateMonthYearHour(
        body[TICKET_FORM_IDS.SubmissionDate]
      )?.slice(0, 10),
      [TICKET_FORM_IDS.CompletionDate]: formatDateInDateMonthYearHour(
        body[TICKET_FORM_IDS.CompletionDate]
      )?.slice(0, 10),
    };

    setIsSearching(true);

    await AuthSetAgent.tickets
      .searchTickets(formattedBody)
      .then((response) => {
        setSearchResults(response?.result?.items);
      })
      .finally(() => {
        setIsSearching(false);
        setTimeout(() => {
          scrollPageToTable();
        }, 100);
      });
  }, [watch]);

  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);

      await AuthSetAgent.tickets
        .getSearchLists()
        .then((response) => {
          response?.result && setOptions(response.result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    getOptions();
  }, []);

  return (
    <div>
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <div className="ml-4">
        <Typography
          className="py-5"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Αναζήτηση Αιτήματος
        </Typography>
        <section className="pb-10 mt-12">
          <Typography
            variant="h4"
            fontWeight={'bold'}
            gutterBottom
            className="pb-5"
          >
            Φίλτρα
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-3 gap-3">
              {/* Id */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.Id}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="bg-[#FFFF]"
                      fullWidth
                      label={'Κωδικός Αιτήματος'}
                      onChange={onChange}
                      error={!!error}
                      helperText={error && 'Error text'}
                      type="text"
                    />
                  )}
                />
              </div>

              {/* Submission Date */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.SubmissionDate}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MyDatePicker
                      value={value}
                      onChange={onChange}
                      placeholder="Ημ/νία Υποβολής"
                    />
                  )}
                />
              </div>

              {/* Completion Date */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.CompletionDate}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <MyDatePicker
                      value={value}
                      onChange={onChange}
                      placeholder="Ημ/νία Ολοκλήρωσης"
                    />
                  )}
                />
              </div>

              {/* Category */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.CategoryId}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={options?.ticketCategories || []}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        setValue(
                          TICKET_FORM_IDS.CategoryId,
                          newValue?.id?.toString()
                        );

                        if (
                          watch(TICKET_FORM_IDS.CategoryId?.toString()) ===
                          newValue?.id?.toString()
                        ) {
                          setValue(TICKET_FORM_IDS.ServiceId, null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          fullWidth
                          label={'Κατηγορία'}
                        />
                      )}
                    />
                  )}
                />
              </div>

              {/* Service */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.ServiceId}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      disabled={!serviceOptions?.length}
                      options={serviceOptions}
                      getOptionLabel={(option) => option.title}
                      onChange={(event, newValue) => {
                        setValue(TICKET_FORM_IDS.ServiceId, newValue?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          fullWidth
                          label={'Υπηρεσία/Πρόβλημα'}
                        />
                      )}
                      inputValue={
                        serviceOptions?.find(
                          (option) =>
                            option?.id === watch(TICKET_FORM_IDS.ServiceId)
                        )?.title || ''
                      }
                    />
                  )}
                />
              </div>

              {/* Status */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.Status}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={options?.statuses || []}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, newValue) => {
                        setValue(TICKET_FORM_IDS.Status, newValue?.value);
                      }}
                      // sx={{ width: 3 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          fullWidth
                          label={'Κατάσταση'}
                        />
                      )}
                    />
                  )}
                />
              </div>

              {/* Department */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.Department}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={options?.departments || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setValue(TICKET_FORM_IDS.Department, newValue?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          fullWidth
                          label={'Επιλογή Τμήματος'}
                        />
                      )}
                    />
                  )}
                />
              </div>

              {/* Priority */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.Priority}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={options?.priorities || []}
                      getOptionLabel={(option) => option.description}
                      onChange={(event, newValue) => {
                        setValue(TICKET_FORM_IDS.Priority, newValue?.value);
                      }}
                      // sx={{ width: 3 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          fullWidth
                          label={'Προτεραιότητα'}
                        />
                      )}
                    />
                  )}
                />
              </div>

              {/* Afm */}
              <div className="cols-4">
                <Controller
                  name={TICKET_FORM_IDS.Afm}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      className="bg-[#FFFF]"
                      fullWidth
                      onChange={onChange}
                      label={'ΑΦΜ Πολίτη'}
                      error={!!error}
                      helperText={
                        error && 'Το ΑΦΜ πρέπει να είναι 9-ψήφιος αριθμός'
                      }
                      type="number"
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex justify-end mt-5">
              <AnimateButton>
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    textTransform: 'none',
                    width: width.button.md,
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  loading={isSearching}
                  variant="contained"
                  type="submit"
                  title="Εύρεση"
                />
              </AnimateButton>
            </div>
          </form>
        </section>
        <Box className="border-b-2 w-1/2 mb-10" />
        <section ref={tableRef} className="pb-10">
          <TicketsSearchTable
            label="Αποτελέσματα Αναζήτησης"
            tickets={searchResults}
          />
        </section>
      </div>
    </div>
  );
};

export default TicketsSearch;
