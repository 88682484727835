import { TICKET_STATUS } from '../../Config/constant';

export const canAssignToMe = (ticket) => {
  return ticket?.status === TICKET_STATUS.NEW.id;
};

export const canComplete = (ticket) => {
  return ticket?.status === TICKET_STATUS.IN_PROGRESS.id;
};

export const canViewEmployeeActions = ({ ticket, user, isSuperAdmin }) => {
  if (isSuperAdmin) {
    return true;
  }

  if (
    ticket?.status === TICKET_STATUS.REJECTED.id ||
    ticket?.status === TICKET_STATUS.COMPLETED.id
  ) {
    return false;
  }

  if (!ticket?.employeeUserId && canAssignToMe(ticket)) {
    return true;
  }

  const isStatusEditable =
    ticket?.status !== TICKET_STATUS.REJECTED.id ||
    ticket?.status !== TICKET_STATUS.COMPLETED.id;

  const isMine = ticket?.employeeUserId === user?.id;

  return isStatusEditable && isMine;
};
