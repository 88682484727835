import { Box, Modal, Typography } from '@mui/material';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import CancelIcon from '@mui/icons-material/Cancel';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const ApplicationCategoriesDeleteModal = ({
  handleClose,
  item,
  refreshList,
}) => {
  const [actionLoading, setActionLoading] = useState(false);

  const submitDelete = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    await AuthSetAgent.applicationCategory
      .deleteApplicationCategory(item?.id)
      .then(async (resp) => {
        if (resp?.data.success === true) {
          success('Η Κατηγορία Υπηρεσίας διαγράφτηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά');
        }
        handleClose();
        await refreshList();
      })
      .catch(() => {
        error('Παρουσιάστηκε σφάλμα κατά την διαγραφή Κατηγορίας Υπηρεσίας!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <Modal
      className="flex items-center justify-center"
      open={true}
      onClose={handleClose}
      aria-labelledby="slot-details-modal"
    >
      <MainCard className="m-4 max-w-[30vw]">
        <Box className="flex items-center justify-between mb-10">
          <Typography variant="h4" fontWeight="bold">
            Διαγραφή Κατηγορίας Υπηρεσίας
          </Typography>
          <CancelIcon
            className="modal-close-icon cursor-pointer"
            onClick={handleClose}
            fontSize={'large'}
          />
        </Box>
        <Box>
          <Typography fontSize={16}>
            Είστε βέβαιοι ότι θέλετε να διαγράψετε τη
            <strong className="ml-1">
              {item?.title || 'Κατηγορία Υπηρεσίας'}
            </strong>
            ;
          </Typography>
          <Box className="flex justify-end mt-5">
            <AnimateButton>
              <SubmitButton
                sx={{
                  backgroundColor: palette.error.main,
                  borderRadius: '99px',
                  marginRight: '10px',
                  width: 120,
                }}
                className="capitalize text-sm"
                title={'Διαγραφή'}
                onClick={submitDelete}
                variant="contained"
                type="submit"
              />
            </AnimateButton>
          </Box>
        </Box>
      </MainCard>
    </Modal>
  );
};

export default ApplicationCategoriesDeleteModal;
