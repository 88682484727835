import {
  Box,
  CircularProgress,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  // DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { FORM_STATUS } from './Sectors';
import { palette } from '../../../styles/variables/colors';
import DataTable from '../../../Components/DataTable/DataTable';
import { useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const SECTOR_FIELDS = {
  Id: {
    id: 'id',
  },
  Name: {
    id: 'name',
  },
  RegionUnit: { id: 'regionUnitName' },
  MunicipalityUnit: { id: 'municipalityUnitName' },
  Organization: {
    id: 'organizationName',
  },
  Suborganization: {
    id: 'subOrganizationName',
  },
  IsActive: {
    id: 'isActive',
  },
  Actions: {
    id: 'actions',
  },
};

const loader = (
  <Box className="flex justify-center items-center pl-8">
    <CircularProgress size={20} />
  </Box>
);

const ActionButtons = ({
  className,
  item,
  formStatus,
  focusedItem,
  handleAction,
  handleDelete,
  refreshList,
}) => {
  const isFocused = focusedItem?.id === item?.id;

  const [isChecked, setIsChecked] = useState(item?.isActive || false);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    const sectorId = item?.id;
    setLoading(true);

    if (newValue) {
      AuthSetAgent.sectors
        .activateSector(sectorId)
        .then((resp) => {
          handleAction({
            item: { ...item, isActive: true },
            formStatus: FORM_STATUS.HIDDEN,
          });
          if (resp?.data.success === true) {
            success('Η Διεύθυνση ενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          setLoading(false);
          refreshList();
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      AuthSetAgent.sectors
        .deactivateSector(sectorId)
        .then((resp) => {
          handleAction({
            item: { ...item, isActive: false },
            formStatus: FORM_STATUS.HIDDEN,
          });
          if (resp?.data.success === true) {
            success('Η Διεύθυνση απενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          setLoading(false);
          refreshList();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? (
        loader
      ) : (
        <>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.EDIT });
            }}
            placement="top"
            title={'Επεξεργασία'}
          >
            <EditOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.EDIT
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.VIEW });
            }}
            placement="top"
            title={'Προβολή'}
          >
            <VisibilityOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                marginLeft: '0.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.VIEW
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={'Αλλαγή Κατάστασης'}>
            <Switch
              checked={isChecked}
              onChange={handleChange}
              sx={{
                cursor: 'pointer',
                marginLeft: '0.4rem',
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: palette.info.main,
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: palette.info.main,
                },
              }}
            />
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

const SectorsTable = ({
  focusedItem,
  formStatus,
  isLoading,
  handleAction,
  refreshList,
  // handleDelete,
  sectors,
}) => {
  const columns = [
    {
      field: SECTOR_FIELDS?.Id?.id,
      headerName: 'Κωδικός',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => (
        <Typography
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-5"
        >
          {params?.row?.[SECTOR_FIELDS?.Id?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.Name?.id,
      headerName: 'Ονομασία',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.Name?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.Organization?.id,
      headerName: 'Σχετιζόμενοι Οργανισμοί',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.Organization?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.Suborganization?.id,
      headerName: 'Σχετιζόμενοι Υποοργανισμοί',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.Suborganization?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.RegionUnit?.id,
      headerName: 'Σχετιζόμενη Περιφερειακή Ενότητα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.RegionUnit?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.MunicipalityUnit?.id,
      headerName: 'Σχετιζόμενη Δημοτική Ενότητα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.MunicipalityUnit?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.IsActive?.id,
      headerName: 'Ενεργή',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[SECTOR_FIELDS?.IsActive?.id] ? 'Ναι' : 'Όχι' || '-'}
        </Typography>
      ),
    },
    {
      field: SECTOR_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 124,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          formStatus={formStatus}
          item={params?.row}
          handleAction={handleAction}
          // handleDelete={handleDelete}
          refreshList={refreshList}
          className="ml-1"
        />
      ),
    },
  ];

  return (
    <>
      <>
        {!!sectors?.length && (
          <DataTable
            columns={columns}
            rows={sectors || []}
            loading={isLoading}
          />
        )}
      </>
      {!sectors?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default SectorsTable;
