import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import Accordions from '../../../Components/Accordion/Accordion';
import {
  Box,
  Button,
  CircularProgress,
  // TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import parse from 'html-react-parser';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
// import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { success } from '../../../Common/Toast/Toast';
import FileUploader from '../../../Components/FileUploader/FileUploader';
import { statusTranslations } from '../../../Config/constant';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { width } from '../../../styles/variables/width';

const CertificateSubmissionView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [
    certificateSubmissionAttachments,
    setCertificateSubmissionAttachments,
  ] = useState([]);
  const departmentStore = useDepartmentStore();
  const certificatesStore = useCertificatesStore();
  const { id } = useParams();

  const renderComponentsText = (components) => {
    return components.map((component, index) => {
      if (component.type === 'text' && component.text) {
        return <p key={index}>{parse(component.text)}</p>;
      }
      return null;
    });
  };

  const claimCertificateSubmission = (certSubId) => {
    setLoading(true);

    certificatesStore
      .claimCertificateSubmission(certSubId)
      .then((resp) => {
        navigate(`/certificates-submissions-edit/${certSubId}`);
        success('Αναλάβατε την αίτηση επιτυχώς');
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [certificatesStore.certificatesSubmissionStatusesList?.length]);

  useEffect(() => {
    setLoading(true);
    certificatesStore
      .getCertificatesSubmissionsById(id)
      .then((resp) => {})
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, [
    certificatesStore.certificateSubmissionsByIdList?.length,
    id,
    certificatesStore,
  ]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  useEffect(() => {
    if (certificatesStore.certificateSubmissionsByIdList?.id) {
      setLoading(true);
      AuthSetAgent.certificatesSubmissions
        .getCertificateSubmissionAttachments(
          certificatesStore.certificateSubmissionsByIdList?.id
        )
        .then((resp) => {
          setCertificateSubmissionAttachments(resp?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [certificatesStore.certificateSubmissionsByIdList?.id]);

  return (
    <>
      {loading ? (
        <div className="text-center py-10">
          <CircularProgress color="primary" size="3rem" />
        </div>
      ) : (
        <>
          <div>
            <Topbar title="Ηλεκτρονικές Υπηρεσίες ΠΔΕ" />
          </div>
          <div className="flex justify-start items-start pl-2">
            <Typography
              className="pt-4 pr-4"
              variant="h3"
              color={'#003375'}
              gutterBottom
            >
              {certificatesStore.certificateSubmissionsByIdList?.certificate
                ?.name
                ? certificatesStore.certificateSubmissionsByIdList?.certificate
                    ?.name
                : '-'}{' '}
              - Προβολή αιτήματος
            </Typography>
          </div>
          <div className="flex justify-start items-start pl-2 pt-8">
            <div className="flex flex-col">
              <div className="rounded-xl bg-[#BFE5EF] w-52 h-[38px]">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Αίτησης:{' '}
                  {certificatesStore.certificateSubmissionsByIdList?.id
                    ? certificatesStore.certificateSubmissionsByIdList?.id
                    : '-'}
                  <div />
                </div>
              </div>

              {/* <div className="rounded-xl bg-[#BFE5EF] w-52 h-[38px] mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList?.protocolNo
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.protocolNo
                    : '-'}
                  <div />
                </div>
              </div> */}

              {/* <div className="rounded-xl bg-[#BFE5EF] w-52 mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.submissionsProtocol
                    ?.value
                    ? certificatesStore?.certificateSubmissionsByIdList
                        ?.submissionInfo?.formSubmittedValues
                        ?.submissionsProtocol?.value
                    : '-'}
                </div>
              </div> */}
              <div className="rounded-xl bg-[#BFE5EF] w-52 mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.submissionsProtocol
                    ?.value
                    ? certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues?.submissionsProtocol?.value.slice(
                        0,
                        3
                      )
                    : '-'}
                </div>
              </div>
            </div>

            <div className="rounded-xl bg-[#0965D4] w-48 h-[80px] ml-4">
              <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                Κατάσταση: <br />
                {statusTranslations[
                  certificatesStore.certificateSubmissionsByIdList?.status
                ] || '-'}
                <div />
              </div>
            </div>

            <div className="rounded-xl bg-[#56A22E] w-48 h-[80px] ml-6 p-0">
              <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                <div className="flex justify-center items-center">
                  <div>Στάδιο ελέγχου:</div>
                </div>
                <div>
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.activeTaskId
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.activeTaskId
                    : '---'}
                </div>
              </div>
            </div>
          </div>
          <Accordions
            className="mt-8"
            text={`Οδηγίες αίτησης`}
            icon={<InfoOutlinedIcon className="mr-2" fontSize="large" />}
          >
            <div className="py-4 px-2 border-[1px] border-gray-400">
              <Typography variant="h4" className="text-[20px]">
                Η αίτηση επέχει θέση υπεύθυνης δήλωσης του άρθρου 8 Ν.1599/1986.
                Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή τη δήλωση
                μπορεί να ελεγχθεί με βάση το αρχείο άλλων υπηρεσιών.
              </Typography>

              <Typography variant="h4" className="pt-4">
                Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις (1), που
                προβλέπεται από τις διατάξεις της παρ.6 του άρθρου 22 του
                Ν.1599/1986, δηλώνει ότι:
              </Typography>

              <Typography variant="h4" className="pt-4">
                (1) Όποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείτε ή
                αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8
                τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος
                αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε
                άλλον περιουσιακό όφελος τρίτον ή σκόπευε να βλάψει άλλον,
                τιμωρείται με κάθειρξη μέχρι 10 ετών.
              </Typography>
            </div>
          </Accordions>
          {certificatesStore.certificateSubmissionsByIdList?.certificate?.formComponents?.map(
            (certificateSubmissions, index) => (
              <div key={index} className="flex justify-start items-start">
                <Accordions
                  key={index}
                  className="mt-8"
                  text={certificateSubmissions.name}
                >
                  <Typography className="pl-4 pt-2" variant="h4" gutterBottom>
                    {renderComponentsText(certificateSubmissions.components)}
                  </Typography>
                  <div key={index}>
                    <div className="mt-6 grid grid-cols-3 gap-4">
                      {certificateSubmissions.components
                        ?.filter((component) => component.type !== 'text')
                        .map((component, componentIndex) => {
                          if (component.type === 'group') {
                            const groupComponents = component.components || [];

                            const groupFields = groupComponents?.filter(
                              (groupField) =>
                                groupField?.type !== 'text' &&
                                groupField?.type !== 'button'
                            );

                            return groupFields?.map(
                              (groupField, groupIndex) => {
                                const key = groupField?.key;
                                const baseKey = key?.split('_index')[0];

                                const formValueKeys = Object?.keys(
                                  certificatesStore
                                    .certificateSubmissionsByIdList
                                    ?.submissionInfo?.formSubmittedValues
                                )?.filter((valueKey) =>
                                  valueKey?.startsWith(baseKey)
                                );

                                return formValueKeys?.map(
                                  (formValueKey, formValueIndex) => {
                                    const submittedValue =
                                      certificatesStore
                                        .certificateSubmissionsByIdList
                                        ?.submissionInfo?.formSubmittedValues[
                                        formValueKey
                                      ]?.value || '';

                                    return (
                                      <div
                                        key={`${componentIndex}-${groupIndex}-${formValueIndex}`}
                                        className="flex flex-col w-full items-start justify-between"
                                        style={{
                                          minHeight: 'max-content',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight={'bold'}
                                          p={2}
                                          pb={0}
                                          m={1}
                                          mb={0}
                                        >
                                          {`${groupField?.label}  ${formValueIndex}`}
                                        </Typography>

                                        <Tooltip
                                          title={
                                            component.key
                                              ?.toString()
                                              ?.includes('file')
                                              ? 'Πατήστε για να δείτε το αρχείο'
                                              : submittedValue
                                              ? component?.type === 'select'
                                                ? component?.values?.find(
                                                    (item) =>
                                                      item?.value ===
                                                      submittedValue
                                                  )?.label
                                                : submittedValue.trim() !== ''
                                                ? submittedValue
                                                : '-'
                                              : '-'
                                          }
                                          placement="bottom"
                                        >
                                          {component.key
                                            ?.toString()
                                            ?.includes('file') ? (
                                            <Box p={2} m={1} width={'100%'}>
                                              <FileUploader
                                                certificateSubmissionId={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.id
                                                }
                                                isEditMode={true}
                                                businessKey={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    .businessKey
                                                }
                                                fieldName={component.key}
                                                formFieldValues={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.submissionInfo
                                                    ?.formSubmittedValues
                                                }
                                                certificateSubmissionAttachments={
                                                  certificateSubmissionAttachments
                                                }
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              variant="body1"
                                              border={1}
                                              borderColor="#555555"
                                              color={'#555555'}
                                              fontStyle={'italic'}
                                              p={2}
                                              m={1}
                                              mx={2}
                                              width={'100%'}
                                              height={'100%'}
                                              overflow={'auto'}
                                            >
                                              {submittedValue
                                                ? component?.type === 'select'
                                                  ? component?.values?.find(
                                                      (item) =>
                                                        item?.value ===
                                                        submittedValue
                                                    )?.label
                                                  : submittedValue.trim() !== ''
                                                  ? submittedValue
                                                  : '-'
                                                : '-'}
                                            </Typography>
                                          )}
                                        </Tooltip>
                                      </div>
                                    );
                                  }
                                );
                              }
                            );
                          } else if (component.id !== 'description') {
                            const key = component?.key;
                            const submittedValue =
                              certificatesStore.certificateSubmissionsByIdList
                                ?.submissionInfo?.formSubmittedValues[key]
                                ?.value || '';
                            if (submittedValue.trim() !== '') {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col w-full items-start justify-between"
                                  style={{
                                    minHeight: 'max-content',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'bold'}
                                    p={2}
                                    pb={0}
                                    m={1}
                                    mb={0}
                                  >
                                    {component?.label}
                                  </Typography>

                                  <Tooltip
                                    title={
                                      component.key
                                        ?.toString()
                                        ?.includes('file')
                                        ? 'Πατήστε για να δείτε το αρχείο'
                                        : certificatesStore
                                            .certificateSubmissionsByIdList
                                            ?.submissionInfo
                                            ?.formSubmittedValues[
                                            component?.key
                                          ]?.value
                                        ? component?.type === 'select'
                                          ? component?.values?.find(
                                              (item) =>
                                                item?.value ===
                                                certificatesStore
                                                  .certificateSubmissionsByIdList
                                                  ?.submissionInfo
                                                  ?.formSubmittedValues[
                                                  component?.key
                                                ]?.value
                                            )?.label
                                          : certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues[
                                              component?.key
                                            ]?.value.trim() !== ''
                                          ? certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.submissionInfo
                                              ?.formSubmittedValues[
                                              component?.key
                                            ]?.value
                                          : '-'
                                        : '-'
                                    }
                                    placement="bottom"
                                  >
                                    {component.key
                                      ?.toString()
                                      ?.includes('file') ? (
                                      <Box p={2} m={1} width={'100%'}>
                                        <FileUploader
                                          certificateSubmissionId={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.id
                                          }
                                          isEditMode={true}
                                          businessKey={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              .businessKey
                                          }
                                          fieldName={component.key}
                                          formFieldValues={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.submissionInfo
                                              ?.formSubmittedValues
                                          }
                                          certificateSubmissionAttachments={
                                            certificateSubmissionAttachments
                                          }
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        border={1}
                                        borderColor="#555555"
                                        color={'#555555'}
                                        fontStyle={'italic'}
                                        p={2}
                                        m={1}
                                        mx={2}
                                        width={'100%'}
                                        height={'100%'}
                                        overflow={'auto'}
                                      >
                                        {certificatesStore
                                          .certificateSubmissionsByIdList
                                          ?.submissionInfo?.formSubmittedValues[
                                          component?.key
                                        ]?.value
                                          ? component?.type === 'select'
                                            ? component?.values?.find(
                                                (item) =>
                                                  item?.value ===
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.submissionInfo
                                                    ?.formSubmittedValues[
                                                    component?.key
                                                  ]?.value
                                              )?.label
                                            : certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues[
                                                component?.key
                                              ]?.value.trim() !== ''
                                            ? certificatesStore
                                                .certificateSubmissionsByIdList
                                                ?.submissionInfo
                                                ?.formSubmittedValues[
                                                component?.key
                                              ]?.value
                                            : '-'
                                          : '-'}
                                      </Typography>
                                    )}
                                  </Tooltip>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }

                          // OLD VERSION
                          {
                            /* {
                             component.key?.includes('file') ? (
                               <FileUploader
                                 certificateSubmissionId={
                                   certificatesStore
                                     .certificateSubmissionsByIdList?.id
                                 }
                                 isEditMode={true}
                                 businessKey={
                                   certificatesStore
                                     .certificateSubmissionsByIdList.businessKey
                                 }
                                 fieldName={component.key}
                               />
                             ) : (
                               <TextField
                                 disabled
                                 className="w-[100%]"
                                 value={
                                   certificatesStore
                                     .certificateSubmissionsByIdList
                                     ?.submissionInfo?.formSubmittedValues[
                                     component.key
                                   ]?.value
                                 }
                               />
                             );
                           } */
                          }
                        })}
                    </div>
                  </div>
                </Accordions>
              </div>
            )
          )}
          <div className="flex justify-end items-end mb-4">
            <div className="mr-4">
              <AnimateButton>
                <Button
                  variant="contained"
                  loading={loading}
                  onClick={() => navigate(-1)}
                  type="button"
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    width: width.button.md,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                >
                  Ακύρωση
                </Button>
              </AnimateButton>
            </div>

            <AnimateButton>
              <SubmitButton
                sx={{
                  backgroundColor: '#003375',
                  color: '#f9f9f9',

                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                  },
                  fontSize: '1rem',
                }}
                onClick={() =>
                  claimCertificateSubmission(
                    certificatesStore.certificateSubmissionsByIdList?.id
                  )
                }
                variant="contained"
                loading={loading}
                type="button"
                title="Το αναλαμβάνω"
              />
            </AnimateButton>
          </div>
        </>
      )}
    </>
  );
};

export default observer(CertificateSubmissionView);
