import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { useState } from 'react';
import { palette } from '../../../styles/variables/colors';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import DepartmentsTable from './DepartmentsTable';
import DepartmentCreateEditForm from './DepartmentCreateEditForm';
import DepartmentViewForm from './DepartmentViewForm';
// import DepartmentDeleteModal from './DepartmentDeleteModal';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';

export const FORM_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  // DELETE: 'delete',
  VIEW: 'view',
  HIDDEN: 'hidden',
};

const PAGE_SIZE = 15;

const Departments = () => {
  const formSectionRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState();
  const [sectors, setSectors] = useState();
  const [organizations, setOrganizations] = useState();
  const [subOrganizations, setSubOrganizations] = useState();
  const [formStatus, setFormStatus] = useState(FORM_STATUS.HIDDEN);
  const [focusedItem, setFocusedItem] = useState();
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAction = useCallback(({ item, formStatus: status }) => {
    setFocusedItem(item);
    setFormStatus(status);
  }, []);

  // const handleDelete = useCallback(({ item }) => {
  //   setFocusedItem(item);
  //   setShowDeleteModal(true);
  // }, []);

  const refreshList = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      AuthSetAgent.department
        .getDepartments(currentPage, PAGE_SIZE)
        .then((response) => {
          const totalPages = Math.ceil(
            response.result.totalCount / response.result.pageSize
          );
          setTotalPages(totalPages);
          setDepartments(response.result.items);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.organizations
        .getOrganizations()
        .then((response) => {
          const organizations = response?.result?.items;
          setOrganizations(organizations);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.sectors
        .getSectors()
        .then((response) => {
          const sectors = response.result.items;
          setSectors(sectors);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.subOrganizations
        .getSubOrganizations()
        .then((response) => {
          const subOrganizations = response.result.items;
          setSubOrganizations(subOrganizations);
        })
        .catch((error) => {
          console.error(error);
        }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [currentPage]);

  const processedItems = useMemo(
    () =>
      departments?.map((item) => ({
        ...item,
        organizationName:
          item?.sector?.subOrganization?.organization?.name || null,
        organizationId: item?.sector?.subOrganization?.organization?.id || null,
        subOrganizationName: item?.sector?.subOrganization?.name || null,
        subOrganizationId: item?.sector?.subOrganization?.id || null,
        sectorName: item?.sector?.name || null,
        sectorId: item?.sector?.id || null,
        isActive: item?.isActive || null,
      })),
    [departments]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await Promise.all([
        AuthSetAgent.department
          .getDepartments(currentPage, PAGE_SIZE)
          .then((response) => {
            const totalPages = Math.ceil(
              response.result.totalCount / response.result.pageSize
            );
            setTotalPages(totalPages);
            setDepartments(response.result.items);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.organizations
          .getOrganizations()
          .then((response) => {
            const organizations = response?.result?.items;
            setOrganizations(organizations);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.sectors
          .getSectors()
          .then((response) => {
            const sectors = response.result.items;
            setSectors(sectors);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.subOrganizations
          .getSubOrganizations()
          .then((response) => {
            const subOrganizations = response.result.items;
            setSubOrganizations(subOrganizations);
          })
          .catch((error) => {
            console.error(error);
          }),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [currentPage]);

  useEffect(() => {
    const shouldScroll = formStatus !== FORM_STATUS.HIDDEN;

    if (shouldScroll) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }
  }, [formStatus, focusedItem?.id]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <Typography
        className="pt-6 pb-5"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Λίστα Τμημάτων
      </Typography>
      <DepartmentsTable
        focusedItem={focusedItem}
        formStatus={formStatus}
        items={processedItems}
        handleAction={handleAction}
        refreshList={refreshList}
        // handleDelete={handleDelete}
      />
      <CustomPagination
        className={'my-4 flex justify-center items-center'}
        color={'#003375'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              setFormStatus((prev) =>
                prev === FORM_STATUS.CREATE
                  ? FORM_STATUS.HIDDEN
                  : FORM_STATUS.CREATE
              );
            }}
            variant="contained"
            type="submit"
          >
            Προσθήκη Νέου
          </Button>
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <Box className="min-h-[110vh] pb-20">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Box className="ml-4">
        {isLoading ? loader : content}
        {(formStatus === FORM_STATUS.CREATE ||
          formStatus === FORM_STATUS.EDIT) && (
          <ExpandableSectionContainer ref={formSectionRef}>
            <DepartmentCreateEditForm
              isEdit={formStatus === FORM_STATUS.EDIT}
              item={focusedItem}
              handleAction={handleAction}
              refreshList={refreshList}
              sectors={sectors}
              subOrganizations={subOrganizations}
              organizations={organizations}
            />
          </ExpandableSectionContainer>
        )}
        {formStatus === FORM_STATUS.VIEW && (
          <ExpandableSectionContainer ref={formSectionRef}>
            <DepartmentViewForm
              handleAction={handleAction}
              item={focusedItem}
            />
          </ExpandableSectionContainer>
        )}

        {/* {showDeleteModal && (
          <DepartmentDeleteModal
            item={focusedItem}
            handleClose={() => setShowDeleteModal(false)}
            refreshList={refreshList}
            handleSubmit={() => {}}
          />
        )} */}
      </Box>
    </Box>
  );
};

export default Departments;
