import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { Typography } from '@mui/material';
import StatsDoughnut from '../../../Components/Statistics/StatsDoughnut';
import { useStatsStore } from '../../../Stores/StatsStore';
import StatsGroupedBarChart from '../../../Components/Statistics/StatsGroupedBarChart';
import { useEffect, useState } from 'react';
import DateFilterBar from '../../../Components/DateFilterBar/DateFilterBar';
import dayjs from 'dayjs';
import { useAuthStore } from '../../../Stores/AuthStore';

const colorsPalette = [
  '#00163b',
  '#003375',
  '#0052b4',
  '#0074f8',
  '#0098ff',
  '#00bcff',
  '#00e0ff',
  '#33f0ff',
  '#66f9ff',
  '#99ffff',
];

const getColorInCycle = (index, opacity = null) => {
  const color = colorsPalette[index % colorsPalette.length];
  return opacity ? `${color}${opacity}` : color;
};

const transformMinutesToDays = (minutes) => {
  if (minutes < 1440) {
    return (minutes / 1440).toFixed(2);
  }
  return Math.round(minutes / 1440).toFixed(2);
};

const Statistics = () => {
  const { isOrganizationSupervisor } = useAuthStore();
  const statsStore = useStatsStore();
  const [data, setData] = useState(null);
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, 'month'));
  const [dateTo, setDateTo] = useState(dayjs());

  useEffect(() => {
    isOrganizationSupervisor &&
      statsStore.fetchUnassignedTicketsByCategory(dateFrom, dateTo);
    statsStore.fetchTicketsByCategory(dateFrom, dateTo);
    statsStore.fetchCertificatesByCategory(dateFrom, dateTo);
    statsStore.fetchRandevousByCategory(dateFrom, dateTo);
    statsStore.fetchWorkloadByDepartment(dateFrom, dateTo);
    statsStore.fetchAverageTicketCompletionTimeByDepartment(dateFrom, dateTo);
    statsStore.fetchAverageTicketModeratorTimeByDepartment(dateFrom, dateTo);
    statsStore.fetchAverageTicketWorkTimeToCompletionByDepartment(
      dateFrom,
      dateTo
    );
    statsStore.fetchAverageCertificateCompletionTimeByDepartment(
      dateFrom,
      dateTo
    );
  }, [statsStore, dateFrom, dateTo]);

  useEffect(() => {
    if (
      ((isOrganizationSupervisor && statsStore.unassignedTicketsByCategory) ||
        !isOrganizationSupervisor) &&
      statsStore.ticketsByCategory &&
      statsStore.certificatesByCategory &&
      statsStore.randevousByCategory &&
      statsStore.workloadByDepartment &&
      statsStore.averageTicketCompletionTimeByDepartment &&
      statsStore.averageTicketModeratorTimeByDepartment &&
      statsStore.averageTicketWorkTimeToCompletionByDepartment &&
      statsStore.averageCertificateCompletionTimeByDepartment
    ) {
      setData({
        unassignedTicketsByCategory: isOrganizationSupervisor
          ? statsStore.unassignedTicketsByCategory?.reduce(
              (acc, item) => {
                const label = item?.ticketCategoryName;
                const count = item?.count;
                acc.labels.push(label);
                acc.datasets[0].data.push(count);
                acc.datasets[0].backgroundColor.push(
                  getColorInCycle(acc.labels.length)
                );
                acc.datasets[0].hoverBackgroundColor.push(
                  getColorInCycle(acc.labels.length, '80')
                );
                return acc;
              },
              {
                labels: [],
                datasets: [
                  {
                    label: 'Πλήθος αιτημάτων χωρίς ανάθεση',
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: [],
                    hoverOffset: 4,
                  },
                ],
              }
            )
          : null,
        ticketsByCategory: statsStore.ticketsByCategory?.reduce(
          (acc, item) => {
            const label = item?.ticketCategoryName;
            const count = item?.count;
            acc.labels.push(label);
            acc.datasets[0].data.push(count);
            acc.datasets[0].backgroundColor.push(
              getColorInCycle(acc.labels.length)
            );
            acc.datasets[0].hoverBackgroundColor.push(
              getColorInCycle(acc.labels.length, '80')
            );
            return acc;
          },
          {
            labels: [],
            datasets: [
              {
                label: 'Πλήθος αιτημάτων',
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
                hoverOffset: 4,
              },
            ],
          }
        ),
        certificatesByCategory: statsStore.certificatesByCategory?.reduce(
          (acc, item) => {
            const label = item?.certificateName;
            const count = item?.count;
            acc.labels.push(label);
            acc.datasets[0].data.push(count);
            acc.datasets[0].backgroundColor.push(
              getColorInCycle(acc.labels.length)
            );
            acc.datasets[0].hoverBackgroundColor.push(
              getColorInCycle(acc.labels.length, '80')
            );
            return acc;
          },
          {
            labels: [],
            datasets: [
              {
                label: 'Πλήθος αιτήσεων έκδοσης πιστοποιητικών',
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
                hoverOffset: 4,
              },
            ],
          }
        ),
        randevousByCategory: statsStore.randevousByCategory?.reduce(
          (acc, item) => {
            const label = item?.applicationCategoryName;
            const count = item?.count;
            acc.labels.push(label);
            acc.datasets[0].data.push(count);
            acc.datasets[0].backgroundColor.push(
              getColorInCycle(acc.labels.length)
            );
            acc.datasets[0].hoverBackgroundColor.push(
              getColorInCycle(acc.labels.length, '80')
            );
            return acc;
          },
          {
            labels: [],
            datasets: [
              {
                label: 'Πλήθος ραντεβού',
                data: [],
                backgroundColor: [],
                hoverBackgroundColor: [],
                hoverOffset: 4,
              },
            ],
          }
        ),
        workloadByDepartment: statsStore.workloadByDepartment?.reduce(
          (acc, item) => {
            if (
              item.rantevouCount === 0 &&
              item.certificateSubmissionCount === 0 &&
              item.ticketCount === 0
            ) {
              return acc;
            }
            const label = item?.departmentName;
            const rantevouCount = item?.rantevouCount;
            const certificateCount = item?.certificateSubmissionCount;
            const ticketCount = item?.ticketCount;
            acc.labels.push(label);
            acc.datasets[0].data.push(rantevouCount);
            acc.datasets[0].backgroundColor.push(getColorInCycle(0));
            acc.datasets[1].data.push(certificateCount);
            acc.datasets[1].backgroundColor.push(getColorInCycle(1));
            acc.datasets[2].data.push(ticketCount);
            acc.datasets[2].backgroundColor.push(getColorInCycle(2));
            return acc;
          },
          {
            labels: [],
            datasets: [
              {
                label: 'Ραντεβού',
                data: [],
                backgroundColor: [],
              },
              {
                label: 'Πιστοποιητικά',
                data: [],
                backgroundColor: [],
              },
              {
                label: 'Αιτήματα',
                data: [],
                backgroundColor: [],
              },
            ],
          }
        ),
        averageTicketCompletionTimeByDepartment:
          statsStore.averageTicketCompletionTimeByDepartment
            ?.filter((item) => item?.averageInMinutes > 0)
            ?.sort((a, b) => b?.averageInMinutes - a?.averageInMinutes)
            ?.reduce(
              (acc, item, index) => {
                const label = item?.departementName;
                const averageTime = transformMinutesToDays(
                  item?.averageInMinutes
                );
                acc.labels.push(label);
                acc.datasets[0].data.push(averageTime);
                acc.datasets[0].backgroundColor.push(getColorInCycle(index));
                return acc;
              },
              {
                labels: [],
                datasets: [
                  {
                    label: 'Μέσος χρόνος ολοκλήρωσης αιτήματος',
                    data: [],
                    backgroundColor: [],
                  },
                ],
              }
            ),
        averageTicketModeratorTimeByDepartment:
          statsStore.averageTicketModeratorTimeByDepartment
            ?.filter((item) => item?.averageInMinutes > 0)
            ?.sort((a, b) => b?.averageInMinutes - a?.averageInMinutes)
            ?.reduce(
              (acc, item, index) => {
                const label = item?.departementName;
                const averageTime = transformMinutesToDays(
                  item?.averageInMinutes
                );
                acc.labels.push(label);
                acc.datasets[0].data.push(averageTime);
                acc.datasets[0].backgroundColor.push(getColorInCycle(index));
                return acc;
              },
              {
                labels: [],
                datasets: [
                  {
                    label: 'Μέσος χρόνος αναμονής αιτήματος',
                    data: [],
                    backgroundColor: [],
                  },
                ],
              }
            ),
        averageWorkTimeToCompletionByDepartment:
          statsStore.averageTicketWorkTimeToCompletionByDepartment
            ?.filter((item) => item?.averageInMinutes > 0)
            ?.sort((a, b) => b?.averageInMinutes - a?.averageInMinutes)
            ?.reduce(
              (acc, item, index) => {
                const label = item?.departementName;
                const averageTime = transformMinutesToDays(
                  item?.averageInMinutes
                );
                acc.labels.push(label);
                acc.datasets[0].data.push(averageTime);
                acc.datasets[0].backgroundColor.push(getColorInCycle(index));
                return acc;
              },
              {
                labels: [],
                datasets: [
                  {
                    label: 'Μέσος χρόνος ολοκλήρωσης αιτήματος',
                    data: [],
                    backgroundColor: [],
                  },
                ],
              }
            ),
        averageCertificateCompletionTimeByDepartment:
          statsStore.averageCertificateCompletionTimeByDepartment
            ?.filter((item) => item?.averageInMinutes > 0)
            ?.sort((a, b) => b?.averageInMinutes - a?.averageInMinutes)
            ?.reduce(
              (acc, item, index) => {
                const label = item?.departementName;
                const averageTime = transformMinutesToDays(
                  item?.averageInMinutes
                );
                acc.labels.push(label);
                acc.datasets[0].data.push(averageTime);
                acc.datasets[0].backgroundColor.push(getColorInCycle(index));
                return acc;
              },
              {
                labels: [],
                datasets: [
                  {
                    label: 'Μέσος χρόνος ολοκλήρωσης πιστοποιητικού',
                    data: [],
                    backgroundColor: [],
                  },
                ],
              }
            ),
      });
    } else {
      setData(null);
    }
  }, [
    statsStore.unassignedTicketsByCategory,
    statsStore.ticketsByCategory,
    statsStore.certificatesByCategory,
    statsStore.randevousByCategory,
    statsStore.workloadByDepartment,
    statsStore.averageTicketCompletionTimeByDepartment,
    statsStore.averageTicketModeratorTimeByDepartment,
    statsStore.averageTicketWorkTimeToCompletionByDepartment,
    statsStore.averageCertificateCompletionTimeByDepartment,
  ]);

  const renderCertificateStats = () => {
    return (
      <>
        {data?.certificatesByCategory && (
          <StatsDoughnut
            data={data.certificatesByCategory}
            title="Πλήθος αιτήσεων έκδοσης πιστοποιητικών ανά πιστοποιητικό"
            total={`${data.certificatesByCategory.datasets[0].data?.reduce(
              (a, b) => a + b,
              0
            )}`}
          />
        )}

        <div className="mt-12 w-full">
          {data?.averageCertificateCompletionTimeByDepartment && (
            <StatsGroupedBarChart
              data={data.averageCertificateCompletionTimeByDepartment}
              title="Μέσος χρόνος ολοκλήρωσης πιστοποιητικού ανά τμήμα"
              horizontal
              xAxisTitle={'Ημέρες'}
              showLegend={false}
              charsPerLine={40}
              limitLabelsInOneLine
              tooltipLabelCallback={(context) => {
                const label = context.dataset.label || '';
                if (label) {
                  return `${label}: ${context.formattedValue} ημέρες`;
                }
                return '';
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderTicketStats = () => {
    return (
      <>
        {isOrganizationSupervisor && data?.unassignedTicketsByCategory && (
          <StatsDoughnut
            data={data.unassignedTicketsByCategory}
            title="Πλήθος αιτημάτων χωρίς ανάθεση ανά κατηγορία"
            total={`${data.unassignedTicketsByCategory.datasets[0].data?.reduce(
              (a, b) => a + b,
              0
            )}`}
          />
        )}

        {data?.ticketsByCategory && (
          <StatsDoughnut
            data={data.ticketsByCategory}
            title="Πλήθος αιτημάτων που ανατέθηκαν ανά κατηγορία"
            total={`${data.ticketsByCategory.datasets[0].data?.reduce(
              (a, b) => a + b,
              0
            )}`}
          />
        )}

        <div className="mt-12 w-full">
          {data?.averageTicketModeratorTimeByDepartment && (
            <StatsGroupedBarChart
              data={data.averageTicketModeratorTimeByDepartment}
              title="Μέσος χρόνος αναμονής αιτήματος ανά τμήμα"
              horizontal
              xAxisTitle={'Ημέρες'}
              showLegend={false}
              charsPerLine={40}
              limitLabelsInOneLine
              tooltipLabelCallback={(context) => {
                const label = context.dataset.label || '';
                if (label) {
                  return `${label}: ${context.formattedValue} ημέρες`;
                }
                return '';
              }}
            />
          )}
        </div>

        <div className="mt-12 w-full">
          {data?.averageTicketCompletionTimeByDepartment && (
            <StatsGroupedBarChart
              data={data.averageTicketCompletionTimeByDepartment}
              title="Μέσος συνολικός χρόνος ολοκλήρωσης αιτήματος ανά τμήμα"
              horizontal
              xAxisTitle={'Ημέρες'}
              showLegend={false}
              charsPerLine={40}
              limitLabelsInOneLine
              tooltipLabelCallback={(context) => {
                const label = context.dataset.label || '';
                if (label) {
                  return `${label}: ${context.formattedValue} ημέρες`;
                }
                return '';
              }}
            />
          )}
        </div>

        <div className="mt-12 w-full">
          {data?.averageWorkTimeToCompletionByDepartment && (
            <StatsGroupedBarChart
              data={data.averageWorkTimeToCompletionByDepartment}
              title="Μέσος χρόνος διεκπεραίωσης αιτήματος ανά τμήμα"
              horizontal
              xAxisTitle={'Ημέρες'}
              showLegend={false}
              charsPerLine={40}
              limitLabelsInOneLine
              tooltipLabelCallback={(context) => {
                const label = context.dataset.label || '';
                if (label) {
                  return `${label}: ${context.formattedValue} ημέρες`;
                }
                return '';
              }}
            />
          )}
        </div>
      </>
    );
  };

  const renderRandevousStats = () => {
    return (
      <>
        {data?.randevousByCategory && (
          <StatsDoughnut
            data={data.randevousByCategory}
            title="Πλήθος ραντεβού ανά κατηγορία"
            total={`${data.randevousByCategory.datasets[0].data?.reduce(
              (a, b) => a + b,
              0
            )}`}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>

      <div className="ml-6 mr-6 mb-15">
        <div className="mt-6 flex gap-8 flex-col items-start justify-center">
          <Typography variant="h3" color={'#003375'} gutterBottom>
            Προβολή Στατιστικών
          </Typography>

          <DateFilterBar
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
          />

          <div className="mt-6 w-full bg-[#003375] p-4 rounded-lg shadow-md">
            <Typography variant="h4" color={'#fff'}>
              Γενικά
            </Typography>
          </div>

          <div className="mt-6 w-full">
            {data?.workloadByDepartment && (
              <StatsGroupedBarChart
                data={data.workloadByDepartment}
                title="Φόρτος εργασίας ανά τμήμα και ανά είδος υπηρεσίας"
                horizontal
                xAxisTitle="Πλήθος αιτήσεων"
              />
            )}
          </div>

          <div className="mt-2 w-full min-h-max flex flex-wrap justify-center gap-8 flex-col lg:flex-row">
            <div className="mt-6 w-full bg-[#003375] p-4 rounded-lg shadow-md">
              <Typography variant="h4" color={'#fff'}>
                Πιστοποιητικά
              </Typography>
            </div>
            {renderCertificateStats()}

            <div className="mt-6 w-full bg-[#003375] p-4 rounded-lg shadow-md">
              <Typography variant="h4" color={'#fff'}>
                Αιτήματα
              </Typography>
            </div>
            {renderTicketStats()}

            <div className="mt-6 w-full bg-[#003375] p-4 rounded-lg shadow-md">
              <Typography variant="h4" color={'#fff'}>
                Ραντεβού
              </Typography>
            </div>
            {renderRandevousStats()}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Statistics);
