import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import RantevouSearchForm from './RantevouSearchForm';

const RantevouSearch = () => {
  return (
    <div className="w-full">
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>
      <div className="mt-6 ml-4">
        <Typography variant="h3" color={'#003375'} gutterBottom>
          Αναζήτηση ραντεβού
        </Typography>
      </div>
      <div className="w-full mt-12">
        <RantevouSearchForm />
      </div>
    </div>
  );
};

export default observer(RantevouSearch);
