import { action, makeObservable, observable, reaction } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import { authStore } from './AuthStore';
import { error } from '../Common/Toast/Toast';

class AnnouncementsStore {
  categories = [];

  constructor() {
    makeObservable(this, {
      categories: observable,
      getAnnouncements: action,
      getAnnouncementById: action,
      createAnnouncement: action,
      updateAnnouncement: action,
      deleteAnnouncement: action,
      uploadImage: action,
      deleteImage: action,
      downloadImage: action,
      getAnnouncementCategories: action,
      getAnnouncementCategoryById: action,
      createAnnouncementCategory: action,
      updateAnnouncementCategory: action,
      deleteAnnouncementCategory: action,
    });

    reaction(
      () => {
        if (
          authStore.userAuthenticated &&
          (authStore.isContentManager || authStore.isSuperAdmin)
        ) {
          this.getAnnouncementCategories();
        }
      },
      () => {
        console.log(this.categories);
      }
    );
  }

  getAnnouncements = (currentPage = null, pageSize = null) => {
    return AuthSetAgent.announcements
      .getAnnouncements(currentPage, pageSize)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAnnouncementById = (id) => {
    return AuthSetAgent.announcements
      .getAnnouncementById(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createAnnouncement = (announcement, config) => {
    return AuthSetAgent.announcements
      .createAnnouncement(announcement, config)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateAnnouncement = (id, announcement, config) => {
    return AuthSetAgent.announcements
      .updateAnnouncement(id, announcement, config)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteAnnouncement = (id) => {
    return AuthSetAgent.announcements
      .deleteAnnouncement(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  uploadImage = (id, image) => {
    return AuthSetAgent.announcements
      .uploadImage(id, image)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteImage = (filename) => {
    return AuthSetAgent.announcements
      .deleteImage(filename)
      .then((response) => {
        if (response?.success) {
          return response;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  downloadImage = (filename) => {
    return AuthSetAgent.announcements
      .downloadImage(filename)
      .then((response) => {
        if (!response?.data) {
          return null;
        }
        const blob = new Blob([response?.data], {
          type: response?.headers['content-type'],
        });
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAnnouncementCategories = (currentPage = null, pageSize = null) => {
    return AuthSetAgent.announcementCategories
      .getAnnouncementCategories(currentPage, pageSize)
      .then((response) => {
        this.categories = response.result.items;
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAnnouncementCategoryById = (id) => {
    return AuthSetAgent.announcementCategories
      .getAnnouncementCategoryById(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createAnnouncementCategory = (category) => {
    return AuthSetAgent.announcementCategories
      .createAnnouncementCategory(category)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateAnnouncementCategory = (id, category) => {
    return AuthSetAgent.announcementCategories
      .updateAnnouncementCategory(id, category)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteAnnouncementCategory = (id) => {
    return AuthSetAgent.announcementCategories
      .deleteAnnouncementCategory(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const announcementsStore = new AnnouncementsStore();
export const AnnouncementsStoreContext = createContext(announcementsStore);
export const AnnouncementsStoreProvider = ({ children, store }) => (
  <AnnouncementsStoreContext.Provider value={store}>
    {children}
  </AnnouncementsStoreContext.Provider>
);
export const useAnnouncementsStore = () =>
  useContext(AnnouncementsStoreContext);
