import { Box, Button, Typography } from '@mui/material';
import { FORM_STATUS } from './Organizations';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { useEffect } from 'react';

const EMPTY_STRING = '---';

const Field = ({ title, value }) => {
  return (
    <Box className="flex items-center mb-2">
      <Typography
        variant="h5"
        className="pr-5  min-w-[250px]"
        fontWeight="bold"
      >
        {`${title}:`}
      </Typography>
      <Typography variant="h5" className="pr-5">
        {value || EMPTY_STRING}
      </Typography>
    </Box>
  );
};

const OrganizationsViewForm = ({ handleAction, item }) => {
  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        Προβολή Οργανισμού
      </Typography>
      <Field title="Ονομασία" value={item?.name} />
      <Field title="Περιγραφή" value={item?.description} />
      <Field title="Διεύθυνση" value={item?.address} />
      <Field title="Τύπος" value={item?.organizationType} />
      <Field title="Εχει Ραντεβού" value={item?.hasERantevou ? 'Ναι' : 'Όχι'} />
      <Field
        title="Εχει Πιστοποιητικά"
        value={item?.hasECertificates ? 'Ναι' : 'Όχι'}
      />
      <Field title="Εχει Αιτήματα" value={item?.hasERequests ? 'Ναι' : 'Όχι'} />
      <Field title="Ενεργός" value={item?.isActive ? 'Ναι' : 'Όχι'} />
      <Box className="flex justify-end">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              handleAction({ formStatus: FORM_STATUS.HIDDEN });
            }}
            variant="contained"
            type="submit"
          >
            Κλείσιμο
          </Button>
        </AnimateButton>
      </Box>
    </Box>
  );
};

export default OrganizationsViewForm;
