import { observer } from 'mobx-react-lite';
import { useRantevouStore } from '../../Stores/RantevouStore';
import { useSubDepartmentStore } from '../../Stores/SubDepartmentStore';
import { useEffect, useState } from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import SubmitButton from '../Theme/Extended/SubmitButton';
import { formatDateInDateMonthYear } from '../../Common/formatDate/formatDate';
import { Typography, Modal, Tooltip, IconButton, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from '../Theme/Cards/MainCard';
import { useUserStore } from '../../Stores/UserStore';
import ReservationItem from './ReservationItem';
import ReservationItemEditMode from './ReservationItemEditMode';
import AuthSetAgent from '../../Api/AuthSetAgent';
import { success, warning } from '../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../Stores/AuthStore';
import CircularSpinner from '../Theme/Spinners/CircularSpinner';
import dayjs from 'dayjs';
import { toJS } from 'mobx';

const Calendar = ({
  subDepartmentId,
  active = false,
  scheduleId = null,
  newlyCreated = false,
}) => {
  const subDepartmentStore = useSubDepartmentStore();
  const { isAdmin } = useAuthStore();
  const { users } = useUserStore();
  const rantevouStore = useRantevouStore();
  const [conflictingDates, setConflictingDates] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [isSchedulePublished, setIsSchedulePublished] = useState(true);
  const [subDepartmentName, setSubDepartmentName] = useState('');
  const [reservedSlots, setReservedSlots] = useState({});
  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedSlotGroup, setSelectedSlotGroup] = useState(null);
  const [selectedSlotGroupList, setSelectedSlotGroupList] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReservationDetailsModalOpen, setIsReservationDetailsModalOpen] =
    useState(false);
  const [isModalPublishOpen, setIsModalPublishOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isEditOn, setIsEditOn] = useState(false);
  const [isWholeDay, setIsWholeDay] = useState(false);
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [dailyAvailableSlots, setDailyAvailableSlots] = useState([]);
  const [resetData, setResetData] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [slotsToBeDeleted, setSlotsToBeDeleted] = useState([]);
  const [slotGroupIdsForCreateNewSlots, setSlotGroupIdsForCreateNewSlots] =
    useState([]);
  const [reload, setReload] = useState(false);
  const [isScheduleConflicting, setIsScheduleConflicting] = useState(false);
  const [isPastSchedule, setIsPastSchedule] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
    const isPast = !rantevouStore.rantevouScheduleData?.days?.some((day) =>
      dayjs(day.slice(0, 10)).isAfter(tomorrow)
    );
    setIsPastSchedule(isPast);
  }, [rantevouStore.rantevouScheduleData]);

  useEffect(() => {
    if (scheduleId) {
      rantevouStore.resetRantevouScheduleData();
      AuthSetAgent.schedule
        .getScheduleById(scheduleId)
        .then((response) => {
          rantevouStore.setScheduleResp(response.result);
          setIsSchedulePublished(response.result.status === 1);
          setReload(false);
        })
        .catch((error) => {
          console.log(error);
          setReload(false);
        });
    }
  }, [rantevouStore, subDepartmentId, active, scheduleId, reload]);

  useEffect(() => {
    if (
      rantevouStore.rantevouScheduleData !== undefined &&
      Object.keys(rantevouStore.rantevouScheduleData).length > 0
    ) {
      setScheduleData(rantevouStore.rantevouScheduleData);
      setIsSchedulePublished(rantevouStore.rantevouScheduleData.status === 1);
    }
  }, [rantevouStore.rantevouScheduleData]);

  useEffect(() => {
    if (
      rantevouStore.rantevouScheduleData !== undefined &&
      Object.keys(rantevouStore.rantevouScheduleData).length > 0 &&
      resetData
    ) {
      setSlotsToBeDeleted([]);
      setSlotGroupIdsForCreateNewSlots([]);
      setScheduleData(rantevouStore.rantevouScheduleData);
      setResetData(false);
      setHasBeenEdited(false);
    }
  }, [rantevouStore.rantevouScheduleData, resetData]);

  useEffect(() => {
    if (selectedSlotGroupList !== null) {
      setDailyAvailableSlots(
        selectedSlotGroupList
          .map((slotGroup) =>
            slotGroup.slots.filter((slot) => !slot.isAvailable)
          )
          .flat()
      );
    }
  }, [selectedSlotGroupList]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(rantevouStore.rantevouScheduleData?.days?.length / pageSize)
    );
  }, [pageSize, rantevouStore.rantevouScheduleData]);

  useEffect(() => {
    let employees = users?.filter((user) => {
      return scheduleData?.userIds?.includes(user.id);
    });
    employees ? setEmployees(employees) : setEmployees([]);
  }, [scheduleData?.userIds, users]);

  useEffect(() => {
    const subDepartment = subDepartmentStore.subDepartmentsList.find(
      (subDepartment) => subDepartment.id === subDepartmentId
    );
    setSubDepartmentName(subDepartment?.name);
  }, [subDepartmentStore.subDepartmentsList, subDepartmentId]);

  useEffect(() => {
    getListOfReservedSlotsPerDay();
  }, [scheduleData]);

  const getListOfReservedSlotsPerDay = () => {
    let reservedSlotsPerDay = {};

    scheduleData?.days?.forEach((day, index) => {
      const reservedSlotsCount = scheduleData?.slotGroups
        .filter(
          (slotGroup) => slotGroup.timeFrom.slice(0, 10) === day.slice(0, 10)
        )
        .reduce((count, slotGroup) => {
          const reservedSlots = slotGroup?.slots?.filter(
            (slot) => !slot.isAvailable
          );
          return count + reservedSlots.length;
        }, 0);

      reservedSlotsPerDay[index] = reservedSlotsCount;
    });

    setReservedSlots(reservedSlotsPerDay);
  };

  const handleSlotClick = (slotGroup) => {
    if (active) {
      setSelectedSlotGroup(slotGroup);
      isEditOn ? setIsModalOpen(true) : setIsReservationDetailsModalOpen(true);
    } else {
      setSelectedSlotGroup(slotGroup);
      setIsModalOpen(true);
    }
  };

  const handleDeleteConfirmationMessage = (slot) => {
    setSelectedSlot(slot);
    setDeleteConfirmation(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDeleteConfirmation(false);
    setIsWholeDay(false);
    setConflictingDates([]);
  };

  const closeReservationDetailsModal = () => {
    setIsReservationDetailsModalOpen(false);
  };

  const handleDeleteSlot = (isAvailable, rantevouId, timeFrom, timeTo) => {
    setHasBeenEdited(true);
    handleRemoveSpecificSlot(isAvailable, rantevouId, timeFrom, timeTo);
    setDeleteConfirmation(false);
  };

  const handleRemoveSpecificSlot = (
    isAvailable,
    rantevouId,
    timeFrom,
    timeTo
  ) => {
    const newScheduleData = JSON.parse(JSON.stringify(scheduleData));
    let modifiedSlotGroup = null;
    let removedSlot = null;

    newScheduleData.slotGroups.forEach((slotGroupItem) => {
      if (
        slotGroupItem.timeFrom === timeFrom &&
        slotGroupItem.timeTo === timeTo
      ) {
        modifiedSlotGroup = slotGroupItem;

        if (isAvailable) {
          modifiedSlotGroup.slots = modifiedSlotGroup.slots.filter(
            (slot, index, self) => {
              if (
                slot.isAvailable &&
                index === self.findIndex((s) => s.isAvailable)
              ) {
                removedSlot = slot;
                return false;
              }
              return true;
            }
          );
        } else {
          modifiedSlotGroup.slots = modifiedSlotGroup.slots.filter((slot) => {
            if (slot.rantevou && slot.rantevou.id === rantevouId) {
              removedSlot = slot;
              return false;
            }
            return true;
          });
        }
      }
    });

    setSelectedSlotGroup(modifiedSlotGroup);
    setScheduleData(newScheduleData);

    if (removedSlot) {
      setSlotsToBeDeleted((prev) => [
        ...prev,
        {
          id: removedSlot.id,
          isAvailable: removedSlot.isAvailable,
          slotGroupId: removedSlot.slotGroupId,
        },
      ]);
    }
  };

  const handleRemoveSlotsClick = (selectedDate) => {
    setHasBeenEdited(true);
    const newScheduleData = JSON.parse(JSON.stringify(scheduleData));
    const slotsToBeRemoved = [];
    newScheduleData.slotGroups.forEach((slotGroup) => {
      const dateFromTime = slotGroup.timeFrom.split('T')[0];

      if (dateFromTime === selectedDate) {
        const removedSlots = slotGroup.slots.map((slot) => {
          const { id, isAvailable, slotGroupId } = slot;

          return {
            id,
            isAvailable,
            slotGroupId,
          };
        });
        slotsToBeRemoved.push(...removedSlots);
        slotGroup.slots = [];
      }
    });

    setScheduleData(newScheduleData);
    closeModal();

    if (slotsToBeRemoved.length > 0) {
      setSlotsToBeDeleted((prevIds) => [...prevIds, ...slotsToBeRemoved]);
    }
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev') {
      let myPage = page > 0 ? page - 1 : 0;
      setPage(myPage);
    } else if (direction === 'next') {
      let myPage = page + 1 < totalPages ? page + 1 : totalPages - 1;
      setPage(myPage);
    }
  };

  const renderRantevouBadge = () => {
    return (
      <div className="flex justify-evenly items-center mt-8 space-x-4">
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center px-4 py-2 text-xs font-bold leading-none text-white bg-[#003375] rounded-full" />
          <span className="ml-2">Πλήρης διαθεσιμότητα</span>
        </div>
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center px-4 py-2 text-xs font-bold leading-none text-white bg-[#4E5F75] rounded-full" />
          <span className="ml-2">Μερική διαθεσιμότητα</span>
        </div>
        <div className="flex items-center">
          <span className="inline-flex items-center justify-center px-4 py-2 text-xs font-bold leading-none text-gray-800 bg-gray-200 rounded-full" />
          <span className="ml-2">Χωρίς διαθεσιμότητα</span>
        </div>
      </div>
    );
  };

  const onScheduleSave = () => {
    let tempSlotGroupsIds = [];

    const slotGroupIdCountMap = slotGroupIdsForCreateNewSlots.reduce(
      (countMap, slotGroupId) => {
        countMap[slotGroupId] = (countMap[slotGroupId] || 0) + 1;
        return countMap;
      },
      {}
    );

    const filteredSlotsToBeDeleted = slotsToBeDeleted.filter((slot) => {
      const slotGroupIdCount = slotGroupIdCountMap[slot.slotGroupId] || 0;
      if (slot.isAvailable && slotGroupIdCount > 0) {
        slotGroupIdCountMap[slot.slotGroupId] = slotGroupIdCount - 1;
        tempSlotGroupsIds.push(slot.slotGroupId);
        return false;
      }
      return true;
    });

    const removedSlotGroupIdCountMap = tempSlotGroupsIds.reduce(
      (countMap, slotGroupId) => {
        countMap[slotGroupId] = (countMap[slotGroupId] || 0) + 1;
        return countMap;
      },
      {}
    );

    const updatedSlotGroupIdsForCreateNewSlots =
      slotGroupIdsForCreateNewSlots.filter((slotGroupId) => {
        const occurrenceCount = removedSlotGroupIdCountMap[slotGroupId] || 0;
        if (occurrenceCount > 0) {
          removedSlotGroupIdCountMap[slotGroupId] = occurrenceCount - 1;
          return false;
        }
        return true;
      });

    const distinctDateList = filteredSlotsToBeDeleted.reduce(
      (datesList, slot) => {
        const date = scheduleData.slotGroups
          .find((slotGroup) => slotGroup.id === slot.slotGroupId)
          .timeFrom.split('T')[0];

        if (!datesList.includes(date)) {
          datesList.push(date);
        }

        return datesList;
      },
      []
    );

    const remainSlotsArray = distinctDateList.map((date) => {
      const slots = scheduleData.slotGroups
        .filter((slotGroup) => slotGroup.timeFrom.split('T')[0] === date)
        .map((slotGroup) => slotGroup.slots)
        .flat();

      return {
        date: date,
        remainSlotsExist: slots.length > 0,
      };
    });

    let datesChecked = [];

    const deleteSlotPromises = filteredSlotsToBeDeleted
      .reduce((promises, slot) => {
        const parentSlotGroup = scheduleData.slotGroups.find(
          (slotGroup) => slotGroup.id === slot.slotGroupId
        );

        const date = parentSlotGroup.timeFrom.split('T')[0];

        if (
          remainSlotsArray.find((item) => item.date === date).remainSlotsExist
        ) {
          promises.push(AuthSetAgent.slot.deleteSlot(slot.id));
        } else if (!datesChecked.includes(date)) {
          datesChecked.push(date);
          promises.push(AuthSetAgent.slot.deleteByDate(scheduleData.id, date));
        } else {
          promises.push(null);
        }

        return promises;
      }, [])
      .filter((promise) => promise !== null);

    Promise.all(deleteSlotPromises)
      .then(() => {
        const createSlotPromises = updatedSlotGroupIdsForCreateNewSlots.map(
          (slotGroupId) => {
            const body = {
              slotGroupId: slotGroupId,
              isAvailable: true,
            };
            return AuthSetAgent.slot.createSlot(body);
          }
        );

        return Promise.all(createSlotPromises)
          .then(() => {
            setResetData(false);
            setHasBeenEdited(false);
            setIsEditOn(false);
            setSlotsToBeDeleted([]);
            setSlotGroupIdsForCreateNewSlots([]);
            setReload(true);
            success('Η αποθήκευση έγινε με επιτυχία!');
          })
          .catch((error) => {
            setReload(true);
            warning('Κάτι πήγε λάθος!');
            console.error('An error occurred while creating slots: ', error);
          })
          .finally(() => {
            rantevouStore.getSchedule(rantevouStore.rantevouScheduleData?.id);
          });
      })
      .catch((error) => {
        setReload(true);
        warning('Κάτι πήγε λάθος!');
        console.error(
          'An error occurred while deleting or creating slots: ',
          error
        );
      });
  };

  const onSchedulePublish = () => {
    setIsModalPublishOpen(false);

    if (!isSchedulePublished) {
      setLoading(true);
      AuthSetAgent.schedule
        .updateSchedule(scheduleData.id, {
          status: 1,
          userIds: scheduleData.userIds,
        })
        .then((res) => {
          if (res.success) {
            setIsSchedulePublished(true);
            setResetData(false);
            setHasBeenEdited(false);
            setIsEditOn(false);
            setSlotsToBeDeleted([]);
            setSlotGroupIdsForCreateNewSlots([]);
            setReload(true);
            success('Η δημοσίευση έγινε με επιτυχία!');
            navigate('/active-schedules');
          }
        })
        .catch((error) => {
          setSlotsToBeDeleted([]);
          setSlotGroupIdsForCreateNewSlots([]);
          setReload(true);
          warning('Κάτι πήγε λάθος!');
          console.error('An error occurred while publishing schedule: ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onClickBack = () => {
    setResetData(true);
    setHasBeenEdited(false);
    setIsEditOn(false);
    setSlotGroupIdsForCreateNewSlots([]);
    setSlotsToBeDeleted([]);
  };

  const handleAddSlot = (slotGroup) => {
    setHasBeenEdited(true);
    const newScheduleData = JSON.parse(JSON.stringify(scheduleData));

    const newSlot = {
      isAvailable: true,
      rantevou: null,
      id: undefined,
      slotGroupId: slotGroup.id,
    };

    newScheduleData.slotGroups.forEach((slotGroupItem) => {
      if (slotGroupItem.timeFrom === slotGroup.timeFrom) {
        slotGroupItem.slots.push(newSlot);
        setSelectedSlotGroup(slotGroupItem);
        setSlotGroupIdsForCreateNewSlots((prevIds) => [
          ...prevIds,
          slotGroupItem.id,
        ]);
      }
    });

    setScheduleData(newScheduleData);
  };

  const renderRantevouCalendar = () => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    const myDays = rantevouStore.rantevouScheduleData?.days?.slice(
      startIndex,
      endIndex
    );
    const totalDaysCount = rantevouStore.rantevouScheduleData?.days?.length;

    return (
      <>
        <div className="flex flex-col justify-center items-center">
          <Typography variant="h3" color={'#003375'} gutterBottom>
            {subDepartmentName}
          </Typography>
          {myDays && (
            <Typography variant="h3" color={'#003375'} gutterBottom>
              Από{' '}
              {formatDateInDateMonthYear(
                rantevouStore.rantevouScheduleData?.days[0]
              )}{' '}
              έως{' '}
              {formatDateInDateMonthYear(
                rantevouStore.rantevouScheduleData?.days[totalDaysCount - 1]
              )}
            </Typography>
          )}
        </div>

        <div
          className="grid mt-6 gap-4 ml-0 grid-cols-5 overflow-auto justify-center items-start"
          style={{ gridTemplateColumns: '14rem 14rem 14rem 14rem 14rem' }}
        >
          {myDays?.map((day, dayIndex) => {
            const isLastDay = dayIndex === myDays.length - 1;
            const isFirstDay = dayIndex === 0;

            return (
              <div key={day + dayIndex} className="relative">
                {isFirstDay && (
                  <div className="absolute top-0 left-[100px] sm:left-[0px] sm:right-26">
                    {page > 0 && (
                      <div onClick={() => handlePageChange('prev')}>
                        <ArrowCircleLeftOutlinedIcon
                          style={{
                            fill: '#004a75',
                            cursor: 'pointer',
                          }}
                          fontSize="large"
                        />
                      </div>
                    )}
                  </div>
                )}
                {isLastDay && (
                  <div className="absolute top-0 right-[100px] sm:right-[0px] sm:left-26">
                    {page + 1 < totalPages && (
                      <div onClick={() => handlePageChange('next')}>
                        <ArrowCircleRightOutlinedIcon
                          style={{
                            fill: '#004a75',
                            cursor: 'pointer',
                          }}
                          fontSize="large"
                        />
                      </div>
                    )}
                  </div>
                )}

                <p className="flex justify-center items-center font-bold text-[1.4em] text-sm mb-2">
                  {formatDateInDateMonthYear(day)}
                </p>
                <h3 className="flex justify-center items-center text-lg mb-2">
                  {new Date(day).toLocaleDateString('el-GR', {
                    weekday: 'long',
                  })}
                </h3>
                <div className="flex flex-wrap justify-center items-center">
                  {scheduleData.slotGroups
                    ?.filter(
                      (slotGroup) =>
                        slotGroup.timeFrom.slice(0, 10) === day.slice(0, 10)
                    )
                    .map((slotGroup, index, filteredSlotGroups) => {
                      const slots = slotGroup.slots || [];
                      const notAvailableSlots = slots.filter(
                        (slot) => !slot?.isAvailable
                      );
                      const timeFrom = slotGroup.timeFrom.slice(11, 16);
                      const timeTo = slotGroup.timeTo.slice(11, 16);
                      const isSlotFull =
                        slots.length === notAvailableSlots.length;
                      const isSlotNotEmpty =
                        notAvailableSlots.length > 0 && slots.length > 0;

                      return (
                        <div
                          className="min-w-[93%] max-w-[93%] flex flex-col items-center"
                          key={index + slotGroup.timeFrom}
                        >
                          <button
                            key={index + slotGroup.timeFrom}
                            disabled={!isEditOn && !active}
                            className={`px-4 sm:px-8 py-[10px] rounded-full min-w-[93%] max-w-[93%] ${
                              isSlotFull
                                ? 'bg-[#c9c9c9] text-black'
                                : isSlotNotEmpty && !isSlotFull
                                ? 'bg-[#4E5F75] text-white'
                                : 'bg-[#003375] text-white'
                            } text-xs sm:text-sm mb-2`}
                            onClick={() => handleSlotClick(slotGroup)}
                          >
                            <Typography variant="h5">
                              {timeFrom} - {timeTo} | {notAvailableSlots.length}
                              /{slots.length}
                            </Typography>
                          </button>
                          {!isEditOn &&
                            active &&
                            index === filteredSlotGroups.length - 1 && (
                              <div className="text-center mt-4">
                                <Typography variant="h5">
                                  Σύνολο κρατήσεων
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className={`items-center px-4 sm:px-12 py-[10px] rounded-full max-w-[93%] sm:text-sm mb-2`}
                                >
                                  {reservedSlots[dayIndex]}
                                </Typography>
                              </div>
                            )}
                          {isEditOn &&
                            index === filteredSlotGroups.length - 1 && (
                              <Button
                                key={index + slotGroup.timeTo}
                                disabled={
                                  !filteredSlotGroups.some((slotGroup) =>
                                    slotGroup.slots.some(
                                      (slot) => slot.isAvailable
                                    )
                                  )
                                }
                                onClick={() => {
                                  setIsWholeDay(true);
                                  setIsModalOpen(true);
                                  setDeleteConfirmation(true);
                                  setSelectedSlotGroupList(
                                    scheduleData.slotGroups.filter(
                                      (slotGroup) =>
                                        slotGroup.timeFrom.slice(0, 10) ===
                                        day.slice(0, 10)
                                    )
                                  );
                                }}
                              >
                                <RemoveCircleIcon
                                  fontSize="large"
                                  color={
                                    filteredSlotGroups.some((slotGroup) =>
                                      slotGroup.slots.some(
                                        (slot) => slot.isAvailable
                                      )
                                    )
                                      ? 'error'
                                      : 'disabled'
                                  }
                                />
                              </Button>
                            )}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        {renderRantevouBadge()}
      </>
    );
  };

  const renderEditModeModalContent = () => {
    if (selectedSlotGroup) {
      const timeFrom = selectedSlotGroup.timeFrom.slice(11, 16);
      const timeTo = selectedSlotGroup.timeTo.slice(11, 16);

      return (
        <div className="modal-container overflow-auto max-h-screen">
          <div className="flex justify-center items-center">
            <Typography variant="h4" color={'#003375'} gutterBottom>
              Διαθεσιμότητα θέσεων
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Typography variant="h4" className="pr-1 pt-4 font-bold">
              {new Date(selectedSlotGroup.timeFrom).toLocaleDateString('el', {
                weekday: 'long',
              })}
            </Typography>
            <Typography variant="h4" className="pt-4 font-bold">
              {formatDateInDateMonthYear(selectedSlotGroup.timeFrom)}
            </Typography>
          </div>

          <div className="ml-8 mr-8 mt-6">
            {selectedSlotGroup.slots?.map((slot, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '1rem',
                  width: '100%',
                }}
              >
                <ReservationItemEditMode
                  index={index}
                  slot={slot}
                  timeFrom={selectedSlotGroup.timeFrom}
                  timeTo={selectedSlotGroup.timeTo}
                  onDeleteClick={handleDeleteConfirmationMessage}
                />
              </div>
            ))}

            {selectedSlotGroup.slots?.length === 0 && (
              <div className="flex justify-center items-center">
                <Typography variant="h5">
                  Δεν υπάρχουν διαθέσιμες θέσεις
                </Typography>
              </div>
            )}

            {selectedSlotGroup.slots?.length < scheduleData.defaultQuantity && (
              <div className="flex justify-end items-center">
                <div className="ml-4">
                  <Tooltip title="Προσθήκη Θέσης">
                    <IconButton
                      size="large"
                      onClick={() => handleAddSlot(selectedSlotGroup)}
                    >
                      <AddCircleOutlineIcon sx={{ fontSize: '1.8rem' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}

            {deleteConfirmation && (
              <>
                {selectedSlot.isAvailable ? (
                  <div>
                    <div className="flex justify-center items-center">
                      <Typography variant="h4" className="pt-4">
                        Θα διαγράψετε τη διαθεσιμότητα
                      </Typography>
                    </div>
                    <div className="flex justify-center items-center">
                      <Typography variant="h4" className="pt-2">
                        μιας θέσης {`${timeFrom} - ${timeTo}`}.
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex justify-center items-center">
                      <Typography variant="h4" className="pt-4">
                        Προσοχή! Θα ακυρώσετε το ραντεβού
                      </Typography>
                    </div>
                    <div className="flex justify-center items-center">
                      <Typography variant="h4" className="pt-2">
                        στη συγκεκριμένη θέση.
                      </Typography>
                    </div>
                  </div>
                )}

                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-2">
                    Θέλετε να συνεχίσετε;
                  </Typography>
                </div>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-4">
                    <SubmitButton
                      buttonTitle="rantevouModal"
                      onClick={() =>
                        handleDeleteSlot(
                          selectedSlot.isAvailable,
                          selectedSlot.rantevou?.id,
                          selectedSlotGroup.timeFrom,
                          selectedSlotGroup.timeTo
                        )
                      }
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>

                  <Typography variant="h4" className="pt-4 pl-4">
                    <SubmitButton
                      onClick={() => setDeleteConfirmation(false)}
                      buttonTitle="rantevouModalNo"
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderWholeDayEditModeModalContent = () => {
    if (selectedSlotGroupList) {
      const timeFrom = selectedSlotGroupList[0]?.timeFrom;

      return (
        <div className="modal-container overflow-auto max-h-screen">
          <div className="ml-8 mr-8 mt-6">
            {deleteConfirmation && (
              <>
                <div>
                  <div className="flex justify-center items-center">
                    <Typography variant="h4" className="pt-4">
                      Θα διαγράψετε το πρόγραμμα
                    </Typography>
                  </div>
                  <div className="flex justify-center items-center mb-6">
                    <Typography variant="h4" className="pt-2">
                      για{' '}
                      {new Date(timeFrom).toLocaleDateString('el', {
                        weekday: 'long',
                      })}{' '}
                      {formatDateInDateMonthYear(timeFrom)}{' '}
                      {dailyAvailableSlots.length !== 0 && 'με ραντεβού:'}
                    </Typography>
                  </div>
                </div>

                {selectedSlotGroupList.map((slotGroup, index) => {
                  let unavailableSlots = slotGroup.slots?.filter(
                    (slot) => !slot.isAvailable
                  );

                  return (
                    unavailableSlots.length > 0 &&
                    unavailableSlots.map((slot, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '1rem',
                          width: '100%',
                        }}
                      >
                        <ReservationItemEditMode
                          index={index}
                          dayMode
                          slot={slot}
                          timeFrom={slotGroup.timeFrom}
                          timeTo={slotGroup.timeTo}
                          onDeleteClick={handleDeleteConfirmationMessage}
                        />
                      </div>
                    ))
                  );
                })}

                {dailyAvailableSlots.length === 0 && (
                  <Typography variant="h4" className="pt-2">
                    Δεν υπάρχουν ραντεβού για αυτή την ημέρα
                  </Typography>
                )}

                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-2">
                    Θέλετε να συνεχίσετε;
                  </Typography>
                </div>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-4">
                    <SubmitButton
                      buttonTitle="rantevouModal"
                      onClick={() =>
                        handleRemoveSlotsClick(
                          selectedSlotGroupList[0].timeFrom.split('T')[0]
                        )
                      }
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>

                  <Typography variant="h4" className="pt-4 pl-4">
                    <SubmitButton
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                      buttonTitle="rantevouModalNo"
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderReservationDetailsModalContent = () => {
    if (selectedSlotGroup) {
      const timeFrom = selectedSlotGroup.timeFrom.slice(11, 16);
      const timeTo = selectedSlotGroup.timeTo.slice(11, 16);

      return (
        <div className="modal-container overflow-auto max-h-screen">
          <div className="flex justify-center items-center">
            <Typography variant="h4" color={'#003375'} gutterBottom>
              Στοιχεία κρατήσεων / διαθεσιμότητας
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Typography variant="h4" className="pr-1 pt-4 font-bold">
              {new Date(selectedSlotGroup.timeFrom).toLocaleDateString('el', {
                weekday: 'long',
              })}
            </Typography>
            <Typography variant="h4" className="pt-4 font-bold">
              {formatDateInDateMonthYear(selectedSlotGroup.timeFrom)}
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Typography variant="h4" className="pt-2 font-bold">
              {timeFrom} - {timeTo}
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Typography variant="h5" className="pr-2 pt-1 font-bold">
              Διαθεσιμότητα: {selectedSlotGroup?.slots?.length} υπάλληλοι
            </Typography>
          </div>
          <div className="flex justify-center items-center">
            <Typography variant="h5" className="pr-2 pt-1 font-bold">
              Υπάλληλοι:{' '}
              {employees?.map((employee) => employee?.name).join(', ')}
            </Typography>
          </div>

          <div className="flex justify-center items-center">
            <Typography variant="h5" className="pr-2 pt-1 font-bold">
              Κρατήσεις:{' '}
              {selectedSlotGroup?.slots?.length -
                selectedSlotGroup?.slots?.filter((slot) => slot?.isAvailable)
                  .length}
            </Typography>
          </div>

          <div className="ml-8 mr-8 mt-6">
            {selectedSlotGroup.slots?.map(
              (slot, index) =>
                !slot?.isAvailable && (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <ReservationItem index={index} rantevou={slot.rantevou} />
                  </div>
                )
            )}

            {deleteConfirmation && (
              <>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-4">
                    Θα διαγράψετε τη διαθεσιμότητα
                  </Typography>
                </div>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-2">
                    μιας θέσης {`${timeFrom} - ${timeTo}`}.
                  </Typography>
                </div>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-2">
                    Θέλετε να συνεχίσετε;
                  </Typography>
                </div>
                <div className="flex justify-center items-center">
                  <Typography variant="h4" className="pt-4">
                    <SubmitButton
                      buttonTitle="rantevouModal"
                      onClick={() =>
                        handleDeleteSlot(
                          selectedSlot.isAvailable,
                          selectedSlot.rantevou?.id,
                          selectedSlotGroup.timeFrom,
                          selectedSlotGroup.timeTo
                        )
                      }
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>

                  <Typography variant="h4" className="pt-4 pl-4">
                    <SubmitButton
                      onClick={() => setDeleteConfirmation(false)}
                      buttonTitle="rantevouModalNo"
                      sx={{ backgroundColor: '#003375' }}
                      type="button"
                    />
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderRantevouCalendarButtons = () => {
    return (
      <>
        {isEditOn ? (
          <div className="text-[#FFFF] mt-6 mr-6">
            <SubmitButton
              title="Ακυρωση"
              sx={{
                borderRadius: '99px',
                backgroundColor: '#87CEEB',
                marginRight: '1rem',
              }}
              type="button"
              onClick={() => onClickBack()}
            />
            <SubmitButton
              disabled={!hasBeenEdited}
              title="Επαναφορα"
              sx={{
                borderRadius: '99px',
                backgroundColor: '#87CEEB',
                marginRight: '1rem',
              }}
              type="button"
              onClick={() => setResetData(true)}
            />
            <SubmitButton
              disabled={!hasBeenEdited}
              title="Αποθηκευση"
              sx={{
                borderRadius: '99px',
                backgroundColor: '#87CEEB',
                marginRight: '1rem',
              }}
              type="button"
              onClick={() => onScheduleSave()}
            />
          </div>
        ) : (
          isAdmin && (
            <div className="text-[#FFFF] mt-6 mr-6">
              <SubmitButton
                title="Επεξεργασια"
                sx={{ borderRadius: '99px', backgroundColor: '#87CEEB' }}
                type="button"
                onClick={() => setIsEditOn(true)}
              />
              {!isSchedulePublished && (
                <SubmitButton
                  title="Δημοσιευση"
                  sx={{
                    borderRadius: '99px',
                    backgroundColor: '#87CEEB',
                    marginLeft: '1rem',
                  }}
                  type="button"
                  onClick={() => {
                    if (rantevouStore.activeSchedules?.length > 0) {
                      const maxDate = (date1, date2) =>
                        dayjs(date1).isAfter(dayjs(date2)) ? date1 : date2;
                      const minDate = (date1, date2) =>
                        dayjs(date1).isBefore(dayjs(date2)) ? date1 : date2;

                      const activeScedulesStartDates =
                        rantevouStore.activeSchedules?.map((schedule) =>
                          schedule.timeFrom.slice(0, 10)
                        );
                      const activeScedulesEndDates =
                        rantevouStore.activeSchedules?.map((schedule) =>
                          schedule.timeTo.slice(0, 10)
                        );
                      const scheduleStartDate = scheduleData.timeFrom.slice(
                        0,
                        10
                      );
                      const scheduleEndDate = scheduleData.timeTo.slice(0, 10);

                      const activeSchedulesPeriods =
                        activeScedulesStartDates?.map((date, index) => {
                          return {
                            startDate: date,
                            endDate: activeScedulesEndDates[index],
                          };
                        });

                      const conflicts = activeSchedulesPeriods
                        .filter((period) => {
                          return (
                            dayjs(scheduleStartDate).isBefore(
                              dayjs(period.endDate)
                            ) &&
                            dayjs(scheduleEndDate).isAfter(
                              dayjs(period.startDate)
                            )
                          );
                        })
                        .map((period) => ({
                          startDate: maxDate(
                            scheduleStartDate,
                            period.startDate
                          ),
                          endDate: minDate(scheduleEndDate, period.endDate),
                        }));

                      setConflictingDates(conflicts);

                      if (conflicts.length === 0) {
                        setIsScheduleConflicting(false);
                      } else {
                        setIsScheduleConflicting(true);
                      }
                    }
                    setIsModalPublishOpen(true);
                  }}
                />
              )}
              {newlyCreated && (
                <SubmitButton
                  title="Τελος"
                  sx={{
                    borderRadius: '99px',
                    backgroundColor: '#87CEEB',
                    marginLeft: '1rem',
                  }}
                  type="button"
                  onClick={() => navigate('/active-schedules')}
                />
              )}
            </div>
          )
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        className="flex items-center justify-center"
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="slot-details-modal"
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-4 min-w-[25vw] min-h-[15vw]"
        >
          <div className="flex items-end justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={closeModal}
            />
          </div>
          {isEditOn
            ? isWholeDay
              ? renderWholeDayEditModeModalContent()
              : renderEditModeModalContent()
            : null}
        </MainCard>
      </Modal>

      <Modal
        className="flex items-center justify-center "
        open={isReservationDetailsModalOpen}
        onClose={() => setIsReservationDetailsModalOpen(false)}
        aria-labelledby="slot-reservations-details-modal"
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-4 min-w-[25vw] min-h-[15vw] mb-6 mt-6 overflow-auto max-h-screen"
        >
          <div className="flex items-end justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={closeReservationDetailsModal}
            />
          </div>
          {renderReservationDetailsModalContent()}
        </MainCard>
      </Modal>

      <Modal
        className="flex items-center justify-center "
        open={isModalPublishOpen}
        onClose={() => setIsModalPublishOpen(false)}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-6 min-w-[25vw] min-h-[15vw] overflow-auto max-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex items-end justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalPublishOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen m-8 flex flex-col justify-center items-center">
            {isScheduleConflicting && (
              <div className="flex flex-col gap-4 justify-center items-center">
                <Typography variant="h4">
                  Υπάρχει επικάλυψη μεταξύ των ενεργών προγραμμάτων και αυτού
                  που θέλετε να δημοσιεύσετε.
                </Typography>
                <Typography variant="h4">
                  Εντοπίστηκαν επικαλύψεις στις παρακάτω ημερομηνίες:
                </Typography>
                {conflictingDates?.map((conflict, index) => {
                  return (
                    <Typography variant="h4" key={index}>
                      από {dayjs(conflict.startDate).format('DD/MM/YYYY')} έως{' '}
                      {dayjs(conflict.endDate).format('DD/MM/YYYY')}
                    </Typography>
                  );
                })}
                <Typography variant="h4">
                  Αν θέλετε να δημοσιεύσετε το τρέχον πρόγραμμα πρέπει πρώτα να
                  αρχειοθετήσετε το αντίστοιχο ενεργό πρόγραμμα.
                </Typography>
              </div>
            )}
            {!isScheduleConflicting && (
              <div className="flex flex-col gap-4 justify-center items-center">
                <Typography variant="h4">
                  Το τρέχον πρόγραμμα θα γίνει διαθέσιμο στους πολίτες.
                </Typography>
                <Typography variant="h4">Θέλετε να συνεχίσετε;</Typography>
              </div>
            )}
            {isScheduleConflicting && (
              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalPublishOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="OK"
                />
              </Typography>
            )}
            {!isScheduleConflicting && (
              <div className="flex justify-center items-center">
                <Typography variant="h4" className="pt-4">
                  <SubmitButton
                    onClick={() => onSchedulePublish()}
                    sx={{ backgroundColor: '#003375' }}
                    type="button"
                    title="NAI"
                  />
                </Typography>
                <Typography variant="h4" className="pt-4 pl-4">
                  <SubmitButton
                    onClick={() => setIsModalPublishOpen(false)}
                    sx={{ backgroundColor: '#003375' }}
                    type="button"
                    title="ΟΧΙ"
                  />
                </Typography>
              </div>
            )}
          </div>
        </MainCard>
      </Modal>

      <div className="container mx-auto py-8">
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularSpinner />
          </div>
        ) : Object.keys(scheduleData)?.length > 0 ? (
          <>
            {renderRantevouCalendar()}
            {Object.keys(scheduleData)?.length > 0 && (
              <div className="ml-8 sm:ml-20 flex justify-end mt-2">
                {!isPastSchedule && renderRantevouCalendarButtons()}
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center">
            <Typography variant="h4" color={'#003375'} gutterBottom>
              Δεν βρέθηκε ενεργό πρόγραμμα για το επιλεγμένο υποτμήμα
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(Calendar);
