import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import Topbar from '../../../Components/Topbar/Topbar';
import { CircularProgress, Typography } from '@mui/material';
import { formatDateInDateMonthYear } from '../../../Common/formatDate/formatDate';
import GoogleMap from '../../../Components/GoogleMap/GoogleMap';
import ImageGrid from '../../../Components/ImageGrid/ImageGrid';
import { observer } from 'mobx-react';
import { useAuthStore } from '../../../Stores/AuthStore';
import TicketModeratorActions from './TicketModeratorActions';
import TicketEmployeeActions from './TicketEmployeeActions';
import { useCallback } from 'react';
import NoDataCard from '../../../Components/NoDataCard/NoDataCard';
import { ErrorOutline } from '@mui/icons-material';
import { palette } from '../../../styles/variables/colors';
import {
  TICKET_HIGH_PRIORITY_ID,
  TICKET_STATUS,
} from '../../../Config/constant';
import { error, success } from '../../../Common/Toast/Toast';

const EMPTY_FIELD_TEXT = ' ';

const ListItem = ({ fieldTitle, fieldValue }) => (
  <li>
    <Typography fontSize={17}>
      {`${fieldTitle}: ${fieldValue || EMPTY_FIELD_TEXT}`}
    </Typography>
  </li>
);

const TicketDetail = () => {
  const { isModerator, isSuperAdmin, isUser } = useAuthStore();
  const params = useParams();
  const [ticket, setTicket] = useState();
  const [fetching, setFetching] = useState(false);
  const [processing, setProcessing] = useState(false);

  const statusBgColor =
    Object.values(TICKET_STATUS)?.find((status) => status.id === ticket?.status)
      ?.color || palette.info.main;

  const markerPosition = useMemo(() => {
    return {
      lat: ticket?.lat,
      lng: ticket?.lon,
    };
  }, [ticket]);

  const mapData = useMemo(() => {
    if (!ticket) {
      return;
    }

    const polygon = JSON.parse(ticket?.geoJsonMap);
    return polygon?.coordinates;
  }, [ticket]);

  const onApprove = useCallback(
    async ({ department, priority }) => {
      setProcessing(true);

      await AuthSetAgent.tickets
        .approveTicket(ticket?.id, {
          department,
          priority,
        })
        .then((response) => {
          if (response?.success) {
            success('Εγκρίθηκε!');
            response?.result && setTicket(response.result);
          } else {
            error('Παρουσιάστηκε σφάλμα!');
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [ticket]
  );

  const onReject = useCallback(
    async ({ comments }) => {
      setProcessing(true);

      await AuthSetAgent.tickets
        .rejectTicket(ticket?.id, {
          comments,
        })
        .then((response) => {
          if (response?.success) {
            success('Απορρίφθηκε!');
            response?.result && setTicket(response.result);
          } else {
            error('Παρουσιάστηκε σφάλμα!');
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [ticket]
  );

  const onAssign = useCallback(async () => {
    setProcessing(true);

    await AuthSetAgent.tickets
      .manageTicket(ticket?.id)
      .then((response) => {
        if (response?.success) {
          success('Έχετε αναλάβει το αίτημα επιτυχώς!');
          response?.result && setTicket(response.result);
        } else {
          error('Παρουσιάστηκε σφάλμα!');
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  }, [ticket]);

  const onComplete = useCallback(
    async (comments) => {
      await AuthSetAgent.tickets
        .completeTicket(ticket?.id, { comments })
        .then((response) => {
          if (response?.success) {
            success('Έχετε ολοκληρώσει το αίτημα επιτυχώς!');
            response?.result && setTicket(response.result);
          } else {
            error('Παρουσιάστηκε σφάλμα!');
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [ticket]
  );

  const actionSection = useMemo(() => {
    if (!ticket) {
      return null;
    }

    const moderatorActions = (
      <TicketModeratorActions
        onApprove={onApprove}
        onReject={onReject}
        ticket={ticket}
      />
    );
    const employeeActions = (
      <TicketEmployeeActions
        onAssign={onAssign}
        onComplete={onComplete}
        ticket={ticket}
      />
    );
    const canViewAllActions = isSuperAdmin || (isModerator && isUser);

    if (canViewAllActions) {
      return (
        <>
          {moderatorActions}
          {employeeActions}
        </>
      );
    }

    if (isModerator) {
      return <>{moderatorActions}</>;
    }

    return <>{employeeActions}</>;
  }, [
    isModerator,
    isSuperAdmin,
    isUser,
    onApprove,
    onAssign,
    onComplete,
    onReject,
    ticket,
  ]);

  const loader = (
    <section className="flex justify-center items-center p-20">
      <CircularProgress />
    </section>
  );

  const detailSection = (
    <div className="ml-4">
      <section className="flex items-center">
        <Typography
          className="py-5"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Προβολή Αιτήματος
        </Typography>
        {ticket?.ticketPriority === TICKET_HIGH_PRIORITY_ID && (
          <ErrorOutline
            color={palette.error.main}
            sx={{
              fontSize: 40,
              marginBottom: '7px',
              marginLeft: 1,
              color: palette.error.main,
            }}
          />
        )}
      </section>

      {/* Overview */}
      <section className="grid grid-cols-2 gap-x-10 gap-y-2 items-center pb-10">
        <section className="text-center m-auto grid grid-cols-1 gap-2">
          <Typography
            fontSize={18}
            fontWeight="bold"
            color={'#003375'}
            className="bg-blue-200 mb-10 py-2 rounded-md w-[400px]"
          >
            {`Αρ. Αιτήματος: ${ticket?.id}`}
          </Typography>
          <Typography
            fontSize={18}
            fontWeight="bold"
            color={'#003375'}
            className="bg-blue-200 mb-10 py-2 rounded-md w-[400px]"
          >
            {`Ημ/νία Καταχώρησης: ${
              ticket?.entityCreatedAt
                ? formatDateInDateMonthYear(ticket.entityCreatedAt)
                : EMPTY_FIELD_TEXT
            }`}
          </Typography>
          {ticket?.departmentName && (
            <Typography
              fontSize={18}
              fontWeight="bold"
              color={'#003375'}
              className="bg-blue-200 mb-10 py-2 rounded-md w-[400px]"
            >
              {ticket?.departmentName || EMPTY_FIELD_TEXT}
            </Typography>
          )}
          {ticket?.dateOfCompletion && (
            <Typography
              fontSize={18}
              fontWeight="bold"
              color={'#003375'}
              className="bg-blue-200 mb-10 py-2 rounded-md w-[400px]"
            >
              {`Ημ/νία Ολοκλήρωσης: ${
                formatDateInDateMonthYear(ticket.dateOfCompletion) ||
                EMPTY_FIELD_TEXT
              }`}
            </Typography>
          )}
          {!!ticket?.employeeUserName && !!ticket?.employeeUserSurName && (
            <Typography
              fontSize={18}
              fontWeight="bold"
              color={'#003375'}
              className="bg-blue-200 mb-10 py-2 rounded-md w-[400px]"
              style={{ marginBottom: 5 }}
            >
              {`Υπεύθυνος εξυπηρέτησης: ${
                ticket?.employeeUserName || EMPTY_FIELD_TEXT
              } ${ticket?.employeeUserSurName || EMPTY_FIELD_TEXT}`}
            </Typography>
          )}
        </section>
        <section className="text-center">
          <div
            className="inline-block px-10 py-5 rounded-lg text-white"
            style={{ backgroundColor: statusBgColor }}
          >
            <Typography fontSize={18} fontWeight="bold">
              Κατάσταση:
            </Typography>
            <Typography fontSize={18} fontWeight="bold">
              {ticket?.statusLiteral || EMPTY_FIELD_TEXT}
            </Typography>
          </div>
        </section>
      </section>

      {/* Ticket info */}
      <section className="pb-10">
        <Typography fontWeight="bold" variant="h4" className="pb-2">
          Στοιχεία αιτήματος
        </Typography>
        <ul className="list-disc ml-5">
          <ListItem
            fieldTitle="ΚΑΤΑΣΤΑΣΗ"
            fieldValue={ticket?.ticketCategoryTitle}
          />
          <ListItem
            fieldTitle="ΥΠΗΡΕΣΙΑ / ΠΡΟΒΛΗΜΑ"
            fieldValue={ticket?.ticketServiceTitle}
          />
          <ListItem
            fieldTitle="ΣΧΟΛΙΟ"
            fieldValue={ticket?.ticketNoteFromCitizen}
          />
        </ul>
      </section>

      {/* Map */}
      <section className="pb-10">
        <Typography fontWeight="bold" variant="h4" className="pb-5">
          Θέση στο χάρτη
        </Typography>
        <div className="pl-2 pb-2">
          <Typography fontSize={17}>
            ΔΙΕΥΘΥΝΣΗ: {ticket?.formattedAddress || EMPTY_FIELD_TEXT}
          </Typography>
        </div>
        {markerPosition && mapData && (
          <GoogleMap
            initialZoom={16}
            markerPosition={markerPosition}
            customCenter={markerPosition}
            // mapData={mapData}
          />
        )}
      </section>

      {/* Photos */}
      <section className="pb-10">
        <Typography fontWeight="bold" variant="h4" className="pb-5">
          Φωτογραφίες
        </Typography>
        <ImageGrid images={ticket?.images || []} isBase64 />
      </section>

      {/* Citizen info */}
      <section className="pb-10">
        <Typography fontWeight="bold" variant="h4" className="pb-2">
          Στοιχεία πολίτη
        </Typography>
        <ul className="list-disc ml-5">
          <ListItem
            fieldTitle="ΟΝΟΜΑ & ΕΠΩΝΥΜΟ"
            fieldValue={`${ticket?.citizenUserName || EMPTY_FIELD_TEXT} ${
              ticket?.citizenUserSurName || EMPTY_FIELD_TEXT
            }`}
          />
          <ListItem
            fieldTitle="ΠΑΤΡΩΝΥΜΟ"
            fieldValue={ticket?.citizenUserFatherName}
          />
          <ListItem
            fieldTitle="ΜΗΤΡΩΝΥΜΟ"
            fieldValue={ticket?.citizenUserMotherName}
          />
          <ListItem fieldTitle="ΑΦΜ" fieldValue={ticket?.citizenUserAfm} />
          <ListItem
            fieldTitle="ΤΗΛ"
            fieldValue={ticket?.citizenUserPhoneNumber}
          />
          <ListItem fieldTitle="EMAIL" fieldValue={ticket?.citizenUserEmail} />
        </ul>
      </section>

      {/* Priority */}
      <section className="pb-10">
        <section className="flex items-center">
          <Typography fontWeight="bold" variant="h4" className="pb-2">
            Προτεραιότητα
          </Typography>
          {ticket?.ticketPriority === TICKET_HIGH_PRIORITY_ID && (
            <ErrorOutline
              color={palette.error.main}
              sx={{
                fontSize: 25,
                marginBottom: '7px',
                marginLeft: 1,
                color: palette.error.main,
              }}
            />
          )}
        </section>
        <Typography>{ticket?.priorityLiteral || EMPTY_FIELD_TEXT}</Typography>
      </section>

      {/* Actions */}
      {actionSection}
    </div>
  );

  const noDataSection = <NoDataCard className="mt-10" />;

  useEffect(() => {
    const getTicket = async () => {
      setFetching(true);

      await AuthSetAgent.tickets
        .getTicketDetails(params?.ticketId)
        .then((response) => {
          setTicket(response?.result);
        })
        .finally(() => {
          setFetching(false);
        });
    };

    params?.ticketId && getTicket();
  }, [params?.ticketId]);

  // Force scroll to top of page on every visit
  // OR every time ticket data is updated
  //
  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, [ticket]);

  if (fetching || processing) {
    return loader;
  }

  return (
    <div>
      <Topbar title="Ηλεκτρονικές Υπηρεσίες ΠΔΕ" />
      {!ticket ? noDataSection : detailSection}
    </div>
  );
};

export default observer(TicketDetail);
