import { useEffect, useState } from 'react';
import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react-lite';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useUserStore } from '../../../Stores/UserStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import { ORG_NAME } from '../../../Config/constant';
import ScheduleActive from './ScheduleActive';

const ActiveSchedulesSelection = () => {
  const subDepartmentStore = useSubDepartmentStore();
  const userStore = useUserStore();
  const { authUser, isAdmin } = useAuthStore();
  const [selectedSubDepartment, setSelectedSubDepartment] = useState();

  useEffect(() => {
    if (subDepartmentStore.subDepartmentsList.length === 0)
      subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore]);

  useEffect(() => {
    if (userStore.users?.length === 0) userStore.getAllUsers();
  }, [userStore]);

  useEffect(() => {
    if (isAdmin === false && authUser?.subDepartmentId) {
      setSelectedSubDepartment(
        subDepartmentStore.subDepartmentsList.find(
          (item) => item.id === authUser?.subDepartmentId
        )
      );
    }
  }, [authUser, subDepartmentStore]);

  return (
    <div>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <div className="p-4">
        <Typography
          sx={{ fontWeight: 'medium' }}
          mt={2}
          variant="h3"
          color={'#003375'}
        >
          Ενεργά προγράμματα ραντεβού
        </Typography>
        {isAdmin === true && (
          <Typography
            sx={{ fontWeight: 'medium' }}
            mt={2}
            variant="h4"
            gutterBottom
          >
            Επιλογή υποτμήματος:
            <Autocomplete
              options={subDepartmentStore.subDepartmentsList}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setSelectedSubDepartment(newValue);
              }}
              sx={{ width: 300, marginTop: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="bg-[#FFFF] mt-14"
                  fullWidth
                  label={'Υποτμήμα'}
                />
              )}
            />
          </Typography>
        )}
      </div>
      {selectedSubDepartment?.name && (
        <ScheduleActive selectedSubDepartmentId={selectedSubDepartment.id} />
      )}
    </div>
  );
};

export default observer(ActiveSchedulesSelection);
