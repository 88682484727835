import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Modal, Typography } from '@mui/material';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { useNavigate } from 'react-router-dom';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs from 'dayjs';

const ScheduleSaved = ({ selectedSubDepartmentId, setLoading }) => {
  const authStore = useAuthStore();
  const subDepartmentStore = useSubDepartmentStore();
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [isModalPublishOpen, setIsModalPublishOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [isScheduleConflicting, setIsScheduleConflicting] = useState(false);
  const [conflictingDates, setConflictingDates] = useState([]);

  useEffect(() => {
    if (!authStore.authUserIsNotLoaded) {
      rantevouStore.getScheduleList(selectedSubDepartmentId);
      setReload(false);
      setLoading(false);
    }
  }, [
    rantevouStore.scheduleList?.length,
    authStore.authUserIsNotLoaded,
    authStore.authUser,
    reload,
    selectedSubDepartmentId,
  ]);

  useEffect(() => {
    rantevouStore.getActiveSchedules(selectedSubDepartmentId);
  }, [rantevouStore, selectedSubDepartmentId]);

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  const viewSchedule = (scheduleId) => {
    navigate(`/schedule-history/${scheduleId}`);
  };

  const deleteSchedule = async (scheduleId) => {
    try {
      setLoading(true);
      await rantevouStore.deleteSchedule(scheduleId);
    } catch (error) {
      console.error('An error occurred while deleting the schedule:', error);
    } finally {
      await rantevouStore.getScheduleList(authStore.authUser.departmentId);
      setLoading(false);
    }
  };

  const publishSchedule = async (scheduleId) => {
    setIsModalPublishOpen(false);

    try {
      setLoading(true);
      await rantevouStore.publishSchedule(scheduleId);
    } catch (error) {
      console.error('An error occurred while publishing the schedule:', error);
    } finally {
      await rantevouStore.getScheduleList(authStore.authUser.departmentId);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="ml-4 mt-6 max-w-[60%] min-w-[50%]">
        {rantevouStore.scheduleList
          ?.filter((schedule) => schedule.status === 0)
          ?.sort((a, b) => new Date(a.timeFrom) - new Date(b.timeFrom))
          ?.map((schedule) => {
            const isPastSchedule = dayjs(schedule.timeTo).isBefore(dayjs());

            const matchingSubDepartment =
              subDepartmentStore.subDepartmentsList?.find(
                (subDepartment) => subDepartment.id === schedule.subDepartmentId
              );

            if (matchingSubDepartment) {
              return (
                <div
                  className="flex flex-wrap mb-1 px-4 py-2 bg-[#ccf0ff] lg:items-center lg:justify-between"
                  key={schedule.id}
                >
                  <Typography
                    className="font-bold pt-2 pr-2"
                    variant="h5"
                    gutterBottom
                  >
                    Ημερ. {format(new Date(schedule.timeFrom), 'dd/MM/yyyy')} -{' '}
                    {format(new Date(schedule.timeTo), 'dd/MM/yyyy')} | Υποτμήμα{' '}
                    {matchingSubDepartment.name}
                  </Typography>
                  <div className="flex flex-wrap gap-2">
                    {authStore?.isAdmin && (
                      <>
                        <SubmitButton
                          sx={{
                            backgroundColor: '#e87272',
                            '&:hover': {
                              backgroundColor: '#9e5454',
                            },
                          }}
                          onClick={() => {
                            setScheduleId(schedule.id);
                            setIsModalDeleteOpen(true);
                          }}
                          className="capitalize text-sm"
                          title="Διαγραφη"
                          size="small"
                          variant="contained"
                          type="button"
                        />
                        <SubmitButton
                          disabled={isPastSchedule}
                          sx={{
                            backgroundColor: '#3CB371',
                            '&:hover': {
                              backgroundColor: '#8FBC8F',
                            },
                          }}
                          onClick={() => {
                            const maxDate = (date1, date2) =>
                              dayjs(date1).isAfter(dayjs(date2))
                                ? date1
                                : date2;
                            const minDate = (date1, date2) =>
                              dayjs(date1).isBefore(dayjs(date2))
                                ? date1
                                : date2;

                            const activeScedulesStartDates = Array.isArray(
                              rantevouStore.activeSchedules
                            )
                              ? rantevouStore.activeSchedules?.map((schedule) =>
                                  schedule.timeFrom.slice(0, 10)
                                )
                              : [];
                            const activeScedulesEndDates = Array.isArray(
                              rantevouStore.activeSchedules
                            )
                              ? rantevouStore.activeSchedules?.map((schedule) =>
                                  schedule.timeTo.slice(0, 10)
                                )
                              : [];
                            const scheduleStartDate = schedule.timeFrom.slice(
                              0,
                              10
                            );
                            const scheduleEndDate = schedule.timeTo.slice(
                              0,
                              10
                            );

                            const activeSchedulesPeriods =
                              activeScedulesStartDates?.map((date, index) => {
                                return {
                                  startDate: date,
                                  endDate: activeScedulesEndDates[index],
                                };
                              });

                            const conflicts = activeSchedulesPeriods
                              .filter((period) => {
                                return (
                                  dayjs(scheduleStartDate).isBefore(
                                    dayjs(period.endDate)
                                  ) &&
                                  dayjs(scheduleEndDate).isAfter(
                                    dayjs(period.startDate)
                                  )
                                );
                              })
                              .map((period) => ({
                                startDate: maxDate(
                                  scheduleStartDate,
                                  period.startDate
                                ),
                                endDate: minDate(
                                  scheduleEndDate,
                                  period.endDate
                                ),
                              }));

                            setConflictingDates(conflicts);
                            if (conflicts.length === 0) {
                              setIsScheduleConflicting(false);
                            } else {
                              setIsScheduleConflicting(true);
                            }
                            setScheduleId(schedule.id);
                            setIsModalPublishOpen(true);
                          }}
                          className="capitalize text-sm"
                          title="Δημοσιευση"
                          size="small"
                          variant="contained"
                          type="button"
                        />
                      </>
                    )}
                    <SubmitButton
                      sx={{
                        backgroundColor: '#003375',
                      }}
                      onClick={() => viewSchedule(schedule.id)}
                      className="capitalize text-sm"
                      title="Προβολη"
                      size="small"
                      variant="contained"
                      type="button"
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>

      {rantevouStore.scheduleList?.filter((schedule) => schedule.status === 0)
        ?.length === 0 && (
        <div className="flex justify-start items-center">
          <Typography variant="h5">
            Δεν υπάρχουν αποθηκευμένα προγράμματα
          </Typography>
        </div>
      )}

      <Modal
        className="flex items-center justify-center"
        open={isModalPublishOpen}
        onClose={() => {
          setIsModalPublishOpen(false);
          setConflictingDates([]);
        }}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-6 min-w-[25vw] min-h-[15vw] overflow-auto max-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalPublishOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen m-8 flex flex-col justify-center items-center">
            {isScheduleConflicting && (
              <div className="flex flex-col gap-4 justify-center items-center">
                <Typography variant="h4">
                  Υπάρχει επικάλυψη μεταξύ των ενεργών προγραμμάτων και αυτού
                  που θέλετε να δημοσιεύσετε.
                </Typography>
                <Typography variant="h4">
                  Εντοπίστηκαν επικαλύψεις στις παρακάτω ημερομηνίες:
                </Typography>
                {conflictingDates?.map((conflict, index) => {
                  return (
                    <Typography variant="h4" key={index}>
                      από {dayjs(conflict.startDate).format('DD/MM/YYYY')} έως{' '}
                      {dayjs(conflict.endDate).format('DD/MM/YYYY')}
                    </Typography>
                  );
                })}
                <Typography variant="h4">
                  Αν θέλετε να δημοσιεύσετε το τρέχον πρόγραμμα πρέπει πρώτα να
                  αρχειοθετήσετε το αντίστοιχο ενεργό πρόγραμμα.
                </Typography>
              </div>
            )}
            {!isScheduleConflicting && (
              <div className="flex flex-col gap-4 justify-center items-center">
                <Typography variant="h4">
                  Το τρέχον πρόγραμμα θα γίνει διαθέσιμο στους πολίτες.
                </Typography>
                <Typography variant="h4" className="pt-6">
                  Θέλετε να συνεχίσετε;
                </Typography>
              </div>
            )}
            {isScheduleConflicting && (
              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalPublishOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="OK"
                />
              </Typography>
            )}
            {!isScheduleConflicting && (
              <div className="flex justify-center items-center">
                <Typography variant="h4" className="pt-4">
                  <SubmitButton
                    onClick={() => publishSchedule(scheduleId)}
                    sx={{ backgroundColor: '#003375' }}
                    type="button"
                    title="NAI"
                  />
                </Typography>
                <Typography variant="h4" className="pt-4 pl-4">
                  <SubmitButton
                    onClick={() => setIsModalPublishOpen(false)}
                    sx={{ backgroundColor: '#003375' }}
                    type="button"
                    title="ΟΧΙ"
                  />
                </Typography>
              </div>
            )}
          </div>
        </MainCard>
      </Modal>

      <Modal
        className="flex items-center justify-center"
        open={isModalDeleteOpen}
        onClose={() => {
          setIsModalDeleteOpen(false);
        }}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-6 min-w-[25vw] min-h-[15vw] overflow-auto max-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalDeleteOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen m-8 flex flex-col justify-center items-center">
            <div className="flex flex-col gap-4 justify-center items-center">
              <Typography variant="h4">
                Το επιλεγμένο πρόγραμμα θα διαγραφεί.
              </Typography>
              <Typography variant="h4">Θέλετε να συνεχίσετε;</Typography>
            </div>
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                <SubmitButton
                  onClick={() => deleteSchedule(scheduleId)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="NAI"
                />
              </Typography>
              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalDeleteOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="ΟΧΙ"
                />
              </Typography>
            </div>
          </div>
        </MainCard>
      </Modal>
    </>
  );
};

export default observer(ScheduleSaved);
