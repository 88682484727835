import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { FORM_STATUS } from './ApplicationCategories';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutline } from '@mui/icons-material';
import { useEffect } from 'react';
import { subOrganizationsStore } from '../../../Stores/SubOrganizationsStore';
import { error, success } from '../../../Common/Toast/Toast';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';

const OrganizationsList = ({
  value,
  error,
  onChange,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Οργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="suborganizations-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {subOrganizationsStore.organizationsList?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio disabled={isEdit} value={item?.id} />}
              label={item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const APP_CATEGORIES_FIELDS = {
  Title: 'title',
  OrganizationId: 'organizationId',
};

const AppCategoriesSchema = yup.object().shape({
  [APP_CATEGORIES_FIELDS.Title]: yup.string().required(),
  [APP_CATEGORIES_FIELDS.OrganizationId]: yup.string().required(),
});

const defaultValues = {
  [APP_CATEGORIES_FIELDS.Title]: '',
  [APP_CATEGORIES_FIELDS.OrganizationId]: null,
};

const ApplicationCategoriesCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
  organizations,
}) => {
  const appointmentServiceStore = useAppointmentServiceStore();
  const [actionLoading, setActionLoading] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: isEdit
      ? {
          [APP_CATEGORIES_FIELDS.Title]: item?.[APP_CATEGORIES_FIELDS.Title],
          [APP_CATEGORIES_FIELDS.OrganizationId]:
            item?.[APP_CATEGORIES_FIELDS.OrganizationId],
        }
      : defaultValues,
    resolver: yupResolver(AppCategoriesSchema),
  });

  useEffect(() => {
    subOrganizationsStore.getAllOrganizations();
  }, [subOrganizationsStore.organizationsList?.length]);

  const submitCreate = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      ...watch(),
      [APP_CATEGORIES_FIELDS.OrganizationId]: parseInt(
        formValues[APP_CATEGORIES_FIELDS.OrganizationId]
      ),
    };
    await appointmentServiceStore
      .createNewApplicationCategory(formattedBody)
      .then(async () => {
        success('Η Κατηγορία Υπηρεσίας δημιουργήθηκε επιτυχώς');
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        reset(defaultValues);
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά τη δημιουργία Κατηγορίας Υπηρεσίας!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formattedBody = {
      ...watch(),
    };
    await appointmentServiceStore
      .updateApplicationCategory(item.id, formattedBody)
      .then(async () => {
        success('Η Κατηγορία Υπηρεσίας ενημερώθηκε επιτυχώς');
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error(
          'Παρουσιάστηκε σφάλμα κατά την επεξεργασία Κατηγορίας Υπηρεσίας!'
        );
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await submitCreate();
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [APP_CATEGORIES_FIELDS.Title]:
          item?.[APP_CATEGORIES_FIELDS.Title] || '',
        [APP_CATEGORIES_FIELDS.OrganizationId]:
          item?.[APP_CATEGORIES_FIELDS.OrganizationId] || null,
      });
    }
  }, [isEdit, item, reset]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit
          ? 'Επεξεργασία Κατηγορίας Υπηρεσίας'
          : 'Προσθήκη Κατηγορίας Υπηρεσίας'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={APP_CATEGORIES_FIELDS.Title}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label="Τίτλος"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Το πεδίο Τίτλος είναι υποχρεωτικό!'}
                />
              </Box>
            )}
          />
        </Box>
        <Controller
          name={APP_CATEGORIES_FIELDS.OrganizationId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OrganizationsList
              isEdit={isEdit}
              error={!!error}
              onChange={onChange}
              value={value}
              organizations={organizations}
            />
          )}
        />
        <Box className="flex justify-end">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              type="submit"
              title="Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default ApplicationCategoriesCreateEditForm;
