import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Typography } from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import {
  canAssignToMe,
  canComplete,
  canViewEmployeeActions,
} from '../../../Common/TicketHelpers/TicketHelpers';
import { useAuthStore } from '../../../Stores/AuthStore';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import { width } from '../../../styles/variables/width';

const SUBMISSION_STATE = {
  ASSIGN: 'assign',
  COMPLETE: 'complete',
  HIDDEN: 'hidden',
};

const CompleteSection = ({ onSubmit }) => {
  const [comments, setComments] = useState('');

  const onChange = useCallback((event) => {
    setComments(event?.target?.value || '');
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(comments);
  }, [comments, onSubmit]);

  return (
    <>
      <section className="pb-5">
        <Typography fontWeight="bold" variant="h4" className="pb-4">
          Ολοκλήρωση
        </Typography>
        <TextField
          fullWidth
          multiline
          placeholder="Σχόλια / απάντηση φορέα..."
          comments
          onChange={onChange}
          rows={4}
        />
      </section>
      <section className="flex justify-end">
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            disabled={!comments}
            onClick={handleSubmit}
            title={'Υποβολή'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </section>
    </>
  );
};

const TicketEmployeeActions = ({ onAssign, onComplete, ticket }) => {
  const { isSuperAdmin, authUser } = useAuthStore();
  const formSectionRef = useRef();
  const navigate = useNavigate();
  const [submissionState, setSubmissionState] = useState(
    SUBMISSION_STATE.HIDDEN
  );

  const assignButtons = useMemo(
    () => (
      <>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              marginRight: '15px',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            title={'Ακύρωση'}
            onClick={() => {
              if (submissionState === SUBMISSION_STATE.HIDDEN) {
                navigate(-1);
              } else {
                setSubmissionState(SUBMISSION_STATE.HIDDEN);
              }
            }}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            onClick={async () => {
              setSubmissionState(SUBMISSION_STATE.ASSIGN);
              await onAssign();
            }}
            title={'Το Αναλαμβάνω'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </>
    ),
    [navigate, onAssign, submissionState]
  );

  const completeButtons = useMemo(
    () => (
      <>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              marginRight: '15px',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            title={'Ακύρωση'}
            onClick={() => {
              if (submissionState === SUBMISSION_STATE.HIDDEN) {
                navigate(-1);
              } else {
                setSubmissionState(SUBMISSION_STATE.HIDDEN);
              }
            }}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: '#f9f9f9',
              width: width.button.md,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            className="capitalize text-sm"
            onClick={() => setSubmissionState(SUBMISSION_STATE.COMPLETE)}
            title={'Ολοκλήρωση'}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </>
    ),
    [navigate, submissionState]
  );

  const showActionButtons = useCallback(() => {
    if (canAssignToMe(ticket)) {
      return assignButtons;
    }

    if (canComplete(ticket)) {
      return completeButtons;
    }

    return null;
  }, [assignButtons, completeButtons, ticket]);

  const showActionForm = useCallback(
    (state) => {
      if (state === SUBMISSION_STATE.COMPLETE) {
        return (
          <ExpandableSectionContainer ref={formSectionRef}>
            <CompleteSection onSubmit={onComplete} />
          </ExpandableSectionContainer>
        );
      }

      return null;
    },
    [onComplete]
  );

  const resolveActionSection = useCallback(
    (state) => {
      return (
        <section>
          <section>
            <section className="flex items-center justify-end pb-10">
              {showActionButtons(state)}
            </section>
            {showActionForm(state)}
          </section>
        </section>
      );
    },
    [showActionButtons, showActionForm]
  );

  useEffect(() => {
    if (submissionState !== SUBMISSION_STATE.HIDDEN) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [submissionState]);

  if (!canViewEmployeeActions({ ticket, user: authUser, isSuperAdmin })) {
    return null;
  }

  return <section>{resolveActionSection(submissionState)}</section>;
};

export default TicketEmployeeActions;
