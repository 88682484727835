import React from 'react';
import CertificateSubmissionTable from '../CertificateSubmissionTable/CertificateSubmissionTable';
import CertificateOverviewCurrentResults from '../CertificatesOverview/CertificateOverviewCurrentResults';
import { observer } from 'mobx-react';

const CertificatesTable = ({ status, certificates }) => {
  const filteredCertificates = certificates?.filter(
    (item) => item?.status === status
  );

  if (status !== null) {
    return (
      <CertificateSubmissionTable
        status={status}
        certificates={filteredCertificates}
      />
    );
  } else {
    return <CertificateOverviewCurrentResults />;
  }
};

export default observer(CertificatesTable);
