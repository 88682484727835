import MainCard from '../Theme/Cards/MainCard';
import { Grid, Typography, useTheme } from '@mui/material';
import { themeColors } from '../../Config/Themes/theme';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
// import Icons from "../../assets/icons";

const NoDataCard = ({ className }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const { t } = useTranslation();
  const containerClasses = clsx([
    'p-[28vh] border-[1px] border-[#D1D5DB]',
    className,
  ]);

  return (
    <MainCard className={containerClasses}>
      <div className="flex items-center justify-center">
        {/* <Icons name="noDataIcon" width={71} height={66} /> */}
      </div>
      <Typography
        sx={{ color: colors.greyAccent.dark2 }}
        className="text-center mt-4 justify-center items-center text-2xl "
      >
        {t('noDataCard.NoData')}
      </Typography>
    </MainCard>
  );
};

export default NoDataCard;
