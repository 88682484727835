import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useCertificatesStore } from '../../Stores/CertificatesStore';
import { observer } from 'mobx-react';
import { error, success } from '../../Common/Toast/Toast';
import pistopoiitika from '../../assets/pistopoiitika.png';
import CircularSpinner from '../Theme/Spinners/CircularSpinner';
import { Button, Typography } from '@mui/material';
import SubmitButton from '../Theme/Extended/SubmitButton';
// import { toJS } from 'mobx';
import { useEffect } from 'react';
import AuthSetAgent from '../../Api/AuthSetAgent';
import { toJS } from 'mobx';
import { width } from '../../styles/variables/width';

const FileUploader = ({
  businessKey,
  fieldName,
  certificateSubmissionId,
  isEditMode = false,
  errors,
  helperText,
  onFileUploaded,
  formFieldValues = {},
  certificateSubmissionAttachments = [],
  signature = false,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  // const [fetchFile, setFetchFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [
  //   certificateSubmissionAttachments,
  //   setCertificateSubmissionAttachments,
  // ] = useState([]);
  const certificatesStore = useCertificatesStore();

  const handleUploadSuccess = (uploadedFileResp) => {
    setUploadedFile(uploadedFileResp);
    onFileUploaded(uploadedFileResp?.result?.fileName?.slice(9));
    certificatesStore.setUploadedFileResp(uploadedFileResp);
    if (uploadedFileResp?.success === true) {
      success('Το αρχείο ανέβηκε επιτυχώς');
    } else {
      error('Κάτι πήγε στραβά κατά την μεταφόρτωση του αρχείου');
    }
    setIsLoading(false);
  };

  const handleDownloadFile = () => {
    const uploadedFileAdjusted = certificateSubmissionAttachments?.filter(
      (att) => att?.fileName?.includes(`st3_file`)
    );

    const adjustedFileName = uploadedFileAdjusted[0]?.fileName;

    if (uploadedFileAdjusted) {
      setIsLoading(true);

      certificatesStore
        .dowloadFile(uploadedFile?.result?.fileName || adjustedFileName)
        .then((resp) => {
          // const dispositionHeader = resp?.headers['content-disposition'];
          // const filenameMatch = dispositionHeader?.match(
          //   /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          // );
          const fileName = adjustedFileName ? adjustedFileName : null;

          const blob = new Blob([resp?.data], {
            type: resp?.headers['content-type'],
          });

          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName || `file`;
          document.body.appendChild(a);
          a.click();

          URL.revokeObjectURL(url);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   if (isEditMode) {
  //     setIsLoading(true);
  //     certificatesStore
  //       .dowloadFile(businessKey, fieldName)
  //       .then((resp) => {
  //         setFetchFile(resp);
  //       })
  //       .catch((error) => {})
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // }, [businessKey, fieldName, fetchFile?.length]);

  // useEffect(() => {
  //   if (isEditMode && fieldName && businessKey && certificateSubmissionId) {
  //     setIsLoading(true);
  //     AuthSetAgent.certificatesSubmissions
  //       .getCertificateSubmissionAttachments(certificateSubmissionId)
  //       .then((resp) => {
  //         setCertificateSubmissionAttachments(resp?.result);
  //       })
  //       .catch(() => {})
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // }, [isEditMode, businessKey, certificateSubmissionId, fieldName]);

  const handleUploadError = (errorType) => {
    switch (errorType) {
      case 'upload':
        error('Κάτι πήγε στραβά κατά τη μεταφόρτωση του αρχείου');
        break;
      case 'delete':
        error('Kάτι πήγε στραβά κατά τη διαγραφή του αρχείου');
        break;
      default:
        error('Παρουσιάστηκε σφάλμα');
        break;
    }
    setIsLoading(false);
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles[0].type !== 'application/pdf') {
      error('Επιτρέπονται μόνο αρχεία τύπου pdf.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    certificatesStore
      .uploadFile(
        businessKey,
        fieldName,
        certificateSubmissionId,
        formData,
        signature
      )
      .then((uploadedFileResp) => {
        handleUploadSuccess(uploadedFileResp);
        certificatesStore.setUploadedFileResp(uploadedFileResp);
      })
      .catch((error) => {
        handleUploadError('upload');
      });
  };

  const handleRemoveFile = () => {
    const uploadedFileAdjusted = certificateSubmissionAttachments?.filter(
      (att) => att?.fileName?.includes(`${businessKey}_${fieldName}`)
    );

    const adjustedFileName = uploadedFileAdjusted[0]?.fileName;

    if (uploadedFile || !isEditMode || uploadedFileAdjusted) {
      setIsLoading(true);

      certificatesStore
        .removeFile(uploadedFile?.result?.fileName || adjustedFileName)
        .then((resp) => {
          if (resp?.success === true) {
            success('Το αρχείο διαγράφηκε με επιτυχία');
          } else {
            error('Κάτι πήγε στραβά κατά την διαγραφή του αρχείου');
          }
          setUploadedFile(null);
          onFileUploaded('');
          setIsLoading(false);
        })
        .catch((error) => {
          handleUploadError('delete');
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.pdf',
    multiple: false,
  });

  // console.log('formFieldValues', toJS(formFieldValues));

  return (
    <div className="file-uploader-container cursor-pointer w-[342px] h-[230px] border-2 border-dashed p-4">
      {isEditMode ? (
        <div className="flex justify-center ml-6 items-center pt-12">
          <img src={pistopoiitika} width={80} alt="pistopiitika" />
        </div>
      ) : (
        <div {...getRootProps()} className="dropzone">
          {errors && (
            <Typography
              className="pt-2"
              variant="h5"
              justifyContent={'center'}
              alignItems={'center'}
              color="error"
            >
              {helperText}
            </Typography>
          )}
          <input {...getInputProps()} />
          <div className="flex justify-center mr-2  items-center pt-12">
            <img src={pistopoiitika} width={80} alt="pistopiitika" />
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="flex justify-center items-center pb-4">
          <CircularSpinner />
        </div>
      ) : (
        uploadedFile && (
          <div className="uploaded-file text-center mt-4">
            {/* <Typography className="pt-2" variant="h5">
              Όνομα αρχείου: {uploadedFile?.result?.fileName.slice(9)}
            </Typography> */}
            <SubmitButton
              sx={{
                backgroundColor: '#003375',
                color: '#f9f9f9',
                // width: width.button.md,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              type="button"
              loading={isLoading}
              variant="contained"
              onClick={handleDownloadFile}
              title="Λήψη"
            />

            <Button
              loading={isLoading}
              sx={{
                backgroundColor: '#B60202',
                color: '#f9f9f9',
                marginLeft: '30px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#B60202',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              className="capitalize text-sm"
              type="button"
              variant="contained"
              onClick={handleRemoveFile}
            >
              Διαγραφή
            </Button>
          </div>
        )
      )}

      {isEditMode && !isLoading && (
        <div className="flex flex-col items-center justify-center w-full gap-4 min-h-[120px]">
          <div className="flex justify-center items-center flex-wrap gap-1 w-full">
            {certificateSubmissionAttachments?.map((attachment, index) => {
              const fileNameParts = attachment.fileName?.split('_');
              const extractedPart = fileNameParts.slice(1).join('_');

              if (extractedPart.includes(fieldName)) {
                const valueExists = certificateSubmissionAttachments?.some(
                  (attachment) =>
                    attachment?.fileName?.includes(
                      formFieldValues[fieldName]?.value
                    )
                );

                if (valueExists) {
                  return (
                    <div key={index}>
                      <SubmitButton
                        sx={{
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                          // width: width.button.md,
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#003375',
                            color: '#f9f9f9',
                          },
                          fontSize: '1rem',
                        }}
                        type="button"
                        loading={isLoading}
                        variant="contained"
                        onClick={handleDownloadFile}
                        title="Λήψη"
                      />
                    </div>
                  );
                }
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(FileUploader);
