import { Grid } from '@mui/material';
import { gridSpacing } from '../../../Config/constant';
import UserForm from '../../UserForm/UserForm';
import Link from '../../../Components/Theme/Link/Link';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { ORG_NAME } from '../../../Config/constant';

const UserCreate = () => {
  return (
    <>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <div
        className="mt-16 bg-[#F9F9F9]"
        title={
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={gridSpacing}
          >
            <Grid item>
              <PageTitle title={'Δημιουργία χρήστη'} />
            </Grid>
            <Grid item className="flex">
              <Link to="/users" className="ml-2 mb-2 capitalize text-sm">
                Πισω
              </Link>
            </Grid>
          </Grid>
        }
        content={false}
      >
        <UserForm />
      </div>
    </>
  );
};

export default observer(UserCreate);
