import { Box } from '@mui/material';
import { forwardRef } from 'react';

const ExpandableSectionContainer = forwardRef(({ children }, ref) => {
  return (
    <section className="pt-10 pb-20" ref={ref}>
      <Box className="w-1/2 pb-10 border-t-2" />
      {children}
    </section>
  );
});

export default ExpandableSectionContainer;
