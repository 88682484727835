import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import { format } from 'date-fns';

class CertificatesStore {
  certificatesList = [];

  availableCertificatesList = [];

  availableCertificatesByKeyList = [];

  certificatesSubmissionStatusesList = [];

  certificatesSearchData = [];

  certificateSubmissionsByIdList = [];

  allCertificateSubmissionData = [];

  currentCertificatesData = [];

  uploadedFileResp = {};

  constructor() {
    makeObservable(this, {
      certificatesList: observable,
      availableCertificatesList: observable,
      availableCertificatesByKeyList: observable,
      certificatesSubmissionStatusesList: observable,
      certificatesSearchData: observable,
      certificateSubmissionsByIdList: observable,
      allCertificateSubmissionData: observable,
      currentCertificatesData: observable,
      uploadedFileResp: observable,
      getCertificates: action,
      getCertificatesById: action,
      getAvailableCertificates: action,
      getAvailableCertificatesByKey: action,
      updateCertificate: action,
      addNewCertificate: action,
      getAllCertificateSubmission: action,
      getCurrentCertificateSubmission: action,
      getCertificatesSubmissionStatuses: action,
      getCertificatesSubmission: action,
      getCertificatesSubmissionsById: action,
      claimCertificateSubmission: action,
      getTaskById: action,
      completeTaskByCertSubId: action,
      uploadFile: action,
      setUploadedFileResp: action,
      dowloadFile: action,
      removeFile: action,
      multiGetCertificatesSubmissionsById: action,
    });
  }

  getAvailableCertificates() {
    AuthSetAgent.certificates
      .getAvailableCertificates()
      .then((availableCertificates) => {
        this.availableCertificatesList = availableCertificates.result;
      })
      .catch((e) => {});
  }

  getAvailableCertificatesByKey(key) {
    AuthSetAgent.certificates
      .getAvailableCertificatesByKey(key)
      .then((availableCertificates) => {
        this.availableCertificatesByKeyList = availableCertificates.result;
      })
      .catch((e) => {});
  }

  getCertificates(departmentId, pageNumber = null, pageSize = null) {
    return AuthSetAgent.certificates
      .getCertificates(departmentId, pageNumber, pageSize)
      .then((res) => {
        this.certificatesList = res.result.items;
        return res.result;
      })
      .catch((e) => {});
  }

  getCertificatesSubmissionStatuses() {
    AuthSetAgent.certificatesSubmissions
      .getCertificatesSubmissionStatuses()
      .then((statuses) => {
        this.certificatesSubmissionStatusesList = statuses;
      })
      .catch((e) => {});
  }

  getCertificatesSubmissionsById(id) {
    return AuthSetAgent.certificatesSubmissions
      .getCertificatesSubmissionsById(id)
      .then((resp) => {
        this.certificateSubmissionsByIdList = resp.result;
      })
      .catch((e) => {});
  }

  async multiGetCertificatesSubmissionsById(iDsArray) {
    const promises = iDsArray?.map((idObj) =>
      AuthSetAgent.certificatesSubmissions
        .getCertificatesSubmissionsById(idObj?.notifiableId)
        .then((resp) => {
          return resp.result;
        })
        .catch((e) => {})
    );

    const submissions = await Promise.all(promises).then((response) => {
      return response;
    });

    this.certificateSubmissionsByIdList = submissions.map((item, index) => ({
      ...item,
      notificationId: iDsArray[index]?.id,
    }));
  }

  getCertificatesSubmission(
    departmentId,
    Id,
    ProtocolNo,
    FinalizedFrom,
    FinalizedTo,
    ApplicationCategoryId,
    Status,
    CitizenLastName,
    CitizenAfm
  ) {
    return AuthSetAgent.certificatesSubmissions
      .getCertificatesSubmission(
        departmentId || '',
        Id || '',
        ProtocolNo || '',
        FinalizedFrom?.slice(0, 10) || '',
        FinalizedTo?.slice(0, 10) || '',
        ApplicationCategoryId || {},
        Status || '',
        CitizenLastName || '',
        CitizenAfm || ''
      )
      .then((response) => {
        this.certificatesSearchData = response.result.items;
      });
  }

  getAllCertificateSubmission = (
    departmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    return AuthSetAgent.certificatesSubmissions
      .getAllCertificatesSubmission(
        departmentId ? departmentId : '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        pageNumber,
        pageSize
      )
      .then((res) => {
        this.allCertificateSubmissionData = res.result.items;
        return res;
      })
      .catch((error) => {});
  };

  getCurrentCertificateSubmission = (
    departmentId,
    FinalizedFrom,
    FinalizedTo,
    pageNumber = null,
    pageSize = null
  ) => {
    return AuthSetAgent.certificatesSubmissions
      .getAllCertificatesSubmission(
        departmentId || '',
        '',
        '',
        FinalizedFrom?.slice(0, 10) ??
          format(new Date(), 'yyyy-MM-dd').slice(0, 10),
        FinalizedTo?.slice(0, 10) ??
          format(new Date(), 'yyyy-MM-dd').slice(0, 10),
        '',
        '',
        '',
        '',
        pageNumber,
        pageSize
      )
      .then((res) => {
        this.currentCertificatesData = res.result.items;
        return res;
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  getCertificatesById = (certificateId) => {
    return AuthSetAgent.certificates.getCertificatesById(certificateId);
  };

  getTaskById = (certSubId) => {
    return AuthSetAgent.certificatesSubmissions.getTaskById(certSubId);
  };

  addNewCertificate(data) {
    return AuthSetAgent.certificates.addNewCertificate(data);
  }

  claimCertificateSubmission = (certSubId) => {
    return AuthSetAgent.certificatesSubmissions.claimCertificateSubmission(
      certSubId
    );
  };

  completeTaskByCertSubId = (certSubId, body) => {
    return AuthSetAgent.certificatesSubmissions.completeTaskByCertSubId(
      certSubId,
      body
    );
  };

  uploadFile = (
    businessKey,
    fieldName,
    certificateSubmissionId,
    formData,
    signature
  ) => {
    return AuthSetAgent.certificatesSubmissions
      .uploadFile(
        businessKey,
        fieldName,
        certificateSubmissionId,
        formData,
        signature
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };

  setUploadedFileResp = (response) => {
    this.uploadedFileResp = response.result;
  };

  removeFile = (fileName) => {
    return AuthSetAgent.certificatesSubmissions
      .removeFile(fileName)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };

  dowloadFile = (fileName) => {
    return AuthSetAgent.certificatesSubmissions
      .dowloadFile(fileName)
      .then((response) => {
        return response;
      })
      .catch((error) => {});
  };

  updateCertificate(certificateId, body) {
    return AuthSetAgent.certificates.updateCertificateById(certificateId, body);
  }

  getStatusCount = () => {
    return AuthSetAgent.certificatesSubmissions.getStatusCount();
  };
}

export const certificatesStore = new CertificatesStore();
export const CertificatesStoreContext = createContext(certificatesStore);
export const CertificatesStoreProvider = ({ children, store }) => (
  <CertificatesStoreContext.Provider value={store}>
    {children}
  </CertificatesStoreContext.Provider>
);
export const useCertificatesStore = () => useContext(CertificatesStoreContext);
