import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useState } from 'react';
import { useEffect } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error } from '../../../Common/Toast/Toast';
import { ExpandMore } from '@mui/icons-material';
import { palette } from '../../../styles/variables/colors';

const ChartAccordion = ({ item, children }) => (
  <Accordion
    defaultExpanded
    disableGutters
    key={item?.accordionKey}
    className="w-full pl-[24px] py-[5px]"
    sx={{
      border: 'none',
      minHeight: 'initial',
      margin: 0,
      boxShadow: 'none',
      '& .MuiPaper-root:before': {
        display: 'none',
        backgroundColor: 'white',
      },
      '& .MuiPaper-root-MuiAccordion-root:before': {
        display: 'none',
        backgroundColor: 'white',
      },
      '& .MuiPaper-root-MuiAccordion-root.Mui-expanded': {
        minHeight: 'initial',
        margin: 0,
      },
      '& .MuiAccordionSummary-content': {
        padding: 0,
        margin: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
        padding: 0,
        minHeight: 'initial',
      },
      '& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'initial',
        margin: 0,
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="suborganization-panel-content"
      sx={{
        border: 'none',
        minHeight: 'initial',
        margin: 0,
        marginVertical: 5,
        boxShadow: 'none',
        '& .MuiPaper-root-MuiAccordion-root:before': {
          display: 'none',
          backgroundColor: 'white',
        },
      }}
    >
      <Box className="py-2">
        <Typography sx={{ flexShrink: 0 }} fontSize={16}>
          <b>{item?.name}</b>
        </Typography>
      </Box>
    </AccordionSummary>
    <AccordionDetails
      sx={{
        border: 'none',
        minHeight: 'initial',
        margin: 0,
        boxShadow: 'none',
        borderLeft: `2px solid ${palette.gray.light}`,
        marginLeft: '20px',
      }}
    >
      {children}
    </AccordionDetails>
  </Accordion>
);

const ChartItemNoChildren = ({ children }) => (
  <div className="flex flex-row flex-wrap ml-[40px] py-[5px]">
    <Typography sx={{ flexShrink: 0 }} fontSize={16}>
      {children}
    </Typography>
  </div>
);

const loader = (
  <Box className="flex justify-center items-center p-20">
    <CircularProgress size={30} />
  </Box>
);

const noDataMessage = (
  <Box className="flex justify-center items-center p-10">
    <Typography className="pt-6 pb-5" variant="h4" gutterBottom>
      Δεν υπάρχουν καταχωρήσεις
    </Typography>
  </Box>
);

const OrganizationChart = () => {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [subOrganizations, setSubOrganizations] = useState();
  const [sectors, setSectors] = useState();
  const [departments, setDepartments] = useState();
  const [subDepartments, setSubDepartments] = useState();

  const handleOrganizationChange = (event, value) => {
    setSelectedOrganization(value);
  };

  const hierarchy = subOrganizations?.map((subOrg) => ({
    ...subOrg,
    children: sectors
      ?.filter((sector) => sector?.subOrganizationId === subOrg?.id)
      ?.map((sector) => ({
        ...sector,
        children: departments
          ?.filter((dep) => dep?.sectorId === sector?.id)
          ?.map((dep) => ({
            ...dep,
            children: subDepartments?.filter(
              (subDep) => subDep?.departmentId === dep?.id
            ),
          })),
      })),
  }));

  const renderHierarchy = (parent) => {
    if (!parent?.children?.length) {
      return (
        <ChartItemNoChildren key={parent?.id}>
          {parent?.name}
        </ChartItemNoChildren>
      );
    }

    return (
      <ChartAccordion item={parent} key={parent?.id}>
        {parent?.children?.map((child) => renderHierarchy(child))}
      </ChartAccordion>
    );
  };

  useEffect(() => {
    (async () => {
      await Promise.all([
        AuthSetAgent.subOrganizations.getSubOrganizations().then((response) => {
          setSubOrganizations(response?.result.items);
        }),
        AuthSetAgent.sectors.getSectors().then((response) => {
          setSectors(response?.result.items);
        }),
        AuthSetAgent.department.getDepartments().then((response) => {
          setDepartments(response?.result.items);
        }),
        AuthSetAgent.subDepartment.getSubDepartments().then((response) => {
          setSubDepartments(response?.result.items);
        }),
        AuthSetAgent.organizations.getOrganizations().then((response) => {
          setOrganizations(response?.result.items);
        }),
      ])
        .catch(() => {
          error('Παρουσιάστηκε σφάλμα!');
        })
        .finally(() => setLoading(false));
    })();
  }, []);

  const resolveContent = () => {
    if (!loading && !subOrganizations?.length) {
      return noDataMessage;
    }

    return loading ? (
      loader
    ) : (
      <Box className="bg-white">
        {hierarchy
          ?.filter((org) => org?.organizationId === selectedOrganization?.id)
          ?.map((org) => renderHierarchy(org))}
      </Box>
    );
  };

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Box className="ml-4 pb-20">
        <Typography
          className="pt-6 pb-5"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Οργανόγραμμα
        </Typography>
        <Autocomplete
          fullWidth
          value={selectedOrganization}
          onChange={handleOrganizationChange}
          options={organizations}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField {...params} label="Επιλέξτε Οργανισμό" />
          )}
        />
        <div className="mt-6">{resolveContent()}</div>
      </Box>
    </Box>
  );
};

export default OrganizationChart;
