import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { FORM_STATUS } from './SubDepartments';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutline } from '@mui/icons-material';
import { useEffect } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const OrganizationsList = ({
  value,
  error,
  onChange,
  organizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="organizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Οργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="organizations-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {organizations?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio disabled={isEdit} value={item?.id} />}
              label={item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SubOrganizationsList = ({
  value,
  error,
  onChange,
  subOrganizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Υποοργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!subOrganizations?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν Υποοργανισμοί για αυτόν τον Οργανισμό
            </Typography>
          ) : (
            subOrganizations?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SectorsList = ({
  value,
  error,
  onChange,
  sectors,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Τομέα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!sectors?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένες Τομείς για αυτόν τον Υποοργανισμό
            </Typography>
          ) : (
            sectors?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const DepartmentsList = ({
  value,
  error,
  onChange,
  departments,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε το Τμήμα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!departments?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένα Τμήματα για αυτόν τον Τομέα
            </Typography>
          ) : (
            departments?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const SUBDEPARTMENT_FIELD = {
  Name: 'name',
  Email: 'email',
  Address: 'address',
  DepartmentId: 'departmentId',
  SectorId: 'sectorId',
  SubOrganizationId: 'subOrganizationId',
  OrganizationId: 'organizationId',
  Telephone: 'tel',
};

const SectorSchema = yup.object().shape({
  [SUBDEPARTMENT_FIELD.Name]: yup.string().required(),
  [SUBDEPARTMENT_FIELD.DepartmentId]: yup.string().required(),
  [SUBDEPARTMENT_FIELD.SectorId]: yup.string().required(),
  [SUBDEPARTMENT_FIELD.SubOrganizationId]: yup.string().required(),
  [SUBDEPARTMENT_FIELD.OrganizationId]: yup.string().notRequired(),
});

const defaultValues = {
  [SUBDEPARTMENT_FIELD.Name]: '',
  [SUBDEPARTMENT_FIELD.DepartmentId]: null,
  [SUBDEPARTMENT_FIELD.SectorId]: null,
  [SUBDEPARTMENT_FIELD.SubOrganizationId]: null,
  [SUBDEPARTMENT_FIELD.OrganizationId]: null,
};

const SubDepartmentCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
  departments,
  sectors,
  subOrganizations,
  organizations,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const { handleSubmit, control, reset, setValue, watch } = useForm({
    defaultValues: isEdit
      ? {
          [SUBDEPARTMENT_FIELD.Name]: item?.[SUBDEPARTMENT_FIELD.Name],
          [SUBDEPARTMENT_FIELD.DepartmentId]:
            item?.[SUBDEPARTMENT_FIELD.DepartmentId],
          [SUBDEPARTMENT_FIELD.SubOrganizationId]:
            item?.[SUBDEPARTMENT_FIELD.SubOrganizationId],
          [SUBDEPARTMENT_FIELD.OrganizationId]:
            item?.[SUBDEPARTMENT_FIELD.OrganizationId],
          [SUBDEPARTMENT_FIELD.SectorId]: item?.[SUBDEPARTMENT_FIELD.SectorId],
        }
      : defaultValues,
    resolver: yupResolver(SectorSchema),
  });
  const selectedOrganizationId = watch(SUBDEPARTMENT_FIELD.OrganizationId);
  const selectedDepartmentId = watch(SUBDEPARTMENT_FIELD.DepartmentId);
  const selectedSubOrganizationId = watch(
    SUBDEPARTMENT_FIELD.SubOrganizationId
  );
  const selectedSectorId = watch(SUBDEPARTMENT_FIELD.SectorId);
  const filteredSubOrganizations = subOrganizations?.filter(
    (subOrg) =>
      subOrg?.organizationId?.toString() === selectedOrganizationId?.toString()
  );
  const filteredSectors = sectors?.filter(
    (sector) =>
      sector?.subOrganizationId?.toString() ===
      selectedSubOrganizationId?.toString()
  );
  const filteredDepartments = departments?.filter(
    (dep) => dep?.sectorId?.toString() === selectedSectorId?.toString()
  );

  const submitCreate = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      [SUBDEPARTMENT_FIELD.Name]: formValues?.[SUBDEPARTMENT_FIELD.Name],
      [SUBDEPARTMENT_FIELD.DepartmentId]: parseInt(
        formValues?.[SUBDEPARTMENT_FIELD.DepartmentId]
      ),
    };
    await AuthSetAgent.subDepartment
      .createSubDepartment(formattedBody)
      .then(async (resp) => {
        if (resp?.success === true) {
          success('To Υποτμήμα δημιουργήθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την δημιουργία του υποτμήματος');
        }
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        reset(defaultValues);
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά τη δημιουργία Υποτμήματος!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      [SUBDEPARTMENT_FIELD.Name]: formValues?.[SUBDEPARTMENT_FIELD.Name],
    };
    await AuthSetAgent.subDepartment
      .updateSubDepartment(item?.id, formattedBody)
      .then(async (resp) => {
        if (resp?.success === true) {
          success('To Υποτμήμα ενημερώθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την ενημέρωση του υποτμήματος');
        }
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά την επεξεργασία Υποτμήματος!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await submitCreate();
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [SUBDEPARTMENT_FIELD.Name]: item?.[SUBDEPARTMENT_FIELD.Name] || '',
        [SUBDEPARTMENT_FIELD.DepartmentId]:
          item?.[SUBDEPARTMENT_FIELD.DepartmentId] || null,
        [SUBDEPARTMENT_FIELD.SectorId]:
          item?.[SUBDEPARTMENT_FIELD.SectorId] || null,
        [SUBDEPARTMENT_FIELD.OrganizationId]:
          item?.[SUBDEPARTMENT_FIELD.OrganizationId] || null,
        [SUBDEPARTMENT_FIELD.SubOrganizationId]:
          item?.[SUBDEPARTMENT_FIELD.SubOrganizationId] || null,
      });
    }
  }, [isEdit, item, reset]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit ? 'Επεξεργασία Υποτμήματος' : 'Προσθήκη Υποτμήματος'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={SUBDEPARTMENT_FIELD.Name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1 max-w-[50%]"
                  label="Ονομασία"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Ονομασία Τμήματος είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
        </Box>

        <Controller
          name={SUBDEPARTMENT_FIELD.OrganizationId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OrganizationsList
              error={!!error}
              onChange={(event) => {
                setValue(
                  SUBDEPARTMENT_FIELD.OrganizationId,
                  event?.target?.value
                );
                if (selectedOrganizationId) {
                  setValue(SUBDEPARTMENT_FIELD.SubOrganizationId, null);
                  setValue(SUBDEPARTMENT_FIELD.SectorId, null);
                  setValue(SUBDEPARTMENT_FIELD.DepartmentId, null);
                }
              }}
              organizations={organizations}
              value={value}
              isEdit={isEdit}
            />
          )}
        />

        <Controller
          name={SUBDEPARTMENT_FIELD.SubOrganizationId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              {!!watch(SUBDEPARTMENT_FIELD.OrganizationId) && (
                <Box className="ml-20">
                  <SubOrganizationsList
                    error={!!error}
                    onChange={onChange}
                    subOrganizations={filteredSubOrganizations || []}
                    value={value}
                    isEdit={isEdit}
                  />
                </Box>
              )}
            </>
          )}
        />

        <Controller
          name={SUBDEPARTMENT_FIELD.SectorId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              {!!watch(SUBDEPARTMENT_FIELD.SubOrganizationId) && (
                <Box className="ml-20">
                  <SectorsList
                    error={!!error}
                    onChange={(event) => {
                      setValue(
                        SUBDEPARTMENT_FIELD.SectorId,
                        event?.target?.value
                      );
                      if (selectedDepartmentId) {
                        setValue(SUBDEPARTMENT_FIELD.DepartmentId, null);
                      }
                    }}
                    value={value}
                    sectors={filteredSectors || []}
                    isEdit={isEdit}
                  />
                </Box>
              )}
            </>
          )}
        />

        <Controller
          name={SUBDEPARTMENT_FIELD.DepartmentId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              {!!watch(SUBDEPARTMENT_FIELD.SectorId) && (
                <Box className="ml-40">
                  <DepartmentsList
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    departments={filteredDepartments || []}
                    isEdit={isEdit}
                  />
                </Box>
              )}
            </>
          )}
        />
        <Box className="flex justify-end pt-5">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              title="Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {}}
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default SubDepartmentCreateEditForm;
