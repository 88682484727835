import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from '@mui/material';

// project imports
import MainCard from '../Theme/Cards/MainCard';
import Transitions from '../Theme/Extended/Transitions';
// assets
import { observer } from 'mobx-react';
import { useThemeStore } from '../../Stores/ThemeStore';
import { useAuthStore } from '../../Stores/AuthStore';

// ==============================|| PROFILE MENU ||============================== //

const ProfileToolTip = () => {
  const theme = useTheme();
  const themeStore = useThemeStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const handleLogout = () => {
    navigate('/');
    authStore.logout();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, route = '') => {
    themeStore.openMenuItem(route);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          background: 'none',
        }}
        label={<img width="45px" src="/profile_user.png" alt="user"></img>}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        className="z-[1] rounded-[4px]"
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper className="mt-2">
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box
                    sx={{ p: 1, paddingTop: 0, backgroundColor: 'lightgray' }}
                  >
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: 'lightgray',
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%',
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton
                        sx={{ borderRadius: `4px` }}
                        selected={themeStore.isMenuItemOpen('/profile')}
                        onClick={(event) =>
                          handleListItemClick(event, '/profile')
                        }
                      >
                        <ListItemIcon>
                          <img width="35px" src="/profile.png" alt="profile" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Grid item>
                              <span className="govgr-caption-l">Προφίλ</span>
                            </Grid>
                          }
                        />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: `4px` }}
                        selected={false}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <img width="35px" src="/exodos.png" alt="exodos" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <span className="govgr-caption-l">Έξοδος</span>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default observer(ProfileToolTip);
