import { Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';

const HomePage = () => {
  return (
    <>
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <div className="ml-6 mb-15">
        <div className="mt-6">
          <Typography variant="h3" color={'#003375'} gutterBottom>
            Καλώς ήρθατε στις ηλεκτρονικές υπηρεσίες
          </Typography>
        </div>
      </div>
    </>
  );
};

export default HomePage;
