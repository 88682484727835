import { action, makeObservable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import { error } from '../Common/Toast/Toast';

class EventsStore {
  constructor() {
    makeObservable(this, {
      getEvents: action,
      getEventById: action,
      createEvent: action,
      updateEvent: action,
      deleteEvent: action,
      uploadImage: action,
      deleteImage: action,
      downloadImage: action,
    });
  }

  getEvents = (currentPage = null, pageSize = null) => {
    return AuthSetAgent.events
      .getEvents(currentPage, pageSize)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getEventById = (id) => {
    return AuthSetAgent.events
      .getEventById(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createEvent = (event, config) => {
    return AuthSetAgent.events
      .createEvent(event, config)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateEvent = (id, event, config) => {
    return AuthSetAgent.events
      .updateEvent(id, event, config)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteEvent = (id) => {
    return AuthSetAgent.events
      .deleteEvent(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  uploadImage = (id, image) => {
    return AuthSetAgent.events
      .uploadImage(id, image)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteImage = (filename) => {
    return AuthSetAgent.events
      .deleteImage(filename)
      .then((response) => {
        if (response?.success) {
          return response;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  downloadImage = (filename) => {
    return AuthSetAgent.events
      .downloadImage(filename)
      .then((response) => {
        if (!response?.data) {
          return null;
        }
        const blob = new Blob([response?.data], {
          type: response?.headers['content-type'],
        });
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const eventsStore = new EventsStore();
export const EventsStoreContext = createContext(eventsStore);
export const EventsStoreProvider = ({ children, store }) => (
  <EventsStoreContext.Provider value={store}>
    {children}
  </EventsStoreContext.Provider>
);
export const useEventsStore = () => useContext(EventsStoreContext);
