import { Box, Typography } from "@mui/material";

const CounterBox = ({ counter, title, borderColor }) => {
    return (
        <Box
            sx={{
                width: 200,
                height: 200,
                border: "2px solid",
                borderColor: borderColor ?? "#003375",
                borderRadius: 2,
                padding: 2,
                margin: 2,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Typography variant="h4" color={borderColor ?? "#003375"} align="center" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h1" color={borderColor ?? "#003375"} align="center" gutterBottom fontWeight={'bold'}>
                {counter}
            </Typography>
            <Typography variant="h4" color={borderColor ?? "#003375"} align="center" gutterBottom>
                νέες ειδοποιήσεις
            </Typography>
        </Box>

    )
}

export default CounterBox;