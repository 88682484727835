import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack, TextField, Autocomplete, Button } from '@mui/material';

// third-party
import * as yup from 'yup';
import AnimateButton from '../../Components/Theme/Extended/AnimateButton';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import SubmitButton from '../../Components/Theme/Extended/SubmitButton';
import { observer } from 'mobx-react';
import { useUserStore } from '../../Stores/UserStore';
import { useSubDepartmentStore } from '../../Stores/SubDepartmentStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { error, success } from '../../Common/Toast/Toast';
import { phoneRegex } from '../../Common/Regex';
import { width } from '../../styles/variables/width';

const UserForm = ({ userData, isEditMode = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const navigate = useNavigate();
  const usersStore = useUserStore();
  const subDepartmentStore = useSubDepartmentStore();

  const filteredRoles = (roles) => {
    const excludedRoleNames = [
      'SectorSupervisor',
      'Citizen',
      'ContentManager',
      'SubOrganizationSupervisor',
      'SuperAdmin',
      'OrganizationSupervisor',
      'Moderator',
      'DepartmentAdmin',
    ];

    return roles?.filter((role) => !excludedRoleNames?.includes(role?.name));
  };

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  useEffect(() => {
    usersStore.getAllRoles();
  }, [usersStore.roles?.length, usersStore]);

  const userSchema = yup.object({
    username: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    name: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    surname: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    role: yup.object().notRequired(),
    email: yup
      .string()
      .email()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    subDepartmentId: yup.object().notRequired(),
    phoneNumber: yup
      .string()
      .trim()
      .strict(true)
      .matches(phoneRegex, t('validation.InvalidPhone'))
      .required('Το πεδίο ειναι υποχρεωτικό'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: userData?.username || '',
      name: userData?.name || '',
      surname: userData?.surname || '',
      email: userData?.email || '',
      subDepartmentId:
        subDepartmentStore.subDepartmentsList?.find(
          (c) => c.id === userData?.departmentId
        ) || null,
      phoneNumber: userData?.phoneNumber || '',
    },
    resolver: yupResolver(userSchema),
  });

  const createNewUser = (data) => {
    if (loading) return;

    setLoading(true);

    usersStore
      .createUserByDepartmentAdmin({
        ...data,
        subDepartmentId: data.subDepartmentId?.id,
        role: data.role.name,
      })
      .then((resp) => {
        if (resp?.success) {
          navigate('/users');
          success('Ο χρήστης δημιουργήθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την δημιουργία του χρήστη');
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const updateExistingUser = (data) => {
    if (loading || !userData.id) return;

    setLoading(true);

    usersStore
      .updateUser(userData.id, {
        ...data,
        subDepartmentId: data.subDepartmentId?.id,
      })
      .then((resp) => {
        if (resp?.success) {
          navigate('/users');
          success('Ο χρήστης ενημερώθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την ενημέρωση του χρήστη');
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const performApiRequest = (data) =>
    userData?.id ? updateExistingUser(data) : createNewUser(data);

  return (
    <div className="pb-5">
      <form onSubmit={handleSubmit(performApiRequest)}>
        <Grid className="p-2" container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controller
              name="username"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className="mt-14 bg-[#FFFF]"
                  fullWidth
                  label={t('users.UserName')}
                  value={value}
                  disabled={isEditMode}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className="mt-14 bg-[#FFFF]"
                  fullWidth
                  label={t('users.Name')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="surname"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className="mt-14 bg-[#FFFF]"
                  fullWidth
                  label={t('users.SurName')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="email"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className="mt-14 bg-[#FFFF]"
                  fullWidth
                  label={t('users.Email')}
                  value={value}
                  disabled={isEditMode}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className="mt-14 bg-[#FFFF]"
                  fullWidth
                  label={t('users.PhoneNumber')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            {subDepartmentStore.subDepartmentsList?.length &&
              selectedRole?.name === 'User' && (
                <Grid item md={12} xs={12}>
                  <Controller
                    name="subDepartmentId"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        disabled={isEditMode}
                        onChange={(e, value) => onChange(value)}
                        value={value}
                        options={subDepartmentStore?.subDepartmentsList}
                        getOptionLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, id) =>
                          option?.id === id?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-[#FFFF]"
                            label={'Υποτμήματα'}
                            error={!!error}
                            helperText={
                              error ? t(error.message, { min: 1 }) : null
                            }
                            disabled={isEditMode}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
          </Grid>

          {isEditMode ? null : (
            <Grid item md={6} xs={12}>
              <Controller
                name="role"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    disabled={isEditMode}
                    onChange={(e, value) => {
                      onChange(value);
                      setSelectedRole(value);
                    }}
                    value={value}
                    options={filteredRoles(usersStore.roles)}
                    getOptionLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, id) => option?.id === id?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-[#FFFF]"
                        label={'Ρόλοι'}
                        error={!!error}
                        helperText={error ? t(error.message, { min: 1 }) : null}
                        disabled={isEditMode}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          )}

          {isEditMode ? (
            <Grid container spacing={3} className="pt-12">
              <Grid item xs={12} className="flex justify-end">
                <Stack direction="row" justifyContent="space-between">
                  <AnimateButton>
                    <Button
                      sx={{
                        backgroundColor: '#003375',
                        color: '#f9f9f9',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                        },
                        fontSize: '1rem',
                      }}
                      loading={loading}
                      variant="contained"
                      type="submit"
                    >
                      Υποβολή
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3} className="pt-6">
              <Grid item xs={12} className="flex justify-end">
                <Stack direction="row" justifyContent="space-between">
                  <div className="mr-4">
                    <AnimateButton>
                      <SubmitButton
                        sx={{
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                          width: width.button.md,
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#003375',
                            color: '#f9f9f9',
                          },
                          fontSize: '1rem',
                        }}
                        className="capitalize text-sm"
                        title="Επιστροφή"
                        variant="contained"
                        type="button"
                        onClick={() => navigate(-1)}
                      />
                    </AnimateButton>
                  </div>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <AnimateButton>
                    <SubmitButton
                      sx={{
                        backgroundColor: '#003375',
                        color: '#f9f9f9',
                        width: width.button.md,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                        },
                        fontSize: '1rem',
                      }}
                      loading={loading}
                      variant="contained"
                      type="submit"
                      title="Υποβολή"
                    />
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

UserForm.propTypes = {
  userData: PropTypes.object,
};

export default observer(UserForm);
