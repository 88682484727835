import AuthCardWrapper from "../../../Components/Theme/AuthCardWrapper";
import {
  Box,
  Divider,
  TextField,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AnimateButton from "../../../Components/Theme/Extended/AnimateButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { authStore } from "../../../Stores/AuthStore";
import SubmitButton from "../../../Components/Theme/Extended/SubmitButton";
import { useState } from "react";
import { themeColors } from "../../../Config/Themes/theme";
import { observer } from "mobx-react";

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const colors = themeColors(theme.palette.mode);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Καταχωρήστε έγκυρο email")
      .trim()
      .strict(true)
      .required("Το email είναι υποχρεωτικό πεδίο"),
  });

  const { handleSubmit, control } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = ({ email }) => {
    if (loading) return;

    setLoading(true);

    authStore.forgotPassword(email).finally(() => {
      setLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthCardWrapper pageName="forgotPass">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item container paddingBottom="1rem" xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t("forgotPassword.EmailAddress")}
                    variant="filled"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message) : null}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <AnimateButton>
              <SubmitButton
                className="text-lg"
                buttonTitle="forgotButton"
                sx={{ backgroundColor: "#003375" }}
                disableElevation
                loading={loading}
                type="submit"
              />
            </AnimateButton>
          </Box>

          <Grid container className="pt-6 flex items-end justify-end" xs={12}>
            <Typography
              component={Link}
              to="/"
              style={{
                color: "#168D8B",
              }}
              sx={{ textDecoration: "none" }}
            >
              Εχετε ήδη λογαριασμό ;
            </Typography>
          </Grid>
        </Grid>
      </AuthCardWrapper>
    </form>
  );
};

export default observer(ForgotPasswordPage);
