import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { TICKET_FIELDS, TICKET_STATUS } from '../../../Config/constant';
import { formatDateInDateMonthYear } from '../../../Common/formatDate/formatDate';
import clsx from 'clsx';
import { palette } from '../../../styles/variables/colors';

const ActionButton = ({ className, id }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => {
    navigate(`/ticket/${id}`);
  }, [navigate, id]);

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Tooltip onClick={onClick} placement="top" title={'Προβολή'}>
        <Visibility
          sx={{
            cursor: 'pointer',
            fontSize: '1.1rem',
            marginLeft: '1.8rem',
            color: '#333333B2',
          }}
        />
      </Tooltip>
    </Stack>
  );
};

const columns = [
  {
    field: TICKET_FIELDS.Code,
    headerName: 'Κωδ.',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 0.5,
    renderCell: (params) => (
      <Typography
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px] pl-2"
      >
        {params?.row?.[TICKET_FIELDS.Id] || '-'}
      </Typography>
    ),
  },
  {
    field: TICKET_FIELDS.SubmissionDate,
    headerName: 'Ημ. Υποβολής',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1.1,
    renderCell: (params) => (
      <Typography
        align="left"
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px]"
      >
        {formatDateInDateMonthYear(
          params?.row?.[TICKET_FIELDS.SubmissionDate]
        ) || '-'}
      </Typography>
    ),
  },
  {
    field: TICKET_FIELDS.CategoryText,
    headerName: 'Κατηγορία',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1.8,
    renderCell: (params) => (
      <Typography
        align="left"
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px]"
      >
        {params?.row?.[TICKET_FIELDS.CategoryText] || '-'}
      </Typography>
    ),
  },
  {
    field: TICKET_FIELDS.ServiceText,
    headerName: 'Υπηρεσία/Πρόβλημα',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1.8,
    renderCell: (params) => (
      <Typography
        align="left"
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px]"
      >
        {params?.row?.[TICKET_FIELDS.ServiceText] || '-'}
      </Typography>
    ),
  },
  {
    field: TICKET_FIELDS.DeparmentName,
    headerName: 'Τμήμα',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    renderCell: (params) => (
      <Typography
        align="left"
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px]"
      >
        {params?.row?.[TICKET_FIELDS.DeparmentName] || '-'}
      </Typography>
    ),
  },
  {
    field: TICKET_FIELDS.StatusText,
    headerName: 'Κατάσταση',
    headerClassName: 'tickets-table-theme--header font-bold',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => {
      return (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[TICKET_FIELDS.StatusText]}
        </Typography>
      );
    },
  },
  {
    field: 'Priority',
    headerName: 'Προτεραιότητα',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    align: 'left',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1.1,
    renderCell: (params) => (
      <Typography
        align="left"
        variant="subtitle1"
        component="div"
        className="flex items-center text-[14px]"
      >
        {params?.row?.priorityLiteral || '-'}
      </Typography>
    ),
  },
  {
    field: 'actions',
    align: 'left',
    headerName: 'Προβολή',
    headerClassName: 'tickets-table-theme--header',
    cellClassName: 'tickets-table-theme--row',
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => (
      <ActionButton id={params?.row?.[TICKET_FIELDS.Id]} />
    ),
  },
];

const TicketsSearchTable = ({ isLoading, label, tickets }) => {
  const tableTitleClasses = clsx([
    'ml-4 my-2 pt-2',
    !tickets?.length && 'pb-2',
  ]);

  return (
    <>
      <div
        className="bg-[#003375] text-[#FFFFFF] text-[1.4rem]"
        style={{ width: '99%' }}
      >
        <div className={tableTitleClasses}>{label}</div>
        {!!tickets?.length && (
          <DataGrid
            sx={{
              '& .MuiDataGrid-root': {
                borderWidth: 0,
              },
              '& .tickets-table-theme--header': {
                backgroundColor: '#FFFFFF',
                fontSize: 15,
                borderBottom: '2px solid black',
              },
              '& .tickets-table-theme--row': {
                backgroundColor: '#FFFFFF',
                border: 'none',
                overflowWrap: 'break-word',
                minHeight: '40px !important',
                borderBottom: `1px solid ${palette.gray.light}`,
                // padding: '10px 0',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              ' & .MuiDataGrid-footerContainer': {
                backgroundColor: '#FFFFFF',
              },
              ' & .MuiTypography-root': {
                textWrap: 'wrap',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              // sorting: {
              //   sortModel: [
              //     { field: TICKET_FIELDS.Code, sort: 'desc' },
              //     { field: TICKET_FIELDS.SubmissionDate, sort: 'desc' },
              //   ],
              // },
            }}
            getRowHeight={() => 'auto'}
            disableColumnSelector
            columns={columns}
            rows={tickets || []}
            loading={isLoading}
            autoHeight
            disableSelectionOnClick
          />
        )}
      </div>
      {!tickets?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default TicketsSearchTable;
