import { observer } from 'mobx-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  FormControl,
} from '@mui/material';

import TextField from '@mui/material/TextField';

// project imports
import AuthCardWrapper from '../../../Components/Theme/AuthCardWrapper';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import { useState } from 'react';
import { useAuthStore } from '../../../Stores/AuthStore';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import PasswordInput from '../../../Components/Theme/Inputs/PasswordInput';
import { themeColors } from '../../../Config/Themes/theme';

const LoginForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const authStore = useAuthStore();

  const loginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Καταχωρήστε έγκυρο email')
      .trim()
      .strict(true)
      .required('Το email είναι υποχρεωτικό πεδίο'),
    password: yup
      .string()
      .required('Ο κωδικός είναι υποχρεωτικός')
      .strict(true)
      .min(8, 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες')
      .max(32, 'Ο κωδικός δεν μπορεί να έχει περισσότερους από 32 χαρακτήρες'),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);

    authStore
      .login(data, navigate, location)
      .then((resp) => {})
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthCardWrapper className="min-w-full" pageName="login">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item container direction="column" alignItems="center" xs={12}>
            <Grid item container marginBottom="1rem" xs={12}>
              <FormControl
                sx={{ marginBottom: '1.2rem', width: '100%', top: '1rem' }}
              >
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label={t('login.EmailAddress')}
                      variant="filled"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? t(error.message) : null}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              container
              direction="column"
              marginTop="1rem"
              alignItems="center"
              xs={12}
              sx={{ marginTop: '8px' }}
            >
              <FormControl
                sx={{
                  marginBottom: '1.2rem',
                  width: '100%',
                  bottom: '1rem',
                  marginTop: '0.5rem',
                }}
              >
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t('login.Password')}
                      onChange={onChange}
                      value={value}
                      error={!!error}
                      helperText={error ? t(error.message) : null}
                      variant="filled"
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Box sx={{ mt: 2 }}>
              <AnimateButton>
                <SubmitButton
                  className="text-lg"
                  buttonTitle="loginButton"
                  sx={{ backgroundColor: '#003375' }}
                  disableElevation
                  loading={loading}
                  type="submit"
                />
              </AnimateButton>
            </Box>
          </Grid>

          <Grid container className="pt-4 flex items-end justify-end" xs={16}>
            <Typography
              style={{
                color: '#168D8B',
              }}
              component={Link}
              to="/forgot-password"
              sx={{ textDecoration: 'none' }}
            >
              Ξεχάσατε τον κωδικό σας ;
            </Typography>
          </Grid>
        </Grid>
      </AuthCardWrapper>
      

       <div className='bg-red-200 flex justify-center items-center'>
        <p>{process.env.REACT_APP_VERSION}</p>
      </div>
     
    </form>
  );
};

export default observer(LoginForm);
