import { Tab, Tabs as MuiTabs } from '@mui/material';
import { useState } from 'react';
import TabPanel from './TabPanel';
import { Link } from 'react-router-dom';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Tabs({ tabs, children, className, initialTab }) {
  const [value, setValue] = useState(initialTab ?? 0);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  return (
    <>
      <div className="historic-filter">
        {tabs.map(
          ({ historicFilter }, index) => index === value && historicFilter
        )}
      </div>

      <MuiTabs
        value={value}
        className={`mb-2 ${className}`}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        sx={{
          color: '#003375',
          mb: 3,
          minHeight: 'auto',
          '& button': {
            minWidth: 100,
            fontSize: '0.9rem',
          },
          '& a': {
            minHeight: 'auto',
            minWidth: 10,
            py: 1.5,
            px: 1,
            mr: 2.25,
            color: 'grey.600',
          },
          '& .Mui-selected': {
            '&.css-11tp5cj-MuiButtonBase-root-MuiTab-root.Mui-selected': {
              color: '#00339F',
            },
          },
          '& .css-1ktsofq-MuiTabs-indicator': {
            backgroundColor: '#00339F',
          },
        }}
      >
        {tabs.map(({ label, to }, index) => (
          <Tab
            key={index}
            component={to ? Link : null}
            to={to}
            label={label}
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>

      {tabs.map(({ Component }, index) => (
        <TabPanel value={value} index={index} key={index}>
          {Component}
        </TabPanel>
      ))}
    </>
  );
}
