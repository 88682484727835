import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import Accordions from '../../../Components/Accordion/Accordion';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import parse from 'html-react-parser';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { error, success } from '../../../Common/Toast/Toast';
import FileUploader from '../../../Components/FileUploader/FileUploader';
import { statusTranslations } from '../../../Config/constant';
import { useRef } from 'react';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { width } from '../../../styles/variables/width';

const CertificateSubmissionEdit = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [selectedButtonLabel, setSelectedButtonLabel] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [errors, setErrors] = useState({});
  const [adminReviewValue, setAdminReviewValue] = useState('');
  const [
    certificateSubmissionAttachments,
    setCertificateSubmissionAttachments,
  ] = useState([]);
  const departmentStore = useDepartmentStore();
  const certificatesStore = useCertificatesStore();
  const { id } = useParams();

  const validateForm = () => {
    const newErrors = {};
    if (!inputValues['admin_comment']) {
      newErrors['admin_comment'] = 'Το πεδίο είναι υποχρεωτικό';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const renderComponentsText = (components) => {
    return components.map((component, index) => {
      if (component.type === 'text' && component.text) {
        return <p key={index}>{parse(component.text)}</p>;
      }
      return null;
    });
  };

  const renderButtonBackgroundColorBasedOnLabel = (label) => {
    switch (label) {
      case 'Έγκριση':
        return '#56A22E';
      case 'Απαιτούνται διορθώσεις':
        return '#5271FF';
      case 'Απόρριψη':
        return '#B60202';
      default:
        return '#FFFFFF';
    }
  };

  const handleInputChange = (componentKey, value) => {
    const newErrors = { ...errors };
    if (newErrors[componentKey]) {
      newErrors[componentKey] = '';
      setErrors(newErrors);
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      [componentKey]: value,
    }));
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();
    const payload = {
      variables: {
        adminReview: {
          value: adminReviewValue,
        },
        // admin_file: {
        //   value: toJS(certificatesStore.uploadedFileResp?.fileName),
        // },
      },
      withVariablesInReturn: true,
    };

    for (const key in inputValues) {
      payload.variables[key] = {
        value: inputValues[key],
      };
    }

    if (isFormValid) {
      setLoading(true);
      certificatesStore
        .completeTaskByCertSubId(
          certificatesStore.certificateSubmissionsByIdList?.id,
          payload
        )
        .then((resp) => {
          if (resp?.code === 200) {
            success('Υποβάλατε επιτυχώς την αίτηση');
            navigate('/certificates-overview');
          } else {
            error('Υπήρξε κάποιο πρόβλημα κατα την υποβολή της αίτησης');
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const approveSubmission = () => {
    const payload = {
      variables: {
        adminReview: {
          value: adminReviewValue,
        },
      },
      withVariablesInReturn: true,
    };
    setLoading(true);
    certificatesStore
      .completeTaskByCertSubId(
        certificatesStore.certificateSubmissionsByIdList?.id,
        payload
      )
      .then((resp) => {
        success('Υποβάλατε επιτυχώς την αίτηση');
        navigate(`/signed-certificate-upload/${id}`);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const renderButtonActionContent = () => {
    switch (selectedButtonLabel) {
      case 'Έγκριση':
        return (
          <ExpandableSectionContainer ref={ref}>
            <div>
              <Typography
                className="bg-[#F2F2F2] w-[80%] h-[47px]"
                variant="h4"
              >
                <div className="pt-[10px]">
                  {' '}
                  <b>Έγκριση δημιουργίας πιστοποιητικού</b>{' '}
                </div>
              </Typography>
              {/* <div className="mt-4 mb-4">
                {taskData?.components
                  ?.filter(
                    (component) =>
                      component.type === 'textarea' ||
                      component.type === 'textfield'
                  )
                  .map((component, index) => (
                    <div key={index}>
                      {component.type === 'textarea' ? (
                        <>
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <TextField
                            fullWidth={true}
                            label={component?.label}
                            variant="outlined"
                            type={
                              component.type === 'textarea'
                                ? 'textarea'
                                : 'text'
                            }
                            multiline={component.type === 'textarea' ? 4 : null}
                            value={inputValues[component.key] || ''}
                            onChange={(e) => {
                              const { value } = e.target;
                              handleInputChange(component.key, value);
                            }}
                            required
                            error={!!errors[component.key]}
                            helperText={errors[component.key] || ''}
                          />
                        </>
                      ) : component.key === 'admin_file' ? (
                        <div className="mt-2">
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <div className="mt-1 mr-12">
                            <FileUploader
                              certificateSubmissionId={
                                certificatesStore.certificateSubmissionsByIdList
                                  ?.id
                              }
                              isEditMode={false}
                              businessKey={
                                certificatesStore.certificateSubmissionsByIdList
                                  .businessKey
                              }
                              fieldName={component.key}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
              </div> */}
              <div className="flex justify-end items-end pb-4 pr-[210px] pt-[25px]">
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    width: width.button.md,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  className="capitalize text-sm"
                  variant="contained"
                  loading={loading}
                  type={'submit'}
                  onClick={approveSubmission}
                  title="Υποβολή"
                />
              </div>
            </div>
          </ExpandableSectionContainer>
        );
      case 'Απαιτούνται διορθώσεις':
        return (
          <ExpandableSectionContainer ref={ref}>
            <div>
              <Typography variant="h4">
                <b>Απαιτούμενες ενέργειες - Εκκρεμότητες</b>
              </Typography>
              <div className="mt-4 mb-4">
                {taskData?.components
                  ?.filter(
                    (component) =>
                      component.type === 'textarea' ||
                      component.type === 'textfield' ||
                      component.type === 'select'
                  )
                  .map((component, index) => (
                    <div key={index}>
                      {component.type === 'textarea' && (
                        <>
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <TextField
                            fullWidth={true}
                            label={component?.label}
                            variant="outlined"
                            type={
                              component.type === 'textarea'
                                ? 'textarea'
                                : 'text'
                            }
                            multiline={component.type === 'textarea' ? 4 : null}
                            value={inputValues[component.key] || ''}
                            onChange={(e) => {
                              const { value } = e.target;
                              handleInputChange(component.key, value);
                            }}
                            required
                            error={!!errors[component.key]}
                            helperText={errors[component.key] || ''}
                          />
                        </>
                      )}
                      {/* ) : component.key === 'admin_file' ? (
                        <div className="mt-2">
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <div className="mt-1 mr-12">
                            <FileUploader
                              certificateSubmissionId={
                                certificatesStore.certificateSubmissionsByIdList
                                  ?.id
                              }
                              isEditMode={false}
                              businessKey={
                                certificatesStore.certificateSubmissionsByIdList
                                  .businessKey
                              }
                              fieldName={component.key}
                            />
                          </div>
                        </div>
                      ) : null */}
                    </div>
                  ))}
              </div>
              <div className="flex justify-end items-end pb-4">
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    width: width.button.md,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  className="capitalize text-sm"
                  variant="contained"
                  loading={loading}
                  type={'submit'}
                  onClick={handleSubmit}
                  title="Υποβολή"
                />
              </div>
            </div>
          </ExpandableSectionContainer>
        );
      case 'Απόρριψη':
        return (
          <ExpandableSectionContainer ref={ref}>
            <div>
              <Typography variant="h4">
                <b>Απόρριψη αίτησης</b>
              </Typography>
              <div className="mt-4 mb-4">
                {taskData?.components
                  ?.filter(
                    (component) =>
                      component.type === 'textarea' ||
                      component.type === 'textfield'
                  )
                  .map((component, index) => (
                    <div key={index}>
                      {component.type === 'textarea' && (
                        <>
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <TextField
                            fullWidth={true}
                            label={component?.label}
                            variant="outlined"
                            type={
                              component.type === 'textarea'
                                ? 'textarea'
                                : 'text'
                            }
                            multiline={component.type === 'textarea' ? 4 : null}
                            value={inputValues[component.key] || ''}
                            onChange={(e) => {
                              const { value } = e.target;
                              handleInputChange(component.key, value);
                            }}
                            required
                            error={!!errors[component.key]}
                            helperText={errors[component.key] || ''}
                          />
                        </>
                      )}
                      {/* ) : component.key === 'admin_file' ? (
                        <div className="mt-2">
                          {' '}
                          <Typography className="pb-4 pt-4" variant="h4">
                            <b>{component?.label}</b>
                          </Typography>
                          <div className="mt-1 mr-12">
                            <FileUploader
                              certificateSubmissionId={
                                certificatesStore.certificateSubmissionsByIdList
                                  ?.id
                              }
                              isEditMode={false}
                              businessKey={
                                certificatesStore.certificateSubmissionsByIdList
                                  .businessKey
                              }
                              fieldName={component.key}
                            />
                          </div>
                        </div>
                      ) : null */}
                    </div>
                  ))}
              </div>
              <div className="flex justify-end items-end pb-4">
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    width: width.button.md,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  className="capitalize text-sm"
                  variant="contained"
                  loading={loading}
                  type={'submit'}
                  onClick={handleSubmit}
                  title="Υποβολή"
                />
              </div>
            </div>
          </ExpandableSectionContainer>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedButtonLabel) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [selectedButtonLabel]);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [
    certificatesStore.certificatesSubmissionStatusesList?.length,
    certificatesStore,
  ]);

  useEffect(() => {
    setLoading(true);
    certificatesStore
      .getCertificatesSubmissionsById(id)
      .then((resp) => {})
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, [
    certificatesStore.certificateSubmissionsByIdList?.length,
    id,
    certificatesStore,
  ]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length, departmentStore]);

  useEffect(() => {
    if (certificatesStore.certificateSubmissionsByIdList?.id) {
      certificatesStore
        .getTaskById(certificatesStore.certificateSubmissionsByIdList?.id)
        .then((resp) => {
          setTaskData(resp?.result);
        })
        .catch(() => {});
    }
  }, [
    certificatesStore.certificateSubmissionsByIdList?.length,
    certificatesStore.certificateSubmissionsByIdList?.id,
    setTaskData,
    taskData?.length,
    certificatesStore,
  ]);

  useEffect(() => {
    if (certificatesStore.certificateSubmissionsByIdList?.id) {
      setLoading(true);
      AuthSetAgent.certificatesSubmissions
        .getCertificateSubmissionAttachments(
          certificatesStore.certificateSubmissionsByIdList?.id
        )
        .then((resp) => {
          setCertificateSubmissionAttachments(resp?.result);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [certificatesStore.certificateSubmissionsByIdList?.id]);

  return (
    <>
      {loading ? (
        <div className="text-center py-10">
          <CircularProgress color="primary" size="3rem" />
        </div>
      ) : (
        <>
          <div>
            <Topbar title="Ηλεκτρονικές Υπηρεσίες ΠΔΕ" />
          </div>

          <div className="flex justify-start items-start pl-2">
            <Typography
              className="pt-4 pr-4"
              variant="h3"
              color={'#003375'}
              gutterBottom
            >
              {certificatesStore.certificateSubmissionsByIdList?.certificate
                ?.name
                ? certificatesStore.certificateSubmissionsByIdList?.certificate
                    ?.name
                : '-'}{' '}
              - Έλεγχος
            </Typography>
          </div>

          <div className="flex justify-start items-start pl-2 pt-8">
            <div className="flex flex-col">
              <div className="rounded-xl bg-[#BFE5EF] w-52 h-[38px]">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Αίτησης:{' '}
                  {certificatesStore.certificateSubmissionsByIdList?.id
                    ? certificatesStore.certificateSubmissionsByIdList?.id
                    : '-'}
                  <div />
                </div>
              </div>

              {/* <div className="rounded-xl bg-[#BFE5EF] w-52 h-[38px] mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList?.protocolNo
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.protocolNo
                    : '-'}
                  <div />
                </div>
              </div> */}

              {/* <div className="rounded-xl bg-[#BFE5EF] w-52 mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.submissionsProtocol
                    ?.value
                    ? certificatesStore?.certificateSubmissionsByIdList
                        ?.submissionInfo?.formSubmittedValues
                        ?.submissionsProtocol?.value
                    : '-'}
                </div>
              </div> */}
              <div className="rounded-xl bg-[#BFE5EF] w-52 mt-1">
                <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                  Aρ. Πρωτοκόλλου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.submissionsProtocol
                    ?.value
                    ? certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues?.submissionsProtocol?.value.slice(
                        0,
                        3
                      )
                    : '-'}
                </div>
              </div>
            </div>

            <div className="rounded-xl bg-[#0965D4] w-48 h-[80px] ml-4">
              <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                Κατάσταση: <br />
                {statusTranslations[
                  certificatesStore.certificateSubmissionsByIdList?.status
                ] || '-'}
                <div />
              </div>
            </div>

            {certificatesStore.certificateSubmissionsByIdList?.status === 3 ? (
              <div className="rounded-xl bg-[#56A22E] w-48 h-[80px] ml-6 p-0">
                <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                  <div className="flex justify-center items-center">
                    <div>Στάδιο ελέγχου:</div>
                    {/* {certificatesStore.certificateSubmissionsByIdList
                      ?.certificate?.reviewProcessId ? (
                      <div className="ml-2">1ο</div>
                    ) : (
                      <div className="ml-2">-</div>
                    )} */}
                  </div>
                  <div>
                    {certificatesStore.certificateSubmissionsByIdList
                      ?.activeTaskId
                      ? certificatesStore.certificateSubmissionsByIdList
                          ?.activeTaskId
                      : '---'}
                  </div>
                </div>
              </div>
            ) : null}

            {certificatesStore.certificateSubmissionsByIdList?.status === 5 ? (
              <div className="rounded-xl bg-[#56A22E] w-48 h-[80px] ml-6 p-0">
                <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                  <div className="flex justify-center items-center">
                    <div>Στάδιο ελέγχου:</div>
                    {/* {certificatesStore.certificateSubmissionsByIdList
                      ?.certificate?.reviewProcessId ? (
                      <div className="ml-2">1ο</div>
                    ) : (
                      <div className="ml-2">-</div>
                    )} */}
                  </div>
                  <div>{'Aξιολόγηση'}</div>
                </div>
              </div>
            ) : null}

            {certificatesStore.certificateSubmissionsByIdList?.status === 4 ||
            certificatesStore.certificateSubmissionsByIdList?.status === 6 ? (
              <div className="rounded-xl bg-[#56A22E] w-48 h-[80px] ml-6 p-0">
                <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                  <div className="flex justify-center items-center">
                    <div>Στάδιο ελέγχου:</div>
                  </div>
                  <div>
                    {certificatesStore.certificateSubmissionsByIdList
                      ?.certificate?.reviewProcessId
                      ? 'Ολοκληρώθηκε'
                      : '-'}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="rounded-xl bg-[#BFE5EF] px-2 flex justify-start w-[634px] h-[37px] mt-6 pt-1 ml-2">
            <div className="text-[1.2rem] text-center text-[#194682]">
              Υπεύθυνος αξιολόγησης σταδίου:{' '}
              {certificatesStore.certificateSubmissionsByIdList?.claimedByUser
                ?.name
                ? certificatesStore.certificateSubmissionsByIdList
                    ?.claimedByUser?.name
                : '-'}{' '}
              {certificatesStore.certificateSubmissionsByIdList?.claimedByUser
                ?.surName
                ? certificatesStore.certificateSubmissionsByIdList
                    ?.claimedByUser?.surName
                : '-'}
              <div />
            </div>
          </div>

          <Accordions
            className="mt-8"
            text={`Οδηγίες αίτησης`}
            icon={<InfoOutlinedIcon className="mr-2" fontSize="large" />}
          >
            <div className="py-4 px-2 border-[1px] border-gray-400">
              <Typography variant="h4" className="text-[20px]">
                Η αίτηση επέχει θέση υπεύθυνης δήλωσης του άρθρου 8 Ν.1599/1986.
                Η ακρίβεια των στοιχείων που υποβάλλονται με αυτή τη δήλωση
                μπορεί να ελεγχθεί με βάση το αρχείο άλλων υπηρεσιών.
              </Typography>

              <Typography variant="h4" className="pt-4">
                Με ατομική μου ευθύνη και γνωρίζοντας τις κυρώσεις (1), που
                προβλέπεται από τις διατάξεις της παρ.6 του άρθρου 22 του
                Ν.1599/1986, δηλώνει ότι:
              </Typography>

              <Typography variant="h4" className="pt-4">
                (1) Όποιος εν γνώσει του δηλώνει ψευδή γεγονότα ή αρνείτε ή
                αποκρύπτει τα αληθινά με έγγραφη υπεύθυνη δήλωση του άρθρου 8
                τιμωρείται με φυλάκιση τουλάχιστον τριών μηνών. Εάν ο υπαίτιος
                αυτών των πράξεων σκόπευε να προσπορίσει στον εαυτόν του ή σε
                άλλον περιουσιακό όφελος τρίτον ή σκόπευε να βλάψει άλλον,
                τιμωρείται με κάθειρξη μέχρι 10 ετών.
              </Typography>
            </div>
          </Accordions>

          {certificatesStore.certificateSubmissionsByIdList?.certificate?.formComponents?.map(
            (certificateSubmissions, index) => (
              <div key={index} className="flex justify-start items-start">
                <Accordions
                  key={index}
                  className="mt-8"
                  text={certificateSubmissions.name}
                >
                  <Typography className="pl-4 pt-2" variant="h4" gutterBottom>
                    {renderComponentsText(certificateSubmissions.components)}
                  </Typography>
                  <div key={index}>
                    <div className="mt-6 grid grid-cols-3 gap-4">
                      {certificateSubmissions.components
                        ?.filter((component) => component.type !== 'text')
                        .map((component, componentIndex) => {
                          if (component.type === 'group') {
                            const groupComponents = component.components || [];

                            const groupFields = groupComponents?.filter(
                              (groupField) =>
                                groupField?.type !== 'text' &&
                                groupField?.type !== 'button'
                            );

                            return groupFields?.map(
                              (groupField, groupIndex) => {
                                const key = groupField?.key;
                                const baseKey = key?.split('_index')[0];

                                const formValueKeys = Object?.keys(
                                  certificatesStore
                                    .certificateSubmissionsByIdList
                                    ?.submissionInfo?.formSubmittedValues
                                )?.filter((valueKey) =>
                                  valueKey?.startsWith(baseKey)
                                );

                                return formValueKeys?.map(
                                  (formValueKey, formValueIndex) => {
                                    const submittedValue =
                                      certificatesStore
                                        .certificateSubmissionsByIdList
                                        ?.submissionInfo?.formSubmittedValues[
                                        formValueKey
                                      ]?.value || '';

                                    return (
                                      <div
                                        key={`${componentIndex}-${groupIndex}-${formValueIndex}`}
                                        className="flex flex-col w-full items-start justify-between"
                                        style={{
                                          minHeight: 'max-content',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          fontWeight={'bold'}
                                          p={2}
                                          pb={0}
                                          m={1}
                                          mb={0}
                                        >
                                          {`${groupField?.label}  ${formValueIndex}`}
                                        </Typography>

                                        <Tooltip
                                          title={
                                            component.key
                                              ?.toString()
                                              ?.includes('file')
                                              ? 'Πατήστε για να δείτε το αρχείο'
                                              : submittedValue
                                              ? component?.type === 'select'
                                                ? component?.values?.find(
                                                    (item) =>
                                                      item?.value ===
                                                      submittedValue
                                                  )?.label
                                                : submittedValue.trim() !== ''
                                                ? submittedValue
                                                : '-'
                                              : '-'
                                          }
                                          placement="bottom"
                                        >
                                          {component.key
                                            ?.toString()
                                            ?.includes('file') ? (
                                            <Box p={2} m={1} width={'100%'}>
                                              <FileUploader
                                                certificateSubmissionId={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.id
                                                }
                                                isEditMode={true}
                                                businessKey={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    .businessKey
                                                }
                                                fieldName={component.key}
                                                formFieldValues={
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.submissionInfo
                                                    ?.formSubmittedValues
                                                }
                                                certificateSubmissionAttachments={
                                                  certificateSubmissionAttachments
                                                }
                                              />
                                            </Box>
                                          ) : (
                                            <Typography
                                              variant="body1"
                                              border={1}
                                              borderColor="#555555"
                                              color={'#555555'}
                                              fontStyle={'italic'}
                                              p={2}
                                              m={1}
                                              mx={2}
                                              width={'100%'}
                                              height={'100%'}
                                              overflow={'auto'}
                                            >
                                              {submittedValue
                                                ? component?.type === 'select'
                                                  ? component?.values?.find(
                                                      (item) =>
                                                        item?.value ===
                                                        submittedValue
                                                    )?.label
                                                  : submittedValue.trim() !== ''
                                                  ? submittedValue
                                                  : '-'
                                                : '-'}
                                            </Typography>
                                          )}
                                        </Tooltip>
                                      </div>
                                    );
                                  }
                                );
                              }
                            );
                          } else if (component.id !== 'description') {
                            const key = component?.key;
                            const submittedValue =
                              certificatesStore.certificateSubmissionsByIdList
                                ?.submissionInfo?.formSubmittedValues[key]
                                ?.value || '';
                            if (submittedValue.trim() !== '') {
                              return (
                                <div
                                  key={index}
                                  className="flex flex-col w-full items-start justify-between"
                                  style={{
                                    minHeight: 'max-content',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={'bold'}
                                    p={2}
                                    pb={0}
                                    m={1}
                                    mb={0}
                                  >
                                    {component?.label}
                                  </Typography>

                                  <Tooltip
                                    title={
                                      component.key
                                        ?.toString()
                                        ?.includes('file')
                                        ? 'Πατήστε για να δείτε το αρχείο'
                                        : certificatesStore
                                            .certificateSubmissionsByIdList
                                            ?.submissionInfo
                                            ?.formSubmittedValues[
                                            component?.key
                                          ]?.value
                                        ? component?.type === 'select'
                                          ? component?.values?.find(
                                              (item) =>
                                                item?.value ===
                                                certificatesStore
                                                  .certificateSubmissionsByIdList
                                                  ?.submissionInfo
                                                  ?.formSubmittedValues[
                                                  component?.key
                                                ]?.value
                                            )?.label
                                          : certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues[
                                              component?.key
                                            ]?.value.trim() !== ''
                                          ? certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.submissionInfo
                                              ?.formSubmittedValues[
                                              component?.key
                                            ]?.value
                                          : '-'
                                        : '-'
                                    }
                                    placement="bottom"
                                  >
                                    {component.key
                                      ?.toString()
                                      ?.includes('file') ? (
                                      <Box p={2} m={1} width={'100%'}>
                                        <FileUploader
                                          certificateSubmissionId={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.id
                                          }
                                          isEditMode={true}
                                          businessKey={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              .businessKey
                                          }
                                          fieldName={component.key}
                                          formFieldValues={
                                            certificatesStore
                                              .certificateSubmissionsByIdList
                                              ?.submissionInfo
                                              ?.formSubmittedValues
                                          }
                                          certificateSubmissionAttachments={
                                            certificateSubmissionAttachments
                                          }
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        variant="body1"
                                        border={1}
                                        borderColor="#555555"
                                        color={'#555555'}
                                        fontStyle={'italic'}
                                        p={2}
                                        m={1}
                                        mx={2}
                                        width={'100%'}
                                        height={'100%'}
                                        overflow={'auto'}
                                      >
                                        {certificatesStore
                                          .certificateSubmissionsByIdList
                                          ?.submissionInfo?.formSubmittedValues[
                                          component?.key
                                        ]?.value
                                          ? component?.type === 'select'
                                            ? component?.values?.find(
                                                (item) =>
                                                  item?.value ===
                                                  certificatesStore
                                                    .certificateSubmissionsByIdList
                                                    ?.submissionInfo
                                                    ?.formSubmittedValues[
                                                    component?.key
                                                  ]?.value
                                              )?.label
                                            : certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues[
                                                component?.key
                                              ]?.value.trim() !== ''
                                            ? certificatesStore
                                                .certificateSubmissionsByIdList
                                                ?.submissionInfo
                                                ?.formSubmittedValues[
                                                component?.key
                                              ]?.value
                                            : '-'
                                          : '-'}
                                      </Typography>
                                    )}
                                  </Tooltip>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }

                          // OLD VERSION
                          {
                            /* {
                             component.key?.includes('file') ? (
                               <FileUploader
                                 certificateSubmissionId={
                                   certificatesStore
                                     .certificateSubmissionsByIdList?.id
                                 }
                                 isEditMode={true}
                                 businessKey={
                                   certificatesStore
                                     .certificateSubmissionsByIdList.businessKey
                                 }
                                 fieldName={component.key}
                               />
                             ) : (
                               <TextField
                                 disabled
                                 className="w-[100%]"
                                 value={
                                   certificatesStore
                                     .certificateSubmissionsByIdList
                                     ?.submissionInfo?.formSubmittedValues[
                                     component.key
                                   ]?.value
                                 }
                               />
                             );
                           } */
                          }
                        })}
                    </div>
                  </div>
                </Accordions>
              </div>
            )
          )}

          <div>
            {certificatesStore.certificateSubmissionsByIdList?.submissionInfo
              ?.formSubmittedValues?.admin_comment?.value ||
            certificatesStore.certificateSubmissionsByIdList?.submissionInfo
              ?.formSubmittedValues?.admin_file?.value ? (
              <Accordions text={'Σχόλια διευθυντή'}>
                <div className="mb-4 ml-6">
                  <Typography variant="h4">
                    <b>Σχόλια</b>
                  </Typography>

                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.admin_comment
                    ?.value ? (
                    <div className="mt-4">
                      <TextField
                        fullWidth
                        disabled
                        type="text"
                        value={
                          certificatesStore.certificateSubmissionsByIdList
                            ?.submissionInfo?.formSubmittedValues?.admin_comment
                            ?.value
                            ? certificatesStore.certificateSubmissionsByIdList
                                ?.submissionInfo?.formSubmittedValues
                                ?.admin_comment?.value
                            : null
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {/* <div className="mb-4 ml-6">
                  <Typography variant="h4">
                    <b>Έγγραφα</b>
                  </Typography>
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.submissionInfo?.formSubmittedValues?.admin_file?.value ? (
                    <div className="mt-4">
                      <FileUploader
                        certificateSubmissionId={
                          certificatesStore.certificateSubmissionsByIdList?.id
                        }
                        isEditMode={true}
                        businessKey={
                          certificatesStore.certificateSubmissionsByIdList
                            ?.businessKey
                        }
                        fieldName={'admin_file'}
                      />
                    </div>
                  ) : null}
                </div> */}
              </Accordions>
            ) : null}
          </div>

          <div className="flex justify-start items-start pt-4 pl-[35px] pb-4 space-x-12">
            {taskData?.components
              ?.filter((component) => component.type === 'button')
              ?.map((component, index) => (
                <div key={index}>
                  <Button
                    sx={{
                      backgroundColor: renderButtonBackgroundColorBasedOnLabel(
                        component?.label
                      ),
                      color: '#f9f9f9',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor:
                          renderButtonBackgroundColorBasedOnLabel(
                            component?.label
                          ),
                        color: '#f9f9f9',
                      },
                      fontSize: '1rem',
                    }}
                    key={component?.key}
                    variant="contained"
                    className="capitalize text-sm"
                    type={component?.type}
                    onClick={() => {
                      setSelectedButtonLabel(component?.label);
                      setAdminReviewValue(
                        component?.properties?.adminReview || ''
                      );
                    }}
                  >
                    {component?.label}
                  </Button>
                </div>
              ))}

            <SubmitButton
              sx={{
                backgroundColor: '#57E4FF',
                color: '#f9f9f9',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#57E4FF',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => navigate('/certificates-overview')}
              variant="contained"
              loading={loading}
              type={'button'}
              title="Ακύρωση"
            />
          </div>

          {selectedButtonLabel ? (
            <Accordions text={selectedButtonLabel}>
              <div className="ml-8 mt-4">{renderButtonActionContent()}</div>
            </Accordions>
          ) : null}
        </>
      )}
    </>
  );
};

export default observer(CertificateSubmissionEdit);
