import { Stack, Tooltip, Typography } from '@mui/material';
import {
  DeleteOutline,
  EditOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { FORM_STATUS } from './ApplicationCategories';
import { palette } from '../../../styles/variables/colors';
import { useEffect } from 'react';
import { useSubOrganizationsStore } from '../../../Stores/SubOrganizationsStore';

const APP_CATEGORIES_FIELDS = {
  Id: {
    id: 'id',
  },
  Name: {
    id: 'title',
  },
  Organization: {
    id: 'organizationId',
  },
  IsActive: {
    id: 'isActive',
  },
  Actions: {
    id: 'actions',
  },
};

const ActionButtons = ({
  className,
  item,
  formStatus,
  focusedItem,
  handleAction,
  handleDelete,
}) => {
  const isFocused = focusedItem?.id === item?.id;
  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Tooltip
        onClick={() => {
          handleAction({ item, formStatus: FORM_STATUS.EDIT });
        }}
        placement="top"
        title={'Επεξεργασία'}
      >
        <EditOutlined
          sx={{
            cursor: 'pointer',
            fontSize: '1.4rem',
            color:
              isFocused && formStatus === FORM_STATUS.EDIT
                ? palette.info.main
                : '#333333B2',
          }}
        />
      </Tooltip>
      <Tooltip
        onClick={() => {
          handleAction({ item, formStatus: FORM_STATUS.VIEW });
        }}
        placement="top"
        title={'Προβολή'}
      >
        <VisibilityOutlined
          sx={{
            cursor: 'pointer',
            fontSize: '1.4rem',
            marginLeft: '0.4rem',
            color:
              isFocused && formStatus === FORM_STATUS.VIEW
                ? palette.info.main
                : '#333333B2',
          }}
        />
      </Tooltip>
      <Tooltip
        onClick={() => {
          handleDelete({ item });
        }}
        placement="top"
        title={'Διαγραφή'}
      >
        <DeleteOutline
          sx={{
            cursor: 'pointer',
            fontSize: '1.4rem',
            marginLeft: '0.4rem',
            color: '#333333B2',
          }}
        />
      </Tooltip>
    </Stack>
  );
};

const ApplicationCategoriesTable = ({
  focusedItem,
  formStatus,
  isLoading,
  handleAction,
  handleDelete,
  appCategories,
}) => {
  const subOrganizationsStore = useSubOrganizationsStore();

  useEffect(() => {
    subOrganizationsStore.getAllOrganizations();
  }, [subOrganizationsStore.organizationsList?.length]);

  const columns = [
    {
      field: APP_CATEGORIES_FIELDS?.Id?.id,
      headerName: 'Κωδικός',
      headerClassName: 'appCategories-table-theme--header',
      cellClassName: 'appCategories-table-theme--row',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <Typography
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-5"
        >
          {params?.row?.[APP_CATEGORIES_FIELDS?.Id?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: APP_CATEGORIES_FIELDS?.Title?.id,
      headerName: 'Ονομασία',
      headerClassName: 'appCategories-table-theme--header',
      cellClassName: 'appCategories-table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 5,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[APP_CATEGORIES_FIELDS?.Name?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: APP_CATEGORIES_FIELDS?.Organization?.id,
      headerName: 'Σχετιζόμενοι Οργανισμοί',
      headerClassName: 'appCategories-table-theme--header',
      cellClassName: 'appCategories-table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 5,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {
            subOrganizationsStore.organizationsList?.find(
              (c) =>
                c.id === params?.row?.[APP_CATEGORIES_FIELDS?.Organization?.id]
            )?.name
          }
        </Typography>
      ),
    },
    {
      field: APP_CATEGORIES_FIELDS?.IsActive?.id,
      headerName: 'Ενεργή',
      headerClassName: 'appCategories-table-theme--header',
      cellClassName: 'appCategories-table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 5,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[APP_CATEGORIES_FIELDS?.IsActive?.id]
            ? 'Ναι'
            : 'Όχι' || '-'}
        </Typography>
      ),
    },
    {
      field: APP_CATEGORIES_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'appCategories-table-theme--header',
      cellClassName: 'appCategories-table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 210,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          formStatus={formStatus}
          item={params?.row}
          handleAction={handleAction}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  return (
    <>
      <div className="bg-white text-[1.4rem]" style={{ width: '99%' }}>
        {!!appCategories?.length && (
          <DataGrid
            sx={{
              border: 'none',
              '& .appCategories-table-theme--header': {
                backgroundColor: '#FFFFFF',
                fontSize: 15,
                borderBottom: '3px solid black',
              },
              '& .appCategories-table-theme--row': {
                backgroundColor: '#FFFFFF',
                border: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              ' & .MuiDataGrid-footerContainer': {
                backgroundColor: '#FFFFFF',
              },
              ' & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            disableColumnSelector
            columns={columns}
            rows={appCategories || []}
            loading={isLoading}
            autoHeight
            disableSelectionOnClick
            hideFooterPagination
            hideFooter
          />
        )}
      </div>
      {!appCategories?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default ApplicationCategoriesTable;
