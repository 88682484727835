import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Modal, Typography } from '@mui/material';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { useNavigate } from 'react-router-dom';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from '../../../Components/Theme/Cards/MainCard';

const ScheduleArchived = ({ selectedSubDepartmentId, setLoading }) => {
  const authStore = useAuthStore();
  const subDepartmentStore = useSubDepartmentStore();
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);

  useEffect(() => {
    if (authStore.authUserIsNotLoaded) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [authStore.authUserIsNotLoaded, authStore]);

  useEffect(() => {
    if (!authStore.authUserIsNotLoaded) {
      rantevouStore.getScheduleList(selectedSubDepartmentId);
    }
  }, [
    rantevouStore.scheduleList?.length,
    authStore.authUserIsNotLoaded,
    authStore.authUser,
    selectedSubDepartmentId,
  ]);

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  const viewSchedule = (scheduleId) => {
    navigate(`/schedule-history/${scheduleId}`);
  };

  const deleteSchedule = async (scheduleId) => {
    try {
      setLoading(true);
      await rantevouStore.deleteSchedule(scheduleId);
    } catch (error) {
      console.error('An error occurred while deleting the schedule:', error);
    } finally {
      await rantevouStore.getScheduleList(authStore.authUser.departmentId);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="ml-4 mt-6 max-w-[60%] min-w-[50%]">
        {rantevouStore.scheduleList
          ?.filter((schedule) => schedule.status === 2)
          ?.sort((a, b) => new Date(a.timeFrom) - new Date(b.timeFrom))
          ?.map((schedule) => {
            const matchingSubDepartment =
              subDepartmentStore.subDepartmentsList?.find(
                (subDepartment) => subDepartment.id === schedule.subDepartmentId
              );

            if (matchingSubDepartment) {
              return (
                <div
                  className="flex flex-wrap mb-1 px-4 py-2 bg-[#ccf0ff] lg:items-center lg:justify-between"
                  key={schedule.id}
                >
                  <Typography
                    className="font-bold pt-2 pr-2"
                    variant="h5"
                    gutterBottom
                  >
                    Ημερ. {format(new Date(schedule.timeFrom), 'dd/MM/yyyy')} -{' '}
                    {format(new Date(schedule.timeTo), 'dd/MM/yyyy')} | Υποτμήμα{' '}
                    {matchingSubDepartment.name}
                  </Typography>
                  <div className="flex flex-wrap gap-2">
                    {authStore?.isAdmin && (
                      <SubmitButton
                        sx={{
                          backgroundColor: '#e87272',
                          '&:hover': {
                            backgroundColor: '#9e5454',
                          },
                        }}
                        onClick={() => {
                          setIsModalDeleteOpen(true);
                          setScheduleId(schedule.id);
                        }}
                        className="capitalize text-sm"
                        title="Διαγραφη"
                        size="small"
                        variant="contained"
                        type="button"
                      />
                    )}
                    <SubmitButton
                      sx={{
                        backgroundColor: '#003375',
                      }}
                      onClick={() => viewSchedule(schedule.id)}
                      className="capitalize text-sm"
                      title={'Προβολη'}
                      size="small"
                      variant="contained"
                      type="button"
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>

      {rantevouStore.scheduleList?.filter((schedule) => schedule.status === 2)
        ?.length === 0 && (
        <div className="flex justify-start items-center">
          <Typography variant="h5">
            Δεν υπάρχουν αρχειοθετημένα προγράμματα
          </Typography>
        </div>
      )}

      <Modal
        className="flex items-center justify-center"
        open={isModalDeleteOpen}
        onClose={() => {
          setIsModalDeleteOpen(false);
        }}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-6 min-w-[25vw] min-h-[15vw] overflow-auto max-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalDeleteOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen m-8 flex flex-col justify-center items-center">
            <div className="flex flex-col gap-4 justify-center items-center">
              <Typography variant="h4">
                Το επιλεγμένο πρόγραμμα θα διαγραφεί.
              </Typography>
              <Typography variant="h4">Θέλετε να συνεχίσετε;</Typography>
            </div>
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                <SubmitButton
                  onClick={() => deleteSchedule(scheduleId)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="NAI"
                />
              </Typography>
              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalDeleteOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="ΟΧΙ"
                />
              </Typography>
            </div>
          </div>
        </MainCard>
      </Modal>
    </>
  );
};

export default observer(ScheduleArchived);
