import React, { useState } from 'react';
import DataTable from '../../../Components/DataTable/DataTable';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAnnouncementsStore } from '../../../Stores/AnnouncementsStore';
import { palette } from '../../../styles/variables/colors';
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { error, success } from '../../../Common/Toast/Toast';
import { useAuthStore } from '../../../Stores/AuthStore';
import { observer } from 'mobx-react';
import { parseGreekDate } from '../../../Common/formatDate/formatDate';

const ANNOUNCEMENT_FIELDS = {
  Title: {
    id: 'title',
  },
  Category: {
    id: 'announcementCategoryName',
  },
  Date: {
    id: 'createdAt',
  },
  Status: {
    id: 'announcementStatus',
  },
  Organization: {
    id: 'organizationId',
  },
  Actions: {
    id: 'actions',
  },
};

const loader = (
  <Box className="flex justify-center items-center pl-8">
    <CircularProgress size={20} />
  </Box>
);

const ActionButtons = ({
  className,
  item,
  focusedItem,
  handleAction,
  refreshList,
}) => {
  const isFocused = focusedItem?.id === item?.id;
  const [isChecked, setIsChecked] = useState(item?.announcementStatus || false);
  const [loading, setLoading] = useState(false);
  const { updateAnnouncement } = useAnnouncementsStore();

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    const announcementId = item?.id;
    setLoading(true);

    if (newValue) {
      updateAnnouncement(
        announcementId,
        {
          title: item?.title,
          text: item?.text,
          announcementStatus: 1,
          announcementCategoryId: item?.announcementCategoryId,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
        .then((result) => {
          if (result) {
            success('Η ανακοίνωση ενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          refreshList();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateAnnouncement(
        announcementId,
        {
          title: item?.title,
          text: item?.text,
          announcementStatus: 0,
          announcementCategoryId: item?.announcementCategoryId,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
        .then((result) => {
          if (result) {
            success('Η ανακοίνωση απενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          refreshList();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      {loading ? (
        loader
      ) : (
        <>
          {/* <Tooltip
            onClick={() => {
              handleAction({ item });
            }}
            placement="top"
            title={'Επεξεργασία'}
          >
            <EditOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                color: isFocused ? palette.info.main : '#333333B2',
              }}
            />
          </Tooltip> */}
          <Tooltip
            onClick={() => {
              handleAction({ item });
            }}
            placement="top"
            title={'Προβολή'}
          >
            <VisibilityOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                marginLeft: '0.4rem',
                color: isFocused ? palette.info.main : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={'Αλλαγή Κατάστασης'}>
            <FormControlLabel
              control={
                <Switch
                  checked={isChecked}
                  onChange={handleChange}
                  sx={{
                    cursor: 'pointer',
                    marginLeft: '0.4rem',
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#003375',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: palette.info.main,
                    },
                  }}
                />
              }
              label={isChecked ? 'Ενεργή' : 'Ανενεργή'}
            />
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

const AnnouncementTable = ({
  focusedItem,
  isLoading,
  handleAction,
  refreshList,
  announcements,
}) => {
  const { isSuperAdmin } = useAuthStore();
  const columns = [
    {
      field: ANNOUNCEMENT_FIELDS?.Title?.id,
      headerName: 'Τίτλος',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ANNOUNCEMENT_FIELDS?.Title?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ANNOUNCEMENT_FIELDS?.Category?.id,
      headerName: 'Κατηγορία',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ANNOUNCEMENT_FIELDS?.Category?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ANNOUNCEMENT_FIELDS?.Organization?.id,
      headerName: 'Οργανισμός',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ANNOUNCEMENT_FIELDS?.Organization?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ANNOUNCEMENT_FIELDS?.Date?.id,
      headerName: 'Ημ/νία δημιουργίας',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {parseGreekDate(
            params?.row?.[ANNOUNCEMENT_FIELDS?.Date?.id],
            'DD/MM/YYYY HH:mm:ss',
            'el'
          ) || '-'}
        </Typography>
      ),
    },
    // {
    //   field: ANNOUNCEMENT_FIELDS?.Status?.id,
    //   headerName: 'Ενεργή',
    //   headerClassName: 'table-theme--header',
    //   cellClassName: 'table-theme--row',
    //   align: 'left',
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 2,
    //   renderCell: (params) => (
    //     <Typography
    //       align="left"
    //       variant="subtitle1"
    //       component="div"
    //       className="flex items-center text-[14px] pl-6"
    //     >
    //       {params?.row?.[ANNOUNCEMENT_FIELDS?.Status?.id] ? 'Ναι' : 'Οχι'}
    //     </Typography>
    //   ),
    // },
    {
      field: ANNOUNCEMENT_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          item={params?.row}
          handleAction={handleAction}
          className="ml-1"
          refreshList={refreshList}
        />
      ),
    },
  ].filter((column) =>
    isSuperAdmin ? true : column.field !== ANNOUNCEMENT_FIELDS?.Organization?.id
  );

  return (
    <>
      <>
        {!!announcements?.length && (
          <DataTable
            columns={columns}
            rows={announcements || []}
            loading={isLoading}
            backgroundColor="#f9f9f9"
          />
        )}
      </>
      {!announcements?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default observer(AnnouncementTable);
