import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  createTheme,
  ThemeProvider,
  FormLabel,
  IconButton,
} from '@mui/material';
import { usePoisStore } from '../../../Stores/PoisStore';
import { error, success, warning } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { observer } from 'mobx-react';
import DeleteIcon from '@mui/icons-material/Delete';
import GoogleMap from '../../../Components/GoogleMap/GoogleMap';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import CircularSpinner from '../../../Components/Theme/Spinners/CircularSpinner';

const EditPoiForm = ({
  isEditMode,
  setIsEditMode,
  poi,
  isCreateModeOn = false,
  onDelete,
  setParentUpdate = null,
}) => {
  const poisStore = usePoisStore();
  const organizationStore = useOrganizationsStore();
  const [poiData, setPoiData] = useState(poi);
  const [updated, setUpdated] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();
  const { isSuperAdmin, authUser } = useAuthStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [positionError, setPositionError] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageDownloading, setIsImageDownloading] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);

  const theme = createTheme({
    palette: {
      primary: { main: '#003375' },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
    },
  });

  const schema = yup
    .object({
      name: yup.string().required('Ο τίτλος είναι υποχρεωτικός'),
      organizationId: isSuperAdmin
        ? yup.string().required('Ο οργανισμός είναι υποχρεωτικός')
        : yup.string(),
      poiCategoryId: yup.string().required('Η κατηγορία είναι υποχρεωτική'),
      address: yup.string().nullable(),
      geoLat: yup
        .number()
        .typeError('Το γεωγραφικό πλάτος πρέπει να είναι αριθμός')
        .min(-90, 'Το γεωγραφικό πλάτος πρέπει να είναι μεγαλύτερο από -90')
        .max(90, 'Το γεωγραφικό πλάτος πρέπει να είναι μικρότερο από 90')
        .nullable(),
      geoLong: yup
        .number()
        .typeError('Το γεωγραφικό μήκος πρέπει να είναι αριθμός')
        .min(-180, 'Το γεωγραφικό μήκος πρέπει να είναι μεγαλύτερο από -180')
        .max(180, 'Το γεωγραφικό μήκος πρέπει να είναι μικρότερο από 180')
        .nullable(),
      tel: yup
        .string()
        .matches(/^[0-9]{10}$/, 'Το τηλέφωνο πρέπει να έχει 10 ψηφία')
        .nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      name: poiData?.name || '',
      organizationId: isSuperAdmin ? poiData?.organizationId : '',
      poiCategoryId: poiData?.poiCategoryId || '',
      address: poiData?.address || null,
      geoLat: poiData?.geoLat || null,
      geoLong: poiData?.geoLong || null,
      tel: poiData?.tel || null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedImage(null);
      setImageRemoved(true);
    }
  };

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      organizationId: isSuperAdmin ? data.organizationId : '',
      poiCategoryId: data.poiCategoryId,
      address: data.address,
      geoLat: data.geoLat,
      geoLong: data.geoLong,
      tel: data.tel,
    };

    setIsSubmitting(true);
    poisStore
      .updatePoi(poiData.id, payload)
      .then((result) => {
        if (result) {
          if (selectedImage) {
            poisStore
              .uploadImage(poiData.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Το σημείο ενδιαφέροντος ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                } else {
                  warning(
                    'Το σημείο ενδιαφέροντος ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Το σημείο ενδιαφέροντος ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else if (imageRemoved && imageUrl) {
            poisStore
              .deleteImage(`Po_${poiData.id}`)
              .then((imageResponse) => {
                if (imageResponse?.success) {
                  success('Το σημείο ενδιαφέροντος ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                  setUpdated(true);
                } else {
                  warning(
                    'Το σημείο ενδιαφέροντος ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Το σημείο ενδιαφέροντος ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else {
            success('Το σημείο ενδιαφέροντος ενημερώθηκε επιτυχώς');
            setSelectedImage(null);
            setIsSubmitting(false);
            setIsEditMode(false);
            setUpdated(true);
            setParentUpdate && setParentUpdate(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error(
          'Υπήρξε κάποιο πρόβλημα κατά την ενημέρωση του σημείου ενδιαφέροντος'
        );
        setIsSubmitting(false);
      });
  };

  const onCreate = (data) => {
    const payload = {
      name: data.name,
      organizationId: isSuperAdmin ? data.organizationId : '',
      poiCategoryId: data.poiCategoryId,
      address: data.address,
      geoLat: data.geoLat,
      geoLong: data.geoLong,
      tel: data.tel,
    };

    setIsSubmitting(true);
    poisStore
      .createPoi(payload)
      .then((result) => {
        if (result) {
          if (selectedImage) {
            poisStore
              .uploadImage(result.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Το σημείο ενδιαφέροντος δημιουργήθηκε επιτυχώς');
                  setSelectedImage(null);
                  navigate(`/pois/${result.id}`);
                } else {
                  warning(
                    'Το σημείο ενδιαφέροντος δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Το σημείο ενδιαφέροντος δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          } else {
            success('Το σημείο ενδιαφέροντος δημιουργήθηκε επιτυχώς');
            setSelectedImage(null);
            navigate(`/pois/${result.id}`);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error(
          'Υπήρξε κάποιο πρόβλημα κατά την δημιουργία του σημείου ενδιαφέροντος'
        );
        setIsSubmitting(false);
      });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const getSelectedOrganization = (id) => {
    organizationStore.getOrganizationById(id);
  };

  useEffect(() => {
    isDirty && !isEditMode && !updated && reset();
    if (!isEditMode) {
      setImageRemoved(false);
      setIsSubmitting(false);
      setSelectedImage(null);
    }
  }, [isEditMode]);

  useEffect(() => {
    setPoiData(poi);
  }, [poi]);

  useEffect(() => {
    if (isSuperAdmin) {
      organizationStore.getOrganizations().then((response) => {
        setOrganizations(response.items);
      });
    }
  }, [isSuperAdmin, organizationStore]);

  useEffect(() => {
    if (!isCreateModeOn && updated) {
      setIsImageDownloading(true);
      poisStore
        .downloadImage(`Po_${poiData?.id}`)
        .then((imageUrl) => {
          setImageUrl(imageUrl);
        })
        .catch(() => {
          setImageUrl(null);
        })
        .finally(() => {
          setIsImageDownloading(false);
          updated && setUpdated(false);
        });
    }
  }, [isCreateModeOn, updated]);

  useEffect(() => {
    if (
      (!isSuperAdmin ||
        (isSuperAdmin && poiData?.organizationId) ||
        (isSuperAdmin && getValues('organizationId'))) &&
      organizationStore.organizationById?.mapArea
    ) {
      const mapData = JSON.parse(
        organizationStore.organizationById?.mapArea
      )?.coordinates;
      setMapData(mapData);
    }
  }, [organizationStore.organizationById]);

  useEffect(() => {
    if (organizationStore.organizationById || poiData?.organizationId) {
      if (
        ((!organizationStore.organizationById ||
          Object.keys(organizationStore.organizationById).length === 0) &&
          poiData?.organizationId) ||
        (Object.keys(organizationStore.organizationById).length > 0 &&
          poiData?.organizationId &&
          organizationStore.organizationById?.id !== poiData?.organizationId)
      ) {
        organizationStore.getOrganizationById(poiData?.organizationId);
      }
    } else if (
      !organizationStore.organizationById &&
      authUser.organizationId !== undefined &&
      !isSuperAdmin
    ) {
      organizationStore.getOrganizationById(authUser.organizationId);
    }
  }, [organizationStore.organizationById]);

  useEffect(() => {
    setMarkerPosition({
      lat: Number(poiData?.geoLat),
      lng: Number(poiData?.geoLong),
    });
  }, [poiData]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(isCreateModeOn ? onCreate : onSubmit)}
      noValidate
      sx={{
        mt: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="flex justify-between items-center gap-4 mb-4">
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: '#f9f9f9',
            color: '#003375',
            border: '1px solid #003375',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#003375',
              color: '#f9f9f9',
            },
            fontSize: '1rem',
          }}
        >
          Επιστροφή
        </Button>

        <div className="flex gap-4">
          {isEditMode && (
            <SubmitButton
              title={'Ενημέρωση'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage && !imageRemoved}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}
          {isCreateModeOn && (
            <SubmitButton
              title={'Δημιουργία'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}
          <div className="flex justify-between items-center gap-4">
            {isEditMode && (
              <Button
                variant="contained"
                onClick={onDelete}
                sx={{
                  backgroundColor: '#f9f9f9',
                  color: '#B60202',
                  border: '1px solid #B60202',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#B60202',
                    color: '#f9f9f9',
                  },
                  fontSize: '1rem',
                }}
              >
                Διαγραφή
              </Button>
            )}
            {!isCreateModeOn && (
              <Button
                variant="contained"
                onClick={toggleEditMode}
                sx={{
                  backgroundColor: '#003375',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#003375',
                    color: 'white',
                    opacity: 0.9,
                  },
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                {isEditMode ? 'Ακύρωση' : 'Επεξεργασία'}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Controller
        name="image"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col items-start justify-start w-full gap-4 my-4 min-h-[120px]">
            <FormLabel
              sx={{
                fontSize: '0.8571428571428571rem',
              }}
            >
              Εικόνα σημείου ενδιαφέροντος
            </FormLabel>

            {(isEditMode || isCreateModeOn) && (
              <div className="flex justify-start items-center flex-wrap gap-2 w-full">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                  }}
                  disabled={!isEditMode && !isCreateModeOn}
                  ref={fileInputRef}
                />
              </div>
            )}

            {!imageUrl &&
              !isImageDownloading &&
              !isEditMode &&
              !isCreateModeOn && <p>Δεν υπάρχει εικόνα</p>}

            <div className="flex justify-start items-center flex-wrap mb-4 gap-1 w-full">
              {isImageDownloading ? (
                <Box className="flex justify-center items-center">
                  <CircularSpinner
                    size="20px"
                    color="inherit"
                    sx={{ marginRight: '15px' }}
                  />
                </Box>
              ) : (
                (selectedImage || (!imageRemoved && imageUrl)) && (
                  <div className="flex justify-start items-center flex-wrap gap-4 w-full">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : imageUrl
                      }
                      alt="poi"
                      style={{ width: '50%', height: 'auto' }}
                    />

                    <IconButton
                      sx={{
                        backgroundColor: '#B60202',
                        borderRadius: '99px',
                        textTransform: 'none',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#B60202',
                          opacity: 0.8,
                        },
                      }}
                      disabled={!isEditMode && !isCreateModeOn}
                      size={'small'}
                      type="button"
                      title={'Διαγραφή'}
                      onClick={() => clearFileInput()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      />

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Τίτλος"
            error={!!errors.name}
            helperText={errors.name?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />
      <div className="flex justify-center gap-4">
        <Controller
          name="tel"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Τηλέφωνο"
              type="tel"
              onChange={(e) => {
                const newValue = e.target.value;
                field.onChange(newValue === '' ? null : newValue);
              }}
              error={!!errors.tel}
              helperText={errors.tel?.message}
              InputProps={{
                readOnly: !isEditMode && !isCreateModeOn,
              }}
              sx={{
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    borderColor: '#003375',
                  },
                '& .MuiInputLabel-outlined.Mui-focused': {
                  color: '#003375',
                },
              }}
            />
          )}
        />
        <Controller
          name="poiCategoryId"
          control={control}
          render={({ field }) => (
            <ThemeProvider theme={theme}>
              <FormControl fullWidth margin="normal">
                <InputLabel
                  id="poi-category-label"
                  required
                  sx={{
                    fontSize: '0.8571428571428571rem',
                  }}
                >
                  Κατηγορία
                </InputLabel>
                <Select
                  {...field}
                  labelId="poi-category-label"
                  label="Κατηγορία"
                  required
                  error={!!errors.poiCategoryId}
                  value={field.value || ''}
                  readOnly={!isEditMode && !isCreateModeOn}
                >
                  {poisStore.categories?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.poiCategoryId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.6428571428571428rem',
                      marginTop: '3px',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                  >
                    {errors.poiCategoryId.message}
                  </p>
                )}
              </FormControl>
            </ThemeProvider>
          )}
        />
      </div>
      {isSuperAdmin && (
        <Controller
          name="organizationId"
          control={control}
          render={({ field }) => (
            <ThemeProvider theme={theme}>
              <FormControl fullWidth margin="normal">
                <InputLabel
                  id="organization-label"
                  sx={{
                    fontSize: '0.8571428571428571rem',
                  }}
                  required={isSuperAdmin}
                >
                  Οργανισμός
                </InputLabel>
                <Select
                  {...field}
                  labelId="organization-label"
                  label="Οργανισμός"
                  required={isSuperAdmin}
                  error={!!errors.organizationId}
                  value={field.value || ''}
                  disabled={!isCreateModeOn}
                  onChange={(e) => {
                    field.onChange(e);
                    getSelectedOrganization(e.target.value);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#003375',
                      },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: '#003375',
                    },
                  }}
                >
                  {organizations?.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.organizationId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.6428571428571428rem',
                      marginTop: '3px',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                  >
                    {errors.organizationId.message}
                  </p>
                )}
              </FormControl>
            </ThemeProvider>
          )}
        />
      )}

      {mapData &&
        (poiData?.organizationId ||
          getValues('organizationId') ||
          (!isSuperAdmin && isCreateModeOn)) && (
          <div className="w-full mt-4">
            <GoogleMap
              readOnly={!isEditMode && !isCreateModeOn}
              editable={isCreateModeOn || isEditMode}
              mapData={mapData}
              setAddress={(address) => {
                setValue('address', address, { shouldDirty: true });
              }}
              markerPosition={markerPosition}
              setMarkerPosition={(position) => {
                setMarkerPosition(position);
                setValue('geoLat', position?.lat, { shouldDirty: true });
                setValue('geoLong', position?.lng, { shouldDirty: true });
              }}
              defaultAddress={poiData?.address}
              positionError={positionError}
              setPositionError={setPositionError}
              initialZoom={poiData?.geoLat && poiData?.geoLong ? 15 : 10}
              customCenter={
                poiData?.geoLat && poiData?.geoLong
                  ? {
                      lat: Number(poiData?.geoLat),
                      lng: Number(poiData?.geoLong),
                    }
                  : null
              }
              fitPolygonData={!(poiData?.geoLat && poiData?.geoLong)}
            />
          </div>
        )}
    </Box>
  );
};

export default observer(EditPoiForm);
