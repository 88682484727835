import { useEffect, useState } from "react";
import Topbar from "../../../Components/Topbar/Topbar";
import { observer } from "mobx-react-lite";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useSubDepartmentStore } from "../../../Stores/SubDepartmentStore";
import { useAuthStore } from "../../../Stores/AuthStore";
import NoAccessCard from "../../../Components/NoAccessCard/NoAccessCard";
import RantevouServiceForm from "./RantevouServiceForm";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "../../../Components/Theme/Link/Link";
import { ORG_NAME } from "../../../Config/constant";

const CreateRantevouService = () => {
    const { state } = useLocation();
    const subDepartmentStore = useSubDepartmentStore();
    const { isAdmin } = useAuthStore();
    const [loading, setLoading] = useState(true);
    const [subDepartmentId, setSubDepartmentId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (state?.subDepartmentId) {
            setSubDepartmentId(state?.subDepartmentId);
            setLoading(false);
        }
        else {
            navigate("/noAccessCard");
        }
    }, [subDepartmentStore, state]);

    useEffect(() => {
        if (subDepartmentId)
            subDepartmentStore.getSubDepartmentById(subDepartmentId);
    }, [subDepartmentStore, subDepartmentId]);

    return (
        <div>
            <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
            {loading ?
                <div className="flex justify-center items-center h-screen">
                    <CircularProgress sx={{
                        color: '#003375',
                    }} />
                </div> :
                isAdmin ? (
                    <div className="p-4">
                        <Typography sx={{ fontWeight: 'medium' }} mt={2} variant="h3" color={"#003375"}>
                            Προσθήκη νέας υπηρεσίας ραντεβού
                        </Typography>

                        <div className="flex items-center justify-center">
                            <Typography sx={{ fontWeight: 'bold' }} mt={4} variant="h4" color={"#003375"}>
                                {subDepartmentStore.selectedSubDepartment?.name}
                            </Typography>
                        </div>

                        <div className="w-full">
                            <RantevouServiceForm subDepartmentId={subDepartmentId} />
                        </div>
                    </div>
                ) : (
                    <NoAccessCard />
                )}
        </div >
    );
}

export default observer(CreateRantevouService);