import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const centerTextPlugin = {
  id: 'centerText',
  beforeDraw(chart) {
    const ctx = chart.ctx;
    const centerConfig = chart.config.options.elements.center;
    if (!centerConfig || !centerConfig.text) return;

    const { width, height } = chart.chartArea;
    const text = centerConfig.text;
    const fontStyle = centerConfig.fontStyle || 'Arial';
    const color = centerConfig.color || '#000';
    let maxFontSize = centerConfig.maxFontSize || 75;
    let sidePadding = (width * (centerConfig.sidePadding || 20)) / 100;
    let lineHeight = centerConfig.lineHeight || 25;
    let minFontSize = centerConfig.minFontSize || 20;

    ctx.save();

    let fontSize = calculateFontSize(
      ctx,
      text,
      fontStyle,
      width - sidePadding * 2,
      maxFontSize,
      minFontSize
    );
    ctx.font = `${fontSize}px ${fontStyle}`;
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

    const lines = wrapText(ctx, text, width - sidePadding * 2);
    let startY = centerY - ((lines.length - 1) * lineHeight) / 2;

    for (const element of lines) {
      ctx.fillText(element, centerX, startY);
      startY += lineHeight;
    }

    ctx.restore();
  },
};

const calculateFontSize = (
  ctx,
  text,
  fontStyle,
  maxWidth,
  maxFontSize,
  minFontSize
) => {
  let fontSize = maxFontSize;
  ctx.font = `${fontSize}px ${fontStyle}`;
  let textWidth = ctx.measureText(text).width;

  while (textWidth > maxWidth && fontSize > minFontSize) {
    fontSize -= 1;
    ctx.font = `${fontSize}px ${fontStyle}`;
    textWidth = ctx.measureText(text).width;
  }

  return fontSize;
};

const wrapText = (ctx, text, maxWidth) => {
  let words = text.split(' ');
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    let word = words[i];
    let width = ctx.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  lines.push(currentLine);

  return lines;
};

const CustomLegend = ({ labels, colors }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '2px',
      width: '100%',
    }}
  >
    {labels.map((label, index) => (
      <div
        key={label}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '2px',
          wordBreak: 'break-all',
          wordWrap: 'break-word',
          gap: '2px',
        }}
      >
        <span
          style={{
            width: '14px',
            height: '14px',
            backgroundColor: colors[index],
            marginRight: '5px',
            display: 'inline-block',
            borderRadius: '10px',
          }}
        />
        <span>{label}</span>
      </div>
    ))}
  </div>
);

const StatsDoughnut = ({ data, title = null, total = null }) => {
  const modifiedData =
    data.labels?.length > 0
      ? data
      : {
          labels: ['Δεν υπάρχουν δεδομένα'],
          datasets: [
            {
              data: [1],
              backgroundColor: ['#d3d3d3'],
            },
          ],
        };

  const isEmpty = data.labels?.length === 0;

  const legendLabels = data.labels;
  const legendColors = data.datasets[0].backgroundColor;

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          if (isEmpty) return '';
          const data = context.chart.data.datasets[0].data;
          const label = context.chart.data.labels[context.dataIndex];
          const total = data?.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(2);
          return `${percentage}%\n(${value})`;
        },
        color: '#fff',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return isEmpty ? '' : `${context.label}: ${context.formattedValue}`;
          },
        },
      },
    },
    elements: {
      center: {
        text: total || '',
        color: '#003375',
        fontStyle: 'Arial',
        sidePadding: 45,
        minFontSize: 24,
        maxFontSize: 50,
        lineHeight: 25,
      },
    },
  };

  return (
    <Box
      sx={{
        width: { md: '600px', lg: '400px' },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
          width: '100%',
          minHeight: '4rem',
        }}
      >
        <h3
          style={{
            color: isEmpty ? '#666666' : '#003375',
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {title}
        </h3>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Doughnut
          data={modifiedData}
          options={options}
          height={'250px'}
          plugins={[ChartDataLabels, centerTextPlugin]}
        />
        <CustomLegend labels={legendLabels} colors={legendColors} />
      </div>
    </Box>
  );
};

export default StatsDoughnut;
