import { Navigate, Route, Routes, useLocation, Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import LoginForm from "../../Pages/Guest/LoginForm/LoginForm";
import ForgotPassword from "../../Pages/Guest/ForgotPassword/ForgotPassword";
import ResetPassword from "../../Pages/Guest/ResetPassword/ResetPassword";

const GuestRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route exact path="/" element={<Outlet />}>
        <Route exact path="/" element={<LoginForm />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        />
      </Route>

      <Route
        path="*"
        element={<Navigate to="/" state={{ from: location }} />}
      />
    </Routes>
  );
};

export default observer(GuestRoutes);
