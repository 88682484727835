import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';
import { palette } from '../../../styles/variables/colors';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import UsersTable from './UsersTable';
import UsersViewForm from './UsersViewForm';
import UsersCreateEditForm from './UsersCreateEditForm';

export const FORM_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
  HIDDEN: 'hidden',
};

const PAGE_SIZE = 15;

const Users = () => {
  const formSectionRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState();
  const [subOrganizations, setSubOrganizations] = useState();
  const [subOrganizationMap, setSubOrganizationMap] = useState({});
  const [organizations, setOrganizations] = useState();
  const [organizationsMap, setOrganizationsMap] = useState({});
  const [sectors, setSectors] = useState();
  const [sectorsMap, setSectorsMap] = useState({});
  const [departments, setDepartments] = useState();
  const [departmentsMap, setDepartmentsMap] = useState({});
  const [subDepartments, setSubDepartments] = useState();
  const [subDepartmentsMap, setSubDepartmentsMap] = useState({});
  const [roles, setRoles] = useState();
  const [claims, setClaims] = useState();
  const [formStatus, setFormStatus] = useState(FORM_STATUS.HIDDEN);
  const [focusedUser, setFocusedUser] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAction = useCallback(({ item, formStatus: status }) => {
    setFocusedUser(item);
    setFormStatus(status);
  }, []);

  const refreshList = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      AuthSetAgent.users
        .getAllUsers(currentPage, PAGE_SIZE)
        .then((response) => {
          const totalPages = Math.ceil(
            response.result.totalCount / response.result.pageSize
          );
          setTotalPages(totalPages);
          const users = response.result.items;
          setUsers(users);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.subOrganizations.getSubOrganizations().then((response) => {
        const mapObject = {};
        const subOrganizations = response.result.items;
        subOrganizations?.forEach((item) => {
          mapObject[item?.id] = item;
        });
        setSubOrganizationMap(mapObject);
        setSubOrganizations(subOrganizations);
      }),
      AuthSetAgent.organizations.getOrganizations().then((response) => {
        const mapObject = {};
        const organizations = response.result.items;
        organizations?.forEach((item) => {
          mapObject[item?.id] = item;
        });
        setOrganizationsMap(mapObject);
        setOrganizations(organizations);
      }),
      AuthSetAgent.sectors.getSectors().then((response) => {
        const mapObject = {};
        const sectors = response.result.items;
        sectors?.forEach((item) => {
          mapObject[item?.id] = item;
        });
        setSectorsMap(mapObject);
        setSectors(sectors);
      }),
      AuthSetAgent.department.getDepartments().then((response) => {
        const mapObject = {};
        const departments = response.result.items;
        departments?.forEach((item) => {
          mapObject[item?.id] = item;
        });
        setDepartmentsMap(mapObject);
        setDepartments(departments);
      }),
      AuthSetAgent.subDepartment.getSubDepartments().then((response) => {
        const mapObject = {};
        const subDepartments = response.result.items;
        subDepartments?.forEach((item) => {
          mapObject[item?.id] = item;
        });
        setSubDepartmentsMap(mapObject);
        setSubDepartments(subDepartments);
      }),
      AuthSetAgent.authSetup.getAllRoles().then((response) => {
        const roles = response;
        setRoles(roles);
      }),
      AuthSetAgent.authSetup.getAllClaims().then((response) => {
        const claims = response;
        setClaims(claims);
      }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [currentPage]);

  const processedUsers = useMemo(
    () =>
      users?.map((item) => ({
        ...item,
        id: item?.id || null,
        username: item?.username || null,
        email: item?.email || null,
        phoneNumber: item?.phoneNumber || null,
        name: item?.name || null,
        surname: item?.surname || null,
        motherName: item?.motherName || null,
        fatherName: item?.fatherName || null,
        afm: item?.afm || null,
        subOrganizationId: item?.subOrganizationId || null,
        subOrganizationName:
          subOrganizationMap[item?.subOrganizationId]?.name || null,
        organizationId: item?.organizationId || null,
        organizationName: organizationsMap[item?.organizationId]?.name || null,
        sectorId: item?.sectorId || null,
        sectorName: sectorsMap[item?.sectorId]?.name || null,
        departmentId: item?.departmentId || null,
        departmentName: departmentsMap[item?.departmentId]?.name || null,
        subDepartmentId: item?.subDepartmentId || null,
        subDepartmentName:
          subDepartmentsMap[item?.subDepartmentId]?.name || null,
        role: item?.role || null,
        status: item?.status || null,
      })),
    [
      users,
      subOrganizationMap,
      organizationsMap,
      sectorsMap,
      departmentsMap,
      subDepartmentsMap,
    ]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await Promise.all([
        AuthSetAgent.users
          .getAllUsers(currentPage, PAGE_SIZE)
          .then((response) => {
            const totalPages = Math.ceil(
              response.result.totalCount / response.result.pageSize
            );
            setTotalPages(totalPages);
            const users = response.result.items;
            setUsers(users);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.subOrganizations.getSubOrganizations().then((response) => {
          const mapObject = {};
          const subOrganizations = response.result.items;
          subOrganizations?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setSubOrganizationMap(mapObject);
          setSubOrganizations(subOrganizations);
        }),
        AuthSetAgent.organizations.getOrganizations().then((response) => {
          const mapObject = {};
          const organizations = response.result.items;
          organizations?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setOrganizationsMap(mapObject);
          setOrganizations(organizations);
        }),
        AuthSetAgent.sectors.getSectors().then((response) => {
          const mapObject = {};
          const sectors = response.result.items;
          sectors?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setSectorsMap(mapObject);
          setSectors(sectors);
        }),
        AuthSetAgent.department.getDepartments().then((response) => {
          const mapObject = {};
          const departments = response.result.items;
          departments?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setDepartmentsMap(mapObject);
          setDepartments(departments);
        }),
        AuthSetAgent.subDepartment.getSubDepartments().then((response) => {
          const mapObject = {};
          const subDepartments = response.result.items;
          subDepartments?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setSubDepartmentsMap(mapObject);
          setSubDepartments(subDepartments);
        }),
        AuthSetAgent.authSetup.getAllRoles().then((response) => {
          const roles = response;
          setRoles(roles);
        }),
        AuthSetAgent.authSetup.getAllClaims().then((response) => {
          const claims = response;
          setClaims(claims);
        }),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [currentPage]);

  useEffect(() => {
    const shouldScroll = formStatus !== FORM_STATUS.HIDDEN;

    if (shouldScroll) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }
  }, [formStatus, focusedUser?.id]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <Typography
        className="pt-6 pb-5"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Λίστα Χρηστών
      </Typography>
      <UsersTable
        focusedItem={focusedUser}
        formStatus={formStatus}
        users={processedUsers}
        handleAction={handleAction}
        refreshList={refreshList}
      />
      <CustomPagination
        className={'my-4 flex justify-center items-center'}
        color={'#003375'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              setFormStatus((prev) =>
                prev === FORM_STATUS.CREATE
                  ? FORM_STATUS.HIDDEN
                  : FORM_STATUS.CREATE
              );
            }}
            variant="contained"
            type="submit"
          >
            Προσθήκη Νέου
          </Button>
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <>
      <Box className="min-h-[110vh]">
        <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
        <Box className="ml-4">
          {isLoading ? loader : content}
          {(formStatus === FORM_STATUS.CREATE ||
            formStatus === FORM_STATUS.EDIT) && (
            <ExpandableSectionContainer ref={formSectionRef}>
              <UsersCreateEditForm
                isEdit={formStatus === FORM_STATUS.EDIT}
                item={focusedUser}
                handleAction={handleAction}
                refreshList={refreshList}
                organizations={organizations}
                subOrganizations={subOrganizations}
                departments={departments}
                subDepartments={subDepartments}
                sectors={sectors}
                roles={roles}
                claims={claims}
              />
            </ExpandableSectionContainer>
          )}
          {formStatus === FORM_STATUS.VIEW && (
            <ExpandableSectionContainer ref={formSectionRef}>
              <UsersViewForm
                claims={claims}
                handleAction={handleAction}
                item={focusedUser}
              />
            </ExpandableSectionContainer>
          )}
        </Box>
      </Box>
    </>
  );
};

export default observer(Users);
