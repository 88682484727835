import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { FORM_STATUS } from './SubOrganizations';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutline } from '@mui/icons-material';
import { useEffect } from 'react';
import {
  subOrganizationsStore,
  useSubOrganizationsStore,
} from '../../../Stores/SubOrganizationsStore';
import { error, success } from '../../../Common/Toast/Toast';

const OrganizationsList = ({
  value,
  error,
  onChange,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Οργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="suborganizations-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {subOrganizationsStore.organizationsList?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio disabled={isEdit} value={item?.id} />}
              label={item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const SUB_ORG_FIELD = {
  Name: 'name',
  Description: 'description',
  Address: 'address',
  OrganizationId: 'organizationId',
  Telephone: 'tel',
};

const SubOrgSchema = yup.object().shape({
  [SUB_ORG_FIELD.Name]: yup.string().required(),
  [SUB_ORG_FIELD.Description]: yup.string().notRequired(),
  [SUB_ORG_FIELD.Address]: yup.string().required(),
  [SUB_ORG_FIELD.OrganizationId]: yup.string().required(),
  [SUB_ORG_FIELD.Telephone]: yup
    .string()
    .trim()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .min(10)
    .max(10)
    .required(),
});

const defaultValues = {
  [SUB_ORG_FIELD.Name]: '',
  [SUB_ORG_FIELD.Description]: '',
  [SUB_ORG_FIELD.Address]: '',
  [SUB_ORG_FIELD.OrganizationId]: null,
  [SUB_ORG_FIELD.Telephone]: '',
};

const SubOrganizationsCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
  organizations,
}) => {
  const [loading, setLoading] = useState(false);
  const subOrganizationStore = useSubOrganizationsStore();
  const [actionLoading, setActionLoading] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: isEdit
      ? {
          [SUB_ORG_FIELD.Name]: item?.[SUB_ORG_FIELD.Name],
          [SUB_ORG_FIELD.Description]: item?.[SUB_ORG_FIELD.Description],
          [SUB_ORG_FIELD.Address]: item?.[SUB_ORG_FIELD.Address],
          [SUB_ORG_FIELD.OrganizationId]: item?.[SUB_ORG_FIELD.OrganizationId],
          [SUB_ORG_FIELD.Telephone]: item?.[SUB_ORG_FIELD.Telephone],
        }
      : defaultValues,
    resolver: yupResolver(SubOrgSchema),
  });

  useEffect(() => {
    subOrganizationsStore.getAllOrganizations();
  }, [subOrganizationsStore.organizationsList?.length]);

  const submitCreate = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      ...watch(),
      [SUB_ORG_FIELD.OrganizationId]: parseInt(
        formValues[SUB_ORG_FIELD.OrganizationId]
      ),
    };
    await subOrganizationStore
      .createNewSubOrganization(formattedBody)
      .then(async () => {
        success('Η Γενική Διεύθυνση δημιουργήθηκε επιτυχώς');
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        reset(defaultValues);
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά τη δημιουργία Γενικής Διεύθυνσης!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formattedBody = {
      ...watch(),
    };
    await subOrganizationStore
      .updateSubOrganization(item.id, formattedBody)
      .then(async () => {
        success('Η Γενική Διεύθυνση δημιουργήθηκε επιτυχώς');
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά την επεξεργασία Γενικής Διεύθυνσης!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await submitCreate();
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [SUB_ORG_FIELD.Name]: item?.[SUB_ORG_FIELD.Name] || '',
        [SUB_ORG_FIELD.Description]: item?.[SUB_ORG_FIELD.Description] || '',
        [SUB_ORG_FIELD.Address]: item?.[SUB_ORG_FIELD.Address] || '',
        [SUB_ORG_FIELD.OrganizationId]:
          item?.[SUB_ORG_FIELD.OrganizationId] || null,
        [SUB_ORG_FIELD.Telephone]: item?.[SUB_ORG_FIELD.Telephone] || '',
      });
    }
  }, [isEdit, item, reset]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit
          ? 'Επεξεργασία Γενικής Διεύθυνσης'
          : 'Προσθήκη Γενικής Διεύθυνσης'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={SUB_ORG_FIELD.Name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label="Ονομασία"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error &&
                    'Το πεδίο Ονομασία Γενικής Διεύθυνσης είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={SUB_ORG_FIELD.Telephone}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label="Τηλέφωνο"
                  onChange={onChange}
                  value={value}
                  type="number"
                  error={!!error}
                  helperText={
                    error && 'Το Τηλέφωνο πρέπει να είναι 10-ψήφιος αριθμός'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={SUB_ORG_FIELD.Description}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label="Περιγραφή"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error &&
                    'Το πεδίο Περιγραφή Γενικής Διεύθυνσης είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={SUB_ORG_FIELD.Address}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label={'Τοποθεσία'}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Το πεδίο Τοποθεσία είναι υποχρεωτικό!'}
                />
              </Box>
            )}
          />
        </Box>
        <Controller
          name={SUB_ORG_FIELD.OrganizationId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <OrganizationsList
              isEdit={isEdit}
              error={!!error}
              onChange={onChange}
              value={value}
              organizations={organizations}
            />
          )}
        />
        <Box className="flex justify-end pt-5">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              title=" Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              className="capitalize text-sm"
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default SubOrganizationsCreateEditForm;
