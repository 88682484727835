import { observer } from 'mobx-react';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useState, useEffect } from 'react';
import Accordions from '../../../Components/Accordion/Accordion';
import Topbar from '../../../Components/Topbar/Topbar';
import { t } from 'i18next';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { error, success } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { ORG_NAME } from '../../../Config/constant';
import CheckboxComponent from '../../../Components/Theme/Inputs/CheckboxComponent';
import ShowOnlyDatePicker from '../../../Components/Theme/Inputs/ShowOnlyDatePicker';

const CreateCertificateForm = ({
  certificateData = {},
  isEditMode = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(isEditMode);
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const subDepartmentsStore = useSubDepartmentStore();
  const departmentStore = useDepartmentStore();
  const certificatesStore = useCertificatesStore();
  const appointmentServiceStore = useAppointmentServiceStore();

  const certificateSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    certificateKey: yup.object().required('Το πεδίο ειναι υποχρεωτικό'),
    instructions: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    description: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    applicationCategoryId: yup.object().required('Το πεδίο ειναι υποχρεωτικό'),
    isEnabled: yup.bool().notRequired(),
    isCompany: yup.bool().required('Το πεδίο ειναι υποχρεωτικό'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: certificateData?.name || '',
      instructions: certificateData?.instructions || '',
      description: certificateData?.description || '',
      certificateKey:
        certificatesStore.availableCertificatesList?.find(
          (c) => c.key === certificateData?.certificateKey
        ) || null,
      applicationCategoryId:
        appointmentServiceStore.applicationCategories?.find(
          (c) => c.id === certificateData?.applicationCategoryId
        ) || null,
      isEnabled: certificateData.isEnabled,
      isCompany: certificateData.isCompany || false,
    },
    resolver: yupResolver(certificateSchema),
  });

  const addNewCertificate = (data) => {
    const payload = {
      name: data.name,
      certificateKey: data.certificateKey.key,
      instructions: data.instructions,
      description: data.description,
      applicationCategoryId: data?.applicationCategoryId.id,
      departmentId: authStore.authUser?.departmentId,
      isCompany: data.isCompany || false,
    };

    setLoading(true);
    certificatesStore
      .addNewCertificate(payload)
      .then((response) => {
        success('Το πιστοποιητικό προσθέθηκε επιτυχώς');
        navigate('/certificates');
      })
      .catch((err) => {
        error(err);
        error('Το πιστοποιητικό δεν προσθέθηκε');
        setLoading(false);
      });
  };

  const updateCertificate = (data) => {
    if (loading || !certificateData.id) return;

    setLoading(true);

    certificatesStore
      .updateCertificate(certificateData.id, {
        name: data?.name,
        certificateKey: data?.certificateKey.key,
        instructions: data?.instructions,
        description: data?.description,
        applicationCategoryId: data?.applicationCategoryId.id,
        isEnabled: data?.isEnabled,
        isCompany: data?.isCompany,
      })
      .then(() => {
        navigate('/certificates');
        success('Το πιστοποιητικό ενημερώθηκε επιτυχώς');
      })
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  const renderComponentsText = (components) => {
    return components.map((component, index) => {
      if (component.type === 'text' && component.text) {
        return <p key={index}>{parse(component.text)}</p>;
      }
      return null;
    });
  };

  const performApiRequest = (data) =>
    certificateData?.id ? updateCertificate(data) : addNewCertificate(data);

  useEffect(() => {
    appointmentServiceStore.getApplicationCategories(
      authStore.authUser?.organizationId
    );
  }, [appointmentServiceStore.applicationCategories?.length]);

  useEffect(() => {
    subDepartmentsStore.getSubDepartments();
  }, [subDepartmentsStore.subDepartmentsList?.length]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  useEffect(() => {
    certificatesStore.getAvailableCertificates();
  }, [certificatesStore.availableCertificatesList?.length]);

  return (
    <>
      <form onSubmit={handleSubmit(performApiRequest)}>
        <div>
          <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
        </div>

        <div className="flex justify-start items-start">
          <Typography
            className="pl-4 pt-4"
            variant="h3"
            color={'#003375'}
            gutterBottom
          >
            {isEditMode
              ? 'Προβολή Πιστοποιητικού'
              : 'Προσθήκη νέου πιστοποιητικού'}
          </Typography>
        </div>

        <div className=" pl-4 pt-6">
          <Controller
            name="isEnabled"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label={
                  certificateData?.isEnabled === true ? (
                    <span className="text-[1.1rem]">
                      {' '}
                      Κατάσταση πιστοποιητικού ενεργή{' '}
                    </span>
                  ) : (
                    <span className="text-[1.1rem]">
                      Κατάσταση πιστοποιητικού ανενεργή
                    </span>
                  )
                }
                control={
                  <Checkbox
                    disabled={editMode}
                    checked={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </div>

        <div className=" pl-4 pt-6">
          <Controller
            name="isCompany"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControlLabel
                label="Για Επιχείρηση"
                control={
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    disabled={editMode}
                  />
                }
              />
            )}
          />
        </div>

        <div>
          <Typography className="pl-4 pt-8" variant="h4" gutterBottom>
            Τίτλος Πιστοποιητικού:
          </Typography>

          <Grid container className="pl-4 pt-6">
            <Controller
              name="name"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled={editMode}
                  fullWidth
                  type="text"
                  className="mt-14 bg-[#FFFF]"
                  label={'Τίτλος Πιστοποιητικού'}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </Grid>
        </div>

        <div className="flex justify-start items-start">
          <Accordions className="mt-8 w-full" text={'Επιλογή Πιστοποιητικού'}>
            <Grid className="pl-4 pt-2">
              {isEditMode &&
              certificatesStore.availableCertificatesList?.length ? (
                <Grid container>
                  <Controller
                    fullWidth
                    name="certificateKey"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        disabled={editMode}
                        fullWidth
                        onChange={(e, value) => onChange(value)}
                        value={value}
                        options={certificatesStore.availableCertificatesList}
                        getOptionLabel={(option) => option.name || ''}
                        isOptionEqualToValue={(option, key) =>
                          option?.key === key?.key
                        }
                        renderInput={(params) => (
                          <TextField
                            disabled={editMode}
                            fullWidth
                            {...params}
                            className="bg-[#FFFF]"
                            label={'Πιστοποιητικά'}
                            error={!!error}
                            helperText={
                              error ? t(error.message, { min: 1 }) : null
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Controller
                  fullWidth
                  name="certificateKey"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      disabled={editMode}
                      fullWidth
                      onChange={(e, value) => {
                        onChange(value);
                        if (value) {
                          certificatesStore.getAvailableCertificatesByKey(
                            value?.key
                          );
                        }
                      }}
                      value={value}
                      options={certificatesStore.availableCertificatesList}
                      getOptionLabel={(option) => option.name || ''}
                      isOptionEqualToValue={(option, key) =>
                        option?.key === key?.key
                      }
                      renderInput={(params) => (
                        <TextField
                          disabled={editMode}
                          fullWidth
                          {...params}
                          className="bg-[#FFFF]"
                          label={'Πιστοποιητικά'}
                          error={!!error}
                          helperText={
                            error ? t(error.message, { min: 1 }) : null
                          }
                        />
                      )}
                    />
                  )}
                />
              )}
            </Grid>
          </Accordions>
        </div>

        <div className="flex justify-start items-start">
          <Accordions
            className="mt-8 w-full"
            text={'Επιλογή Κατηγορίας Υπηρεσίας'}
          >
            <Grid className="pl-4 pt-2">
              {appointmentServiceStore.applicationCategories?.length ? (
                <Grid container>
                  <Controller
                    name="applicationCategoryId"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        disabled={editMode}
                        className="w-full mt-2"
                        onChange={(e, value) => onChange(value)}
                        value={value}
                        options={appointmentServiceStore.applicationCategories}
                        getOptionLabel={(option) => option?.title || ''}
                        isOptionEqualToValue={(option, id) =>
                          option.id === id.id
                        }
                        renderInput={(params) => (
                          <TextField
                            disabled={editMode}
                            {...params}
                            label={'Επιλογή κατηγορίας'}
                            className="bg-[#FFFF]"
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Accordions>
        </div>

        <div className="flex justify-start items-start">
          <Typography className="pl-4 pt-12" variant="h4" gutterBottom>
            Οδηγίες αίτησης
          </Typography>
        </div>

        <Grid className="pl-4 pt-4">
          <Controller
            name="instructions"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                disabled={editMode}
                fullWidth
                multiline
                rows={4}
                type="text"
                className="mt-14 bg-[#FFFF]"
                label={
                  'Συμπλήρωση οδηγιών προς πολίτες για την ψηφιακή έκδοση του πιστοποιητικού'
                }
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message) : null}
              />
            )}
          />
        </Grid>

        <div className="flex justify-start items-start">
          <Typography className="pl-4 pt-12" variant="h4" gutterBottom>
            Περιγραφή αίτησης
          </Typography>
        </div>

        <Grid className="pl-4 pt-4">
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                disabled={editMode}
                fullWidth
                multiline
                rows={4}
                type="text"
                className="mt-14 bg-[#FFFF]"
                label={'Περιγραφή αίτησης'}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? t(error.message) : null}
              />
            )}
          />
        </Grid>

        {isEditMode
          ? certificateData.formComponents?.map((certificateFields, index) => (
              <div key={index} className="flex justify-start items-start">
                <Accordions className="mt-8" text={certificateFields.name}>
                  <Typography className="pl-4 pt-2" variant="h4" gutterBottom>
                    {renderComponentsText(certificateFields.components)}
                  </Typography>
                  <div key={index}>
                    <p className="mt-6 grid grid-cols-3">
                      {certificateFields.components
                        ?.filter((component) => component.type !== 'text')
                        ?.map((component, componentIndex) => {
                          if (component.type === 'group') {
                            const groupComponents = component.components || [];

                            const groupFields = groupComponents?.filter(
                              (groupField) =>
                                groupField?.type !== 'text' &&
                                groupField?.type !== 'button'
                            );

                            return groupFields?.map(
                              (groupField, groupIndex) => (
                                <div key={`${index}-${groupIndex}`}>
                                  {groupField.type === 'textfield' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-6">
                                        <TextField
                                          disabled={true}
                                          className="w-[75%]"
                                          type="text"
                                          label={groupField.label}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === 'datetime' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-2 w-full">
                                        <ShowOnlyDatePicker
                                          disabled={true}
                                          placeholder={groupField.label}
                                          format="DD-MM-YYYY"
                                          className="w-[72%]"
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === 'checkbox' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <CheckboxComponent
                                          disabled={true}
                                          label={groupField.label}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === 'textarea' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <TextField
                                          className="w-[75%]"
                                          InputLabelProps={{ shrink: false }}
                                          label=""
                                          placeholder={groupField.label}
                                          type="text"
                                          multiline
                                          rows={2}
                                          disabled={true}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === 'number' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-6">
                                        <TextField
                                          disabled={true}
                                          className="w-[75%]"
                                          type="number"
                                          label={groupField.label}
                                        />
                                      </div>
                                    </>
                                  ) : groupField.type === 'select' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {groupField.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <Autocomplete
                                          disabled={true}
                                          className="w-[100%]"
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.value === value.value}
                                          options={groupField.values}
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              disabled={true}
                                              {...params}
                                              type="text"
                                              label={groupField.label}
                                            />
                                          )}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              )
                            );
                          } else if (component.id !== 'description') {
                            return (
                              <div key={componentIndex}>
                                {component.type === 'textfield' ? (
                                  <>
                                    <p className="govgr-body-s ml-8 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-6">
                                      <TextField
                                        disabled={true}
                                        className="w-[75%]"
                                        type="text"
                                        label={component.label}
                                      />
                                    </div>
                                  </>
                                ) : component.type === 'datetime' ? (
                                  <>
                                    <p className="govgr-body-s ml-6 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-2 w-full">
                                      <ShowOnlyDatePicker
                                        disabled={true}
                                        placeholder={component.label}
                                        format="DD-MM-YYYY"
                                        className="w-[72%]"
                                      />
                                    </div>
                                  </>
                                ) : component.type === 'checkbox' ? (
                                  <>
                                    <p className="govgr-body-s ml-6 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-2">
                                      <CheckboxComponent
                                        disabled={true}
                                        label={component.label}
                                      />
                                    </div>
                                  </>
                                ) : component.type === 'textarea' ? (
                                  <>
                                    <p className="govgr-body-s ml-6 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-2">
                                      <TextField
                                        className="w-[75%]"
                                        InputLabelProps={{ shrink: false }}
                                        label=""
                                        placeholder={component.label}
                                        type="text"
                                        multiline
                                        rows={2}
                                        disabled={true}
                                      />
                                    </div>
                                  </>
                                ) : component.type === 'number' ? (
                                  <>
                                    <p className="govgr-body-s ml-8 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-6">
                                      <TextField
                                        disabled={true}
                                        className="w-[75%]"
                                        type="number"
                                        label={component.label}
                                      />
                                    </div>
                                  </>
                                ) : component.type === 'select' ? (
                                  <>
                                    <p className="govgr-body-s ml-8 mb-4 font-bold">
                                      {component.label}
                                    </p>
                                    <div className="ml-6 pb-2">
                                      <Autocomplete
                                        disabled={true}
                                        className="w-[100%]"
                                        isOptionEqualToValue={(option, value) =>
                                          option.value === value.value
                                        }
                                        options={component.values}
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            disabled={true}
                                            {...params}
                                            type="text"
                                            label={component.label}
                                          />
                                        )}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            );
                          }
                          return null;
                        })}
                    </p>
                  </div>
                </Accordions>
              </div>
            ))
          : null}

        {!isEditMode
          ? certificatesStore.availableCertificatesByKeyList?.map(
              (certificateFields, index) => (
                <div key={index} className="flex justify-start items-start">
                  <Accordions className="mt-8" text={certificateFields?.name}>
                    <Typography className="pl-4 pt-2" variant="h4" gutterBottom>
                      {renderComponentsText(certificateFields.components)}
                    </Typography>
                    <div key={index}>
                      <p className="mt-6 grid grid-cols-3">
                        {certificateFields.components
                          ?.filter((component) => component.type !== 'text')
                          ?.map((component, componentIndex) => {
                            if (component.type === 'group') {
                              const groupComponents =
                                component.components || [];

                              const groupFields = groupComponents?.filter(
                                (groupField) =>
                                  groupField?.type !== 'text' &&
                                  groupField?.type !== 'button'
                              );

                              return groupFields?.map(
                                (groupField, groupIndex) => (
                                  <div key={`${index}-${groupIndex}`}>
                                    {groupField.type === 'textfield' ? (
                                      <>
                                        <p className="govgr-body-s ml-8 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-6">
                                          <TextField
                                            disabled={true}
                                            className="w-[75%]"
                                            type="text"
                                            label={groupField.label}
                                          />
                                        </div>
                                      </>
                                    ) : groupField.type === 'datetime' ? (
                                      <>
                                        <p className="govgr-body-s ml-6 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-2 w-full">
                                          <ShowOnlyDatePicker
                                            disabled={true}
                                            placeholder={groupField.label}
                                            format="DD-MM-YYYY"
                                            className="w-[72%]"
                                          />
                                        </div>
                                      </>
                                    ) : groupField.type === 'checkbox' ? (
                                      <>
                                        <p className="govgr-body-s ml-6 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-2">
                                          <CheckboxComponent
                                            disabled={true}
                                            label={groupField.label}
                                          />
                                        </div>
                                      </>
                                    ) : groupField.type === 'textarea' ? (
                                      <>
                                        <p className="govgr-body-s ml-6 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-2">
                                          <TextField
                                            className="w-[75%]"
                                            InputLabelProps={{ shrink: false }}
                                            label=""
                                            placeholder={groupField.label}
                                            type="text"
                                            multiline
                                            rows={2}
                                            disabled={true}
                                          />
                                        </div>
                                      </>
                                    ) : groupField.type === 'number' ? (
                                      <>
                                        <p className="govgr-body-s ml-8 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-6">
                                          <TextField
                                            disabled={true}
                                            className="w-[75%]"
                                            type="number"
                                            label={groupField.label}
                                          />
                                        </div>
                                      </>
                                    ) : groupField.type === 'select' ? (
                                      <>
                                        <p className="govgr-body-s ml-8 mb-4 font-bold">
                                          {groupField.label}
                                        </p>
                                        <div className="ml-6 pb-2">
                                          <Autocomplete
                                            disabled={true}
                                            className="w-[100%]"
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) => option.value === value.value}
                                            options={groupField.values}
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                disabled={true}
                                                {...params}
                                                type="text"
                                                label={groupField.label}
                                              />
                                            )}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                )
                              );
                            } else if (component.id !== 'description') {
                              return (
                                <div key={componentIndex}>
                                  {component.type === 'textfield' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-6">
                                        <TextField
                                          disabled={true}
                                          className="w-[75%]"
                                          type="text"
                                          label={component.label}
                                        />
                                      </div>
                                    </>
                                  ) : component.type === 'datetime' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-2 w-full">
                                        <ShowOnlyDatePicker
                                          disabled={true}
                                          placeholder={component.label}
                                          format="DD-MM-YYYY"
                                          className="w-[72%]"
                                        />
                                      </div>
                                    </>
                                  ) : component.type === 'checkbox' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <CheckboxComponent
                                          disabled={true}
                                          label={component.label}
                                        />
                                      </div>
                                    </>
                                  ) : component.type === 'textarea' ? (
                                    <>
                                      <p className="govgr-body-s ml-6 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <TextField
                                          className="w-[75%]"
                                          InputLabelProps={{ shrink: false }}
                                          label=""
                                          placeholder={component.label}
                                          type="text"
                                          multiline
                                          rows={2}
                                          disabled={true}
                                        />
                                      </div>
                                    </>
                                  ) : component.type === 'number' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-6">
                                        <TextField
                                          disabled={true}
                                          className="w-[75%]"
                                          type="number"
                                          label={component.label}
                                        />
                                      </div>
                                    </>
                                  ) : component.type === 'select' ? (
                                    <>
                                      <p className="govgr-body-s ml-8 mb-4 font-bold">
                                        {component.label}
                                      </p>
                                      <div className="ml-6 pb-2">
                                        <Autocomplete
                                          disabled={true}
                                          className="w-[100%]"
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) => option.value === value.value}
                                          options={component.values}
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              disabled={true}
                                              {...params}
                                              type="text"
                                              label={component.label}
                                            />
                                          )}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              );
                            }
                            return null;
                          })}
                      </p>
                    </div>
                  </Accordions>
                </div>
              )
            )
          : null}

        {isEditMode && (
          <>
            <div className="flex justify-end items-end pr-4 mt-4 mb-4">
              <SubmitButton
                onClick={() => setEditMode(!editMode)}
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: '#003375',
                  color: '#f9f9f9',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                  },
                  fontSize: '1rem',
                }}
                type="button"
                title={editMode ? 'Επεξεργασία' : 'Ακύρωση'}
              />
            </div>
          </>
        )}

        {!editMode && (
          <div className="flex justify-end items-end mt-4 mb-4">
            <SubmitButton
              loading={loading}
              variant="contained"
              sx={{
                backgroundColor: '#003375',
                color: '#f9f9f9',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              type="submit"
              title="Αποθήκευση"
            />
          </div>
        )}
      </form>
    </>
  );
};

export default observer(CreateCertificateForm);
