import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class DepartmentStore {
  departmentList = [];

  constructor() {
    makeObservable(this, {
      departmentList: observable,
      getDepartments: action,
    });
  }

  getDepartments = () => {
    return AuthSetAgent.department.getDepartments().then((res) => {
      this.departmentList = res.result.items;
    });
  };
}

export const departmentStore = new DepartmentStore();
export const DepartmentStoreContext = createContext(departmentStore);
export const DepartmentStoreProvider = ({ children, store }) => (
  <DepartmentStoreContext.Provider value={store}>
    {children}
  </DepartmentStoreContext.Provider>
);
export const useDepartmentStore = () => useContext(DepartmentStoreContext);
