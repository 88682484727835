import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { CircularProgress, Modal, Typography } from '@mui/material';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useEffect } from 'react';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { useNavigate } from 'react-router-dom';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import CancelIcon from '@mui/icons-material/Cancel';

const ScheduleActive = ({ selectedSubDepartmentId }) => {
  const authStore = useAuthStore();
  const subDepartmentStore = useSubDepartmentStore();
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [isModalArchiveOpen, setIsModalArchiveOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);

  useEffect(() => {
    if (!authStore.authUserIsNotLoaded) {
      rantevouStore.getScheduleList(selectedSubDepartmentId);
      setReload(false);
      setLoading(false);
    }
  }, [
    rantevouStore.scheduleList?.length,
    authStore.authUserIsNotLoaded,
    authStore.authUser,
    reload,
    selectedSubDepartmentId,
  ]);

  useEffect(() => {
    rantevouStore.getActiveSchedules(selectedSubDepartmentId);
  }, [rantevouStore, selectedSubDepartmentId]);

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  const viewSchedule = (scheduleId) => {
    navigate(`/active-schedules/${scheduleId}`);
  };

  const deleteSchedule = async (scheduleId) => {
    setIsModalDeleteOpen(false);
    try {
      setLoading(true);
      await rantevouStore.deleteSchedule(scheduleId);
    } catch (error) {
      console.error('An error occurred while deleting the schedule:', error);
    } finally {
      await rantevouStore.getScheduleList(authStore.authUser.departmentId);
      setLoading(false);
    }
  };

  const archiveSchedule = async (scheduleId) => {
    setIsModalArchiveOpen(false);
    try {
      setLoading(true);
      await rantevouStore.archiveSchedule(scheduleId);
    } catch (error) {
      console.error('An error occurred while archiving the schedule:', error);
    } finally {
      await rantevouStore.getScheduleList(authStore.authUser.departmentId);
      setLoading(false);
    }
  };

  return !loading ? (
    <>
      <div className="ml-4 mt-6 max-w-[60%] min-w-[50%]">
        {rantevouStore.scheduleList
          ?.filter((schedule) => schedule.status === 1)
          ?.map((schedule) => {
            const matchingSubDepartment =
              subDepartmentStore.subDepartmentsList?.find(
                (subDepartment) => subDepartment.id === schedule.subDepartmentId
              );

            if (matchingSubDepartment) {
              return (
                <div
                  className="flex flex-wrap mb-1 px-4 py-2 bg-[#ccf0ff] lg:items-center lg:justify-between"
                  key={schedule.id}
                >
                  <Typography
                    className="font-bold pt-2 pr-2"
                    variant="h5"
                    gutterBottom
                  >
                    Ημερ. {format(new Date(schedule.timeFrom), 'dd/MM/yyyy')} -{' '}
                    {format(new Date(schedule.timeTo), 'dd/MM/yyyy')} | Υποτμήμα{' '}
                    {matchingSubDepartment.name}
                  </Typography>
                  <div className="flex flex-wrap gap-2">
                    {authStore?.isAdmin && (
                      <>
                        <SubmitButton
                          disabled={schedule.status === 1}
                          sx={{
                            backgroundColor: '#e87272',
                            '&:hover': {
                              backgroundColor: '#9e5454',
                            },
                          }}
                          onClick={() => {
                            setScheduleId(schedule.id);
                            setIsModalDeleteOpen(true);
                          }}
                          className="capitalize text-sm"
                          title="Διαγραφή"
                          size="small"
                          variant="contained"
                          type="button"
                        />
                        <SubmitButton
                          sx={{
                            backgroundColor: '#3CB371',
                            '&:hover': {
                              backgroundColor: '#8FBC8F',
                            },
                          }}
                          onClick={() => {
                            setScheduleId(schedule.id);
                            setIsModalArchiveOpen(true);
                          }}
                          className="capitalize text-sm"
                          title="Αρχειοθέτηση"
                          size="small"
                          variant="contained"
                          type="button"
                        />
                      </>
                    )}
                    <SubmitButton
                      sx={{
                        backgroundColor: '#003375',
                      }}
                      onClick={() => viewSchedule(schedule.id)}
                      className="capitalize text-sm"
                      title="Προβολή"
                      size="small"
                      variant="contained"
                      type="button"
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>

      {rantevouStore.scheduleList?.filter((schedule) => schedule.status === 1)
        ?.length === 0 && (
        <div className="flex ml-4 justify-start items-center">
          <Typography variant="h5">Δεν υπάρχουν ενεργά προγράμματα</Typography>
        </div>
      )}

      <Modal
        className="flex items-center justify-center "
        open={isModalArchiveOpen}
        onClose={() => setIsModalArchiveOpen(false)}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-6 min-w-[25vw] min-h-[15vw] overflow-auto max-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalArchiveOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen m-8 flex flex-col justify-center items-center">
            <div className="flex flex-col gap-4 justify-center items-center">
              <Typography variant="h4">
                Αν επιλέξετε την αρχειοθέτηση του προγράμματος, τότε θα χάσετε
                όλα τα ενεργά ραντεβού που έχουν κλειστεί σε αυτό.
              </Typography>
              <Typography variant="h4">Θέλετε να συνεχίσετε;</Typography>
            </div>
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                <SubmitButton
                  onClick={() => archiveSchedule(scheduleId)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="NAI"
                />
              </Typography>

              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalArchiveOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="ΟΧΙ"
                />
              </Typography>
            </div>
          </div>
        </MainCard>
      </Modal>

      <Modal
        className="flex items-center justify-center "
        open={isModalDeleteOpen}
        onClose={() => setIsModalDeleteOpen(false)}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-4 min-w-[25vw] min-h-[15vw] mb-6 mt-6 overflow-auto max-h-screen"
        >
          <div className="flex items-end justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalDeleteOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen">
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                Αν επιλέξετε την διαγραφή του προγράμματος, τότε θα χάσετε όλα
                τα ενεργά ραντεβού που έχουν κλειστεί σε αυτό.
              </Typography>
            </div>
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-6">
                Θέλετε να συνεχίσετε;
              </Typography>
            </div>
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                <SubmitButton
                  onClick={() => deleteSchedule(scheduleId)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="NAI"
                />
              </Typography>

              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalDeleteOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="ΟΧΙ"
                />
              </Typography>
            </div>
          </div>
        </MainCard>
      </Modal>
    </>
  ) : (
    <div className="text-center py-10">
      <CircularProgress
        sx={{
          color: '#003375',
        }}
        size="3rem"
      />
    </div>
  );
};

export default observer(ScheduleActive);
