import { Typography } from '@mui/material';
import { observer } from 'mobx-react';

const DashBoardImageTitle = ({ src, alt, text, width }) => {
  return (
    <div className="flex justify-start items-start pt-4">
      <div className="flex items-center">
        <img width={width} src={src} alt={alt} />
        <Typography className="pl-2" variant="h4" color={'#003375'}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default observer(DashBoardImageTitle);
