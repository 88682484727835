import { observer } from 'mobx-react';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import NoDataCard from '../../../Components/NoDataCard/NoDataCard';
import { DataGrid } from '@mui/x-data-grid';
import { formatDateInDateMonthYear } from '../../../Common/formatDate/formatDate';
import clsx from 'clsx';
import { palette } from '../../../styles/variables/colors';

const CertificateSubmissionTable = ({ certificates }) => {
  const { authUser } = useAuthStore();
  const certificatesStore = useCertificatesStore();
  const departmentStore = useDepartmentStore();
  const appointmentServiceStore = useAppointmentServiceStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const viewCertificateSubmission = (id) => {
    navigate(`/certificates-submissions-view/${id}`);
  };

  const editCertificateSubmission = (id) => {
    navigate(`/certificates-submissions-edit/${id}`);
  };

  const signedCertificateUpload = (id) => {
    navigate(`/signed-certificate-upload/${id}`);
  };

  // const signCertificateSubmission = (id) => {
  //   navigate(`/certificates-submissions-sign/${id}`);
  // };

  // const approveCertificateSubmission = (id) => {
  //   navigate(`/certificates-submissions-approval/${id}`);
  // };

  const handleCertificateSubmissionClick = (params) => {
    const { row, id } = params;

    if (
      row?.status === 2 ||
      (row?.status === 3 && params?.row?.activeTaskId === 'Αξιολόγηση') ||
      row?.status === 4 ||
      row?.status === 5 ||
      row?.status === 6
    ) {
      editCertificateSubmission(id);
    } else if (params?.row?.status === 1) {
      viewCertificateSubmission(id);
    } else if (
      row?.status === 3 &&
      row?.activeTaskId === 'Επισύναψη πιστοποιητικού'
    ) {
      signedCertificateUpload(id);
    }
  };

  const tableTitleClasses = clsx([
    'ml-4 my-2 pt-2',
    !certificates?.length && 'pb-2',
  ]);

  function renderStatusName(status) {
    switch (status) {
      case 1:
        return 'Υποβληθείσες αιτήσεις';
      case 2:
        return 'Ακυρωμένες αιτήσεις';
      case 3:
        return 'Αιτήσεις σε εξέλιξη';
      case 4:
        return 'Ολοκληρωμένες αιτήσεις';
      case 5:
        return 'Αιτήσεις σε εκκρεμότητα';
      case 6:
        return 'Απορριφθείσες αιτήσεις';
      default:
        return 'Δεν υπάρχουν αιτήσεις για αυτή την κατάσταση';
    }
  }

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  useEffect(() => {
    appointmentServiceStore.getApplicationCategories(
      authStore.authUser?.organizationId
    );
  }, [appointmentServiceStore.applicationCategories?.length]);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [certificatesStore.certificatesSubmissionStatusesList?.length]);

  const columns = [
    {
      field: 'Id',
      headerName: 'Κωδ. αίτησης',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 2.3,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-12"
        >
          {params?.id ? params?.id : '-'}
        </Typography>
      ),
    },
    {
      field: 'CitizenLastName',
      headerName: 'Επώνυμο',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 2.3,
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-8"
        >
          {params?.row?.citizen?.surName ? params?.row?.citizen?.surName : '-'}
        </Typography>
      ),
    },
    {
      field: 'CitizenAfm',
      headerName: 'ΑΦΜ',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      disableColumnMenu: true,
      flex: 2.3,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-2"
        >
          {params?.row?.citizen?.afm ? params?.row?.citizen?.afm : '-'}
        </Typography>
      ),
    },
    {
      field: 'FinalizedFrom',
      headerName: 'Ημ. υποβολής',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      disableColumnMenu: true,
      flex: 2.3,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-8"
        >
          {params?.row?.finalizedAt
            ? formatDateInDateMonthYear(params?.row?.finalizedAt)
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'ProtocolNo',
      headerName: 'Αρ. πρωτ.',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      disableColumnMenu: true,
      flex: 2.3,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-8"
        >
          {params?.row?.protocolNo ? params?.row?.protocolNo : '-'}
        </Typography>
      ),
    },
    {
      field: 'ApplicationCategoryId',
      headerName: 'Όνομα Αίτησης',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      disableColumnMenu: true,
      flex: 3.8,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="text-[14px] pl-2"
        >
          {appointmentServiceStore.applicationCategories?.find(
            (c) => c.id === params.row.certificate?.applicationCategoryId
          )?.title || '-'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Προβολή',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      disableColumnMenu: true,
      flex: 2.2,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack
          className="mr-36"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {params?.row?.status === 0 ||
          params?.row?.protocolNo === null ||
          params?.row?.protocolNo === undefined ? (
            '-'
          ) : (
            <Tooltip
              onClick={() => handleCertificateSubmissionClick(params)}
              placement="top"
              title={'Προβολή'}
            >
              {params?.row?.status === 0 ||
              params?.row?.protocolNo === null ||
              params?.row?.protocolNo === undefined ? (
                '-'
              ) : (
                <Visibility
                  sx={{
                    cursor: 'pointer',
                    fontSize: '1.1rem',
                    marginLeft: '1.8rem',
                    color: '#333333B2',
                  }}
                />
              )}
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <div
        className="bg-[#003375] text-[#FFFFFF] text-[1.4rem]"
        style={{ width: '99%' }}
      >
        <div className={tableTitleClasses}>
          {' '}
          {renderStatusName(certificates[0]?.status)}{' '}
        </div>
        {!!certificates?.length && (
          <DataGrid
            sx={{
              '& .MuiDataGrid-root': {
                borderWidth: 0,
              },
              '& .certificates-table-theme--header': {
                backgroundColor: '#FFFFFF',
                fontSize: 15,
                borderBottom: '2px solid black',
              },
              '& .certificates-table-theme--row': {
                backgroundColor: '#FFFFFF',
                border: 'none',
                borderBottom: `1px solid ${palette.gray.light}`,
                overflowWrap: 'break-word',
                minHeight: '40px !important',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              ' & .MuiDataGrid-footerContainer': {
                backgroundColor: '#FFFFFF',
              },
              ' & .MuiTypography-root': {
                textWrap: 'wrap',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              // sorting: {
              //   sortModel: [
              //     { field: 'Id', sort: 'desc' },
              //     { field: 'FinalizedFrom', sort: 'desc' },
              //   ],
              // },
            }}
            getRowHeight={() => 'auto'}
            disableColumnSelector
            columns={columns}
            rows={certificates || []}
            loading={!certificates}
            autoHeight
            disableSelectionOnClick
          />
        )}
      </div>
      {!certificates?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default observer(CertificateSubmissionTable);
