import { Box, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import TicketsSearchTable from '../TicketsSearch/TicketsSearchTable';
import { useCallback, useEffect, useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import StatusTile from '../RantevouOverview/StatusTile';

const StatusBox = ({ isActive, count, title, onClick }) => {
  return (
    <StatusTile
      title={title}
      counter={count}
      isActive={isActive}
      onClick={onClick}
    />
  );
};

const TicketsOverview = () => {
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [tickets, setTickets] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [statusCount, setStatusCount] = useState({});
  const selectedStatusLabel =
    statuses?.find((status) => status.value === selectedStatus)?.description ||
    '---';
  const tableTitle = !selectedStatus
    ? 'Τελευταία αιτήματα'
    : `Αιτήματα με κατάσταση:  ${selectedStatusLabel}`;

  const handleStatusChange = useCallback(
    async (statusId) => {
      const newStatus = selectedStatus === statusId ? null : statusId;

      setSelectedStatus(newStatus);
      setIsSearching(true);

      await AuthSetAgent.tickets
        .searchTickets({
          statusId: newStatus,
          getLastUpdatedRecords: newStatus === null ? true : false,
        })
        .then((response) => {
          response?.result?.items && setTickets(response.result?.items);
        })
        .finally(() => {
          setIsSearching(false);
        });
    },
    [selectedStatus]
  );

  const getCount = (statusName) => {
    switch (statusName) {
      case 'Υπό εξέταση':
        return statusCount.UnderInvestigation;
      case 'Απορρίφθηκε':
        return statusCount.Rejected;
      case 'Νέο':
        return statusCount.New;
      case 'Σε εξέλιξη':
        return statusCount.InProgres;
      case 'Ολοκληρωμένο':
        return statusCount.Completed;
      default:
        return 0;
    }
  };

  useEffect(() => {
    AuthSetAgent.tickets
      .getStatusCount()
      .then((res) => {
        setStatusCount({
          UnderInvestigation: res.result.find(
            (item) => item.status === 'UnderInvestigation'
          )?.count,
          Rejected: res.result.find((item) => item.status === 'Rejected')
            ?.count,
          New: res.result.find((item) => item.status === 'New')?.count,
          InProgres: res.result.find((item) => item.status === 'InProgres')
            ?.count,
          Completed: res.result.find((item) => item.status === 'Completed')
            ?.count,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const intitializeOptions = async () => {
      await AuthSetAgent.tickets.getSearchLists().then((response) => {
        response?.result?.statuses && setStatuses(response.result.statuses);
      });
    };

    const initializeTickets = async () => {
      await AuthSetAgent.tickets
        .searchTickets({ getLastUpdatedRecords: true })
        .then((response) => {
          response?.result?.items && setTickets(response.result?.items);
        });
    };

    const prepareData = async () => {
      setIsLoading(true);

      await Promise.all([intitializeOptions(), initializeTickets()]).finally(
        () => {
          setIsLoading(false);
        }
      );
    };

    prepareData();
  }, []);

  return (
    <div>
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <div className="ml-4 min-h-screen">
        <Typography
          className="py-5"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Επισκόπηση αιτημάτων / παραπόνων
        </Typography>
        {isLoading ? (
          <div className="flex justify-center items-center pt-10">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Box className="flex justify-center items-center flex-wrap gap-4 pb-10">
              {statuses.map((status) => (
                <StatusBox
                  count={getCount(status?.description)}
                  key={status?.value}
                  isActive={selectedStatus === status?.value}
                  onClick={() => handleStatusChange(status?.value)}
                  title={status?.description}
                />
              ))}
            </Box>
            <Box className="border-b-2 w-1/2 mb-10" />
            {isSearching ? (
              <div className="flex justify-center items-center pt-10">
                <CircularProgress />
              </div>
            ) : (
              <section className="pb-10">
                <TicketsSearchTable label={tableTitle} tickets={tickets} />
              </section>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TicketsOverview;
