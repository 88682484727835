import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import {
  setBearerToken,
  setImpersonationTokenHeader,
} from '../Common/HttpClient/HttpClient';
import { decodeJwtToken } from '../Common/Encryption/Encryption';
import { error, success } from '../Common/Toast/Toast';
import {
  getRefreshTokenFromSessionStorage,
  getTokenFromSessionStorage,
  saveRefreshTokenToSessionStorage,
  saveImpersonationTokenToSessionStorage,
  saveTokenToSessionStorage,
  getImpersonationTokenFromSessionStorage,
  removeImpersonationTokenToSessionStorage,
} from '../Common/Storage/SessionStorage';
import i18n from '../Config/Localization/i18n';
import axios from 'axios';

class AuthStore {
  authUser = {};

  token = undefined;

  impersonationToken = undefined;

  refreshToken = null;

  navigate = null;

  intendedUrl = null;

  constructor() {
    makeObservable(this, {
      authUser: observable,
      userAuthenticated: computed,
      token: observable,
      impersonationToken: observable,
      refreshToken: observable,
      logout: action,
      login: action,
      setAuthUser: action,
      initializeTokens: action,
      isUser: computed,
      isAdmin: computed,
      isSuperAdmin: computed,
      isContentManager: computed,
      isOrganizationSupervisor: computed,
      isSubOrganizationSupervisor: computed,
      isSectorSupervisor: computed,
      authUserIsNotLoaded: computed,
      setImpersonationToken: action,
      getImpersonatedToken: action,
      clearImpersonationTokenHeader: action,
    });

    reaction(() => this.token, detectTokenValidity.bind(this));
    reaction(() => this.token, getUserProfile.bind(this));
    reaction(
      () => this.refreshToken,
      (refreshToken) => saveRefreshTokenToSessionStorage(refreshToken)
    );
    reaction(
      () => this.impersonationToken,
      (impersonationToken) => setImpersonationTokenHeader(impersonationToken)
    );
    reaction(
      () => this.impersonationToken,
      (impersonationToken) => {
        if (impersonationToken) {
          getUserProfile.call(this, this.token);
        }
      }
    );
    this.initializeTokens();
  }

  initializeTokens() {
    this.refreshToken = getRefreshTokenFromSessionStorage();
    this.token = getTokenFromSessionStorage();
    this.impersonationToken = getImpersonationTokenFromSessionStorage();
  }

  logout() {
    if (this.impersonationToken) {
      this.clearImpersonationTokenHeader();
      this.setImpersonationToken(null);
      removeImpersonationTokenToSessionStorage();
    }
    this.authUser = null;
    sessionStorage.clear();
    setBearerToken(null);
  }

  get userAuthenticated() {
    return !!this.authUser;
  }

  async login({ email, password }) {
    try {
      const loginResp = await AuthSetAgent.user.login(email, password);

      if (loginResp === undefined || loginResp === null) {
        error('Λανθασμένα Στοιχεία Εισόδου ');
      }

      if (!loginResp) {
        return;
      }
      const token = loginResp.token;
      const refreshToken = loginResp.refreshToken;

      if (!token || !refreshToken) {
        return;
      }
      this.saveTokens(token, refreshToken);
      const decoded = JSON.parse(atob(token.split('.')[1]));
      success('Welcome, ' + decoded?.email);
    } catch (error) {}
  }

  saveTokens(token, refreshToken) {
    saveTokenToSessionStorage(token);
    saveRefreshTokenToSessionStorage(refreshToken);
    this.token = token;
  }

  setAuthUser(user) {
    this.authUser = user;
  }

  setImpersonationToken(token) {
    this.impersonationToken = token;
    saveImpersonationTokenToSessionStorage(token);
  }

  getImpersonatedToken(email) {
    return AuthSetAgent.impersonation
      .getImpersonatedToken(email)
      .then((resp) => {
        this.setImpersonationToken(resp?.result);
        setImpersonationTokenHeader(this.impersonationToken);
      })
      .catch((error) => {});
  }

  clearImpersonationTokenHeader() {
    delete axios.defaults.headers.common['ImpersonationToken'];
  }

  updateProfile(phoneNumber) {
    AuthSetAgent.user
      .updateProfile(phoneNumber)
      .then((response) => {
        success(i18n.t('profile.ProfileWasSuccessfullyUpdated'));
      })
      .catch((e) => {});
  }

  updatePassword(currentPassword, newPassword, confirmNewPassword) {
    return AuthSetAgent.user
      .changePassword(currentPassword, newPassword, confirmNewPassword)
      .then((response) => {
        success(i18n.t('forms.PasswordWasSuccessfullyUpdated'));
      })
      .catch((e) => {});
  }

  forgotPassword(email) {
    return AuthSetAgent.user
      .forgotPassword(email)
      .then((resp) => {
        success(i18n.t('forgotPassword.ConfirmationEmail'));
      })
      .catch((e) => {});
  }

  resetPassword(data, token) {
    return AuthSetAgent.user
      .resetPassword(data, token)
      .then((resp) => {
        success(i18n.t('resetPassword.YouHaveSuccessfullyChangedYourPassword'));
      })
      .catch((e) => {});
  }

  async updateToken() {
    try {
      const token = getTokenFromSessionStorage();
      const refreshToken = getRefreshTokenFromSessionStorage();

      if (!token || !refreshToken) {
        return;
      }

      const params = {
        token: token,
        refreshToken: refreshToken,
      };

      const resp = await AuthSetAgent.user.refreshToken(params);
      saveTokenToSessionStorage(resp.token);
      saveRefreshTokenToSessionStorage(resp.refreshToken);
    } catch (error) {
      this.logout();
    }
  }

  navigateToIntendedUrl() {
    if (this.navigate && this.intendedUrl) {
      this.navigate(this.intendedUrl);
      this.navigate = null;
      this.intendedUrl = null;
    }
  }

  get authUserIsNotLoaded() {
    return !Object.keys(this.authUser).length;
  }

  get isUser() {
    return this.authUser?.roles?.includes('User');
  }

  get isAdmin() {
    return this.authUser?.roles?.includes('DepartmentAdmin');
  }

  get isModerator() {
    return this.authUser?.roles?.includes('Moderator');
  }

  get isSuperAdmin() {
    return this.authUser?.roles?.includes('SuperAdmin');
  }

  get isContentManager() {
    return this.authUser?.roles?.includes('ContentManager');
  }

  get isOrganizationSupervisor() {
    return this.authUser?.roles?.includes('OrganizationSupervisor');
  }

  get isSubOrganizationSupervisor() {
    return this.authUser?.roles?.includes('SubOrganizationSupervisor');
  }

  get isSectorSupervisor() {
    return this.authUser?.roles?.includes('SectorSupervisor');
  }
}

export const authStore = new AuthStore();
export const AuthStoreContext = createContext(authStore);
export const AuthStoreProvider = ({ children, store }) => (
  <AuthStoreContext.Provider value={store}>
    {children}
  </AuthStoreContext.Provider>
);
export const useAuthStore = () => useContext(AuthStoreContext);

function detectTokenValidity() {
  let sessionToken = getTokenFromSessionStorage();
  try {
    const payload = decodeJwtToken(sessionToken);
    if (payload.exp <= Math.floor(Date.now() / 1000 && this.refreshToken)) {
      return this.updateToken();
    }
    setBearerToken(sessionToken);
  } catch (e) {
    this.logout();
  }
}

function getUserProfile(token) {
  //The line below is needed to ensure proper functionality of the app
  if (!token) return this.logout();
  AuthSetAgent.user
    .getProfile()
    .then(({ userDto, roles, claims }) => {
      const userObject = {
        ...userDto,
        roles,
        claims,
      };
      this.setAuthUser(userObject);
      this.navigateToIntendedUrl();
    })
    .catch((e) => this.logout());
}
