import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class SubDepartmentStore {
  subDepartmentsList = [];
  selectedSubDepartment = null;

  constructor() {
    makeObservable(this, {
      subDepartmentsList: observable,
      getSubDepartments: action,
    });
  }

  getSubDepartments = () => {
    AuthSetAgent.subDepartment.getSubDepartments().then((res) => {
      this.subDepartmentsList = res.result.items;
    });
  };

  getSubDepartmentById = (id) => {
    AuthSetAgent.subDepartment
      .getSubDepartmentById(id)
      .then((subDepartment) => {
        this.selectedSubDepartment = subDepartment?.result;
      });
  };
}

export const subDepartmentStore = new SubDepartmentStore();
export const SubDepartmentStoreContext = createContext(subDepartmentStore);
export const SubDepartmentStoreProvider = ({ children, store }) => (
  <SubDepartmentStoreContext.Provider value={store}>
    {children}
  </SubDepartmentStoreContext.Provider>
);
export const useSubDepartmentStore = () =>
  useContext(SubDepartmentStoreContext);
