import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import { Typography, TextField, Button } from '@mui/material';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthStore } from '../../../Stores/AuthStore';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { ORG_NAME, normalizeSearchQuery } from '../../../Config/constant';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';

const PAGE_SIZE = 15;

const Certificates = () => {
  const [searchCertificate, setSearchCertificate] = useState('');
  const navigate = useNavigate();
  const certificatesStore = useCertificatesStore();
  const authStore = useAuthStore();
  const departmentStore = useDepartmentStore();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const departmentId = authStore.authUser.departmentId;

  const filteredCertificates = certificatesStore.certificatesList?.filter(
    (certificate) => {
      const certificateTitleNormalized = normalizeSearchQuery(
        certificate?.name || ''
      );
      const searchQueryNormalized = normalizeSearchQuery(searchCertificate);
      return certificateTitleNormalized.includes(searchQueryNormalized);
    }
  );

  useEffect(() => {
    if (departmentId) {
      certificatesStore
        .getCertificates(departmentId, currentPage, PAGE_SIZE)
        .then((result) => {
          const totalPages = Math.ceil(result.totalCount / result.pageSize);
          setTotalPages(totalPages);
        });
    }
  }, [
    certificatesStore.getCertificates,
    departmentId,
    certificatesStore.certificatesList?.length,
    currentPage,
  ]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  return (
    <>
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>

      <div className="flex justify-start items-start">
        <Typography
          className="pl-4 pt-4"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Διαχείριση ψηφιακών πιστοποιητικών
        </Typography>
      </div>

      <div className="flex justify-start items-start">
        <Typography className="pl-4 pt-4" variant="h4" gutterBottom>
          <b> Ψηφιακά προσφερόμενα πιστοποιητικά </b>
        </Typography>
      </div>

      <div className="mt-4 ml-4">
        <TextField
          fullWidth
          label="Πληκτρολογήστε για αναζήτηση ..."
          value={searchCertificate}
          onChange={(e) => setSearchCertificate(e.target.value)}
        />
      </div>

      {filteredCertificates && filteredCertificates.length > 0 && (
        <div className="mt-6 ml-4">
          {filteredCertificates?.map((certificate) => (
            <div className="flex p-2" key={certificate.id}>
              <div className="flex-1 min-w-[53%]  max-w-[53%] pl-2">
                <Typography
                  className="font-bold pt-2"
                  variant="h4"
                  gutterBottom
                >
                  {certificate.name}
                </Typography>
              </div>

              <div className="pt-1 ml-[250px]">
                <SubmitButton
                  onClick={() =>
                    navigate(`/certificates-view/${certificate.id}`)
                  }
                  className="capitalize text-sm"
                  title="Προβολή"
                  size="small"
                  variant="contained"
                  type="button"
                  style={{ backgroundColor: '#003375' }}
                />
              </div>
            </div>
          ))}

          <CustomPagination
            className={'my-4 flex justify-center items-center'}
            color={'#003375'}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}

      <div className="flex justify-end items-end mt-4 mb-4">
        <Button
          sx={{
            backgroundColor: '#003375',
            color: '#f9f9f9',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#003375',
              color: '#f9f9f9',
            },
            fontSize: '1rem',
          }}
          type="button"
          variant="contained"
          onClick={() => navigate('/certificates-create')}
        >
          Προσθήκη
        </Button>
      </div>
    </>
  );
};

export default observer(Certificates);
