import { Box, Button, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import EditPoiCategoryForm from '../EditPoiCategoryForm/EditPoiCategoryForm';
import { useNavigate } from 'react-router-dom';

const PoiCategoriesCreate = () => {
  const navigate = useNavigate();

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Typography
        className="pt-6 pb-5 pl-4"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigate('/poi-categories')}
          style={{ color: '#003375' }}
        >
          Οι κατηγορίες σημείων ενδιαφέροντος
        </span>
        <span style={{ color: '#003375' }}> / Προσθήκη νέας</span>
      </Typography>

      <Box className="flex flex-col w-full justify-right items-end pb-5 pl-4 pr-4">
        <Box className="flex w-full justify-end items-center gap-4">
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: '#f9f9f9',
              color: '#003375',
              border: '1px solid #003375',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#003375',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
          >
            Επιστροφή
          </Button>
        </Box>
        <EditPoiCategoryForm isCreateModeOn />
      </Box>
    </Box>
  );
};

export default PoiCategoriesCreate;
