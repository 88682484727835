import { DataGrid } from '@mui/x-data-grid';
import { palette } from '../../styles/variables/colors';

const DataTable = ({
  columns,
  isLoading,
  rows,
  className,
  backgroundColor,
  ...rest
}) => {
  return (
    <div
      className={`bg-white text-[1.4rem] ${className || ''}`}
      style={{ width: '99%' }}
    >
      <DataGrid
        sx={{
          border: 'none',
          '& .table-theme--header': {
            backgroundColor: backgroundColor || '#FFFFFF',
            fontSize: 15,
            borderBottom: '3px solid black',
          },
          '& .table-theme--header:focus': {
            outline: 'none',
          },
          '& .table-theme--row': {
            backgroundColor: backgroundColor || '#FFFFFF',
            borderBottom: `1px solid ${palette.gray.light}`,
            overflowWrap: 'break-word',
            minHeight: '40px !important',
            padding: '10px 0',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          ' & .MuiDataGrid-footerContainer': {
            backgroundColor: backgroundColor || '#FFFFFF',
          },
          ' & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          ' & .MuiDataGrid-cell': {
            padding: '0 5px',
          },
          ' & .MuiTypography-root': {
            textWrap: 'wrap',
          },
        }}
        getRowHeight={() => 'auto'}
        disableColumnSelector
        columns={columns}
        rows={rows || []}
        loading={isLoading}
        autoHeight
        disableSelectionOnClick
        hideFooterPagination
        hideFooter
        {...rest}
      />
    </div>
  );
};

export default DataTable;
