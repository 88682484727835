import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  TextField,
  MenuItem,
  Select,
  Typography,
  Grid,
  Stack,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import Link from '../../../Components/Theme/Link/Link';
import { error, success } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';

const RantevouServiceForm = ({ subDepartmentId }) => {
  const {
    applicationCategories,
    getApplicationCategories,
    extraFieldTypes,
    createAppointmentService,
  } = useAppointmentServiceStore();
  const { authUser } = useAuthStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const serviceSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    subDepartmentId: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    description: yup.string().trim().strict(true),
    applicationCategoryId: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    extraFields: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
        type: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
        required: yup.boolean().required('Το πεδίο ειναι υποχρεωτικό'),
      })
    ),
  });
  const { handleSubmit, control } = useForm({
    defaultValues: {
      title: '',
      subDepartmentId: subDepartmentId,
      description: '',
      applicationCategoryId: null,
      extraFields: [],
    },
    resolver: yupResolver(serviceSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extraFields',
  });

  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      subDepartmentId: data.subDepartmentId,
      description: data.description,
      applicationCategoryId: data.applicationCategoryId,
      extraFields: JSON.stringify(data.extraFields),
    };

    setLoading(true);
    createAppointmentService(payload)
      .then((response) => {
        success('Η υπηρεσία δημιουργήθηκε επιτυχώς');
        navigate('/rantevou-service', {
          state: { subDepartmentId: subDepartmentId },
        });
      })
      .catch((err) => {
        console.log(err);
        error(err);
        error('Η υπηρεσία δεν δημιουργήθηκε');
      });
    setLoading(false);
  };

  useEffect(() => {
    if (authUser?.organizationId) {
      getApplicationCategories(authUser?.organizationId);
    }
  }, [getApplicationCategories, authUser?.organizationId]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-4 bg-white rounded-lg shadow-md"
    >
      <div className="flex items-center mb-2 flex-wrap md:flex-nowrap">
        <Typography
          sx={{ fontWeight: 'medium' }}
          variant="h5"
          mr={2}
          color={'#003375'}
          className={'min-w-fit'}
        >
          Τίτλος Υπηρεσίας
        </Typography>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              className="mt-2 w-full justify-start"
              placeholder="Εισαγωγή τίτλου παρεχόμενης υπηρεσίας"
              error={!!error}
              helperText={error ? t(error.message) : null}
            />
          )}
        />
      </div>

      <div className="flex items-center mt-4 mb-2 flex-wrap md:flex-nowrap">
        <Typography
          sx={{ fontWeight: 'medium' }}
          variant="h5"
          mr={2}
          color={'#003375'}
          className={'min-w-fit'}
        >
          Επιλογή κατηγορίας Υπηρεσίας
        </Typography>
        <Controller
          name="applicationCategoryId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              className="w-full mt-2"
              onChange={(_, data) => onChange(data?.value || '')}
              options={applicationCategories.map((option) => {
                return {
                  value: option.id,
                  description: option.title,
                };
              })}
              getOptionLabel={(option) => option?.description || ''}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Επιλογή κατηγορίας"
                  className="bg-[#FFFF]"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
      </div>

      <div className="flex-column items-center mt-4 mb-2 flex-wrap md:flex-nowrap">
        <Typography
          sx={{ fontWeight: 'medium' }}
          variant="h5"
          mr={2}
          mb={2}
          color={'#003375'}
          className={'min-w-fit'}
        >
          Οδηγίες ραντεβού
        </Typography>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              multiline
              onChange={onChange}
              rows={4}
              className="w-full mt-2"
              placeholder="Συμπλήρωση οδηγιών προς τους πολίτες για την προσέλευση στο ραντεβού"
              error={!!error}
              helperText={error ? t(error.message) : null}
            />
          )}
        />
      </div>

      <div className="flex-column items-center mt-4 mb-2 flex-wrap lg:flex-nowrap">
        <Typography
          sx={{ fontWeight: 'medium' }}
          variant="h5"
          mr={2}
          mb={2}
          color={'#003375'}
          className={'min-w-fit'}
        >
          Απαίτηση συμπλήρωσης επιπλέον υποχρεωτικών πεδίων
        </Typography>
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-start mb-4">
            <div className="w-1/4">
              <Controller
                control={control}
                name={`extraFields.${index}.name`}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    className="mr-4 mt-2"
                    placeholder="Τίτλος πεδίου"
                    label="Τίτλος πεδίου"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </div>

            <Controller
              control={control}
              name={`extraFields.${index}.type`}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  className="w-1/4 ml-8 mr-4"
                  onChange={(_, data) => onChange(data?.value || '')}
                  options={extraFieldTypes}
                  defaultValue={{ description: '', value: '' }}
                  getOptionLabel={(option) => option.description}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error ? error.message : null}
                      label="Τύπος πεδίου"
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name={`extraFields.${index}.required`}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  className="mt-2"
                  label="Υποχρεωτικό"
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                      color="primary"
                    />
                  }
                />
              )}
            />
            <AnimateButton>
              <SubmitButton
                sx={{ backgroundColor: '#cc0000', marginTop: '0.5rem' }}
                title={'ΑΦΑΙΡΕΣΗ ΠΕΔΙΟΥ'}
                loading={loading}
                type="button"
                onClick={() => remove(index)}
              />
            </AnimateButton>
          </div>
        ))}
        <AnimateButton>
          <SubmitButton
            sx={{ backgroundColor: '#008000' }}
            className="capitalize text-sm"
            title={'ΠΡΟΣΘΗΚΗ ΠΕΔΙΟΥ'}
            loading={loading}
            variant="contained"
            type="button"
            onClick={() => append({ name: '', type: '', required: false })}
          />
        </AnimateButton>
      </div>

      <Grid container spacing={3} className="pt-6">
        <Grid item xs={12} className="flex justify-end">
          <Stack direction="row" justifyContent="space-between">
            <div className="mr-4">
              <Link
                className="ml-2 capitalize text-sm"
                to="/rantevou-service"
                state={{
                  subDepartmentId: subDepartmentId,
                }}
              >
                Επιστροφη
              </Link>
            </div>
            <AnimateButton>
              <SubmitButton
                sx={{ backgroundColor: '#003375', marginRight: '1rem' }}
                className="capitalize text-sm"
                title={'Αποθηκευση'}
                loading={loading}
                variant="contained"
                type="submit"
              />
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(RantevouServiceForm);
