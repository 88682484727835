import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { FORM_STATUS } from './Organizations';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { error, success } from '../../../Common/Toast/Toast';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { parse } from 'date-fns';

export const ORG_FIELD = {
  Description: 'description',
  Address: 'address',
  Tel: 'tel',
  OrganizationType: 'organizationType',
  HasERantevou: 'hasERantevou',
  HasECertificates: 'hasECertificates',
  HasERequests: 'hasERequests',
  Threshold: 'threshold',
  IsActive: 'isActive',
};

const orgSchema = yup.object().shape({
  [ORG_FIELD.Description]: yup
    .string()
    .trim()
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.Address]: yup
    .string()
    .trim()
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.Tel]: yup
    .string()
    .trim()
    .min(10, 'Το τηλέφωνο πρέπει να έχει τουλάχιστον 10 χαρακτήρες')
    .strict(true)
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.HasERantevou]: yup
    .boolean()
    .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.HasECertificates]: yup
    .boolean()
    .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.HasERequests]: yup
    .boolean()
    .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
    .required('Το πεδίο ειναι υποχρεωτικό'),
  [ORG_FIELD.Threshold]: yup
    .number('Το πεδίο δέχετε μόνο αριθμούς')
    .min(1, 'Ο αριθμός πρέπει να είναι τουλάχιστον 1')
    .notRequired(),
  [ORG_FIELD.IsActive]: yup
    .boolean()
    .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
    .required('Το πεδίο ειναι υποχρεωτικό'),
});

const defaultValues = {
  [ORG_FIELD.Description]: '',
  [ORG_FIELD.Address]: '',
  [ORG_FIELD.Tel]: '',
  [ORG_FIELD.HasERantevou]: false,
  [ORG_FIELD.HasECertificates]: false,
  [ORG_FIELD.HasERequests]: false,
  [ORG_FIELD.Threshold]: null,
  [ORG_FIELD.IsActive]: false,
};

const OrganizationsCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
}) => {
  const organizationStore = useOrganizationsStore();
  const [actionLoading, setActionLoading] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: isEdit
      ? {
          [ORG_FIELD.Description]: item?.[ORG_FIELD.Description],
          [ORG_FIELD.Address]: item?.[ORG_FIELD.Address],
          [ORG_FIELD.Tel]: item?.[ORG_FIELD.Tel],
          [ORG_FIELD.OrganizationType]: item?.[ORG_FIELD.OrganizationType],
          [ORG_FIELD.HasERantevou]: item?.[ORG_FIELD.HasERantevou] || false,
          [ORG_FIELD.HasECertificates]:
            item?.[ORG_FIELD.HasECertificates] || false,
          [ORG_FIELD.HasERequests]: item?.[ORG_FIELD.HasERequests] || false,
          [ORG_FIELD.Threshold]:
            item?.cleanupSchedule?.[ORG_FIELD.Threshold] || null,
          [ORG_FIELD.IsActive]:
            item?.cleanupSchedule?.[ORG_FIELD.IsActive] || false,
        }
      : defaultValues,
    resolver: yupResolver(orgSchema),
  });

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);
    const formData = watch();

    const { threshold, isActive, ...restData } = formData;

    const updateOrganizationPayload = {
      ...restData,
    };

    const cleanupSchedulePayload = {
      organizationId: item?.id,
      threshold: parseInt(threshold),
      isActive,
    };

    try {
      await organizationStore.updateCurrentOrganization(
        item.id,
        updateOrganizationPayload
      );
      success('Ο οργανισμός ενημερώθηκε επιτυχώς');
      handleAction({ formStatus: FORM_STATUS.HIDDEN });
      await organizationStore.cleanupSchedule(cleanupSchedulePayload);
      await refreshList();
    } catch (e) {
      error('Παρουσιάστηκε σφάλμα κατά την επεξεργασία οργανισμού!');
    } finally {
      setActionLoading(false);
    }
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await null;
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [ORG_FIELD.Description]: item?.[ORG_FIELD.Description] || '',
        [ORG_FIELD.Address]: item?.[ORG_FIELD.Address] || '',
        [ORG_FIELD.Tel]: item?.[ORG_FIELD.Tel] || '',
        [ORG_FIELD.OrganizationType]: item?.[ORG_FIELD.OrganizationType] || '',
        [ORG_FIELD.HasERantevou]: item?.[ORG_FIELD.HasERantevou] || false,
        [ORG_FIELD.HasECertificates]:
          item?.[ORG_FIELD.HasECertificates] || false,
        [ORG_FIELD.HasERequests]: item?.[ORG_FIELD.HasERequests] || false,
        [ORG_FIELD.Threshold]:
          item?.cleanupSchedule?.[ORG_FIELD.Threshold] || null,
        [ORG_FIELD.IsActive]:
          item?.cleanupSchedule?.[ORG_FIELD.IsActive] || false,
      });
    }
  }, [isEdit, item, reset]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit ? 'Επεξεργασία Οργανισμού' : ''}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={ORG_FIELD.Description}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex col-span-12 mb-2">
                <TextField
                  className="flex-1"
                  label="Περιγραφή"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Περιγραφή Οργανισμού είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={ORG_FIELD.Address}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex col-span-12 mb-2">
                <TextField
                  className="flex-1"
                  label="Διεύθυνση"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Διεύθυνη Οργανισμού είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={ORG_FIELD.Tel}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex col-span-12 mb-2">
                <TextField
                  className="flex-1"
                  label="Τηλέφωνο"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Τηλέφωνο Οργανισμού είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />

          <Controller
            name={ORG_FIELD.OrganizationType}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex col-span-12 mb-2">
                <TextField
                  className="flex-1"
                  label="Tύπος"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Τύπος Οργανισμού είναι υποχρεωτικό!'
                  }
                  disabled
                />
              </Box>
            )}
          />

          <Controller
            name={ORG_FIELD.Threshold}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex col-span-12 mb-2">
                <TextField
                  className="flex-1"
                  label="Όριο διαγραφής αρχείων"
                  onChange={onChange}
                  value={value}
                  type="number"
                  error={!!error}
                  helperText={error && error.message}
                />
              </Box>
            )}
          />

          <Controller
            name={ORG_FIELD.IsActive}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <FormLabel className="ml-2" component="legend">
                  Είναι ενεργό;
                </FormLabel>
                <RadioGroup
                  className="ml-2"
                  row
                  value={value}
                  onChange={(e) => onChange(e.target.value === 'true')}
                >
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Ναι"
                    value={true}
                  />
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Όχι"
                    value={false}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Controller
            name={ORG_FIELD.HasERantevou}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <FormLabel className="ml-2" component="legend">
                  Εχει Ραντεβού;
                </FormLabel>
                <RadioGroup
                  className="ml-2"
                  row
                  value={value}
                  onChange={(e) => onChange(e.target.value === 'true')}
                >
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Ναι"
                    value={true}
                  />
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Όχι"
                    value={false}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Controller
            name={ORG_FIELD.HasECertificates}
            control={control}
            render={({ field: { onChange, value, error } }) => (
              <FormControl component="fieldset">
                <FormLabel className="ml-2" component="legend">
                  Εχει Πιστοποιητικά;
                </FormLabel>
                <RadioGroup
                  className="ml-2"
                  row
                  value={value}
                  onChange={(e) => onChange(e.target.value === 'true')}
                >
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Ναι"
                    value={true}
                  />
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Όχι"
                    value={false}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Controller
            name={ORG_FIELD.HasERequests}
            control={control}
            render={({ field: { onChange, value, error } }) => (
              <FormControl component="fieldset">
                <FormLabel className="ml-2" component="legend">
                  Εχει Αιτήματα;
                </FormLabel>
                <RadioGroup
                  className="ml-2"
                  row
                  value={value}
                  onChange={(e) => onChange(e.target.value === 'true')}
                >
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Ναι"
                    value={true}
                  />
                  <FormControlLabel
                    control={
                      <Radio sx={{ '&.Mui-checked': { color: 'black' } }} />
                    }
                    label="Όχι"
                    value={false}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box className="flex justify-end pt-5">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              type="button"
              title="Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default OrganizationsCreateEditForm;
