import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import AnimateButton from '../Extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import { themeColors } from '../../../Config/Themes/theme';

export default function Link({ to, className, children, size, state }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  const onNavigate = useCallback(() => {
    navigate(to, {
      state: state,
    });
  }, [navigate, to]);

  return (
    <AnimateButton>
      <Button
        style={{
          background: '#003375',
          textTransform: 'none',
        }}
        onClick={onNavigate}
        variant="contained"
        color="primary"
        size={size || 'large'}
        className={`cursor-pointer px-3 py-2 inline-block ${className}`}
      >
        {children}
      </Button>
    </AnimateButton>
  );
}
