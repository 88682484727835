import { useState } from "react";
import Topbar from "../../../Components/Topbar/Topbar";
import { observer } from "mobx-react-lite";
import Calendar from "../../../Components/Calendar/Calendar";
import { useParams } from "react-router-dom";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { useEffect } from "react";
import { useCallback } from "react";
import { ORG_NAME } from "../../../Config/constant";

const ScheduleView = ({ active = false }) => {
  const { id: scheduleId } = useParams();
  const rantevouStore = useRantevouStore();
  const [loading, setLoading] = useState(true);
  const [subDepartmentId, setSubDepartmentId] = useState(null);

  const fetchSchedule = useCallback(
    async (scheduleId) => {
      try {
        setLoading(true);
        await rantevouStore.getSchedule(scheduleId);
        setSubDepartmentId(rantevouStore.rantevouScheduleData.subDepartmentId);
      } catch (error) {
        console.error("An error occurred while fetching the schedule:", error);
      } finally {
        setLoading(false);
      }
    },
    [rantevouStore]
  );

  useEffect(() => {
    if (scheduleId) {
      fetchSchedule(scheduleId);
    }
  }, [fetchSchedule, scheduleId]);

  return (
    !loading && (
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
        {subDepartmentId && (
          <Calendar scheduleId={scheduleId} subDepartmentId={subDepartmentId} active={active} />
        )}
      </div>
    )
  );
};

export default observer(ScheduleView);
