import { Box, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import ProfileToolTip from '../ProfileToolTip/ProfileToolTip';
import { useAuthStore } from '../../Stores/AuthStore';
import { useDepartmentStore } from '../../Stores/DepartmentStore';
import { useEffect } from 'react';

const Topbar = ({ title = '' }) => {
  const authStore = useAuthStore();
  const departmentStore = useDepartmentStore();
  const departmentId = authStore.authUser.departmentId;
  const department = departmentStore.departmentList?.find(
    (item) => item.id === departmentId
  );

  const departmentName = department ? department.name : '';
  const sectorLocationName = department ? department.sectorLocationName : '';

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  return (
    <Box
      className="p-0 pb-2 w-full border-b-2 border-[#003375]"
      display="flex"
      justifyContent="space-between"
      p={2}
    >
      <div className="mt-2">
        <Typography variant="h2" color={'#003375'} gutterBottom>
          {title}
        </Typography>

        <Typography
          className="pl-1 pt-1"
          variant="h4"
          color={'#003375'}
          gutterBottom
        >
          {departmentName}{' '}
          {sectorLocationName !== '' ? ` - ${sectorLocationName}` : ''}
        </Typography>
      </div>
      <Box display="flex"></Box>

      {/* ICONS */}
      <Box display="flex">
        {/* <LanguageSwitcher /> */}
        <Typography
          variant="h4"
          color="#003375"
          gutterBottom
          justifyContent={'center'}
          alignItems={'center'}
          className="pt-2"
          display={'flex'}
          flexDirection={'column'}
        >
          <p>{authStore?.authUser?.name} </p>
          <p>{authStore?.authUser?.surname}</p>
        </Typography>
        <IconButton>
          <ProfileToolTip />
        </IconButton>
      </Box>
    </Box>
  );
};

export default observer(Topbar);
