import { Box, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import EditPoiForm from '../EditPoiForm/EditPoiForm';
import { useNavigate } from 'react-router-dom';

const PoisCreate = () => {
  const navigate = useNavigate();

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Typography
        className="pt-6 pb-5 pl-4"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigate('/pois')}
          style={{ color: '#003375' }}
        >
          Τα σημεία ενδιαφέροντός μου
        </span>
        <span style={{ color: '#003375' }}> / Προσθήκη νέου</span>
      </Typography>

      <Box className="flex flex-col w-full justify-right items-end pb-5 pl-4 pr-4">
        <EditPoiForm isCreateModeOn />
      </Box>
    </Box>
  );
};

export default PoisCreate;
