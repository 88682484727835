// project imports
import Profile from '../Profile/Profile';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import Tabs from '../../../Components/Theme/Tabs/Tabs';
import ChangePassword from '../ChangePassword/ChangePassword';
import Topbar from '../../../Components/Topbar/Topbar';
import { ORG_NAME } from '../../../Config/constant';

const ProfileTabs = () => {
  const tabs = [
    {
      label: 'Βασικες πληροφοριες',
      Component: <Profile />,
    },
    {
      label: 'Αλλαγη κωδικου προσβασης',
      Component: <ChangePassword />,
    },
  ];

  return (
    <>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
      <MainCard className="mt-6">
        <Tabs tabs={tabs} />
      </MainCard>
    </>
  );
};

export default ProfileTabs;
