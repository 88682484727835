import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { FORM_STATUS } from './Organizations';
import { palette } from '../../../styles/variables/colors';
import Switch from '@mui/material/Switch';
import DataTable from '../../../Components/DataTable/DataTable';
import { useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const ORGANIZATION_FIELDS = {
  Id: {
    id: 'id',
  },
  Name: {
    id: 'name',
  },
  Description: { id: 'description' },
  Address: {
    id: 'address',
  },
  Tel: {
    id: 'tel',
  },
  hasERantevou: {
    id: 'hasERantevou',
  },
  hasECertificates: {
    id: 'hasECertificates',
  },
  hasERequests: {
    id: 'hasERequests',
  },
  OrganizationType: {
    id: 'organizationType',
  },
  isActive: {
    id: 'isActive',
  },
  Actions: {
    id: 'actions',
  },
};

const loader = (
  <Box className="flex justify-center items-center pl-8">
    <CircularProgress size={20} />
  </Box>
);

const ActionButtons = ({
  className,
  item,
  formStatus,
  focusedItem,
  handleAction,
  refreshList,
}) => {
  const isFocused = focusedItem?.id === item?.id;

  const [isChecked, setIsChecked] = useState(item?.isActive || false);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    const organizationId = item?.id;
    setLoading(true);

    if (newValue) {
      AuthSetAgent.organizations
        .activateOrganization(organizationId)
        .then((resp) => {
          handleAction({
            item: { ...item, isActive: true },
            formStatus: FORM_STATUS.HIDDEN,
          });
          if (resp?.data.success === true) {
            success('Ο οργανισμός ενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          setLoading(false);
          refreshList();
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      AuthSetAgent.organizations
        .deactivateOrganization(organizationId)
        .then((resp) => {
          handleAction({
            item: { ...item, isActive: false },
            formStatus: FORM_STATUS.HIDDEN,
          });
          if (resp?.data.success === true) {
            success('Ο οργανισμός απενεργοποιήθηκε');
          } else {
            error('Κάτι πήγε στραβά');
          }
          setLoading(false);
          refreshList();
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? (
        loader
      ) : (
        <>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.EDIT });
            }}
            placement="top"
            title={'Επεξεργασία'}
          >
            <EditOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.EDIT
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.VIEW });
            }}
            placement="top"
            title={'Προβολή'}
          >
            <VisibilityOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                marginLeft: '0.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.VIEW
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={'Αλλαγή Κατάστασης'}>
            <Switch
              checked={isChecked}
              onChange={handleChange}
              sx={{
                cursor: 'pointer',
                marginLeft: '0.4rem',
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: palette.info.main,
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: palette.info.main,
                },
              }}
            />
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

const OrganizationsTable = ({
  focusedItem,
  formStatus,
  isLoading,
  handleAction,
  refreshList,
  organizations,
}) => {
  const columns = [
    {
      field: ORGANIZATION_FIELDS?.Id?.id,
      headerName: 'Κωδικός',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      renderCell: (params) => (
        <Typography
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-5"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.Id?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.Name?.id,
      headerName: 'Ονομασία',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.Name?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.Description?.id,
      headerName: 'Περιγραφή',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.Description?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.Address?.id,
      headerName: 'Διεύθυνση',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.Address?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.Tel?.id,
      headerName: 'Τηλέφωνο',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center justify-center text-[14px] pl-3"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.Tel?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.hasERantevou?.id,
      headerName: 'Ραντεβού',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-6"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.hasERantevou?.id] ? 'Ναι' : 'Οχι'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.hasECertificates?.id,
      headerName: 'Πιστοποιητικά',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-8"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.hasECertificates?.id]
            ? 'Ναι'
            : 'Οχι'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.hasERequests?.id,
      headerName: 'Αιτήματα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-6"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.hasERequests?.id] ? 'Ναι' : 'Οχι'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.OrganizationType?.id,
      headerName: 'Τύπος',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-3"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.OrganizationType?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.isActive?.id,
      headerName: 'Ενεργός',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pl-6"
        >
          {params?.row?.[ORGANIZATION_FIELDS?.isActive?.id] ? 'Ναι' : 'Οχι'}
        </Typography>
      ),
    },
    {
      field: ORGANIZATION_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 124,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          formStatus={formStatus}
          item={params?.row}
          handleAction={handleAction}
          className="ml-1"
          refreshList={refreshList}
        />
      ),
    },
  ];

  return (
    <>
      <>
        {!!organizations?.length && (
          <DataTable
            columns={columns}
            rows={organizations || []}
            loading={isLoading}
          />
        )}
      </>
      {!organizations?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default OrganizationsTable;
