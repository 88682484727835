import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import TicketsSearchTable from '../TicketsSearch/TicketsSearchTable';

const DashBoardTicketsTable = () => {
  const [tickets, setTickets] = useState([]);

  const initializeTickets = async () => {
    await AuthSetAgent.tickets
      .searchTickets({ getLastUpdatedRecords: true })
      .then((response) => {
        response?.result?.items && setTickets(response.result.items);
      });
  };

  useEffect(() => {
    initializeTickets();
  }, [tickets?.length, setTickets]);

  return (
    <>
      <TicketsSearchTable label={'Τελευταία αιτήματα'} tickets={tickets} />
    </>
  );
};

export default observer(DashBoardTicketsTable);
