import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './MyDatePicker.css';
import { useTheme } from '@mui/material/styles';
import { themeColors } from '../../Config/Themes/theme';

const MyDatePicker = ({
  value,
  onChange,
  error,
  placeholder,
  inputFormat = 'dd/MM/yyyy',
  minDate,
  maxDate,
}) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [selectedDate, setSelectedDate] = useState(value);

  const handleChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };

  return (
    <>
      <DatePicker
        placeholderText={placeholder}
        selected={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleChange}
        dateFormat={inputFormat}
        className={`w-full ${error ? 'error' : ''}`}
        wrapperClassName="w-full"
      />
      {error && <span className="error-message">{error.message}</span>}
    </>
  );
};

export default MyDatePicker;
