const tailwindColors = require('tailwindcss/colors');

const colors = { ...tailwindColors };

export const palette = {
  primary: {
    light: colors.blue[200],
    main: colors.blue[500],
    dark: colors.blue[700],
    darker: colors.blue[900],
  },
  secondary: {
    light: colors.purple[200],
    main: colors.purple[500],
    dark: colors.purple[800],
    darker: colors.purple[900],
  },
  error: {
    light: colors.red[200],
    main: colors.red[500],
    dark: colors.red[800],
    darker: colors.red[900],
  },
  warning: {
    light: colors.yellow[200],
    main: colors.yellow[500],
    dark: colors.yellow[800],
    darker: colors.yellow[900],
  },
  info: {
    light: colors.blue[200],
    main: colors.blue[500],
    dark: colors.blue[800],
    darker: colors.blue[900],
  },
  success: {
    light: colors.green[200],
    main: colors.green[500],
    dark: colors.green[800],
    darker: colors.green[900],
  },
  gray: {
    light: colors.gray[200],
    main: colors.gray[500],
    dark: colors.gray[800],
    darker: colors.gray[900],
  },
};
