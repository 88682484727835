import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  createTheme,
  ThemeProvider,
  FormLabel,
} from '@mui/material';
import { useEventsStore } from '../../../Stores/EventsStore';
import { error, success, warning } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { el } from 'date-fns/locale';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import GoogleMap from '../../../Components/GoogleMap/GoogleMap';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import CircularSpinner from '../../../Components/Theme/Spinners/CircularSpinner';

const EditEventForm = ({
  isEditMode,
  setIsEditMode,
  event,
  isCreateModeOn = false,
  onDelete,
  setParentUpdate = null,
}) => {
  const eventsStore = useEventsStore();
  const organizationStore = useOrganizationsStore();
  const [eventData, setEventData] = useState(event);
  const [updated, setUpdated] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();
  const { isSuperAdmin, authUser } = useAuthStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [positionError, setPositionError] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageDownloading, setIsImageDownloading] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);

  const theme = createTheme({
    palette: {
      primary: { main: '#003375' },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
    },
  });

  const isValidDate = eventData ? dayjs(eventData?.dateTime)?.isValid() : false;
  const defaultDateTime = isValidDate
    ? dayjs(eventData.dateTime)?.toDate()
    : '';

  const schema = yup
    .object({
      title: yup.string().required('Ο τίτλος είναι υποχρεωτικός'),
      description: yup.string().required('Το κείμενο είναι υποχρεωτικό'),
      organizationId: isSuperAdmin
        ? yup.string().required('Ο οργανισμός είναι υποχρεωτικός')
        : yup.string(),
      dateTime: yup.string().required('Η ημερομηνία είναι υποχρεωτική'),
      address: yup.string().nullable(),
      lat: yup
        .number()
        .typeError('Το γεωγραφικό πλάτος πρέπει να είναι αριθμός')
        .min(-90, 'Το γεωγραφικό πλάτος πρέπει να είναι μεγαλύτερο από -90')
        .max(90, 'Το γεωγραφικό πλάτος πρέπει να είναι μικρότερο από 90')
        .nullable(),
      long: yup
        .number()
        .typeError('Το γεωγραφικό μήκος πρέπει να είναι αριθμός')
        .min(-180, 'Το γεωγραφικό μήκος πρέπει να είναι μεγαλύτερο από -180')
        .max(180, 'Το γεωγραφικό μήκος πρέπει να είναι μικρότερο από 180')
        .nullable(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      title: eventData?.title || '',
      description: eventData?.description || '',
      organizationId: isSuperAdmin ? eventData?.organizationId : '',
      dateTime: defaultDateTime,
      address: eventData?.address || null,
      lat: eventData?.lat || null,
      long: eventData?.long || null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedImage(null);
      setImageRemoved(true);
    }
  };

  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      description: data.description,
      organizationId: isSuperAdmin ? data.organizationId : '',
      dateTime: dayjs(data.dateTime).format('YYYY-MM-DDTHH:mm:ss'),
      address: data.address,
      lat: data.lat.toString().replace('.', ','),
      long: data.long.toString().replace('.', ','),
    };

    setIsSubmitting(true);
    eventsStore
      .updateEvent(eventData.id, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        if (result) {
          if (selectedImage) {
            eventsStore
              .uploadImage(eventData.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Η εκδήλωση ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                } else {
                  warning(
                    'Η εκδήλωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η εκδήλωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else if (imageRemoved && imageUrl) {
            eventsStore
              .deleteImage(`Ev_${eventData.id}`)
              .then((imageResponse) => {
                if (imageResponse?.success) {
                  success('Η εκδήλωση ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                  setUpdated(true);
                } else {
                  warning(
                    'Η εκδήλωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η εκδήλωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else {
            success('Η εκδήλωση ενημερώθηκε επιτυχώς');
            setSelectedImage(null);
            setIsSubmitting(false);
            setIsEditMode(false);
            setUpdated(true);
            setParentUpdate && setParentUpdate(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την ενημέρωση της εκδήλωσης');
        setIsSubmitting(false);
      });
  };

  const onCreate = (data) => {
    const payload = {
      title: data.title,
      description: data.description,
      organizationId: isSuperAdmin ? data.organizationId : '',
      dateTime: dayjs(data.dateTime).format('YYYY-MM-DDTHH:mm:ss'),
      address: data.address,
      lat: data.lat.toString().replace('.', ','),
      long: data.long.toString().replace('.', ','),
    };

    setIsSubmitting(true);
    eventsStore
      .createEvent(payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        if (result) {
          if (selectedImage) {
            eventsStore
              .uploadImage(result.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Η εκδήλωση δημιουργήθηκε επιτυχώς');
                  setSelectedImage(null);
                  navigate(`/events/${result.id}`);
                } else {
                  warning(
                    'Η εκδήλωση δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η εκδήλωση δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          } else {
            success('Η εκδήλωση δημιουργήθηκε επιτυχώς');
            setSelectedImage(null);
            navigate(`/events/${result.id}`);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την δημιουργία της εκδήλωσης');
        setIsSubmitting(false);
      });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const getSelectedOrganization = (id) => {
    organizationStore.getOrganizationById(id);
  };

  useEffect(() => {
    isDirty && !isEditMode && !updated && reset();
    if (!isEditMode) {
      setImageRemoved(false);
      setIsSubmitting(false);
      setSelectedImage(null);
    }
  }, [isEditMode]);

  useEffect(() => {
    setEventData(event);
  }, [event]);

  useEffect(() => {
    if (isSuperAdmin) {
      organizationStore.getOrganizations().then((response) => {
        setOrganizations(response.items);
      });
    }
  }, [isSuperAdmin, organizationStore]);

  useEffect(() => {
    if (!isCreateModeOn && updated) {
      setIsImageDownloading(true);
      eventsStore
        .downloadImage(`Ev_${eventData?.id}`)
        .then((imageUrl) => {
          setImageUrl(imageUrl);
        })
        .catch(() => {
          setImageUrl(null);
        })
        .finally(() => {
          setIsImageDownloading(false);
          updated && setUpdated(false);
        });
    }
  }, [isCreateModeOn, updated]);

  useEffect(() => {
    if (
      (!isSuperAdmin ||
        (isSuperAdmin && eventData?.organizationId) ||
        (isSuperAdmin && getValues('organizationId'))) &&
      organizationStore.organizationById?.mapArea
    ) {
      const mapData = JSON.parse(
        organizationStore.organizationById?.mapArea
      )?.coordinates;
      setMapData(mapData);
    }
  }, [organizationStore.organizationById]);

  useEffect(() => {
    if (organizationStore.organizationById || eventData?.organizationId) {
      if (
        ((!organizationStore.organizationById ||
          Object.keys(organizationStore.organizationById).length === 0) &&
          eventData?.organizationId) ||
        (Object.keys(organizationStore.organizationById).length > 0 &&
          eventData?.organizationId &&
          organizationStore.organizationById?.id !== eventData?.organizationId)
      ) {
        organizationStore.getOrganizationById(eventData?.organizationId);
      }
    } else if (
      !organizationStore.organizationById &&
      authUser.organizationId !== undefined &&
      !isSuperAdmin
    ) {
      organizationStore.getOrganizationById(authUser.organizationId);
    }
  }, [organizationStore.organizationById]);

  useEffect(() => {
    setMarkerPosition({
      lat: eventData?.lat,
      lng: eventData?.long,
    });
  }, [eventData]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(isCreateModeOn ? onCreate : onSubmit)}
      noValidate
      sx={{
        mt: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="flex justify-between items-center gap-4 mb-4">
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: '#f9f9f9',
            color: '#003375',
            border: '1px solid #003375',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#003375',
              color: '#f9f9f9',
            },
            fontSize: '1rem',
          }}
        >
          Επιστροφή
        </Button>

        <div className="flex gap-4">
          {isEditMode && (
            <SubmitButton
              title={'Ενημέρωση'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage && !imageRemoved}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}
          {isCreateModeOn && (
            <SubmitButton
              title={'Δημιουργία'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}
          <div className="flex justify-between items-center gap-4">
            {isEditMode && (
              <Button
                variant="contained"
                onClick={onDelete}
                sx={{
                  backgroundColor: '#f9f9f9',
                  color: '#B60202',
                  border: '1px solid #B60202',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#B60202',
                    color: '#f9f9f9',
                  },
                  fontSize: '1rem',
                }}
              >
                Διαγραφή
              </Button>
            )}
            {!isCreateModeOn && (
              <Button
                variant="contained"
                onClick={toggleEditMode}
                sx={{
                  backgroundColor: '#003375',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#003375',
                    color: 'white',
                    opacity: 0.9,
                  },
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                {isEditMode ? 'Ακύρωση' : 'Επεξεργασία'}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Controller
        name="image"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col items-start justify-start w-full gap-4 my-4 min-h-[120px]">
            <FormLabel
              sx={{
                fontSize: '0.8571428571428571rem',
              }}
            >
              Εικόνα εκδήλωσης
            </FormLabel>

            {(isEditMode || isCreateModeOn) && (
              <div className="flex justify-start items-center flex-wrap gap-2 w-full">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                  }}
                  disabled={!isEditMode && !isCreateModeOn}
                  ref={fileInputRef}
                />
              </div>
            )}

            {!imageUrl &&
              !isImageDownloading &&
              !isEditMode &&
              !isCreateModeOn && <p>Δεν υπάρχει εικόνα</p>}

            <div className="flex justify-start items-center flex-wrap mb-4 gap-1 w-full">
              {isImageDownloading ? (
                <Box className="flex justify-center items-center">
                  <CircularSpinner
                    size="20px"
                    color="inherit"
                    sx={{ marginRight: '15px' }}
                  />
                </Box>
              ) : (
                (selectedImage || (!imageRemoved && imageUrl)) && (
                  <div className="flex justify-start items-center flex-wrap gap-4 w-full">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : imageUrl
                      }
                      alt="event"
                      style={{ width: '50%', height: 'auto' }}
                    />

                    <IconButton
                      sx={{
                        backgroundColor: '#B60202',
                        borderRadius: '99px',
                        textTransform: 'none',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#B60202',
                          opacity: 0.8,
                        },
                      }}
                      disabled={!isEditMode && !isCreateModeOn}
                      size={'small'}
                      type="button"
                      title={'Διαγραφή'}
                      onClick={() => clearFileInput()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      />

      <Controller
        name="dateTime"
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={el}
            >
              <DateTimePicker
                label="Ημερομηνία και ώρα"
                inputFormat="dd/MM/yyyy HH:mm"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                readOnly={!isEditMode && !isCreateModeOn}
                slotProps={{
                  textField: {
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </ThemeProvider>
        )}
      />
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Τίτλος"
            error={!!errors.title}
            helperText={errors.title?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Περιγραφή"
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />

      {isSuperAdmin && (
        <Controller
          name="organizationId"
          control={control}
          render={({ field }) => (
            <ThemeProvider theme={theme}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="organization-label">Οργανισμός</InputLabel>
                <Select
                  {...field}
                  labelId="organization-label"
                  label="Οργανισμός"
                  error={!!errors.organizationId}
                  value={field.value || ''}
                  disabled={!isCreateModeOn}
                  onChange={(e) => {
                    field.onChange(e);
                    getSelectedOrganization(e.target.value);
                  }}
                >
                  {organizations?.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.organizationId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.6428571428571428rem',
                      marginTop: '3px',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                  >
                    {errors.organizationId.message}
                  </p>
                )}
              </FormControl>
            </ThemeProvider>
          )}
        />
      )}

      {mapData &&
        (eventData?.organizationId ||
          getValues('organizationId') ||
          (!isSuperAdmin && isCreateModeOn)) && (
          <div className="w-full mt-4">
            <GoogleMap
              readOnly={!isEditMode && !isCreateModeOn}
              editable={isCreateModeOn || isEditMode}
              mapData={mapData}
              setAddress={(address) => {
                setValue('address', address, { shouldDirty: true });
              }}
              markerPosition={markerPosition}
              setMarkerPosition={(position) => {
                setMarkerPosition(position);
                setValue('lat', position?.lat, { shouldDirty: true });
                setValue('long', position?.lng, { shouldDirty: true });
              }}
              defaultAddress={eventData?.address}
              positionError={positionError}
              setPositionError={setPositionError}
              initialZoom={eventData?.lat && eventData?.long ? 15 : 10}
              customCenter={
                eventData?.lat && eventData?.long
                  ? {
                      lat: Number(eventData?.lat),
                      lng: Number(eventData?.long),
                    }
                  : null
              }
              fitPolygonData={!(eventData?.lat && eventData?.long)}
            />
          </div>
        )}
    </Box>
  );
};

export default observer(EditEventForm);
