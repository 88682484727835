import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserStore } from '../../../Stores/UserStore';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Button,
} from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { Calendar } from 'react-multi-date-picker';
import { format } from 'date-fns';
import { GridClearIcon } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { success, error } from '../../../Common/Toast/Toast';
import {
  formatDateInDateMonthYearHour,
  transformDateFromSlashesToDash,
} from '../../../Common/formatDate/formatDate';
import dayjs from 'dayjs';

const ScheduleForm = () => {
  const [loading, setLoading] = useState(false);
  const [openHour, setOpenTime] = useState(null);
  const [closeHour, setCloseTime] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState([]);
  const { setScheduleResp } = useRantevouStore();
  const userStore = useUserStore();
  const { subDepartmentsList } = useSubDepartmentStore();
  const calendarRef = useRef(null);
  const [scheduleDisplayData, setScheduleDisplayData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  const scheduleSchema = yup.object().shape({
    subDepartmentId: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    days: yup
      .array()
      .of(yup.string())
      .min(1, 'Το πεδίο ειναι υποχρεωτικό')
      .required('Το πεδίο ειναι υποχρεωτικό'),
    openHour: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    closeHour: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    defaultQuantity: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    stepPeriod: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    userIds: yup
      .array()
      .of(yup.string())
      .min(1, 'Το πεδίο ειναι υποχρεωτικό')
      .required('Το πεδίο ειναι υποχρεωτικό'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      subDepartmentId: null,
      days: [],
      openHour: '',
      closeHour: '',
      defaultQuantity: null,
      stepPeriod: null,
      userIds: [],
    },
    resolver: yupResolver(scheduleSchema),
  });
  const subDepartmentId = watch('subDepartmentId');

  useEffect(() => {
    if (userStore.usersList?.length === 0 && subDepartmentId) {
      userStore.getAllUsersByRoleAndDeptId('User', subDepartmentId);
    }
  }, [subDepartmentId]);

  const submitSchedule = (data) => {
    setLoading(true);
    const sortedDays = data.days.sort(
      (a, b) =>
        new Date(transformDateFromSlashesToDash(a)) -
        new Date(transformDateFromSlashesToDash(b))
    );
    const payload = {
      subDepartmentId: data.subDepartmentId,
      days: data.days?.map((day) =>
        transformDateFromSlashesToDash(day).slice(0, 10)
      ),
      openHour: data.openHour,
      closeHour: data.closeHour,
      defaultQuantity: data.defaultQuantity,
      timePeriod: data.stepPeriod,
      userIds: data.userIds,
      timeFrom: transformDateFromSlashesToDash(sortedDays[0]).slice(0, 10),
      timeTo: transformDateFromSlashesToDash(
        sortedDays[sortedDays.length - 1]
      ).slice(0, 10),
    };

    AuthSetAgent.schedule
      .createSchedule(payload)
      .then((response) => {
        setScheduleResp(response.result);
        setLoading(false);
        success('Η προσθήκη πραγματοποιήθηκε επιτυχώς');
        navigate('/rantevou');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        error('Η προσθήκη δεν πραγματοποιήθηκε');
      });
  };

  const generateTimeOptions = (start, end) => {
    const options = [];
    let current = start;

    while (current <= end) {
      let hours = Math.floor(current / 60);
      let minutes = current % 60;

      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;

      options.push(hours + ':' + minutes);
      current += 15;
    }

    return options;
  };

  const timetableOptions = useMemo(
    () => generateTimeOptions(8 * 60, 14 * 60 + 30),
    []
  );

  return (
    <div className="pb-5 relative">
      <div className="w-1/2">
        <form onSubmit={handleSubmit(submitSchedule)}>
          <Grid container spacing={3} className="p-2">
            <Grid item xs={12}>
              <Controller
                name="subDepartmentId"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    onChange={(e, value) => {
                      onChange(
                        isNaN(parseInt(value?.id)) ? null : parseInt(value?.id)
                      );
                      setScheduleDisplayData({
                        ...scheduleDisplayData,
                        subDepartmentId: value,
                      });
                    }}
                    value={scheduleDisplayData.subDepartmentId ?? null}
                    options={subDepartmentsList}
                    onFocus={() => setShowCalendar(false)}
                    getOptionLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-[#FFFF] mt-14"
                        fullWidth
                        label={'Υποτμήμα'}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="days"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    readOnly
                    freeSolo
                    options={[]}
                    onFocus={() => setShowCalendar(true)}
                    value={scheduleDisplayData.days || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-[#FFFF] mt-14"
                        fullWidth
                        label={'Ημερομηνίες'}
                        error={!!error}
                        helperText={error ? error.message : null}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {scheduleDisplayData.days?.length > 0 && (
                                <InputAdornment position="end">
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      setDates([]);
                                      setScheduleDisplayData({
                                        ...scheduleDisplayData,
                                        days: [],
                                      });
                                      onChange([]);
                                    }}
                                  >
                                    <GridClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip
                          key={index}
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                )}
              />
            </Grid>

            <Grid container item xs={true} alignItems="center">
              <Grid item xs={true}>
                <Controller
                  name="openHour"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      onChange={(e, value) => {
                        setOpenTime(value);
                        setCloseTime(null);
                        onChange(value);
                      }}
                      onClose={() => setCloseTime(null)}
                      value={value}
                      options={timetableOptions}
                      onFocus={() => setShowCalendar(false)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-14 bg-[#FFFF]"
                          fullWidth
                          label={'Ώρα Έναρξης'}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={true}>
                <Controller
                  name="closeHour"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      disabled={!openHour}
                      onChange={(e, value) => {
                        setCloseTime(value);
                        onChange(value);
                      }}
                      value={closeHour}
                      options={timetableOptions}
                      onFocus={() => setShowCalendar(false)}
                      getOptionDisabled={(option) =>
                        timetableOptions.indexOf(option) <=
                        timetableOptions.indexOf(openHour)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-14 bg-[#FFFF]"
                          fullWidth
                          label={'Ώρα Λήξης'}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="defaultQuantity"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={Array.from(
                      { length: userStore.usersList?.length },
                      (_, index) => index + 1
                    )}
                    onFocus={() => setShowCalendar(false)}
                    value={scheduleDisplayData.defaultQuantity || ''}
                    onChange={(e, value) => {
                      onChange(value);
                      setScheduleDisplayData({
                        ...scheduleDisplayData,
                        defaultQuantity: value,
                      });
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={(option) => option.toString()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-[#FFFF] mt-14"
                        fullWidth
                        label={'Διαθεσιμότητα'}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="stepPeriod"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={[
                      { value: 15, label: '15 λεπτα' },
                      { value: 30, label: '30 λεπτά' },
                      { value: 60, label: '60 λεπτά' },
                    ]}
                    value={scheduleDisplayData.stepPeriod || ''}
                    onChange={(e, newValue) => {
                      onChange(newValue?.value);
                      setScheduleDisplayData({
                        ...scheduleDisplayData,
                        stepPeriod: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="mt-14 bg-[#FFFF]"
                        fullWidth
                        label={'Βήμα (διάρκεια ραντεβού)'}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="userIds"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    options={userStore.usersList || []}
                    getOptionLabel={(option) =>
                      option?.name + ' ' + option?.surname || ''
                    }
                    value={scheduleDisplayData.userIds || []}
                    onFocus={() => setShowCalendar(false)}
                    onChange={(e, value) => {
                      onChange(value.map((item) => item.id));
                      setScheduleDisplayData({
                        ...scheduleDisplayData,
                        userIds: value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="bg-[#FFFF] mt-14"
                        fullWidth
                        label={'Υπάλληλοι'}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                    renderTags={(selected, getTagProps) =>
                      selected.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={`${option.name} ${option.surname}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                )}
              />
            </Grid>

            <Grid container spacing={3} className="pt-6">
              <Grid item xs={12} className="flex justify-end">
                <Stack direction="row" justifyContent="space-between">
                  <AnimateButton>
                    <Button
                      sx={{
                        backgroundColor: '#003375',
                        color: '#f9f9f9',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                        },
                        fontSize: '1rem',
                      }}
                      loading={loading}
                      variant="contained"
                      type="submit"
                    >
                      Δημιουργία
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>

      {showCalendar && (
        <div className="absolute w-1/2 top-0 right-0">
          <Calendar
            ref={calendarRef}
            value={dates}
            multiple
            minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
            onChange={(dates) => {
              setDates(dates);
              const validDates = dates.map((date) => date.toDate());
              setScheduleDisplayData({
                ...scheduleDisplayData,
                days: validDates.map((date) => format(date, 'dd/MM/yyyy')),
              });
              setValue(
                'days',
                validDates.map((date) => format(date, 'dd/MM/yyyy')),
                { shouldValidate: true }
              );
            }}
            mapDays={({ date }) => {
              let isWeekend = [0, 6].includes(date.weekDay.index);
              if (isWeekend)
                return {
                  disabled: true,
                  style: { color: '#ccc' },
                };
            }}
            weekStartDayIndex={1}
          />
        </div>
      )}
    </div>
  );
};

export default observer(ScheduleForm);
