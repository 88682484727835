import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import { usePoisStore } from '../../../Stores/PoisStore';
import EditPoiForm from '../EditPoiForm/EditPoiForm';
import Topbar from '../../../Components/Topbar/Topbar';
import { error, success } from '../../../Common/Toast/Toast';

const PoiViewEdit = () => {
  const poisStore = usePoisStore();
  const [poi, setPoi] = useState(null);
  const { id } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (id && update) {
      poisStore
        .getPoiById(id)
        .then((response) => {
          setPoi(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, update]);

  const onDelete = () => {
    setDeleteModalOpen(true);
  };

  const deletePoi = () => {
    poisStore
      .deletePoi(id)
      .then(() => {
        success('Το σημείο ενδιαφέροντος διαγράφηκε');
        navigate('/pois');
      })
      .catch((err) => {
        error('Κάτι πήγε στραβά');
        console.error(err);
      });
  };

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Typography
        className="pt-6 pb-5 pl-4"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigate('/pois')}
          style={{ color: '#003375' }}
        >
          Τα σημεία ενδιαφέροντός μου
        </span>
        <span style={{ color: '#003375' }}> / Προβολή - Επεξεργασία</span>
      </Typography>
      {poi ? (
        <Box className="flex flex-col w-full justify-right items-end pt-6 pb-5 pl-4 pr-4">
          <EditPoiForm
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            poi={poi}
            onDelete={onDelete}
            setParentUpdate={setUpdate}
          />
        </Box>
      ) : (
        <div className="text-center py-10">
          <CircularProgress
            sx={{
              color: '#003375',
            }}
            size="3rem"
          />
        </div>
      )}

      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Είστε σίγουροι;
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Το σημείο ενδιαφέροντος θα διαγραφεί οριστικά.
          </Typography>
          <Box className="flex justify-end gap-4 mt-4" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={deletePoi}
              sx={{
                backgroundColor: '#f44336',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#f44336',
                  color: 'white',
                  opacity: 0.9,
                },
                textTransform: 'none',
              }}
            >
              Ναι
            </Button>
            <Button
              variant="contained"
              onClick={() => setDeleteModalOpen(false)}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                  color: 'white',
                  opacity: 0.9,
                },
                textTransform: 'none',
              }}
            >
              Όχι
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default observer(PoiViewEdit);
