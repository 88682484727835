import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import { error, success } from '../Common/Toast/Toast';
import AuthSetAgent from '../Api/AuthSetAgent';

class AppointmentServiceStore {
  applicationCategories = [];

  extraFieldTypes = [
    {
      description: 'Σύντομο κείμενο',
      value: 'text',
    },
    {
      description: 'Αριθμός',
      value: 'number',
    },
    {
      description: 'Παράγραφος',
      value: 'textarea',
    },
  ];

  constructor() {
    makeObservable(this, {
      applicationCategories: observable,
      extraFieldTypes: observable,
      getApplicationCategories: action,
      createAppointmentService: action,
      updateAppointmentService: action,
      createNewApplicationCategory: action,
      updateApplicationCategory: action,
    });
  }

  getApplicationCategories = (organizationId) => {
    AuthSetAgent.applicationCategory
      .getApplicationCategoriesByOrganizationId(organizationId)
      .then((res) => {
        this.applicationCategories = res.result.items;
      })
      .catch((err) => {
        error(err);
      });
  };

  createNewApplicationCategory(body) {
    return AuthSetAgent.applicationCategory.createApplicationCategory(body);
  }

  updateApplicationCategory(appCategoryId, body) {
    return AuthSetAgent.applicationCategory.updateApplicationCategory(
      appCategoryId,
      body
    );
  }

  getAppointmentServices = async (
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    return await AuthSetAgent.appointmentService.getAppointmentServicesBySubDepartmentId(
      subDepartmentId,
      pageNumber,
      pageSize
    );
  };

  createAppointmentService = async (applicationService) => {
    return await AuthSetAgent.appointmentService.createAppointmentService(
      applicationService
    );
  };

  updateAppointmentService = async (id, payload) => {
    return await AuthSetAgent.appointmentService.updateAppointmentService(
      id,
      payload
    );
  };

  getAppointmentServiceById = async (id) => {
    return await AuthSetAgent.appointmentService.getAppointmentServiceById(id);
  };
}

export const appointmentServiceStore = new AppointmentServiceStore();
export const AppointmentServiceStoreContext = createContext(
  appointmentServiceStore
);
export const AppointmentServiceStoreProvider = ({ children, store }) => (
  <AppointmentServiceStore.Provider value={store}>
    {children}
  </AppointmentServiceStore.Provider>
);
export const useAppointmentServiceStore = () =>
  useContext(AppointmentServiceStoreContext);
