import axios from 'axios';
import { requests } from '../Common/HttpClient/HttpClient';

const { post, get, patch, put, delete: deleteRequest, getFile } = requests;

/**************************************** authentication Endpoints ****************************************/
const user = {
  login: (email, password) => post('/Auth/login', { email, password }),
  getProfile: () => get('/Auth/profile'),
  updateProfile: (phoneNumber) =>
    patch('/Auth/profile', { phoneNumber: `${phoneNumber}` }),
  changePassword: (currentPassword, newPassword, confirmNewPassword) =>
    patch('/Auth/updatePassword', {
      currentPassword,
      newPassword,
      confirmNewPassword,
    }),
  forgotPassword: (email) =>
    post('/Auth/forgotPassword', { email: `${email}` }),
  resetPassword: (data, token) =>
    post('/Auth/resetPassword', { ...data, token }),
  refreshToken: (body) => post('/Auth/refreshToken', body),
};

const authSetup = {
  getAllRoles: () => get('/AuthSetup/GetAllRoles'),
  getAllClaims: () => get('/AuthSetup/GetAllClaims'),
  getUserClaims: (userId) => get(`/AuthSetup/GetUserClaims?userId=${userId}`),
  AddClaimToUser: (body) => post('/AuthSetup/AddClaimToUser', body),
  RemoveClaimFromUser: (body) => post('/AuthSetup/RemoveClaimFromUser', body),
};

/**************************************** Users crud Endpoints ****************************************/

const users = {
  getAllUsers: (pageNumber = null, pageSize = null) => {
    const url =
      `/User/GetAll` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAllUsersByDeptId: (
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/User/GetAll?subDepartmentId=${subDepartmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAllUsersByRoleAndDeptId: (
    role,
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/User/GetAll?role=${role}&subDepartmentId=${subDepartmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getUsersByDepartmentId: (
    departmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/User/GetUsersByDepartmentId?departmentId=${departmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getUserById: (userId) => get(`/User/${userId}`),
  createUserByDepartmentAdmin: (body) =>
    post('/User/CreateUserByDepartmentAdmin', body),
  createUserBySuperAdmin: (body) => post('/User/CreateUserBySuperAdmin', body),
  updateUser: (userId, body) => put(`/User/UpdateUser/${userId}`, body),
  deleteUser: (userId) => deleteRequest(`/User/DeleteUser/${userId}`),
  lockUser: (userId) => axios.delete(`/User/LockUser/${userId}`),
  unlockUser: (userId) => axios.patch(`/User/UnlockUser/${userId}`),
};

const subDepartment = {
  getSubDepartments: (pageNumber = null, pageSize = null) => {
    const url =
      `/SubDepartment` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getMySubDepartments: (pageNumber = null, pageSize = null) => {
    const url =
      `/SubDepartment/GetMySubDepartments` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getSubDepartmentById: (id) => get(`/SubDepartment/${id}`),
  createSubDepartment: (body) => post('/SubDepartment', body),
  updateSubDepartment: (id, body) => put(`/SubDepartment/${id}`, body),
  deleteSubDepartment: (id) => deleteRequest(`/SubDepartment/${id}`),
  deactivateSubDepartment: (id) =>
    axios.post(`/SubDepartment/Deactivate/${id}`),
  activateSubDepartment: (id) => axios.put(`/SubDepartment/Activate/${id}`),
};

const department = {
  getDepartments: (pageNumber = null, pageSize = null) => {
    const url =
      `/Department` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  createDepartment: (body) => post('/Department', body),
  updateDepartment: (id, body) => put(`/Department/${id}`, body),
  deleteDepartment: (id) => deleteRequest(`/Department/${id}`),
  deactivateDepartment: (id) => axios.post(`/Department/Deactivate/${id}`),
  activateDepartment: (id) => axios.put(`/Department/Activate/${id}`),
};

const schedule = {
  createSchedule: (body) => post('/Schedule', body),
  getSchedules: (subDepartmentId, pageNumber = null, pageSize = null) => {
    const url =
      `/Schedule?subDepartmentId=${subDepartmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getScheduleById: (id) => get(`/Schedule/${id}`),
  deleteSchedule: (id) => deleteRequest(`/Schedule/${id}`),
  updateSchedule: (id, body) => put(`/Schedule/${id}`, body),
  getActiveSchedulesBySubDepartmentId: (
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/Schedule/ActiveSchedule?subDepartmentId=${subDepartmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
};

const slot = {
  createSlot: (body) => post('/Slot', body),
  deleteSlot: (id) => deleteRequest(`/Slot/${id}`),
  deleteByDate: (scheduleId, date) =>
    deleteRequest(
      `/Slot/DeleteSlotsByDate?scheduleId=${scheduleId}&date=${date}`
    ),
};

const rantevou = {
  createRantevou: (body) => post('/Rantevou', body),
  getRantevou: (
    dateFrom,
    dateTo,
    slotTimeFrom,
    slotTimeTo,
    id,
    afm,
    status,
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/Rantevou?dateFrom=${dateFrom}&dateTo=${dateTo}&slotTimeFrom=${slotTimeFrom}&slotTimeTo=${slotTimeTo}&id=${id}&afm=${afm}&status=${status}&subDepartmentId=${subDepartmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getRantevouById: (id) => get(`/Rantevou/${id}`),
  deleteRantevou: (id) => deleteRequest(`/Rantevou/${id}`),
  updateRantevou: (id, body) => put(`/Rantevou/${id}`, body),
  getStatusCount: () => get('/Rantevou/GetStatusCount'),
};

const appointmentService = {
  createAppointmentService: (body) => post('/AppointmentService', body),
  getAppointmentServicesBySubDepartmentId: (
    subDepartmentId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/AppointmentService/subDepartments/${subDepartmentId}` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAppointmentServices: (pageNumber = null, pageSize = null) => {
    const url =
      `/AppointmentService` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAppointmentServiceById: (id) => get(`/AppointmentService/${id}`),
  deleteAppointmentService: (id) => deleteRequest(`/AppointmentService/${id}`),
  updateAppointmentService: (id, body) =>
    put(`/AppointmentService/${id}`, body),
};

const applicationCategory = {
  getApplicationCategoriesByOrganizationId: (
    organizationId,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/ApplicationCategory?organizationId=${organizationId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAllApplicationCategories: (pageNumber = null, pageSize = null) => {
    const url =
      `/ApplicationCategory` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getApplicationCategoryById: (id) => get(`/ApplicationCategory/${id}`),
  createApplicationCategory: (body) => post('/ApplicationCategory', body),
  updateApplicationCategory: (id, body) =>
    put(`/ApplicationCategory/${id}`, body),
  deleteApplicationCategory: (id) => axios.delete(`/ApplicationCategory/${id}`),
};

const certificates = {
  getAvailableCertificatesByKey: (key) =>
    get(`/Certificates/AvailableCertificates/${key}`),
  getCertificates: (departmentId, pageNumber = null, pageSize = null) => {
    const url =
      `/Certificates?departmentId=${departmentId}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getCertificatesById: (certificateId) => get(`/Certificates/${certificateId}`),
  getAvailableCertificates: () => get('/Certificates/AvailableCertificates'),
  addNewCertificate: (body) => post('/Certificates', body),
  updateCertificateById: (id, body) => put(`/Certificates/${id}`, body),
};

const notifications = {
  getNotifications: (pageNumber = null, pageSize = null, project = null) => {
    const url =
      `/Notification` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '') +
      (project ? `&project=${project}` : '');
    return get(url);
  },
  setNotificationAsRead: (id) => put(`/Notification/${id}`),
  getStatusCount: () => get('/Notification/GetStatusCount'),
};

const certificatesSubmissions = {
  getCertificatesSubmission: ({
    departmentId,
    Id,
    ProtocolNo,
    FinalizedFrom,
    FinalizedTo,
    ApplicationCategoryId,
    Status,
    CitizenLastName,
    CitizenAfm,
    pageNumber = null,
    pageSize = null,
  }) => {
    const url =
      `/CertificatesSubmission?departmentId=${departmentId}&Id=${Id}&ProtocolNo=${ProtocolNo}&FinalizedFrom=${FinalizedFrom}&FinalizedTo=${FinalizedTo}&ApplicationCategoryId=${ApplicationCategoryId}&Status=${Status}&CitizenLastName=${CitizenLastName}&CitizenAfm=${CitizenAfm}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAllCertificatesSubmission: (
    departmentId,
    Id,
    ProtocolNo,
    FinalizedFrom,
    FinalizedTo,
    ApplicationCategoryId,
    Status,
    CitizenLastName,
    CitizenAfm,
    pageNumber = null,
    pageSize = null
  ) => {
    const url =
      `/CertificatesSubmission?departmentId=${departmentId}&Id=${Id}&ProtocolNo=${ProtocolNo}&FinalizedFrom=${FinalizedFrom}&FinalizedTo=${FinalizedTo}&ApplicationCategoryId=${ApplicationCategoryId}&Status=${Status}&CitizenLastName=${CitizenLastName}&CitizenAfm=${CitizenAfm}` +
      (pageNumber && pageSize
        ? `&pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getCertificatesSubmissionStatuses: () =>
    get('/CertificatesSubmission/Statuses'),
  getCertificatesSubmissionsById: (id) => get(`/CertificatesSubmission/${id}`),
  claimCertificateSubmission: (certSubId) =>
    post(`/CertificatesSubmission/${certSubId}/claim`),
  getTaskById: (certSubId) =>
    get(`/CertificatesSubmission/${certSubId}/getTask`),
  completeTaskByCertSubId: (certSubId, body) =>
    post(`/CertificatesSubmission/${certSubId}/completeTask`, body),
  uploadFile: (
    businessKey,
    fieldName,
    certificateSubmissionId,
    formData,
    signature
  ) =>
    post(
      `/CertificatesSubmission/upload-file?certificateSubmissionId=${certificateSubmissionId}&businessKey=${businessKey}&fieldName=${fieldName}&signature=${signature}`,
      formData
    ),
  dowloadFile: (fileName) =>
    getFile(`/CertificatesSubmission/download-file?fileName=${fileName}`),
  removeFile: (fileName) =>
    get(`/CertificatesSubmission/remove-file?fileName=${fileName}`),
  getCertificateSubmissionAttachments: (id) =>
    get(`/CertificatesSubmission/${id}/attachments`),
  getStatusCount: () => get('/CertificatesSubmission/GetStatusCount'),
};

const tickets = {
  getSearchLists: () => get('/Ticket/GetListsForTicketsSearch'),
  getStatusReport: () => get('/Ticket/GetReportByStatus'),
  getTicketDetails: (id) =>
    get(`Ticket/GetTicketDetails?ticketApplicationId=${id}`),
  searchTickets: ({
    ticketApplicationId,
    ticketCode,
    dateCreated,
    dateOfCompletion,
    ticketCategoryId,
    ticketServiceId,
    priorityId,
    statusId,
    departmentId,
    afmOfCitizen,
    employeeUserId,
    getLastUpdatedRecords,
  } = {}) =>
    post('/Ticket/SearchTickets', {
      ticketApplicationId: ticketApplicationId || null,
      ticketCode: ticketCode || '',
      dateCreated,
      dateOfCompletion,
      ticketCategoryId,
      ticketServiceId,
      priorityId,
      statusId,
      departmentId,
      afmOfCitizen: afmOfCitizen || '',
      employeeUserId: employeeUserId || '',
      getLastUpdatedRecords,
    }),
  approveTicket: (id, { department, priority }) =>
    put(`/Ticket/ApproveTicketApplication/${id}`, {
      departmentId: department,
      ticketPriority: priority,
    }),
  manageTicket: (id) => put(`/Ticket/ManageTicketApplication/${id}`),
  completeTicket: (id, { comments }) =>
    put(`/Ticket/CompleteTicketApplication/${id}`, {
      comments: comments || '',
    }),
  rejectTicket: (id, { comments }) =>
    put(`/Ticket/RejectTicketApplication/${id}`, {
      comments: comments || '',
    }),
  getStatusCount: () => get('/Ticket/GetStatusCount'),
  getTicketCategoriesAndMap: () =>
    get('/Ticket/GetTicketCategoriesWithServicesAndMap'),
  createTicket: ({
    externalEmail,
    externalPhoneNumber,
    ticketCategoryId,
    ticketServiceId,
    formattedAddress,
    lat,
    lon,
    notes,
    foo,
  }) => {
    const formData = new FormData();
    formData.append('ExternalEmail', externalEmail);
    formData.append('ExternalPhoneNumber', externalPhoneNumber);
    formData.append('TicketCategoryId', ticketCategoryId);
    formData.append('TicketServiceId', ticketServiceId);
    formData.append('FormattedAddress', formattedAddress);
    formData.append('Lat', lat);
    formData.append('Lon', lon);
    formData.append('Alt', 0); // hard-coded value, Alt not in use
    formData.append('Notes', notes);

    return post('/Ticket/CreateTicket', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  uploadTicketFile: ({ ticketId, files }) => {
    const formData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }

    return post(`/Ticket/upload-files?ticketId=${ticketId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  dowloadTicketImage: (fileName) =>
    getFile(`/Ticket/download-file?fileName=${fileName}`),
};

const sectors = {
  getSectors: (pageNumber = null, pageSize = null) => {
    const url =
      `/Sector` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  createSector: (body) => post(`/Sector`, body),
  updateSector: (id, body) => put(`/Sector/${id}`, body),
  deleteSector: (id) => deleteRequest(`/Sector/${id}`),
  deactivateSector: (id) => axios.post(`/Sector/Deactivate/${id}`),
  activateSector: (id) => axios.put(`/Sector/Activate/${id}`),
};

const organizations = {
  getOrganizations: (pageNumber = null, pageSize = null) => {
    const url =
      `/Organization` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getOrganizationById: (id) => get(`/Organization/${id}`),
  createNewOrganization: (body) => post('/Organization', body),
  updateCurrentOrganization: (id, body) => put(`/Organization/${id}`, body),
  deactivateOrganization: (id) =>
    axios.post(`/Organization/Deactivate/Organization/${id}`),
  activateOrganization: (id) => axios.put(`/Organization/Activate/${id}`),
  cleanupSchedule: (body) => put(`/Organization/CleanupSchedule`, body),
};

const subOrganizations = {
  getSubOrganizations: (pageNumber = null, pageSize = null) => {
    const url =
      `/SubOrganization` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  createNewSubOrganization: (body) => post('/SubOrganization', body),
  updateSubOrganization: (subOrgId, body) =>
    put(`/SubOrganization/${subOrgId}`, body),
  deleteSubOrganization: (subOrgId) =>
    deleteRequest(`/SubOrganization/${subOrgId}`),
  deactivateSubOrganization: (id) =>
    axios.post(`/SubOrganization/Deactivate/${id}`),
  activateSubOrganization: (id) => axios.put(`/SubOrganization/Activate/${id}`),
};

const regionUnits = {
  getRegionUnitsByRegionId: (id) => get(`/RegionUnit/GetByRegion/${id}`),
};

const impersonation = {
  getImpersonatedToken: (email) =>
    get(`/Impersonation/GetImpersonatedToken?email=${email}`),
};

const region = {
  getRegions: (pageNumber = null, pageSize = null) => {
    const url =
      `/Region` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
};

const regionUnit = {
  getRegionUnits: (pageNumber = null, pageSize = null) => {
    const url =
      `/RegionUnit` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getRegionUnitsByRegionId: (regionId, pageNumber = null, pageSize = null) => {
    const url =
      `/RegionUnit/GetByRegion/${regionId}` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
};

const municipality = {
  getMunicipalitys: (pageNumber = null, pageSize = null) => {
    const url =
      `/Municipality` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
};

const municipalityUnit = {
  getMunicipalityUnit: (pageNumber = null, pageSize = null) => {
    const url =
      `/MunicipalityUnit` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getMunicipalityUnitByMunicipalityId: (
    pageNumber = null,
    pageSize = null,
    id
  ) => {
    const url =
      `/MunicipalityUnit/GetByMunicipality/${id}` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
};

const announcements = {
  getAnnouncements: (pageNumber = null, pageSize = null) => {
    const url =
      `/Announcement/GetAnnouncements` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAnnouncementById: (id) => get(`/Announcement/GetAnnouncementById/${id}`),
  createAnnouncement: (body, config) =>
    post('/Announcement/CreateAnnouncement', body, config),
  updateAnnouncement: (id, body, config) =>
    put(`/Announcement/UpdateAnnouncement/${id}`, body, config),
  deleteAnnouncement: (id) =>
    deleteRequest(`/Announcement/DeleteAnnouncement/${id}`),
  getAnnouncementImage: (id) => get(`/Announcement/GetAnnouncementImage/${id}`),
  uploadImage: (id, image) =>
    post(
      `/Announcement/upload-file`,
      {
        id,
        file: image,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ),
  deleteImage: (filename) =>
    deleteRequest(`/Announcement/delete-file?filename=${filename}`),
  downloadImage: (filename) =>
    getFile(`/Announcement/download-file?fileName=${filename}`),
};

const announcementCategories = {
  getAnnouncementCategories: (pageNumber = null, pageSize = null) => {
    const url =
      `/AnnouncementCategory/GetAnnouncementCategoryList` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getAnnouncementCategoryById: (id) =>
    get(`/AnnouncementCategory/GetById/${id}`),
  createAnnouncementCategory: (body) =>
    post('/AnnouncementCategory/AddAnnouncementCategory', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  updateAnnouncementCategory: (id, body) =>
    put(`/AnnouncementCategory/UpdateAnnouncementCategory/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteAnnouncementCategory: (id) =>
    deleteRequest(`/AnnouncementCategory/DeleteAnnouncementCategory/${id}`),
};

const events = {
  getEvents: (pageNumber = null, pageSize = null) => {
    const url =
      `/Event/GetEvents` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getEventById: (id) => get(`/Event/GetEvent/${id}`),
  createEvent: (body, config) => post('/Event/CreateEvent', body, config),
  updateEvent: (id, body, config) =>
    put(`/Event/UpdateEvent/${id}`, body, config),
  deleteEvent: (id) => deleteRequest(`/Event/DeleteEvent/${id}`),
  getEventImage: (id) => get(`/Event/GetEventImage/${id}`),
  uploadImage: (id, image) =>
    post(
      `/Event/upload-file`,
      {
        id,
        file: image,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ),
  deleteImage: (filename) =>
    deleteRequest(`/Event/delete-file?filename=${filename}`),
  downloadImage: (filename) =>
    getFile(`/Event/download-file?fileName=${filename}`),
};

const pois = {
  getPois: (pageNumber = null, pageSize = null) => {
    const url =
      `/Poi/GetPoiList` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getPoiById: (id) => get(`/Poi/GetById/${id}`),
  getPoiByName: (name) => get(`/Poi/GetByName/${name}`),
  createPoi: (body) =>
    post('/Poi/AddPoi', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  updatePoi: (id, body) =>
    put(`/Poi/UpdatePoi/${id}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deletePoi: (id) => deleteRequest(`/Poi/DeletePoi/${id}`),
  getPoiImage: (id) => get(`/Poi/GetPoiImage/${id}`),
  uploadImage: (id, image) =>
    post(
      `/Poi/upload-file`,
      {
        id,
        file: image,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ),
  deleteImage: (filename) =>
    deleteRequest(`/Poi/delete-file?filename=${filename}`),
  downloadImage: (filename) =>
    getFile(`/Poi/download-file?fileName=${filename}`),
};

const poiCategories = {
  getPoiCategories: (pageNumber = null, pageSize = null) => {
    const url =
      `/PoiCategory/GetPoiCategoryList` +
      (pageNumber && pageSize
        ? `?pageNumber=${pageNumber}&pageSize=${pageSize}`
        : '');
    return get(url);
  },
  getPoiCategoryById: (id) => get(`/PoiCategory/GetById/${id}`),
  createPoiCategory: (body) => post('/PoiCategory/AddPoiCategory', body),
  updatePoiCategory: (id, body) =>
    put(`/PoiCategory/UpdatePoiCategory/${id}`, body),
  deletePoiCategory: (id) =>
    deleteRequest(`/PoiCategory/DeletePoiCategory/${id}`),
};

const statistics = {
  getUnassignedTicketsByCategory: (dateFrom = null, dateTo = null) => {
    const url =
      `/Statistics/GetUnassignedTicketsByCategoryCount?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getTicketsByCategory: (dateFrom = null, dateTo = null) => {
    const url =
      `/Statistics/GetTicketsWithDepartmentByCategoryCount?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getRantevouByCategory: (dateFrom = null, dateTo = null) => {
    const url =
      `/Statistics/GetRantevouByCategoryCount?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getCertificatesSubmissionsByCertificateCategory: (
    dateFrom = null,
    dateTo = null
  ) => {
    const url =
      `/Statistics/GetSubmissionsByCertificateCount?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getWorkloadByDepartment: (dateFrom = null, dateTo = null) => {
    const url =
      `/Statistics/GetWorkLoadByDepartmentCount?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getTicketsAverageTotalTimeToCompletionByDepartment: (
    dateFrom = null,
    dateTo = null
  ) => {
    const url =
      `/Statistics/GetTicketAverageCreatedTillCompletedByDepartment?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getTicketsAverageTimeForModeratorByDepartment: (
    dateFrom = null,
    dateTo = null
  ) => {
    const url =
      `/Statistics/GetTicketAverageCreatedTillApprovedOrRejectedByDepartment?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
  getTicketsAverageWorkTimeToCompletionByDepartment: (
    dateFrom = null,
    dateTo = null
  ) => {
    const url =
      `/Statistics/GetTicketAverageNewTillCompletedByDepartment?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}&` : '');
    return get(url);
  },
  getCertificatesAverageTotalTimeToCompletionByDepartment: (
    dateFrom = null,
    dateTo = null
  ) => {
    const url =
      `/Statistics/GetCertificateSubmissionAverageSubmittedTillApprovedOrDeclinedByDepartment?` +
      (dateFrom ? `DateFrom=${dateFrom}&` : '') +
      (dateTo ? `DateTo=${dateTo}` : '');
    return get(url);
  },
};

const AuthSetAgent = {
  authSetup,
  user,
  users,
  department,
  subDepartment,
  schedule,
  rantevou,
  slot,
  appointmentService,
  applicationCategory,
  certificates,
  certificatesSubmissions,
  notifications,
  tickets,
  sectors,
  subOrganizations,
  organizations,
  regionUnits,
  impersonation,
  region,
  regionUnit,
  municipality,
  municipalityUnit,
  announcements,
  announcementCategories,
  events,
  pois,
  poiCategories,
  statistics,
};

export default AuthSetAgent;
