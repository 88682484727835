import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class OrganizationStore {
  organizationTypes = [
    {
      description: 'Δήμος',
      organizationType: 0,
    },
    {
      description: 'Περιφέρεια',
      organizationType: 1,
    },
  ];

  regionsList = [];

  regionUnitsList = [];

  municipalitysList = [];

  organizationById = {};

  organizationList = [];

  constructor() {
    makeObservable(this, {
      organizationTypes: observable,
      organizationList: observable,
      regionsList: observable,
      regionUnitsList: observable,
      municipalitysList: observable,
      organizationById: observable,
      getRegions: action,
      getOrganizationById: action,
      getOrganizations: action,
      getRegionUnits: action,
      getMunicipalitys: action,
      createNewOrganization: action,
      updateCurrentOrganization: action,
      cleanupSchedule: action,
    });
  }

  getRegions() {
    return AuthSetAgent.region
      .getRegions()
      .then((res) => {
        this.regionsList = res.result.items;
      })
      .catch((e) => {});
  }

  getOrganizations() {
    return AuthSetAgent.organizations
      .getOrganizations()
      .then((res) => {
        this.organizationList = res.result.items;
        return res.result;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getOrganizationById(id) {
    return AuthSetAgent.organizations
      .getOrganizationById(id)
      .then((res) => {
        this.organizationById = res?.result;
        return res.result;
      })
      .catch((e) => {});
  }

  getRegionUnits() {
    return AuthSetAgent.regionUnit
      .getRegionUnits()
      .then((res) => {
        this.regionUnitsList = res.result.items;
      })
      .catch((e) => {});
  }

  getMunicipalitys() {
    return AuthSetAgent.municipality
      .getMunicipalitys()
      .then((res) => {
        this.municipalitysList = res.result.items;
      })
      .catch((e) => {});
  }

  createNewOrganization(body) {
    return AuthSetAgent.organizations.createNewOrganization(body);
  }

  updateCurrentOrganization(id, body) {
    return AuthSetAgent.organizations.updateCurrentOrganization(id, body);
  }

  cleanupSchedule(body) {
    return AuthSetAgent.organizations.cleanupSchedule(body);
  }
}

export const organizationsStore = new OrganizationStore();
export const organizationsStoreContext = createContext(organizationsStore);
export const OrganizationsStoreProvider = ({ children, store }) => (
  <organizationsStoreContext.Provider value={store}>
    {children}
  </organizationsStoreContext.Provider>
);
export const useOrganizationsStore = () =>
  useContext(organizationsStoreContext);
