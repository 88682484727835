import moment from 'moment';

export const formatDateInDateMonthYear = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
};

export const formatDateInDateMonthYearHour = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD - HH:mm');
  }
};

export const formatDateInDateMonthYearHourWithoutSpace = (date) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
};

export const transformDateFromSlashesToDash = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

export const parseGreekDate = (
  dateString,
  returnFormat = 'DD/MM/YYYY HH:mm:ss'
) => {
  let adjustedDateString = dateString.replace('πμ', 'AM').replace('μμ', 'PM');
  const inputFormat = 'D/M/YYYY H:mm:ss A';
  const parsedDate = moment(adjustedDateString, inputFormat);
  if (!parsedDate.isValid()) {
    console.error('Invalid date string:', dateString);
  }
  return parsedDate.format(returnFormat);
};
