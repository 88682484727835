import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { statusTranslations } from '../../../Config/constant';
import FileUploader from '../../../Components/FileUploader/FileUploader';
import { Controller, useForm } from 'react-hook-form';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { success } from '../../../Common/Toast/Toast';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { width } from '../../../styles/variables/width';

const SignedCertificateUpload = () => {
  const certificatesStore = useCertificatesStore();
  const departmentStore = useDepartmentStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [adminReviewValue, setAdminReviewValue] = useState('');
  const [taskData, setTaskData] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const payload = {
      variables: {
        adminReview: {
          value: adminReviewValue,
        },
        st3_file_signedCertificate: {
          value:
            certificatesStore.uploadedFileResp?.fileName ||
            data?.st3_file_signedCertificate,
        },
      },
      withVariablesInReturn: true,
    };
    setLoading(true);
    certificatesStore
      .completeTaskByCertSubId(
        certificatesStore.certificateSubmissionsByIdList?.id,
        payload
      )
      .then((resp) => {
        success('Επιτυχής επισύναψη πιστοπιητικού');
        navigate(`/certificates-overview`);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (certificatesStore.certificateSubmissionsByIdList?.id) {
      certificatesStore
        .getTaskById(certificatesStore.certificateSubmissionsByIdList?.id)
        .then((resp) => {
          setTaskData(resp?.result);
        })
        .catch(() => {});
    }
  }, [
    certificatesStore.certificateSubmissionsByIdList?.length,
    certificatesStore.certificateSubmissionsByIdList?.id,
    setTaskData,
    taskData?.length,
    certificatesStore,
  ]);

  useEffect(() => {
    setLoading(true);
    certificatesStore
      .getCertificatesSubmissionsById(id)
      .then((resp) => {})
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, [
    certificatesStore.certificateSubmissionsByIdList?.length,
    id,
    certificatesStore,
  ]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length, departmentStore]);

  useEffect(() => {
    AuthSetAgent.certificatesSubmissions
      .getCertificateSubmissionAttachments(id)
      .then((resp) => {
        setAttachments(resp?.result);
      })
      .catch((error) => {})
      .finally(() => {});
  }, [id]);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [
    certificatesStore.certificatesSubmissionStatusesList?.length,
    certificatesStore,
  ]);

  return (
    <>
      {loading ? (
        <div className="text-center py-10">
          <CircularProgress color="primary" size="3rem" />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Topbar title="Ηλεκτρονικές Υπηρεσίες ΠΔΕ" />
            </div>

            <div className="ml-2">
              <div className="flex justify-start items-start pl-2">
                <Typography
                  className="pt-4 pr-4"
                  variant="h3"
                  color={'#003375'}
                  gutterBottom
                >
                  {certificatesStore.certificateSubmissionsByIdList?.certificate
                    ?.name
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.certificate?.name
                    : '-'}{' '}
                  - Επισύναψη
                </Typography>
              </div>

              <div className="flex justify-start items-start pl-2 pt-8">
                <div className="flex flex-col">
                  <div className="rounded-xl bg-[#BFE5EF] w-52 h-[38px]">
                    <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                      Aρ. Αίτησης:{' '}
                      {certificatesStore.certificateSubmissionsByIdList?.id
                        ? certificatesStore.certificateSubmissionsByIdList?.id
                        : '-'}
                      <div />
                    </div>
                  </div>

                  <div className="rounded-xl bg-[#BFE5EF] w-52 mt-1">
                    <div className="mt-2 text-[1.2rem] text-center text-[#194682]">
                      Aρ. Πρωτοκόλλου:{' '}
                      {certificatesStore.certificateSubmissionsByIdList
                        ?.submissionInfo?.formSubmittedValues
                        ?.submissionsProtocol?.value
                        ? certificatesStore.certificateSubmissionsByIdList?.submissionInfo?.formSubmittedValues?.submissionsProtocol?.value.slice(
                            0,
                            3
                          )
                        : '-'}
                    </div>
                  </div>
                </div>

                <div className="rounded-xl bg-[#0965D4] w-48 h-[80px] ml-4">
                  <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                    Κατάσταση: <br />
                    {statusTranslations[
                      certificatesStore.certificateSubmissionsByIdList?.status
                    ] || '-'}
                    <div />
                  </div>
                </div>

                {certificatesStore.certificateSubmissionsByIdList?.status ===
                3 ? (
                  <div className="rounded-xl bg-[#56A22E] w-[15%] h-[80px] ml-6 p-0">
                    <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                      <div className="flex justify-center items-center">
                        <div>Στάδιο ελέγχου:</div>
                      </div>
                      <div>
                        {' '}
                        {certificatesStore.certificateSubmissionsByIdList
                          ?.activeTaskId
                          ? certificatesStore.certificateSubmissionsByIdList
                              ?.activeTaskId
                          : '---'}
                      </div>
                    </div>
                  </div>
                ) : null}

                {certificatesStore.certificateSubmissionsByIdList?.status ===
                5 ? (
                  <div className="rounded-xl bg-[#56A22E] w-[15%] h-[80px] ml-6 p-0">
                    <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                      <div className="flex justify-center items-center">
                        <div>Στάδιο ελέγχου:</div>
                      </div>
                      <div>{'Aξιολόγηση'}</div>
                    </div>
                  </div>
                ) : null}

                {certificatesStore.certificateSubmissionsByIdList?.status ===
                  4 ||
                certificatesStore.certificateSubmissionsByIdList?.status ===
                  6 ? (
                  <div className="rounded-xl bg-[#56A22E] w-[15%] h-[80px] ml-6 p-0">
                    <div className="mt-4 text-[1.2rem] text-center text-[#FFFFFF]">
                      <div className="flex justify-center items-center">
                        <div>Στάδιο ελέγχου:</div>
                      </div>
                      <div>
                        {certificatesStore.certificateSubmissionsByIdList
                          ?.certificate?.reviewProcessId
                          ? 'Έγκριση'
                          : '-'}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="rounded-xl bg-[#BFE5EF] px-2 flex justify-start w-[634px] h-[37px] mt-6 pt-1 ml-2">
                <div className="text-[1.2rem] text-center text-[#194682]">
                  Υπεύθυνος αξιολόγησης σταδίου:{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.claimedByUser?.name
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.claimedByUser?.name
                    : '-'}{' '}
                  {certificatesStore.certificateSubmissionsByIdList
                    ?.claimedByUser?.surName
                    ? certificatesStore.certificateSubmissionsByIdList
                        ?.claimedByUser?.surName
                    : '-'}
                  <div />
                </div>
              </div>
            </div>

            <div className="mt-4 mb-4 ml-6">
              {taskData &&
                taskData?.name !== null &&
                taskData?.name !== undefined && (
                  <div>
                    <Typography className="pb-4 pt-4" variant="h4">
                      <b>{taskData?.name}</b>
                    </Typography>
                  </div>
                )}

              {taskData &&
                taskData?.components !== null &&
                taskData?.components !== undefined && (
                  <>
                    {taskData?.components
                      ?.filter(
                        (component) =>
                          component?.type === 'textfield' ||
                          component?.type === 'button'
                      )
                      ?.map((component, index) => (
                        <div key={index}>
                          <Controller
                            name={component?.key || ''}
                            index={index}
                            control={control}
                            rules={{
                              required: component?.validate
                                ? component?.validate?.required
                                : false,
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                {component.key
                                  ?.toString()
                                  ?.includes('file') && (
                                  <>
                                    <Typography
                                      className="pb-4 pt-4"
                                      variant="h4"
                                    >
                                      <b>{component?.label}</b>
                                    </Typography>
                                    <Box p={2} m={1} width={'100%'}>
                                      <FileUploader
                                        certificateSubmissionId={
                                          certificatesStore
                                            .certificateSubmissionsByIdList?.id
                                        }
                                        businessKey={
                                          certificatesStore
                                            ?.certificateSubmissionsByIdList
                                            .businessKey
                                        }
                                        fieldName={component?.key}
                                        isEditMode={false}
                                        errors={Boolean(error)}
                                        helperText={
                                          error &&
                                          'Το πεδίο αυτό είναι υποχρεωτικό'
                                        }
                                        onFileUploaded={onChange}
                                        certificateSubmissionAttachments={
                                          attachments
                                        }
                                        signature={true}
                                      />
                                    </Box>
                                  </>
                                )}

                                {component?.type === 'button' && (
                                  <>
                                    <div className="ml-4 mt-6">
                                      <SubmitButton
                                        sx={{
                                          backgroundColor: '#003375',
                                          color: '#f9f9f9',
                                          width: width.button.md,
                                          textTransform: 'none',
                                          '&:hover': {
                                            backgroundColor: '#003375',
                                            color: '#f9f9f9',
                                          },
                                          fontSize: '1rem',
                                        }}
                                        className="capitalize text-sm"
                                        variant="contained"
                                        loading={loading}
                                        type="submit"
                                        onClick={() =>
                                          setAdminReviewValue(
                                            component?.properties
                                              ?.adminReview || ''
                                          )
                                        }
                                        title={component?.label}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          />
                        </div>
                      ))}
                  </>
                )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default observer(SignedCertificateUpload);
