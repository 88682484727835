import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { FORM_STATUS } from './Sectors';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutline } from '@mui/icons-material';
import { useEffect } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const OrganizationsList = ({
  value,
  error,
  onChange,
  organizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="organizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Οργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="organizations-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {organizations?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio disabled={isEdit} value={item?.id} />}
              label={item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SubOrganizationsList = ({
  value,
  error,
  onChange,
  subOrganizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="suborganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Υποοργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!subOrganizations?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένοι Υποοργανισμοί για αυτόν τον Οργανισμό
            </Typography>
          ) : (
            subOrganizations?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const MunicipalityUnitsList = ({
  value,
  error,
  onChange,
  municipalityUnits,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="municipalityUnits-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε Δημοτική Ενότητα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="municipalityUnits-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!municipalityUnits?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένες Δημοτικές Ενότητες για αυτόν τον
              Οργανισμό
            </Typography>
          ) : (
            municipalityUnits?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const RegionUnitsList = ({
  value,
  error,
  onChange,
  regionUnits,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel className="mb-2" id="regions-radio-buttons-group-label">
          <Typography variant="h5">
            Επιλέξτε την Περιφερειακή Ενότητα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="regions-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!regionUnits?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένες Περιφέρειες για αυτόν τον Οργανισμό
            </Typography>
          ) : (
            regionUnits?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const SECTOR_FIELD = {
  Name: 'name',
  Email: 'email',
  Address: 'address',
  OrganizationId: 'organizationId',
  SubOrganizationId: 'subOrganizationId',
  MunicipalityUnitId: 'municipalityUnitId',
  RegionUnitId: 'regionUnitId',
  Telephone: 'tel',
};

const defaultValues = {
  [SECTOR_FIELD.Name]: '',
  [SECTOR_FIELD.Email]: '',
  [SECTOR_FIELD.Address]: '',
  [SECTOR_FIELD.OrganizationId]: null,
  [SECTOR_FIELD.SubOrganizationId]: null,
  [SECTOR_FIELD.MunicipalityUnitId]: null,
  [SECTOR_FIELD.RegionUnitId]: null,
  [SECTOR_FIELD.Telephone]: '',
};

const SectorCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
  organizations,
  subOrganizations,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [filteredMunicipalityUnits, setFilteredMunicipalityUnits] = useState(
    []
  );
  const [filteredRegionUnits, setFilteredRegionUnits] = useState([]);
  const SectorSchema = yup.object().shape({
    [SECTOR_FIELD.Name]: yup.string().required(),
    [SECTOR_FIELD.Email]: yup.string().email().required(),
    [SECTOR_FIELD.Address]: yup.string().required(),
    [SECTOR_FIELD.OrganizationId]: yup.string().required(),
    [SECTOR_FIELD.SubOrganizationId]: yup.string().required(),
    [SECTOR_FIELD.MunicipalityUnitId]:
      selectedOrganization?.organizationType === 0
        ? yup.string().required()
        : yup.string().notRequired(),
    [SECTOR_FIELD.RegionUnitId]:
      selectedOrganization?.organizationType === 1
        ? yup.string().required()
        : yup.string().notRequired(),
    [SECTOR_FIELD.Telephone]: yup
      .string()
      .trim()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .min(10)
      .max(10)
      .required(),
  });
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: isEdit
      ? {
          [SECTOR_FIELD.Name]: item?.[SECTOR_FIELD.Name],
          [SECTOR_FIELD.Email]: item?.[SECTOR_FIELD.Email],
          [SECTOR_FIELD.Address]: item?.[SECTOR_FIELD.Address],
          [SECTOR_FIELD.OrganizationId]: item?.[SECTOR_FIELD.OrganizationId],
          [SECTOR_FIELD.SubOrganizationId]:
            item?.[SECTOR_FIELD.SubOrganizationId],
          [SECTOR_FIELD.MunicipalityUnitId]:
            item?.[SECTOR_FIELD.MunicipalityUnitId],
          [SECTOR_FIELD.Telephone]: item?.[SECTOR_FIELD.Telephone],
          [SECTOR_FIELD.RegionUnitId]: item?.[SECTOR_FIELD.RegionUnitId],
        }
      : defaultValues,
    resolver: yupResolver(SectorSchema),
  });
  const selectedOrganizationId = watch(SECTOR_FIELD.OrganizationId);
  const filteredSubOrganizations = subOrganizations?.filter(
    (subOrg) =>
      subOrg?.organizationId?.toString() === selectedOrganizationId?.toString()
  );

  const submitCreate = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      [SECTOR_FIELD.Name]: formValues?.[SECTOR_FIELD.Name],
      [SECTOR_FIELD.Email]: formValues?.[SECTOR_FIELD.Email],
      [SECTOR_FIELD.Address]: formValues?.[SECTOR_FIELD.Address],
      [SECTOR_FIELD.Telephone]: formValues?.[SECTOR_FIELD.Telephone],
      [SECTOR_FIELD.SubOrganizationId]: parseInt(
        formValues?.[SECTOR_FIELD.SubOrganizationId]
      ),
      [SECTOR_FIELD.RegionUnitId]:
        selectedOrganization?.organizationType === 1
          ? parseInt(formValues?.[SECTOR_FIELD.RegionUnitId])
          : null,
      [SECTOR_FIELD.MunicipalityUnitId]:
        selectedOrganization?.organizationType === 0
          ? parseInt(formValues?.[SECTOR_FIELD.MunicipalityUnitId])
          : null,
    };
    await AuthSetAgent.sectors
      .createSector(formattedBody)
      .then(async (resp) => {
        if (resp?.success === true) {
          success('O Τομέας δημιουργήθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την δημιουργία του τομέα');
        }
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        reset(defaultValues);
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά τη δημιουργία του Τομέα!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      [SECTOR_FIELD.Name]: formValues?.[SECTOR_FIELD.Name],
      [SECTOR_FIELD.Email]: formValues?.[SECTOR_FIELD.Email],
      [SECTOR_FIELD.Address]: formValues?.[SECTOR_FIELD.Address],
      [SECTOR_FIELD.Telephone]: formValues?.[SECTOR_FIELD.Telephone],
      [SECTOR_FIELD.RegionUnitId]:
        selectedOrganization?.organizationType === 1
          ? parseInt(formValues?.[SECTOR_FIELD.RegionUnitId])
          : null,
      [SECTOR_FIELD.MunicipalityUnitId]:
        selectedOrganization?.organizationType === 0
          ? parseInt(formValues?.[SECTOR_FIELD.MunicipalityUnitId])
          : null,
    };
    await AuthSetAgent.sectors
      .updateSector(item?.id, formattedBody)
      .then(async (resp) => {
        if (resp?.success === true) {
          success('Ο Τομέας ενημερώθηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την ενημέρωση του τομέα');
        }
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά την επεξεργασία του Τομέα!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await submitCreate();
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [SECTOR_FIELD.Name]: item?.[SECTOR_FIELD.Name] || '',
        [SECTOR_FIELD.Email]: item?.[SECTOR_FIELD.Email] || '',
        [SECTOR_FIELD.Address]: item?.[SECTOR_FIELD.Address] || '',
        [SECTOR_FIELD.OrganizationId]:
          item?.[SECTOR_FIELD.OrganizationId] || null,
        [SECTOR_FIELD.SubOrganizationId]:
          item?.[SECTOR_FIELD.SubOrganizationId] || null,
        [SECTOR_FIELD.MunicipalityUnitId]:
          item?.[SECTOR_FIELD.MunicipalityUnitId] || null,
        [SECTOR_FIELD.RegionUnitId]: item?.[SECTOR_FIELD.RegionUnitId] || null,
        [SECTOR_FIELD.Telephone]: item?.[SECTOR_FIELD.Telephone] || '',
      });
    }
  }, [isEdit, item, reset]);

  useEffect(() => {
    if (selectedOrganizationId) {
      AuthSetAgent.organizations
        .getOrganizationById(selectedOrganizationId)
        .then((resp) => {
          setSelectedOrganization(resp?.result);
        })
        .catch((error) => {})
        .finally(() => {});
    }
  }, [selectedOrganizationId]);

  // 0 Stands for municipality unit
  useEffect(() => {
    if (
      selectedOrganization?.organizationType === 0 &&
      selectedOrganization?.municipality?.id
    ) {
      AuthSetAgent.municipalityUnit
        .getMunicipalityUnitByMunicipalityId(
          '',
          '',
          selectedOrganization?.municipality?.id
        )
        .then((resp) => {
          setFilteredMunicipalityUnits(resp?.result?.items);
        })
        .catch((error) => {})
        .finally(() => {});
    }
  }, [
    selectedOrganization?.organizationType,
    selectedOrganization?.municipality?.id,
  ]);

  // 1 Stands for region unit
  useEffect(() => {
    if (
      selectedOrganization?.organizationType === 1 &&
      selectedOrganization?.region?.id
    ) {
      AuthSetAgent.regionUnit
        .getRegionUnitsByRegionId(selectedOrganization?.region?.id)
        .then((resp) => {
          setFilteredRegionUnits(resp?.result?.items);
        })
        .catch((error) => {})
        .finally(() => {});
    }
  }, [
    selectedOrganization?.organizationType,
    selectedOrganization?.region?.id,
  ]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit ? 'Επεξεργασία Διεύθυνσης' : 'Προσθήκη Διεύθυνσης'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={SECTOR_FIELD.Name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label="Ονομασία"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={
                    error && 'Το πεδίο Ονομασία Διεύθυνσης είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={SECTOR_FIELD.Telephone}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-6">
                <TextField
                  className="flex-1"
                  label="Τηλέφωνο"
                  onChange={onChange}
                  value={value}
                  type="number"
                  error={!!error}
                  helperText={
                    error && 'Το Τηλέφωνο πρέπει να είναι 10-ψήφιος αριθμός'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={SECTOR_FIELD.Email}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-6">
                <TextField
                  className="flex-1"
                  label="Email"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Εισάγετε έγκυρο email'}
                />
              </Box>
            )}
          />
          <Controller
            name={SECTOR_FIELD.Address}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2 col-span-12">
                <TextField
                  className="flex-1"
                  label={'Τοποθεσία'}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                />
              </Box>
            )}
          />
          <Controller
            name={SECTOR_FIELD.OrganizationId}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex-1 mb-2 col-span-6">
                <OrganizationsList
                  error={!!error}
                  onChange={(event) => {
                    setValue(SECTOR_FIELD.OrganizationId, event?.target?.value);
                    if (selectedOrganizationId) {
                      setValue(SECTOR_FIELD.SubOrganizationId, null);
                      setValue(SECTOR_FIELD.MunicipalityUnitId, null);
                      setValue(SECTOR_FIELD.RegionUnitId, null);
                    }
                  }}
                  value={value}
                  organizations={organizations}
                  isEdit={isEdit}
                />
              </Box>
            )}
          />

          <Controller
            name={SECTOR_FIELD.SubOrganizationId}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                {!!watch(SECTOR_FIELD.OrganizationId) && (
                  <Box className="ml-20">
                    <SubOrganizationsList
                      error={!!error}
                      onChange={onChange}
                      subOrganizations={filteredSubOrganizations || []}
                      value={value}
                      isEdit={isEdit}
                    />
                  </Box>
                )}
              </>
            )}
          />
          {selectedOrganization?.organizationType === 0 && (
            <Controller
              name={SECTOR_FIELD.MunicipalityUnitId}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <MunicipalityUnitsList
                  error={!!error}
                  onChange={onChange}
                  value={value}
                  municipalityUnits={filteredMunicipalityUnits}
                />
              )}
            />
          )}

          {selectedOrganization?.organizationType === 1 && (
            <Controller
              name={SECTOR_FIELD.RegionUnitId}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className="flex-1 mb-2 col-span-6">
                  <RegionUnitsList
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    regionUnits={filteredRegionUnits}
                  />
                </Box>
              )}
            />
          )}
        </Box>
        <Box className="flex justify-end pt-5">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              title="Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {}}
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default SectorCreateEditForm;
