import { action, makeObservable, observable, reaction } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import { authStore } from './AuthStore';
import { error } from '../Common/Toast/Toast';

class PoisStore {
  categories = [];

  constructor() {
    makeObservable(this, {
      categories: observable,
      getPois: action,
      getPoiById: action,
      getPoiByName: action,
      createPoi: action,
      updatePoi: action,
      deletePoi: action,
      uploadImage: action,
      deleteImage: action,
      downloadImage: action,
      getPoiCategories: action,
      getPoiCategoryById: action,
      createPoiCategory: action,
      updatePoiCategory: action,
      deletePoiCategory: action,
    });

    reaction(
      () => {
        if (
          authStore.userAuthenticated &&
          (authStore.isContentManager || authStore.isSuperAdmin)
        ) {
          this.getPoiCategories();
        }
      },
      () => {
        console.log(this.categories);
      }
    );
  }

  getPois = (currentPage = null, pageSize = null) => {
    return AuthSetAgent.pois
      .getPois(currentPage, pageSize)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getPoiById = (id) => {
    return AuthSetAgent.pois
      .getPoiById(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getPoiByName = (name) => {
    return AuthSetAgent.pois
      .getPoiByName(name)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createPoi = (poi) => {
    return AuthSetAgent.pois
      .createPoi(poi)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updatePoi = (id, poi) => {
    return AuthSetAgent.pois
      .updatePoi(id, poi)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deletePoi = (id) => {
    return AuthSetAgent.pois
      .deletePoi(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  uploadImage = (id, image) => {
    return AuthSetAgent.pois
      .uploadImage(id, image)
      .then((response) => {
        if (response?.success) {
          return response.result;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deleteImage = (filename) => {
    return AuthSetAgent.pois
      .deleteImage(filename)
      .then((response) => {
        if (response?.success) {
          return response;
        }
        error('Κάτι πήγε στραβά', response?.errors?.toString());
        return null;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  downloadImage = (filename) => {
    return AuthSetAgent.pois
      .downloadImage(filename)
      .then((response) => {
        if (!response?.data) {
          return null;
        }
        const blob = new Blob([response?.data], {
          type: response?.headers['content-type'],
        });
        const imageUrl = URL.createObjectURL(blob);
        return imageUrl;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getPoiCategories = (currentPage = null, pageSize = null) => {
    return AuthSetAgent.poiCategories
      .getPoiCategories(currentPage, pageSize)
      .then((response) => {
        this.categories = response.result.items;
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getPoiCategoryById = (id) => {
    return AuthSetAgent.poiCategories
      .getPoiCategoryById(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createPoiCategory = (category) => {
    return AuthSetAgent.poiCategories
      .createPoiCategory(category)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updatePoiCategory = (id, category) => {
    return AuthSetAgent.poiCategories
      .updatePoiCategory(id, category)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  deletePoiCategory = (id) => {
    return AuthSetAgent.poiCategories
      .deletePoiCategory(id)
      .then((response) => {
        return response.result;
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const poisStore = new PoisStore();
export const PoisStoreContext = createContext(poisStore);
export const PoisStoreProvider = ({ children, store }) => (
  <PoisStoreContext.Provider value={store}>
    {children}
  </PoisStoreContext.Provider>
);
export const usePoisStore = () => useContext(PoisStoreContext);
