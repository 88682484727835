import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { Typography } from '@mui/material';
import { ORG_NAME } from '../../../Config/constant';
import DashBoardTables from './DashBoardTables';

const DashBoard = () => {
  return (
    <div>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <div className="ml-4 mb-15">
        <div className="mt-8">
          <Typography
            className="pl-2"
            variant="h3"
            color={'#003375'}
            gutterBottom
          >
            Dashboard
          </Typography>
        </div>
        <DashBoardTables />
      </div>
    </div>
  );
};

export default observer(DashBoard);
