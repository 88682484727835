import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class StatsStore {
  unassignedTicketsByCategory = null;
  ticketsByCategory = null;
  certificatesByCategory = null;
  randevousByCategory = null;
  workloadByDepartment = null;
  averageTicketCompletionTimeByDepartment = null;
  averageTicketModeratorTimeByDepartment = null;
  averageTicketWorkTimeToCompletionByDepartment = null;
  averageCertificateCompletionTimeByDepartment = null;

  constructor() {
    makeObservable(this, {
      unassignedTicketsByCategory: observable,
      ticketsByCategory: observable,
      certificatesByCategory: observable,
      randevousByCategory: observable,
      workloadByDepartment: observable,
      averageTicketCompletionTimeByDepartment: observable,
      averageTicketModeratorTimeByDepartment: observable,
      averageTicketWorkTimeToCompletionByDepartment: observable,
      averageCertificateCompletionTimeByDepartment: observable,
      fetchUnassignedTicketsByCategory: action,
      fetchTicketsByCategory: action,
      fetchCertificatesByCategory: action,
      fetchRandevousByCategory: action,
      fetchWorkloadByDepartment: action,
      fetchAverageTicketCompletionTimeByDepartment: action,
      fetchAverageTicketModeratorTimeByDepartment: action,
      fetchAverageTicketWorkTimeToCompletionByDepartment: action,
      fetchAverageCertificateCompletionTimeByDepartment: action,
    });
  }

  fetchTicketsByCategory = async (dateFrom = null, dateTo = null) => {
    await AuthSetAgent.statistics
      .getTicketsByCategory(dateFrom, dateTo)
      .then((response) => {
        this.ticketsByCategory = response?.result;
      })
      .catch((err) => {
        this.ticketsByCategory = null;
        console.error(err);
      });
  };

  fetchUnassignedTicketsByCategory = async (dateFrom = null, dateTo = null) => {
    await AuthSetAgent.statistics
      .getUnassignedTicketsByCategory(dateFrom, dateTo)
      .then((response) => {
        this.unassignedTicketsByCategory = response?.result;
      })
      .catch((err) => {
        this.unassignedTicketsByCategory = null;
        console.error(err);
      });
  };

  fetchCertificatesByCategory = async (dateFrom = null, dateTo = null) => {
    await AuthSetAgent.statistics
      .getCertificatesSubmissionsByCertificateCategory(dateFrom, dateTo)
      .then((response) => {
        this.certificatesByCategory = response?.result;
      })
      .catch((err) => {
        this.certificatesByCategory = null;
        console.error(err);
      });
  };

  fetchRandevousByCategory = async (dateFrom = null, dateTo = null) => {
    await AuthSetAgent.statistics
      .getRantevouByCategory(dateFrom, dateTo)
      .then((response) => {
        this.randevousByCategory = response?.result;
      })
      .catch((err) => {
        this.randevousByCategory = null;
        console.error(err);
      });
  };

  fetchWorkloadByDepartment = async (dateFrom = null, dateTo = null) => {
    await AuthSetAgent.statistics
      .getWorkloadByDepartment(dateFrom, dateTo)
      .then((response) => {
        this.workloadByDepartment = response?.result;
      })
      .catch((err) => {
        this.workloadByDepartment = null;
        console.error(err);
      });
  };

  fetchAverageTicketCompletionTimeByDepartment = async (
    dateFrom = null,
    dateTo = null
  ) => {
    await AuthSetAgent.statistics
      .getTicketsAverageTotalTimeToCompletionByDepartment(dateFrom, dateTo)
      .then((response) => {
        this.averageTicketCompletionTimeByDepartment = response?.result;
      })
      .catch((err) => {
        this.averageTicketCompletionTimeByDepartment = null;
        console.error(err);
      });
  };

  fetchAverageTicketModeratorTimeByDepartment = async (
    dateFrom = null,
    dateTo = null
  ) => {
    await AuthSetAgent.statistics
      .getTicketsAverageTimeForModeratorByDepartment(dateFrom, dateTo)
      .then((response) => {
        this.averageTicketModeratorTimeByDepartment = response?.result;
      })
      .catch((err) => {
        this.averageTicketModeratorTimeByDepartment = null;
        console.error(err);
      });
  };

  fetchAverageTicketWorkTimeToCompletionByDepartment = async (
    dateFrom = null,
    dateTo = null
  ) => {
    await AuthSetAgent.statistics
      .getTicketsAverageWorkTimeToCompletionByDepartment(dateFrom, dateTo)
      .then((response) => {
        this.averageTicketWorkTimeToCompletionByDepartment = response?.result;
      })
      .catch((err) => {
        this.averageTicketWorkTimeToCompletionByDepartment = null;
        console.error(err);
      });
  };
  fetchAverageCertificateCompletionTimeByDepartment = async (
    dateFrom = null,
    dateTo = null
  ) => {
    await AuthSetAgent.statistics
      .getCertificatesAverageTotalTimeToCompletionByDepartment(dateFrom, dateTo)
      .then((response) => {
        this.averageCertificateCompletionTimeByDepartment = response?.result;
      })
      .catch((err) => {
        this.averageCertificateCompletionTimeByDepartment = null;
        console.error(err);
      });
  };
}

export const statsStore = new StatsStore();
export const StatsStoreContext = createContext(statsStore);
export const StatsStoreProvider = ({ children, store }) => (
  <StatsStoreContext.Provider value={store}>
    {children}
  </StatsStoreContext.Provider>
);
export const useStatsStore = () => useContext(StatsStoreContext);
