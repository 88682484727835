import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { Typography } from '@mui/material';
import OrganizationDefinitionForm from './OrganizationDefinitionForm';

const OrganizationDefinition = () => {
  return (
    <>
      <div>
        <Topbar title="Ηλεκτρονικές Υπηρεσίες ΠΔΕ" />
      </div>
      <div className="ml-4">
        <Typography
          className="pt-8 pb-5 pl-1"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Ορισμός οργανισμού
        </Typography>

        <div className="mt-4 ml-2">
          <OrganizationDefinitionForm />
        </div>
      </div>
    </>
  );
};

export default observer(OrganizationDefinition);
