const UploadFileInput = ({ accept, className, label, onChange }) => (
  <div className={className}>
    <label className="govgr-btn bg-blue-400 w-[200px] text-center">
      <input
        accept={accept}
        onChange={onChange}
        type="file"
        name="submission_images"
        hidden
      />
      {label}
    </label>
  </div>
);

export default UploadFileInput;
