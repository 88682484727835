import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import { CircularProgress, Typography } from '@mui/material';
import ScheduleSaved from './ScheduleSaved';
import ScheduleArchived from './ScheduleArchived';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import { ORG_NAME } from '../../../Config/constant';

const ScheduleHistory = () => {
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const subDepartmentStore = useSubDepartmentStore();
  const { authUser, isAdmin } = useAuthStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore]);

  useEffect(() => {
    if (isAdmin === false && authUser?.subDepartmentId) {
      setSelectedSubDepartment(
        subDepartmentStore.subDepartmentsList.find(
          (item) => item.id === authUser?.subDepartmentId
        )
      );
    }
  }, [authUser, subDepartmentStore]);

  return (
    <>
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>

      <div className="ml-6 mb-15">
        <div className="mt-6">
          <Typography variant="h3" color={'#003375'} gutterBottom>
            Ιστορικότητα προγραμμάτων
          </Typography>
        </div>

        {!loading && (
          <>
            {isAdmin === true && (
              <Typography
                sx={{ fontWeight: 'medium' }}
                mt={2}
                variant="h4"
                gutterBottom
              >
                Επιλογή υποτμήματος:
                <Autocomplete
                  options={subDepartmentStore.subDepartmentsList}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setSelectedSubDepartment(newValue);
                  }}
                  sx={{ width: 300, marginTop: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="bg-[#FFFF] mt-14"
                      fullWidth
                      label={'Υποτμήμα'}
                    />
                  )}
                />
              </Typography>
            )}
            <div className="mt-4 w-[80%] flex justify-center">
              <img width="120" src="/rantevou.png" alt="rantevou" />
            </div>

            {selectedSubDepartment && (
              <div className="mt-6">
                <Typography variant="h3" color={'#003375'} gutterBottom>
                  Αποθηκευμένα
                </Typography>
              </div>
            )}

            {selectedSubDepartment && (
              <ScheduleSaved
                selectedSubDepartmentId={selectedSubDepartment.id}
                setLoading={setLoading}
              />
            )}

            {selectedSubDepartment && (
              <div className="mt-12">
                <Typography variant="h3" color={'#003375'} gutterBottom>
                  Αρχειοθετημένα (παλαιότερα δημοσιευμένα)
                </Typography>
              </div>
            )}

            {selectedSubDepartment && (
              <ScheduleArchived
                selectedSubDepartmentId={selectedSubDepartment.id}
                setLoading={setLoading}
              />
            )}
          </>
        )}
        {loading && (
          <div className="flex justify-center items-center">
            <CircularProgress
              sx={{
                color: '#003375',
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ScheduleHistory);
