export const phoneRegex = /^(\+30|0030)?[0-9]{10}$/;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const afmRegex = /^\d{9}$/;

export const passwordRegex =
  /^(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;

export const numberRegex = /^\b([0-9]|[1-9][0-9]|100)\b$/;
