import { useEffect } from 'react';
import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useUserStore } from '../../../Stores/UserStore';
import ScheduleForm from './ScheduleForm';
import { useAuthStore } from '../../../Stores/AuthStore';
import NoAccessCard from '../../../Components/NoAccessCard/NoAccessCard';
import { ORG_NAME } from '../../../Config/constant';

const CreateSchedule = () => {
  const subDepartentStore = useSubDepartmentStore();
  const userStore = useUserStore();
  const { isAdmin } = useAuthStore();

  useEffect(() => {
    if (subDepartentStore.subDepartmentsList.length === 0)
      subDepartentStore.getSubDepartments();
  }, [subDepartentStore]);

  useEffect(() => {
    if (userStore.users.length === 0) userStore.getAllUsers();
  }, [userStore]);

  return (
    <div>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      {isAdmin ? (
        <div className="p-4">
          <Typography
            sx={{ fontWeight: 'medium' }}
            mt={2}
            variant="h3"
            color={'#003375'}
          >
            Δημιουργία νέου προγράμματος ραντεβού
          </Typography>
          <Typography
            sx={{ fontWeight: 'medium' }}
            mt={2}
            variant="h4"
            gutterBottom
          >
            Για την{' '}
            <span style={{ fontWeight: 'bold' }}>
              δημιουργία του προγράμματος
            </span>{' '}
            επιλέξτε:
          </Typography>

          {userStore.users?.length > 0 &&
            subDepartentStore.subDepartmentsList.length > 0 && (
              <div className="w-full">
                <ScheduleForm />
              </div>
            )}
        </div>
      ) : (
        <NoAccessCard />
      )}
    </div>
  );
};

export default observer(CreateSchedule);
