import { observer } from 'mobx-react';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Autocomplete, Grid, Stack, TextField, Button } from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import { useState, useEffect, useRef } from 'react';
import Accordions from '../../../Components/Accordion/Accordion';
import { format, addDays } from 'date-fns';
import MyDatePicker from '../../../Components/MyDatePicker/MyDatePicker';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import CertificatesSearchTable from './CertificatesSearchTable';
import { statusTranslations } from '../../../Config/constant';
import { width } from '../../../styles/variables/width';

const CertificatesSearchForm = () => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [minDateForDateTo, setMinDateForDateTo] = useState(null);
  const [, setSelectedDateFrom] = useState(null);
  const [formIsSubmitted, setFormSubmited] = useState(false);
  const [tableIsVisible, setTableIsVisible] = useState(false);
  const tableRef = useRef(null);
  const departmentStore = useDepartmentStore();
  const authStore = useAuthStore();
  const certificatesStore = useCertificatesStore();
  const appointmentServiceStore = useAppointmentServiceStore();

  const departmentId = authStore.authUser.departmentId;

  const handleDateFromChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
    setDates([date, dates[1]]);
    setValue(
      'days',
      [formattedDate, dates[1] ? format(dates[1], 'yyyy-MM-dd') : ''],
      {
        shouldValidate: true,
      }
    );

    setSelectedDateFrom(date);

    const minDateForDateTo = date ? addDays(date, 0) : null;
    setMinDateForDateTo(minDateForDateTo);
  };

  const handleDateToChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
    setDates([dates[0], date]);
    setValue(
      'days',
      [dates[0] ? format(dates[0], 'yyyy-MM-dd') : '', formattedDate],
      {
        shouldValidate: true,
      }
    );
  };

  const scrollPageToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const certificatesResultsSchema = yup.object().shape({
    Id: yup.string().notRequired(),
    ProtocolNo: yup.string().trim().strict(true).notRequired(),
    days: yup.array().of(yup.string()).notRequired(),
    ApplicationCategoryId: yup.object().notRequired(),
    Status: yup.object().notRequired(),
    CitizenLastName: yup.string().trim().strict(true).notRequired(),
    CitizenAfm: yup.string().trim().strict(true).notRequired(),
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      Id: '',
      ProtocolNo: '',
      days: [],
      ApplicationCategoryId: {},
      Status: {},
      CitizenLastName: '',
      CitizenAfm: '',
    },
    resolver: yupResolver(certificatesResultsSchema),
  });

  const submitCertificatesSearchFilters = (data) => {
    setLoading(true);

    const payload = {
      departmentId: departmentId || '',
      Id: data.Id || '',
      ProtocolNo: data.ProtocolNo || '',
      FinalizedFrom: data.days[0]?.slice(0, 10) || '',
      FinalizedTo: data.days[1]?.slice(0, 10) || '',
      ApplicationCategoryId: data.ApplicationCategoryId?.id || '',
      Status: data.Status?.value?.toString() || '',
      CitizenLastName: data.CitizenLastName || '',
      CitizenAfm: data.CitizenAfm || '',
    };

    certificatesStore
      .getCertificatesSubmission(payload)
      .then((resp) => {
        setFormSubmited(true);
        setTableIsVisible(true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          scrollPageToTable();
        }, 100);
      });
  };

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  useEffect(() => {
    appointmentServiceStore.getApplicationCategories(
      authStore.authUser?.organizationId
    );
  }, [appointmentServiceStore.applicationCategories?.length]);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [certificatesStore.certificatesSubmissionStatusesList?.length]);

  return (
    <>
      <form onSubmit={handleSubmit(submitCertificatesSearchFilters)}>
        <Accordions text={'Φίλτρα'} className={'mb-4'}>
          <div className="grid-cols-3 ml-6">
            <Grid container spacing={2}>
              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="Id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="number"
                      className="mt-14 bg-[#FFFF]"
                      fullWidth
                      label={'Αριθμός αιτήματος'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="ProtocolNo"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="text"
                      className="mt-14 bg-[#FFFF]"
                      fullWidth
                      label={'Αριθμός πρωτοκόλλου'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div className="grid-cols-3 ml-6">
            <Grid className="pt-8" container spacing={2}>
              <Controller
                name="days"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <>
                    <Grid item lg={4} md={12} sm={12}>
                      <MyDatePicker
                        value={dates[0] && new Date(dates[0])}
                        onChange={handleDateFromChange}
                        placeholder="Ημερομηνία Καταχώρησης"
                      />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12}>
                      <MyDatePicker
                        value={dates[1] && new Date(dates[1])}
                        onChange={handleDateToChange}
                        minDate={minDateForDateTo}
                        placeholder="Ημερομηνία Ολοκλήρωσης"
                      />
                    </Grid>
                  </>
                )}
              />
            </Grid>
          </div>

          <div className="grid-cols-3 ml-6">
            <Grid className="pt-8" container spacing={2}>
              <Grid item lg={4} md={12} sm={12}>
                {appointmentServiceStore.applicationCategories?.length ? (
                  <Grid container>
                    <Controller
                      name="ApplicationCategoryId"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          className="w-full mt-2"
                          onChange={(e, value) => onChange(value)}
                          value={value}
                          options={
                            appointmentServiceStore.applicationCategories
                          }
                          getOptionLabel={(option) => option?.title || ''}
                          isOptionEqualToValue={(option, id) =>
                            option.id === id.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Τύπος Πιστοποιητικού'}
                              className="bg-[#FFFF]"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  []
                )}
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                {certificatesStore.certificatesSubmissionStatusesList
                  ?.length ? (
                  <Grid container>
                    <Controller
                      name="Status"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          className="w-full mt-2"
                          onChange={(e, value) => onChange(value)}
                          value={value}
                          options={
                            certificatesStore.certificatesSubmissionStatusesList
                          }
                          getOptionLabel={(option) =>
                            statusTranslations[option?.value] || ''
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.value === value?.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={'Κατάσταση'}
                              className="bg-[#FFFF]"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </div>

          <div className="grid-cols-3 ml-6">
            <Grid className="pt-8" container spacing={2}>
              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="CitizenAfm"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="text"
                      className="mt-14 bg-[#FFFF]"
                      fullWidth
                      label={'ΑΦΜ'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="CitizenLastName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="text"
                      className="mt-14 bg-[#FFFF]"
                      fullWidth
                      label={'Επώνυμο Πολίτη'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid container spacing={3} className="pl-[28%] pt-6">
                <Grid item sm={12} className="flex justify-center">
                  <Stack direction="row">
                    <AnimateButton>
                      <Button
                        sx={{
                          backgroundColor: '#003375',
                          color: '#f9f9f9',
                          textTransform: 'none',
                          width: width.button.md,
                          '&:hover': {
                            backgroundColor: '#003375',
                            color: '#f9f9f9',
                          },
                          fontSize: '1rem',
                        }}
                        className="capitalize text-sm"
                        loading={loading}
                        variant="contained"
                        type="submit"
                      >
                        Εύρεση
                      </Button>
                    </AnimateButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Accordions>
      </form>

      {formIsSubmitted && tableIsVisible && (
        <div ref={tableRef}>
          <CertificatesSearchTable />
        </div>
      )}
    </>
  );
};

export default observer(CertificatesSearchForm);
