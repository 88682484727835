import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { useState } from 'react';
import { useEffect } from 'react';
import { t } from 'i18next';
import { useAuthStore } from '../../../Stores/AuthStore';
import { error, success } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { formatDateInDateMonthYear } from '../../../Common/formatDate/formatDate';
import Accordions from '../../../Components/Accordion/Accordion';

const RantevouViewEditForm = ({
  rantevouData,
  returnToUrl = '/rantevou-search',
}) => {
  const [loading, setLoading] = useState(false);
  const [formEnabled, setFormEnabled] = useState(false);
  const rantevouStore = useRantevouStore();
  const authStore = useAuthStore();
  const subDepartmentStore = useSubDepartmentStore();
  const useStore = useAuthStore();
  const { isAdmin } = useStore;
  const [isRantevouClaimed, setIsRantevouClaimed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  useEffect(() => {
    if (
      rantevouStore?.rantevouStatusesList.find(
        (status) => rantevouData?.status === status.value
      )?.description === 'Ολοκληρωμένο'
    ) {
      setIsRantevouClaimed(true);
    }
  }, [rantevouData?.status]);

  useEffect(() => {
    isRantevouClaimed
      ? setValue(
          'status',
          rantevouStore?.rantevouStatusesList.find(
            (item) => item.description === 'Ολοκληρωμένο'
          )
        )
      : setValue(
          'status',
          rantevouStore?.rantevouStatusesList.find(
            (status) => rantevouData?.status === status.value
          )
        );
  }, [isRantevouClaimed]);

  useEffect(() => {
    initializeFormData();
  }, []);

  const enableForm = () => {
    setFormEnabled(true);
  };

  const rantevouSchema = yup.object().shape({
    status: yup.object().notRequired(),
    comments: yup.string().trim().strict(true).notRequired(),
    employeeId: yup.string().notRequired(),
  });

  const initializeFormData = () => {
    setValue('comments', rantevouData.comments);

    const statusValue = rantevouData?.status;
    const selectedStatus = rantevouStore.rantevouStatusesList?.find(
      (option) => option.value === statusValue
    );

    setValue('status', selectedStatus);
  };

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      employeeId: rantevouData?.employeeId || null,
      status: rantevouData?.status || {},
      comments: rantevouData?.comments || '',
    },
    resolver: yupResolver(rantevouSchema),
  });

  const updateExistingRantevou = (data) => {
    setLoading(true);

    const payload = {
      comments: data?.comments,
      status: data.status?.value,
      employeeId: data.employeeId,
    };
    rantevouStore
      .updateRantevou(rantevouData.id, payload)
      .then((resp) => {
        if (resp?.success) {
          success('Το ραντεβού ανανεώθηκε επιτυχώς');
          navigate('/rantevou-overview');
        } else {
          error('Κάτι πήγε στραβά κατά την αννανέωση του ραντεβού');
        }
        setFormEnabled(false);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  let extraFields = [];

  try {
    extraFields = JSON.parse(rantevouData?.extraFields || '[]');
  } catch (error) {}

  return (
    <div className="pb-5 relative">
      <div>
        <form onSubmit={handleSubmit(updateExistingRantevou)}>
          <Grid container className="p-2 py-4 ml-4">
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
              variant="h4"
              gutterBottom
            >
              ΚΩΔΙΚΟΣ ΡΑΝΤΕΒΟΥ: {rantevouData?.id}
            </Typography>
          </Grid>
          <div>
            <Grid className="p-2">
              <Accordions text={'ΣΤΟΙΧΕΙΑ ΡΑΝΤΕΒΟΥ'}>
                <Typography variant="h5" gutterBottom>
                  ● ΥΠΟΤΜΗΜΑ {rantevouData?.subDepartment}
                </Typography>

                <Typography className="pt-1" variant="h5" gutterBottom>
                  ● ΥΠΗΡΕΣΙΑ: {rantevouData?.appointmentService}
                </Typography>

                <Typography className="pt-1" variant="h5" gutterBottom>
                  ● ΗΜΕΡΟΜΗΝΙΑ:{' '}
                  {formatDateInDateMonthYear(
                    rantevouData?.timeFrom.slice(0, 10)
                  )}
                </Typography>

                <Typography className="pt-1" variant="h5" gutterBottom>
                  ● ΩΡΑ:{' '}
                  {`${rantevouData?.timeFrom.slice(
                    11,
                    16
                  )} - ${rantevouData?.timeTo.slice(11, 16)}`}
                </Typography>

                <Typography className="pt-1" variant="h5" gutterBottom>
                  ● ΔΙΕΥΘΥΝΣΗ: {rantevouData?.sectorAddress}
                </Typography>
              </Accordions>
            </Grid>

            {rantevouData?.citizenComments && (
              <div className="flex pl-6 pt-2">
                <Typography
                  sx={{
                    fontWeight: 'bold',
                  }}
                  variant="h4"
                  gutterBottom
                >
                  ΣΧΟΛΙΑ ΠΟΛΙΤΗ:
                </Typography>
                <Typography className="pl-2" variant="h5" gutterBottom>
                  {rantevouData?.citizenComments}
                </Typography>
              </div>
            )}

            {extraFields?.length ? (
              <Grid className="p-2 py-4">
                <Accordions text={'ΕΠΙΠΛΕΟΝ ΣΤΟΙΧΕΙΑ'}>
                  {extraFields?.map((info, index) => (
                    <Typography
                      key={index}
                      className="pt-2"
                      variant="h5"
                      gutterBottom
                    >
                      ● {info?.Name} * :{info?.Value}
                    </Typography>
                  ))}
                </Accordions>
              </Grid>
            ) : null}

            <Grid className="p-2">
              <Accordions text={'ΣΤΟΙΧΕΙΑ ΠΟΛΙΤΗ'}>
                <Typography variant="h5" gutterBottom>
                  ● ΟΝΟΜΑ & ΕΠΩΝΥΜΟ:{' '}
                  {rantevouData?.name + ' ' + rantevouData?.surName}
                </Typography>

                <Typography variant="h5" gutterBottom>
                  ● ΠΑΤΡΩΝΥΜΟ: {rantevouData?.fatherName}
                </Typography>

                <Typography variant="h5" gutterBottom>
                  ● ΜΗΤΡΩΝΥΜΟ: {rantevouData.motherName}
                </Typography>

                <Typography variant="h5" gutterBottom>
                  ● ΑΦΜ: {rantevouData.afm}
                </Typography>

                <Typography variant="h5" gutterBottom>
                  ● ΤΗΛ: {rantevouData.phoneNumber}
                </Typography>

                <Typography variant="h5" gutterBottom>
                  ● EMAIL: {rantevouData.email}
                </Typography>
              </Accordions>
            </Grid>
          </div>

          <Grid container className="p-2 py-6">
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
              variant="h4"
              gutterBottom
            >
              ΚΑΤΑΣΤΑΣΗ
            </Typography>
            <Grid item md={12} xs={12}>
              <Controller
                name="status"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    disabled={!formEnabled || isRantevouClaimed}
                    onChange={(e, value) => onChange(value)}
                    value={value}
                    options={
                      isRantevouClaimed
                        ? rantevouStore?.rantevouStatusesList.filter(
                            (item) => item.description === 'Ολοκληρωμένο'
                          )
                        : rantevouStore?.rantevouStatusesList.filter(
                            (item) =>
                              item.description !== 'Ολοκληρωμένο' &&
                              item.description !== 'Διαθέσιμο' &&
                              (!isAdmin ? item.description !== 'Ακύρωση' : true)
                          )
                    }
                    getOptionLabel={(option) => option?.description || ''}
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value?.value
                    }
                    getOptionDisabled={(option) => {
                      return !isRantevouClaimed
                        ? option?.description === 'Ολοκληρωμένο'
                        : false;
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={!formEnabled}
                        placeholder="Κατάσταση"
                        {...params}
                        error={!!error}
                        helperText={error ? t(error.message, { min: 1 }) : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container className="p-2 py-4">
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
              variant="h4"
              gutterBottom
            >
              ΣΧΟΛΙΑ ΦΟΡΕΑ
            </Typography>

            <Grid item md={14} xs={12}>
              <Controller
                name="comments"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    defaultValue={rantevouData.comments}
                    disabled={!formEnabled}
                    placeholder="Σχόλια Φορέα"
                    fullWidth
                    multiline
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? t(error.message) : null}
                  />
                )}
              />
            </Grid>
          </Grid>

          {authStore.isAdmin ? null : (
            <Grid container className="p-2 py-4">
              <Grid item md={14} xs={12}>
                <Controller
                  name="employeeId"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControlLabel
                      label={'Το αναλαμβάνω'}
                      control={
                        <Checkbox
                          label={'Το αναλαμβάνω'}
                          disabled={!formEnabled}
                          checked={!!value ?? false}
                          onChange={(e) => {
                            setIsRantevouClaimed(e.target.checked);
                            onChange(
                              e.target.checked ? authStore.authUser.id : null
                            );
                          }}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </form>

        <div className="flex justify-end items-end my-6">
          <Grid item className="flex justify-end items-end pr-6">
            <AnimateButton>
              <SubmitButton
                sx={{ backgroundColor: '#003375', marginRight: '10px' }}
                title="Επιστροφη"
                onClick={() => navigate(returnToUrl)}
                variant="contained"
                type="button"
                className="capitalize text-sm"
              />
            </AnimateButton>
            {!formEnabled ? (
              rantevouData?.status !== 2 &&
              rantevouData?.status !== 4 &&
              rantevouData?.status !== 3 && (
                <AnimateButton>
                  <SubmitButton
                    onClick={enableForm}
                    sx={{ backgroundColor: '#003375' }}
                    className="capitalize text-sm"
                    title={'Επεξεργασια'}
                    loading={loading}
                    variant="contained"
                  />
                </AnimateButton>
              )
            ) : (
              <AnimateButton>
                <SubmitButton
                  sx={{ backgroundColor: '#003375' }}
                  className="capitalize text-sm"
                  title={'Αποθηκευση'}
                  loading={loading}
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit(updateExistingRantevou)}
                />
              </AnimateButton>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

RantevouViewEditForm.propTypes = {
  rantevouData: PropTypes.object,
};

export default observer(RantevouViewEditForm);
