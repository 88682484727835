import { useEffect, useState } from 'react';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { gridSpacing } from '../../../Config/constant';
import { useTranslation } from 'react-i18next';
import Link from '../../../Components/Theme/Link/Link';
import { useNavigate, useParams } from 'react-router-dom';
import UserForm from '../../UserForm/UserForm';
import { useUserStore } from '../../../Stores/UserStore';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import { observer } from 'mobx-react';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import Topbar from '../../../Components/Topbar/Topbar';

const UsersEdit = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams();
  const [user, setUser] = useState(null);
  const userStore = useUserStore();
  const subDepartmentStore = useSubDepartmentStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (subDepartmentStore.subDepartmentsList.length === 0) {
      setLoading(true);
      subDepartmentStore.getSubDepartments();
    }
    if (subDepartmentStore.subDepartmentsList.length > 0) {
      setLoading(false);
    }
  }, [subDepartmentStore.subDepartmentsList, subDepartmentStore]);

  useEffect(() => {
    userStore
      .getUserById(userId)
      .then((response) => {
        setUser(response?.user);
      })
      .catch(() => {
        navigate('/users');
      });
  }, [setUser, userStore, userId]);

  return loading ? null : (
    <>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <MainCard
        className="mt-5"
        title={
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={gridSpacing}
          >
            <Grid item className="flex justify-between items-center w-full">
              <PageTitle title={'Επεξεργασία Χρήστη'} />
              <Link className="ml-2 mb-2" to="/users">
                Επιστροφή
              </Link>
            </Grid>
          </Grid>
        }
        content={false}
      >
        {user ? (
          <MainCard className="bg-[#F9F9F9]">
            <UserForm isEditMode={true} userData={user} />
          </MainCard>
        ) : (
          <div className="text-center py-10">
            <CircularProgress
              sx={{
                color: '#003375',
              }}
              size="3rem"
            />
          </div>
        )}
      </MainCard>
    </>
  );
};

export default observer(UsersEdit);
