import React from 'react';

const CheckboxComponent = ({ value, onChange, label, disabled }) => {
  // const handleChange = (event) => {
  //   onChange(event.target.checked ? 'true' : 'false');
  // };

  return (
    <div class="govgr-checkboxes__item">
      <label class="govgr-label text-base govgr-checkboxes__label">
        {label}
        <input
          className="govgr-checkboxes__input"
          type="checkbox"
          // value={value}
          // checked={value === 'true'}
          disabled={disabled}
          // onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default CheckboxComponent;
