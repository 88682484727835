import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { success, error } from '../../../Common/Toast/Toast';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Stack,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Button,
} from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import Accordions from '../../../Components/Accordion/Accordion';

const OrganizationDefinitionForm = () => {
  const organizationStore = useOrganizationsStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [selectedOrganizationType, setSelectedOrganizationType] =
    useState(null);
  const [selectedRegionWhenType1, setSelectedRegionWhenType1] = useState(null);
  const [selectedRegionWhenType2, setSelectedRegionWhenType2] = useState(null);
  const [selectedRegionUnit, setSelectedRegionUnit] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);

  const organizationSchema = yup.object().shape({
    description: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    address: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    tel: yup
      .string()
      .trim()
      .min(10, 'Το τηλέφωνο πρέπει να έχει τουλάχιστον 10 χαρακτήρες')
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    hasERantevou: yup
      .boolean()
      .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
      .required('Το πεδίο ειναι υποχρεωτικό'),
    hasECertificates: yup
      .boolean()
      .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
      .required('Το πεδίο ειναι υποχρεωτικό'),
    hasERequests: yup
      .boolean()
      .oneOf([true, false], 'Το πεδίο είναι υποχρεωτικό')
      .required('Το πεδίο ειναι υποχρεωτικό'),
    organizationType: yup.object().required('Το πεδίο ειναι υποχρεωτικό'),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      description: '',
      address: '',
      tel: '',
      hasERantevou: null,
      hasECertificates: null,
      hasERequests: null,
      organizationType: null,
    },
    resolver: yupResolver(organizationSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    const payload = {
      name:
        selectedOrganizationType === 1
          ? selectedRegionWhenType2?.name
          : selectedMunicipality?.name,
      description: data?.description,
      address: data?.address,
      tel: data?.tel,
      hasERantevou: data?.hasERantevou,
      hasECertificates: data?.hasECertificates,
      hasERequests: data?.hasERequests,
      organizationType: data?.organizationType?.organizationType,
    };
    organizationStore
      .createNewOrganization(payload)
      .then(() => {
        success('Ο οργανισμός δημιουργήθηκε με επιτυχία');
        navigate('/org-list');
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    organizationStore.getRegions();
  }, [organizationStore?.regionsList?.length]);

  useEffect(() => {
    organizationStore.getRegionUnits();
  }, [organizationStore?.regionUnitsList?.length]);

  useEffect(() => {
    organizationStore.getMunicipalitys();
  }, [organizationStore?.municipalitysList?.length]);

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={3}
            className="p-2 text-center grid grid-cols-3"
          >
            <Grid item xs={2}>
              <Typography variant="h4" color={'#000000'} gutterBottom>
                Περιγραφή Οργανισμού
              </Typography>
              <div className="mt-4 ml-4">
                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="text"
                      fullWidth
                      label={'Περιγραφή'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="pl-5">
                <Typography variant="h4" color={'#000000'} gutterBottom>
                  Διεύθυνση Οργανισμού
                </Typography>
                <div className="mt-4 ml-1">
                  <Controller
                    name="address"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        fullWidth
                        label={'Διεύθυνση'}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="pl-5">
                <Typography variant="h4" color={'#000000'} gutterBottom>
                  Τηλέφωνο Οργανισμού
                </Typography>
                <div className="mt-4 ml-3">
                  <Controller
                    name="tel"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        type="text"
                        fullWidth
                        label={'Τηλέφωνο'}
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            className="p-2 pl-6 pt-8 grid grid-cols-3"
          >
            <Grid item xs={2}>
              <div className="mt-3">
                <Typography variant="h4" color={'#000000'} gutterBottom>
                  Εφαρμογή Ραντεβού
                </Typography>
                <Controller
                  name="hasERantevou"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === true}
                            onChange={() => onChange(true)}
                          />
                        }
                        label="Ναι"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === false}
                            onChange={() => onChange(false)}
                          />
                        }
                        label="Όχι"
                      />
                      {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="mt-3">
                <Typography variant="h4" color={'#000000'} gutterBottom>
                  Εφαρμογή Πιστοποιητικών
                </Typography>
                <Controller
                  name="hasECertificates"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === true}
                            onChange={() => onChange(true)}
                          />
                        }
                        label="Ναι"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === false}
                            onChange={() => onChange(false)}
                          />
                        }
                        label="Όχι"
                      />
                      {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className="mt-3 ml-6">
                <Typography variant="h4" color={'#000000'} gutterBottom>
                  Εφαρμογή Αιτημάτων
                </Typography>
                <Controller
                  name="hasERequests"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === true}
                            onChange={() => onChange(true)}
                          />
                        }
                        label="Ναι"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            checked={value === false}
                            onChange={() => onChange(false)}
                          />
                        }
                        label="Όχι"
                      />
                      {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="p-2 grid grid-cols-3">
            <Grid item xs={4}>
              <Accordions
                textClassName="text-[#000000]"
                textSuperAdminPages={'Επιλογή κατηγορίας οργανισμού'}
              >
                <div>
                  <Controller
                    name="organizationType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        key={selectedOrganizationType}
                        onChange={(e, value) => {
                          onChange(value);
                          setSelectedOrganizationType(value?.organizationType);
                          setSelectedRegionWhenType1(null);
                          setSelectedRegionUnit(null);
                          setSelectedRegionWhenType2(null);
                          setSelectedMunicipality(null);
                        }}
                        value={value}
                        options={organizationStore.organizationTypes}
                        getOptionLabel={(option) => option?.description || ''}
                        isOptionEqualToValue={(option, organizationType) =>
                          option?.organizationType ===
                          organizationType?.organizationType
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-[#FFFF]"
                            label={'Επιλογή κατηγορίας'}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </Accordions>
            </Grid>

            {selectedOrganizationType === 0 && (
              <Grid item xs={4}>
                <Accordions
                  textClassName="text-[#000000]"
                  textSuperAdminPages={'Επιλογή Περιφέρειας'}
                >
                  <div>
                    <Controller
                      name="selectedRegion"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={organizationStore.regionsList}
                          getOptionLabel={(option) => option?.name || ''}
                          isOptionEqualToValue={(option, name) =>
                            option?.name === name?.name
                          }
                          onChange={(e, value) => {
                            setSelectedRegionWhenType1(value);
                            onChange(value);
                            setSelectedRegionUnit(null);
                            setIsSubmitDisabled(!value);
                          }}
                          value={selectedRegionWhenType1}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="bg-[#FFFF]"
                              label={'Επιλογή Περιφέρειας'}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </Accordions>
              </Grid>
            )}

            {selectedRegionWhenType1 && (
              <Grid item xs={4}>
                <Accordions
                  textClassName="text-[#000000]"
                  textSuperAdminPages={'Επιλογή Περιφερειακής Ενότητας'}
                >
                  <div>
                    <Controller
                      name="selectedRegionUnit"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={organizationStore.regionUnitsList?.filter(
                            (unit) =>
                              unit?.regionId === selectedRegionWhenType1?.id
                          )}
                          getOptionLabel={(option) => option?.name || ''}
                          isOptionEqualToValue={(option, name) =>
                            option?.name === name?.name
                          }
                          onChange={(e, value) => {
                            setSelectedRegionUnit(value);
                            onChange(value);
                            setIsSubmitDisabled(!value);
                          }}
                          value={selectedRegionUnit}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="bg-[#FFFF]"
                              label={'Επιλογή Περιφερειακής Ενότητας'}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </Accordions>
              </Grid>
            )}

            {selectedRegionUnit && (
              <Grid item xs={4}>
                <Accordions
                  textClassName="text-[#000000]"
                  textSuperAdminPages={'Επιλογή Δήμου'}
                >
                  <div>
                    <Controller
                      name="selectedMunicipality"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={organizationStore.municipalitysList?.filter(
                            (municipality) =>
                              municipality?.regionUnitId ===
                              selectedRegionUnit?.id
                          )}
                          getOptionLabel={(option) => option?.name || ''}
                          isOptionEqualToValue={(option, name) =>
                            option?.name === name?.name
                          }
                          onChange={(e, value) => {
                            setSelectedMunicipality(value);
                            onChange(value);
                            setIsSubmitDisabled(!value);
                          }}
                          value={selectedMunicipality}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="bg-[#FFFF]"
                              label={'Επιλογή Δήμου'}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </Accordions>
              </Grid>
            )}

            {selectedOrganizationType === 1 && (
              <Grid item xs={4}>
                <Accordions
                  textClassName="text-[#000000]"
                  textSuperAdminPages={'Επιλογή Περιφέρειας (βάσει Καλλικράτη)'}
                >
                  <div>
                    <Controller
                      name="selectedRegion"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={organizationStore.regionsList}
                          getOptionLabel={(option) => option?.name || ''}
                          isOptionEqualToValue={(option, name) =>
                            option?.name === name?.name
                          }
                          onChange={(e, value) => {
                            setSelectedRegionWhenType2(value);
                            onChange(value);
                            setIsSubmitDisabled(!value);
                          }}
                          value={selectedRegionWhenType2}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className="bg-[#FFFF]"
                              label={'Επιλογή Περιφέρειας'}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </Accordions>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3} className="pt-[1%]">
            <Grid item sm={12} className="flex justify-end">
              <Stack direction="row" justifyContent="space-between">
                <div className="mr-4">
                  <AnimateButton>
                    <SubmitButton
                      sx={{
                        backgroundColor: '#184682',
                        color: '#f9f9f9',
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: '#184682',
                          color: '#f9f9f9',
                        },
                        fontSize: '1rem',
                      }}
                      variant="contained"
                      loading={loading}
                      type="submit"
                      title="Επιστροφή"
                      onClick={() => navigate(-1)}
                    />
                  </AnimateButton>
                </div>
                <AnimateButton>
                  <SubmitButton
                    sx={{
                      backgroundColor: '#184682',
                      color: '#f9f9f9',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#184682',
                        color: '#f9f9f9',
                      },
                      fontSize: '1rem',
                    }}
                    loading={loading}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitDisabled}
                    title="Δημιουργία"
                  />
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default observer(OrganizationDefinitionForm);
