import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import CreateCertificateForm from "../CreateCertificateForm/CreateCertificateForm";
import { useCertificatesStore } from "../../../Stores/CertificatesStore";
import { useAppointmentServiceStore } from "../../../Stores/AppointmentServiceStore";
import { useAuthStore } from "../../../Stores/AuthStore";
import { useSubDepartmentStore } from "../../../Stores/SubDepartmentStore";

const CertificateViewEdit = () => {
  const appointmentServiceStore = useAppointmentServiceStore();
  const authStore = useAuthStore();
  const subDepartmentsStore = useSubDepartmentStore();
  const certificatesStore = useCertificatesStore();
  const [certificate, setCertificate] = useState(null);
  const { certificateId } = useParams();

  useEffect(() => {
    appointmentServiceStore.getApplicationCategories(
      authStore.authUser?.organizationId
    );
  }, [appointmentServiceStore.applicationCategories?.length]);

  useEffect(() => {
    subDepartmentsStore.getSubDepartments();
  }, [subDepartmentsStore.subDepartmentsList?.length]);

  useEffect(() => {
    certificatesStore.getAvailableCertificates();
  }, [certificatesStore.availableCertificatesList?.length]);

  useEffect(() => {
    certificatesStore
      .getCertificatesById(certificateId)
      .then((response) => {
        setCertificate(response.result);
      })
      .catch(() => { });
  }, [setCertificate, certificatesStore, certificateId]);

  return (
    <>
      <div className="mt-6 ml-4">
        {certificate ? (
          <CreateCertificateForm
            isEditMode={true}
            certificateData={certificate}
          />
        ) : (
          <div className="text-center py-10">
            <CircularProgress sx={{
              color: '#003375',
            }} size="3rem" />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(CertificateViewEdit);
