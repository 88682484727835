import Topbar from "../../../Components/Topbar/Topbar";
import { observer } from "mobx-react";
import Calendar from "../../../Components/Calendar/Calendar";
import { Typography } from "@mui/material";
import { useRantevouStore } from "../../../Stores/RantevouStore";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toJS } from "mobx";
import { ORG_NAME } from "../../../Config/constant";

const Rantevou = () => {
  const rantevouStore = useRantevouStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(rantevouStore.rantevouScheduleData).length === 0) {
      navigate("/new-schedule");
    }
  }, [rantevouStore.rantevouScheduleData]);

  return (
    <>
      <div>
        {" "}
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
      </div>
      <div className="mt-4 ml-4">
        <Typography variant="h3" color={"#003375"} gutterBottom>
          Δημιουργία νέου προγράμματος ραντεβού
        </Typography>
      </div>
      <div className="flex justify-center items-center mt-2">
        <Calendar
          newlyCreated
          subDepartmentId={rantevouStore.rantevouScheduleData?.subDepartmentId}
        />
      </div>
    </>
  );
};

export default observer(Rantevou);
