import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MapGoogle from '../../../Components/GoogleMap/GoogleMap';
import ImageUploader from '../../../Components/ImageUploader/ImageUploader';
import { Alert, TextField } from '@mui/material';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import Select from '../../../Components/Select/Select';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import { width } from '../../../styles/variables/width';
import { emailRegex, phoneRegex } from '../../../Common/Regex';

const REDIRECT_TIMEOUT_INTERVAL = 1000;

const TicketCreate = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [mapData, setMapData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);
  const [images, setImages] = useState([]);
  const [notes, setNotes] = useState('');
  const [loadDataError, setLoadDataError] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const categoryError = !selectedCategory;
  const serviceError =
    selectedCategory && services.length > 0 && !selectedService;
  const submitDisabled =
    categoryError || serviceError || !markerPosition || positionError;

  const handleCategoryChange = useCallback(
    (newValue) => {
      setSelectedCategory(newValue);
      setSelectedService('');

      const servicesOfCategory =
        categories?.find((item) => newValue?.value === item?.value)
          ?.ticketServices || [];
      setServices(
        servicesOfCategory?.map((service) => ({
          label: service?.title,
          value: service?.id,
        }))
      );
    },
    [categories]
  );

  const handleProblemChange = useCallback((newValue) => {
    setSelectedService(newValue);
  }, []);

  const handleTextInput = useCallback((e) => {
    setNotes(e.target.value);
  }, []);

  const handleEmailInput = useCallback((e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    if (inputValue?.length > 0) {
      setValidEmail(emailRegex.test(inputValue));
    } else {
      setValidEmail(true);
    }
  }, []);

  const handlePhoneNumberInput = useCallback((e) => {
    const inputValue = e.target.value;
    setPhoneNumber(inputValue);
    if (inputValue?.length > 0) {
      setValidPhoneNumber(phoneRegex.test(inputValue));
    } else {
      setValidPhoneNumber(true);
    }
  }, []);

  const onSubmit = useCallback(async () => {
    setLoadingSubmit(true);

    try {
      const response = await AuthSetAgent.tickets.createTicket({
        externalEmail: email,
        externalPhoneNumber: phoneNumber,
        ticketCategoryId: selectedCategory && selectedCategory?.value,
        ticketServiceId: selectedService && selectedService?.value,
        lat: markerPosition?.lat,
        lon: markerPosition?.lng,
        formattedAddress: address,
        notes,
      });

      if (response?.success) {
        success('Το αίτημά σας υποβλήθηκε επιτυχώς!');

        const backendResponse = response?.result;
        const parts = backendResponse?.split(',');
        const ticketId = parts[0];

        if (ticketId && response?.success) {
          await AuthSetAgent.tickets.uploadTicketFile({
            ticketId,
            files: images,
          });

          setTimeout(() => {
            navigate('/tickets-overview');
          }, REDIRECT_TIMEOUT_INTERVAL);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingSubmit(false);
    }
  }, [
    selectedCategory,
    selectedService,
    markerPosition?.lat,
    markerPosition?.lng,
    address,
    notes,
    images,
    email,
    phoneNumber,
    navigate,
  ]);

  useEffect(() => {
    const getData = async () => {
      await AuthSetAgent.tickets
        .getTicketCategoriesAndMap()
        .then(async (response) => {
          // set category ans services data
          //
          if (Array.isArray(response?.result?.ticketCategories)) {
            setCategories(
              response.result.ticketCategories.map((item) => ({
                label: item?.title,
                value: item?.id,
                ticketServices: item?.ticketServices,
              }))
            );
          }

          // set map data
          //
          if (response?.result?.geoJsonMap) {
            const polygon = await JSON.parse(response.result.geoJsonMap);
            setMapData(polygon?.coordinates);
          }
        })
        .catch(() => {
          setLoadDataError(true);
        });
    };

    getData();
  }, []);

  // Remove map data on unmount
  //
  useEffect(() => {
    return () => setMapData(null);
  }, []);

  return (
    <div>
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <div className="ml-4">
        <Typography
          className="py-5"
          variant="h3"
          color={'#003375'}
          gutterBottom
        >
          Καταχώρηση Αιτήματος
        </Typography>
      </div>

      <div className="flex flex-col lg:flex-row w-full mb-20">
        <div className="w-full lg:w-2/3 md:ml-40 sm:ml-20 ml-10">
          <div className="flex justify-between items-center">
            <h2 className="text-[1.3rem] font-bold mt-6 mb-6">
              Επιλογή Κατηγορίας / Υπηρεσία
            </h2>
            <span className="font-bold text-red-500">* Υποχρεωτικό πεδίο</span>
          </div>

          <p className="text-[1.1rem]">
            Επιλέξτε την <b>Κατηγορία</b> του αιτήματός σας και την{' '}
            <b>Υπηρεσία / Πρόβλημα</b> που θέλετε να δηλώσετε.
          </p>

          <div className={'mt-4 flex'}>
            <div className="grow">
              <Select
                label="Επιλέξτε Κατηγορία"
                value={selectedCategory}
                options={categories}
                onChange={handleCategoryChange}
                containerClass="w-[360px]"
              />
            </div>

            <div className="grow">
              <Select
                label="Επιλέξτε Υπηρεσία / Πρόβλημα"
                value={selectedService}
                options={services}
                onChange={handleProblemChange}
                containerClass="w-[360px]"
                disabled={!services?.length}
              />
            </div>
          </div>

          <div className="mb-10">
            <div className="flex justify-between items-center">
              <h2 className="text-[1.3rem] font-bold mt-6 mb-6">
                Θέση στο χάρτη
              </h2>
              <span className="font-bold text-red-500">
                * Υποχρεωτικό πεδίο
              </span>
            </div>

            <p className="text-[1.1rem] mb-5">
              Προσδιορίστε τη <b>θέση του αιτήματός</b> σας στο χάρτη ή
              πληκτρολογήστε τη διεύθυνση στο πεδίο "Αναζήτηση διεύθυνσης στο
              χάρτη"
            </p>
            {mapData && (
              <MapGoogle
                editable
                mapData={mapData}
                setAddress={setAddress}
                markerPosition={markerPosition}
                setMarkerPosition={setMarkerPosition}
                positionError={positionError}
                setPositionError={setPositionError}
              />
            )}
          </div>

          <h2 className="text-[1.1rem] font-bold mt-6 mb-6">Φωτογραφίες</h2>

          <p className="text-[1.1rem] mb-3">
            Eπισυνάψτε φωτογραφίες σχετικά με το άιτημά σας και πατήστε{' '}
            <b>"Υποβολή"</b> για να το υποβάλλετε.
          </p>

          <ImageUploader images={images} setImages={setImages} />
          <div className="mt-12">
            <h2 className="govgr-heading-m font-bold">Σχόλια / Παρατηρήσεις</h2>
            <p className="govgr-caption-m mb-3">
              Προσθέσετε σχόλια ή παρατηρήσεις που θα βοηθήσουν να εξυπηρετηθεί
              καλύτερα το αίτημά σας.
            </p>
            <TextField
              fullWidth
              multiline
              placeholder="Προσθήκη κειμένου..."
              value={notes}
              onChange={handleTextInput}
              rows={7}
            />
          </div>

          <div className="mt-12">
            <h2 className="govgr-heading-m font-bold">Email</h2>
            <p className="govgr-caption-m mb-3">
              Προσθέσετε email σε περίπτωση που θέλετε να ενημερωθεί ο χρήστης
            </p>
            <TextField
              fullWidth
              placeholder="Προσθήκη Email..."
              value={email}
              onChange={handleEmailInput}
              error={!validEmail}
              helperText={!validEmail ? 'Μη έγκυρη διεύθυνση email' : ''}
            />
          </div>

          <div className="mt-12">
            <h2 className="govgr-heading-m font-bold">Τηλέφωνο</h2>
            <p className="govgr-caption-m mb-3">
              Προσθέσετε τηλέφωνο σε περίπτωση που θέλετε να ενημερωθεί ο
              χρήστης
            </p>
            <TextField
              fullWidth
              placeholder="Προσθήκη Τηλεφώνου..."
              value={phoneNumber}
              onChange={handlePhoneNumberInput}
              error={!validPhoneNumber}
              helperText={
                !validPhoneNumber ? 'Μη έγκυρος αριθμός τηλεφώνου' : ''
              }
            />
          </div>

          <div className="mt-15">
            {categoryError && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει κατηγορία αιτήματος!
              </Alert>
            )}
            {serviceError && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει υπηρεσία/πρόβλημα!
              </Alert>
            )}
            {!markerPosition && (
              <Alert className="w-full mb-1" severity="error">
                Δεν έχετε επιλέξει σημείο στον χάρτη!
              </Alert>
            )}
            {positionError && (
              <Alert className="w-full mb-1" severity="error">
                Έχετε επιλέξει σημείο εκτός ορίων!
              </Alert>
            )}

            <div className="flex justify-end">
              <AnimateButton>
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    width: width.button.md,
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  title="Υποβολή"
                  disabled={submitDisabled}
                  onClick={onSubmit}
                  loading={loadingSubmit}
                  variant="contained"
                  type="submit"
                />
              </AnimateButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TicketCreate);
