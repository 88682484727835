import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class SubOrganizationsStore {
  organizationsList = [];

  subOrganizationsList = [];

  organizationTypes = [
    {
      id: 0,
      description: 'Δήμος',
      value: 0,
    },
    {
      id: 1,
      description: 'Περιφέρεια',
      value: 1,
    },
    {
      id: 2,
      description: 'Ανεξάρτητος φορέας',
      value: 2,
    },
  ];

  constructor() {
    makeObservable(this, {
      organizationsList: observable,
      subOrganizationsList: observable,
      organizationTypes: observable,
      getOrganizationTypes: action,
      getAllOrganizations: action,
      getAllSubOrganizations: action,
      createNewSubOrganization: action,
      updateSubOrganization: action,
    });
  }

  getOrganizationTypes = () => {
    return new Promise((resolve, reject) => {
      try {
        const organizationTypes = this.organizationTypes;
        resolve(organizationTypes);
      } catch (error) {
        reject(error);
      }
    });
  };

  getAllOrganizations = () => {
    return AuthSetAgent.organizations
      .getOrganizations()
      .then((res) => {
        this.organizationsList = res.result.items;
        return res;
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  getAllSubOrganizations = () => {
    return AuthSetAgent.subOrganizations
      .getSubOrganizations()
      .then((res) => {
        this.subOrganizationsList = res.result.items;
        return res;
      })
      .catch((err) => {
        console.error('err', err);
      });
  };

  createNewSubOrganization = (body) => {
    return AuthSetAgent.subOrganizations.createNewSubOrganization(body);
  };

  updateSubOrganization = (subOrgId, body) => {
    return AuthSetAgent.subOrganizations.updateSubOrganization(subOrgId, body);
  };
}

export const subOrganizationsStore = new SubOrganizationsStore();
export const SubOrganizationsStoreContext = createContext(
  subOrganizationsStore
);
export const SubOrganizationsStoreProvider = ({ children, store }) => (
  <SubOrganizationsStoreContext.Provider value={store}>
    {children}
  </SubOrganizationsStoreContext.Provider>
);
export const useSubOrganizationsStore = () =>
  useContext(SubOrganizationsStoreContext);
