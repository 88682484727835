import {
  Box,
  CircularProgress,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { FORM_STATUS } from './Users';
import { palette } from '../../../styles/variables/colors';
import DataTable from '../../../Components/DataTable/DataTable';
import { useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';

const USER_FIELDS = {
  UserName: {
    id: 'username',
  },
  Email: { id: 'email' },
  PhoneNumber: {
    id: 'phoneNumber',
  },
  Name: {
    id: 'name',
  },
  SurName: {
    id: 'surname',
  },
  MotherName: {
    id: 'motherName',
  },
  FatherName: {
    id: 'fatherName',
  },
  Afm: {
    id: 'afm',
  },
  SubOrganizationName: {
    id: 'subOrganizationName',
  },
  OrganizationName: {
    id: 'organizationName',
  },
  SectorName: {
    id: 'sectorName',
  },
  DepartmentName: {
    id: 'departmentName',
  },
  SubDepartmentName: {
    id: 'subDepartmentName',
  },
  Role: {
    id: 'role',
  },
  Status: {
    id: 'status',
  },
  Actions: {
    id: 'actions',
  },
};

const loader = (
  <Box className="flex justify-center items-center pl-8">
    <CircularProgress size={20} />
  </Box>
);

const ActionButtons = ({
  className,
  item,
  formStatus,
  focusedItem,
  handleAction,
  refreshList,
}) => {
  const isFocused = focusedItem?.id === item?.id;

  const [isChecked, setIsChecked] = useState(item?.status === 1 || 0);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    const newValue = item.status === 1 ? 0 : 1;
    setIsChecked(newValue === 1);

    const userId = item?.id;
    setLoading(true);

    const lockOrUnlockUser =
      newValue === 1
        ? AuthSetAgent.users.unlockUser
        : AuthSetAgent.users.lockUser;

    lockOrUnlockUser(userId)
      .then((resp) => {
        handleAction({
          item: { ...item, status: newValue },
          formStatus: FORM_STATUS.HIDDEN,
        });
        if (resp?.data.success === true) {
          const successMessage =
            newValue === 1
              ? 'O Χρήστης ενεργοποιήθηκε'
              : 'O Χρήστης απενεργοποιήθηκε';
          success(successMessage);
        } else {
          error('Κάτι πήγε στραβά');
        }
        setLoading(false);
        refreshList();
      })
      .catch((error) => {
        setLoading(false);
        error('Κάτι πήγε στραβά');
      });
  };

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? (
        loader
      ) : (
        <>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.EDIT });
            }}
            placement="top"
            title={'Επεξεργασία'}
          >
            <EditOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.EDIT
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip
            onClick={() => {
              handleAction({ item, formStatus: FORM_STATUS.VIEW });
            }}
            placement="top"
            title={'Προβολή'}
          >
            <VisibilityOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1.4rem',
                marginLeft: '0.4rem',
                color:
                  isFocused && formStatus === FORM_STATUS.VIEW
                    ? palette.info.main
                    : '#333333B2',
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={'Αλλαγή Κατάστασης'}>
            <Switch
              checked={isChecked}
              onChange={handleChange}
              sx={{
                cursor: 'pointer',
                marginLeft: '0.4rem',
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: palette.info.main,
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: palette.info.main,
                },
              }}
            />
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

const UsersTable = ({
  focusedItem,
  formStatus,
  isLoading,
  handleAction,
  refreshList,
  users,
}) => {
  const columns = [
    {
      field: USER_FIELDS?.Email?.id,
      headerName: 'Email',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 4,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.Email?.id] || '-'}
        </Typography>
      ),
    },
    // {
    //   field: USER_FIELDS?.PhoneNumber?.id,
    //   headerName: 'Τηλέφωνο',
    //   headerClassName: 'table-theme--header',
    //   cellClassName: 'table-theme--row',
    //   align: 'center',
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 2,
    //   renderCell: (params) => (
    //     <Typography
    //       align="center"
    //       variant="subtitle1"
    //       component="div"
    //       className="flex items-center text-[14px]"
    //     >
    //       {params?.row?.[USER_FIELDS?.PhoneNumber?.id] || '-'}
    //     </Typography>
    //   ),
    // },
    {
      field: USER_FIELDS?.Name?.id,
      headerName: 'Ονομα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.Name?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: USER_FIELDS?.SurName?.id,
      headerName: 'Επώνυμο',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.SurName?.id] || '-'}
        </Typography>
      ),
    },
    // {
    //   field: USER_FIELDS?.OrganizationName?.id,
    //   headerName: 'Οργανισμός',
    //   headerClassName: 'table-theme--header',
    //   cellClassName: 'table-theme--row',
    //   align: 'left',
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 4,
    //   renderCell: (params) => (
    //     <Typography
    //       align="left"
    //       variant="subtitle1"
    //       component="div"
    //       className="flex items-center text-[14px]"
    //     >
    //       {params?.row?.[USER_FIELDS?.OrganizationName?.id] || '-'}
    //     </Typography>
    //   ),
    // },
    // {
    //   field: USER_FIELDS?.SubOrganizationName?.id,
    //   headerName: 'Υποοργανισμός',
    //   headerClassName: 'table-theme--header',
    //   cellClassName: 'table-theme--row',
    //   align: 'left',
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 4,
    //   renderCell: (params) => (
    //     <Typography
    //       align="left"
    //       variant="subtitle1"
    //       component="div"
    //       className="flex items-center text-[14px]"
    //     >
    //       {params?.row?.[USER_FIELDS?.SubOrganizationName?.id] || '-'}
    //     </Typography>
    //   ),
    // },
    {
      field: USER_FIELDS?.SectorName?.id,
      headerName: 'Τομέας',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.SectorName?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: USER_FIELDS?.DepartmentName?.id,
      headerName: 'Τμήμα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.DepartmentName?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: USER_FIELDS?.SubDepartmentName?.id,
      headerName: 'Υποτμήμα',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 3,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[USER_FIELDS?.SubDepartmentName?.id] || '-'}
        </Typography>
      ),
    },
    // {
    //   field: USER_FIELDS?.Role?.id,
    //   headerName: 'Ρόλος',
    //   headerClassName: 'table-theme--header',
    //   cellClassName: 'table-theme--row',
    //   align: 'left',
    //   filterable: false,
    //   sortable: false,
    //   disableColumnMenu: true,
    //   flex: 2,
    //   renderCell: (params) => (
    //     <Typography
    //       align="left"
    //       variant="subtitle1"
    //       component="div"
    //       className="flex items-center text-[14px]"
    //     >
    //       {params?.row?.[USER_FIELDS?.Role?.id] || '-'}
    //     </Typography>
    //   ),
    // },
    {
      field: USER_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 125,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          formStatus={formStatus}
          item={params?.row}
          handleAction={handleAction}
          refreshList={refreshList}
          className="ml-1"
        />
      ),
    },
  ];

  return (
    <>
      <>
        {!!users?.length && (
          <DataTable columns={columns} rows={users || []} loading={isLoading} />
        )}
      </>
      {!users?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default UsersTable;
