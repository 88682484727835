import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { FORM_STATUS } from './Users';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { useEffect, useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { observer } from 'mobx-react';

const EMPTY_STRING = '---';

const Field = ({ title, value }) => {
  return (
    <Box className="flex items-center mb-2">
      <Typography
        variant="h5"
        className="pr-5  min-w-[250px]"
        fontWeight="bold"
      >
        {`${title}:`}
      </Typography>
      <Typography variant="h5" className="pr-5">
        {value || EMPTY_STRING}
      </Typography>
    </Box>
  );
};

const UsersViewForm = ({ handleAction, item, claims }) => {
  const [userClaims, setUserClaims] = useState([]);

  const checkUserClaim = (claimName) => {
    const userClaimsArray = userClaims;
    return userClaimsArray?.includes(claimName);
  };

  useEffect(() => {
    AuthSetAgent.authSetup
      .getUserClaims(item?.id)
      .then((resp) => {
        setUserClaims(resp?.claims);
      })
      .catch(() => {})
      .finally(() => {});
  }, [item?.id, setUserClaims]);

  return (
    <Box classNameitem="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        Προβολή Χρήστη
      </Typography>
      <Field title="Ονομασία" value={item?.username} />
      <Field title="Ονομα" value={item?.name} />
      <Field title="Επώνυμο" value={item?.surname} />
      <Field title="Μητρώνυμο" value={item?.motherName} />
      <Field title="Πατρώνυμο" value={item?.fatherName} />
      <Field title="ΑΦΜ" value={item?.afm} />
      <Field title="Email" value={item?.email} />
      <Field title="Τηλέφωνο" value={item?.phoneNumber} />
      <Field title="Ρόλος" value={item?.role} />
      <Field
        title="Κατάσταση"
        value={item?.status === 1 ? 'Ενεργός' : 'Ανενεργός'}
      />
      <Field title="Οργανισμός" value={item?.organizationName} />
      <Field title="Υποοργανισμός" value={item?.subOrganizationName} />
      <Field title="Τμήμα" value={item?.departmentName} />
      <Field title="Υποτμήμα" value={item?.subDepartmentName} />
      <Field title="Τομέας" value={item?.sectorName} />{' '}
      <div className="mt-4">
        {claims
          ?.filter((claim) => claim?.name !== 'can_manage_thresholds')
          .map((claim, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disabled
                  value={checkUserClaim(claim?.name)}
                  checked={checkUserClaim(claim?.name)}
                />
              }
              label={
                claim?.name === 'ManageTickets'
                  ? 'Δικαίωμα Αίτησης'
                  : claim?.name === 'ManageRantevou'
                  ? 'Δικαίωμα Ραντεβού'
                  : claim?.name === 'ManageCertificates'
                  ? 'Δικαίωμα Πιστοποιητικών'
                  : null || '-'
              }
            />
          ))}
      </div>
      <Box className="flex justify-end">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              handleAction({ formStatus: FORM_STATUS.HIDDEN });
            }}
            variant="contained"
            type="submit"
          >
            Κλείσιμο
          </Button>
        </AnimateButton>
      </Box>
    </Box>
  );
};

export default observer(UsersViewForm);
