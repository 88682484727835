import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class UserStore {
  users = [];

  usersList = [];

  usersByDepIdList = [];

  roles = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      usersList: observable,
      usersByDepIdList: observable,
      roles: observable,
      getAllUsers: action,
      getUserById: action,
      createUserByDepartmentAdmin: action,
      updateUser: action,
      deleteUser: action,
      getAllUsersByRoleAndDeptId: action,
      getAllUsersByDeptId: action,
      getUsersByDepartmentId: action,
      getAllRoles: action,
    });
  }

  getAllUsers() {
    AuthSetAgent.users
      .getAllUsers()
      .then((res) => {
        this.users = res.result.items;
      })
      .catch((e) => {});
  }

  getAllRoles() {
    AuthSetAgent.authSetup
      .getAllRoles()
      .then((roles) => {
        this.roles = roles;
      })
      .catch((e) => {});
  }

  getAllUsersByRoleAndDeptId(role, subDepartmentId) {
    AuthSetAgent.users
      .getAllUsersByRoleAndDeptId(role, subDepartmentId)
      .then((res) => {
        this.usersList = res.result.items;
      })
      .catch((e) => {});
  }

  getAllUsersByDeptId(subDepartmentId) {
    return AuthSetAgent.users.getAllUsersByDeptId(subDepartmentId);
  }

  getUsersByDepartmentId(departmentId) {
    return AuthSetAgent.users
      .getUsersByDepartmentId(departmentId)
      .then((res) => {
        const usersByDepId = res.result.items;
        this.usersByDepIdList = usersByDepId;
      })
      .catch((e) => {});
  }

  getUserById = (userId) => {
    return AuthSetAgent.users.getUserById(userId);
  };

  createUserByDepartmentAdmin(body) {
    return AuthSetAgent.users.createUserByDepartmentAdmin(body);
  }

  updateUser(userId, body) {
    return AuthSetAgent.users.updateUser(userId, body);
  }

  deleteUser(userId) {
    return AuthSetAgent.users
      .deleteUser(userId)
      .then((resp) => {
        const userIndex = this.users.findIndex((s) => s.id === userId);
        this.users.splice(userIndex, 1);
      })
      .catch((e) => {});
  }
}

export const userStore = new UserStore();
export const UserStoreContext = createContext(userStore);
export const AuthStoreProvider = ({ children, store }) => (
  <UserStoreContext.Provider value={store}>
    {children}
  </UserStoreContext.Provider>
);
export const useUserStore = () => useContext(UserStoreContext);
