import React from 'react';
import clsx from 'clsx';
import { Autocomplete, TextField } from '@mui/material';

const Select = ({
  containerClass,
  disabled = false,
  label,
  value,
  options,
  onChange,
}) => {
  const containerClasses = clsx([containerClass]);

  return (
    <div className={containerClasses}>
      <Autocomplete
        value={value || null}
        onChange={(event, newValue) => onChange(newValue)}
        options={options}
        getOptionLabel={(option) => option?.label || ''}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || 'Επιλέξτε...'}
            variant="outlined"
            disabled={disabled}
          />
        )}
      />
    </div>
  );
};

export default Select;
