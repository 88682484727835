import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';
import HistoricScheduleResp from './DummyData/historicScheduleResp.json';
import { error, success, warning } from '../Common/Toast/Toast';

class RantevouStore {
  rantevouScheduleData = {};
  rantevouData = {};
  scheduleList = [];
  activeSchedules = [];
  rantevouSearchData = [];
  allRantevouData = [];
  currentRantevouData = [];

  rantevouStatusesList = [
    {
      description: 'Διαθέσιμο',
      value: 0,
    },
    {
      description: 'Δεσμευμένο',
      value: 1,
    },
    {
      description: 'Ολοκληρωμένο',
      value: 2,
    },
    {
      description: 'Δεν προσήλθε',
      value: 3,
    },
    {
      description: 'Ακύρωση',
      value: 4,
    },
  ];

  scheduleStatusesList = [
    {
      description: 'Αποθηκευμένο',
      value: 0,
    },
    {
      description: 'Δημοσιευμένο',
      value: 1,
    },
    {
      description: 'Αρχειοθετημένο',
      value: 2,
    },
  ];

  constructor() {
    makeObservable(this, {
      rantevouScheduleData: observable,
      rantevouData: observable,
      rantevouSearchData: observable,
      allRantevouData: observable,
      currentRantevouData: observable,
      rantevouStatusesList: observable,
      scheduleList: observable,
      activeSchedules: observable,
      setRantevouResp: action,
      setScheduleResp: action,
      getScheduleList: action,
      resetRantevouScheduleData: action,
      getRantevou: action,
      getAllRantevou: action,
      getCurrentRantevou: action,
      getRantevouById: action,
      updateRantevou: action,
      deleteSchedule: action,
      publishSchedule: action,
      getActiveSchedules: action,
      resetRantevouData: action,
      getSchedule: action,
      archiveSchedule: action,
    });
    this.setScheduleResp();

    this.setRantevouResp();
  }

  setRantevouResp = (rantevou = {}) => {
    this.rantevouData = rantevou;
  };

  setScheduleResp = (schedule = {}) => {
    this.rantevouScheduleData = schedule;
  };

  resetRantevouScheduleData = () => {
    this.rantevouScheduleData = {};
  };

  deleteSchedule = async (scheduleId) => {
    await AuthSetAgent.schedule
      .deleteSchedule(scheduleId)
      .then((response) => {
        success('Το πρόγραμμα διαγράφηκε επιτυχώς');
      })
      .catch((err) => {
        error('Σφάλμα κατά τη διαγραφή του προγράμματος');
      });
  };

  publishSchedule = async (scheduleId) => {
    let userIds = [];

    await AuthSetAgent.schedule
      .getScheduleById(scheduleId)
      .then(async (response) => {
        userIds = response.result.userIds;

        await AuthSetAgent.schedule
          .updateSchedule(scheduleId, {
            userIds: userIds,
            status: 1,
          })
          .then((resp) => {
            if (resp.success === true) {
              success('Το πρόγραμμα δημοσιεύτηκε επιτυχώς');
            } else {
              warning(
                'Σφάλμα κατά την δημοσίευση του προγράμματος. Υπάρχουν επικαλυπτόμενες ημερομηνίες'
              );
            }
          })
          .catch((err) => {
            console.error(err);
            error('Σφάλμα κατά την δημοσίευση του προγράμματος');
          });
      })
      .catch((err) => {
        console.error(err);
        error('Λανθασμένο ID προγράμματος');
      });
  };

  archiveSchedule = async (scheduleId) => {
    let userIds = [];

    await AuthSetAgent.schedule
      .getScheduleById(scheduleId)
      .then(async (response) => {
        userIds = response.result.userIds;

        await AuthSetAgent.schedule
          .updateSchedule(scheduleId, {
            userIds: userIds,
            status: 2,
          })
          .then((response) => {
            success('Το πρόγραμμα αρχειοθετήθηκε επιτυχώς');
          })
          .catch((err) => {
            error('Σφάλμα κατά την αρχειοθέτηση του προγράμματος');
          });
      })
      .catch((err) => {
        error('Λανθασμένο ID προγράμματος');
      });
  };

  getScheduleList = async (subDepartmentId, dummy = false) => {
    if (dummy) {
      this.scheduleList = HistoricScheduleResp.result;
    } else {
      await AuthSetAgent.schedule
        .getSchedules(subDepartmentId)
        .then((res) => {
          this.scheduleList = res.result.items;
        })
        .catch((err) => {
          error('Σφάλμα κατά την ανάκτηση των προγραμμάτων', err);
        });
    }
  };

  getActiveSchedules = async (subDepartmentId) => {
    await AuthSetAgent.schedule
      .getActiveSchedulesBySubDepartmentId(subDepartmentId)
      .then((res) => {
        if (res) this.activeSchedules = res.result.items;
        else this.activeSchedules = [];
      })
      .catch((err) => {
        this.activeSchedules = {};
        console.error('Δεν υπάρχουν ενεργά προγράμματα', err);
      });
  };

  resetRantevouData = () => {
    this.rantevouSearchData = [];
  };

  getRantevou = ({
    dateFrom,
    dateTo,
    slotTimeFrom,
    slotTimeTo,
    id,
    afm,
    status,
    subDepartmentId,
  }) => {
    return AuthSetAgent.rantevou
      .getRantevou(
        dateFrom ? dateFrom?.slice(0, 10) : '',
        dateTo ? dateTo?.slice(0, 10) : '',
        slotTimeFrom ? slotTimeFrom : '',
        slotTimeTo ? slotTimeTo : '',
        id ? id : '',
        afm ? afm : '',
        status ? status : '',
        subDepartmentId ? subDepartmentId : ''
      )
      .then((res) => {
        this.rantevouSearchData = res.result.items;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getAllRantevou = (subDepartmentId = null) => {
    return AuthSetAgent.rantevou
      .getRantevou(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        subDepartmentId ? subDepartmentId : ''
      )
      .then((res) => {
        this.allRantevouData = res.result.items;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCurrentRantevou = (subDepartmentId = null, dateFrom, dateTo) => {
    return AuthSetAgent.rantevou
      .getRantevou(
        dateFrom ?? new Date().toLocaleDateString().slice(0, 10),
        dateTo ?? new Date().toLocaleDateString().slice(0, 10),
        '',
        '',
        '',
        '',
        '',
        subDepartmentId ? subDepartmentId : ''
      )
      .then((response) => {
        this.currentRantevouData = response.result.items;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getRantevouById = (rantevouId) => {
    return AuthSetAgent.rantevou.getRantevouById(rantevouId);
  };

  getStatusCount = () => {
    return AuthSetAgent.rantevou.getStatusCount();
  };

  updateRantevou(rantevouId, body) {
    return AuthSetAgent.rantevou.updateRantevou(rantevouId, body);
  }

  getSchedule = async (scheduleId) => {
    await AuthSetAgent.schedule
      .getScheduleById(scheduleId)
      .then((response) => {
        this.rantevouScheduleData = response.result;
      })
      .catch((err) => {
        error('Σφάλμα κατά την ανάκτηση του προγράμματος', err);
      });
  };
}

export const rantevouStore = new RantevouStore();
export const RantevouStoreContext = createContext(rantevouStore);
export const RantevouStoreProvider = ({ children, store }) => (
  <RantevouStoreContext.Provider value={store}>
    {children}
  </RantevouStoreContext.Provider>
);
export const useRantevouStore = () => useContext(RantevouStoreContext);
