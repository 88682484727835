import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Box,
  FormControlLabel,
  Switch,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  createTheme,
  ThemeProvider,
  FormLabel,
  IconButton,
} from '@mui/material';
import { useAnnouncementsStore } from '../../../Stores/AnnouncementsStore';
import { error, success, warning } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { observer } from 'mobx-react';
import DeleteIcon from '@mui/icons-material/Delete';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import CircularSpinner from '../../../Components/Theme/Spinners/CircularSpinner';

const EditAnnouncementForm = ({
  isEditMode,
  setIsEditMode,
  announcement,
  isCreateModeOn = false,
  onDelete,
  setParentUpdate = null,
}) => {
  const announcementsStore = useAnnouncementsStore();
  const organizationStore = useOrganizationsStore();
  const [announcementData, setAnnouncementData] = useState(announcement);
  const [updated, setUpdated] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();
  const { isSuperAdmin } = useAuthStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImageDownloading, setIsImageDownloading] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [storedImageFilenames, setStoredImageFilenames] = useState(null);

  const theme = createTheme({
    palette: {
      primary: { main: '#003375' },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            fontSize: '0.8571428571428571rem',
          },
        },
      },
    },
  });

  const schema = yup
    .object({
      title: yup.string().required('Ο τίτλος είναι υποχρεωτικός'),
      text: yup.string().required('Το κείμενο είναι υποχρεωτικό'),
      announcementCategoryId: yup
        .string()
        .required('Η κατηγορία είναι υποχρεωτική'),
      announcementStatus: yup.bool().required('Η κατάσταση είναι υποχρεωτική'),
      organizationId: isSuperAdmin
        ? yup.string().required('Ο οργανισμός είναι υποχρεωτικός')
        : yup.string(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      title: announcementData?.title || '',
      text: announcementData?.text || '',
      announcementCategoryId: announcementData?.announcementCategoryId || '',
      announcementStatus:
        isCreateModeOn || announcementData?.announcementStatus === 1,
      organizationId: isSuperAdmin ? announcementData?.organizationId : '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedImage(null);
      setImageRemoved(true);
    }
  };

  const onSubmit = (data) => {
    const payload = {
      title: data.title,
      text: data.text,
      announcementCategoryId: data.announcementCategoryId,
      announcementStatus: data.announcementStatus ? 1 : 0,
    };

    setIsSubmitting(true);
    announcementsStore
      .updateAnnouncement(announcementData.id, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        if (result) {
          if (selectedImage) {
            announcementsStore
              .uploadImage(announcementData.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Η ανακοίνωση ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                } else {
                  warning(
                    'Η ανακοίνωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η ανακοίνωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else if (imageRemoved && imageUrl) {
            announcementsStore
              .deleteImage(`An_${announcementData.id}`)
              .then((imageResponse) => {
                if (imageResponse?.success) {
                  success('Η ανακοίνωση ενημερώθηκε επιτυχώς');
                  setSelectedImage(null);
                  setUpdated(true);
                } else {
                  warning(
                    'Η ανακοίνωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η ανακοίνωση ενημερώθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την διαγραφή της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
                setIsEditMode(false);
                setUpdated(true);
                setParentUpdate && setParentUpdate(true);
              });
          } else {
            success('Η ανακοίνωση ενημερώθηκε επιτυχώς');
            setSelectedImage(null);
            setIsSubmitting(false);
            setIsEditMode(false);
            setUpdated(true);
            setParentUpdate && setParentUpdate(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την ενημέρωση της ανακοίνωσης');
        setIsSubmitting(false);
      });
  };

  const onCreate = (data) => {
    const payload = {
      title: data.title,
      text: data.text,
      announcementCategoryId: data.announcementCategoryId,
      announcementStatus: data.announcementStatus ? 1 : 0,
      organizationId: isSuperAdmin ? data.organizationId : '',
    };

    setIsSubmitting(true);
    announcementsStore
      .createAnnouncement(payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        if (result) {
          if (selectedImage) {
            announcementsStore
              .uploadImage(result.id, selectedImage)
              .then((imageResult) => {
                if (imageResult) {
                  success('Η ανακοίνωση δημιουργήθηκε επιτυχώς');
                  setSelectedImage(null);
                  navigate(`/announcements/${result.id}`);
                } else {
                  warning(
                    'Η ανακοίνωση δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                warning(
                  'Η ανακοίνωση δημιουργήθηκε, αλλά υπήρξε κάποιο πρόβλημα κατά την ανέβασμα της εικόνας'
                );
              })
              .finally(() => {
                setIsSubmitting(false);
              });
          } else {
            success('Η ανακοίνωση δημιουργήθηκε επιτυχώς');
            setSelectedImage(null);
            navigate(`/announcements/${result.id}`);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την δημιουργία της ανακοίνωσης');
        setIsSubmitting(false);
      });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  useEffect(() => {
    isDirty && !isEditMode && !updated && reset();
    if (!isEditMode) {
      setImageRemoved(false);
      setIsSubmitting(false);
      setSelectedImage(null);
    }
  }, [isEditMode]);

  useEffect(() => {
    setAnnouncementData(announcement);
    if (announcement?.images?.length > 0)
      setStoredImageFilenames(
        announcement?.images.map((image) => image.fileName?.slice(0, -4))
      );
  }, [announcement]);

  useEffect(() => {
    if (isSuperAdmin) {
      organizationStore.getOrganizations().then((response) => {
        setOrganizations(response.items);
      });
    }
  }, [isSuperAdmin, organizationStore]);

  useEffect(() => {
    if (!isCreateModeOn && updated && storedImageFilenames?.length > 0) {
      setIsImageDownloading(true);
      announcementsStore
        .downloadImage(storedImageFilenames[0])
        .then((imageUrl) => {
          setImageUrl(imageUrl);
        })
        .catch(() => {
          setImageUrl(null);
        })
        .finally(() => {
          setIsImageDownloading(false);
          updated && setUpdated(false);
        });
    }
  }, [isCreateModeOn, updated, storedImageFilenames]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(isCreateModeOn ? onCreate : onSubmit)}
      noValidate
      sx={{
        mt: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="flex justify-between items-center gap-4 mb-4">
        <Button
          variant="contained"
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: '#f9f9f9',
            color: '#003375',
            border: '1px solid #003375',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#003375',
              color: '#f9f9f9',
            },
            fontSize: '1rem',
          }}
        >
          Επιστροφή
        </Button>

        <div className="flex gap-4">
          {isEditMode && (
            <SubmitButton
              title={'Ενημέρωση'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage && !imageRemoved}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}
          {isCreateModeOn && (
            <SubmitButton
              title={'Δημιουργία'}
              type={'submit'}
              variant="contained"
              disabled={!isDirty && !selectedImage}
              loading={isSubmitting}
              sx={{
                backgroundColor: '#003375',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#003375',
                },
                textTransform: 'none',
                fontSize: '1rem',
              }}
            />
          )}

          <div className="flex justify-between items-center gap-4">
            {isEditMode && (
              <Button
                variant="contained"
                onClick={onDelete}
                sx={{
                  backgroundColor: '#f9f9f9',
                  color: '#B60202',
                  border: '1px solid #B60202',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#B60202',
                    color: '#f9f9f9',
                  },
                  fontSize: '1rem',
                }}
              >
                Διαγραφή
              </Button>
            )}
            {!isCreateModeOn && (
              <Button
                variant="contained"
                onClick={toggleEditMode}
                sx={{
                  backgroundColor: '#003375',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#003375',
                    color: 'white',
                    opacity: 0.9,
                  },
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
              >
                {isEditMode ? 'Ακύρωση' : 'Επεξεργασία'}
              </Button>
            )}
          </div>
        </div>
      </div>

      <Controller
        name="image"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col items-start justify-start w-full gap-4 my-4 min-h-[120px]">
            <FormLabel
              sx={{
                fontSize: '0.8571428571428571rem',
              }}
            >
              Εικόνα ανακοίνωσης
            </FormLabel>

            {(isEditMode || isCreateModeOn) && (
              <div className="flex justify-start items-center flex-wrap gap-2 w-full">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                  }}
                  disabled={!isEditMode && !isCreateModeOn}
                  ref={fileInputRef}
                />
              </div>
            )}

            {!imageUrl &&
              !isImageDownloading &&
              !isEditMode &&
              !isCreateModeOn && <p>Δεν υπάρχει εικόνα</p>}

            <div className="flex justify-start items-center flex-wrap mb-4 gap-1 w-full">
              {isImageDownloading ? (
                <Box className="flex justify-center items-center">
                  <CircularSpinner
                    size="20px"
                    color="inherit"
                    sx={{ marginRight: '15px' }}
                  />
                </Box>
              ) : (
                (selectedImage || (!imageRemoved && imageUrl)) && (
                  <div className="flex justify-start items-center flex-wrap gap-4 w-full">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : imageUrl
                      }
                      alt="announcement"
                      style={{ width: '50%', height: 'auto' }}
                    />

                    <IconButton
                      sx={{
                        backgroundColor: '#B60202',
                        borderRadius: '99px',
                        textTransform: 'none',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: '#B60202',
                          opacity: 0.8,
                        },
                      }}
                      disabled={!isEditMode && !isCreateModeOn}
                      size={'small'}
                      type="button"
                      title={'Διαγραφή'}
                      onClick={() => clearFileInput()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      />

      <Controller
        name="announcementStatus"
        control={control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            control={
              <Switch
                onChange={isEditMode || isCreateModeOn ? onChange : () => {}}
                onBlur={onBlur}
                checked={
                  isCreateModeOn || isEditMode
                    ? value
                    : announcementData?.announcementStatus === 1
                }
                value={
                  isCreateModeOn || isEditMode
                    ? value
                    : announcementData?.announcementStatus === 1
                }
                inputRef={ref}
                readOnly={!isEditMode && !isCreateModeOn}
                sx={{
                  cursor: 'pointer',
                  marginLeft: '0.4rem',
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#003375',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#003375',
                  },
                }}
              />
            }
            label={
              isCreateModeOn || isEditMode
                ? value
                  ? 'Ενεργή'
                  : 'Ανενεργή'
                : announcementData?.announcementStatus === 1
                ? 'Ενεργή'
                : 'Ανενεργή'
            }
          />
        )}
      />
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Τίτλος"
            error={!!errors.title}
            helperText={errors.title?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />
      <Controller
        name="text"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Κείμενο"
            multiline
            rows={4}
            error={!!errors.text}
            helperText={errors.text?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />
      <Controller
        name="announcementCategoryId"
        control={control}
        render={({ field }) => (
          <ThemeProvider theme={theme}>
            <FormControl fullWidth margin="normal">
              <InputLabel
                id="announcement-category-label"
                required
                sx={{
                  fontSize: '0.8571428571428571rem',
                }}
              >
                Κατηγορία
              </InputLabel>
              <Select
                {...field}
                labelId="poi-category-label"
                label="Κατηγορία"
                required
                error={!!errors.announcementCategoryId}
                value={field.value || ''}
                readOnly={!isEditMode && !isCreateModeOn}
              >
                {announcementsStore.categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.announcementCategoryId && (
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.6428571428571428rem',
                    marginTop: '3px',
                    marginLeft: '14px',
                    marginRight: '14px',
                  }}
                >
                  {errors.announcementCategoryId.message}
                </p>
              )}
            </FormControl>
          </ThemeProvider>
        )}
      />
      {isSuperAdmin && (
        <Controller
          name="organizationId"
          control={control}
          render={({ field }) => (
            <ThemeProvider theme={theme}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="organization-label">Οργανισμός</InputLabel>
                <Select
                  {...field}
                  labelId="organization-label"
                  label="Οργανισμός"
                  error={!!errors.organizationId}
                  value={field.value || ''}
                  disabled={!isCreateModeOn}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                      {
                        borderColor: '#003375',
                      },
                    '& .MuiInputLabel-outlined.Mui-focused': {
                      color: '#003375',
                    },
                  }}
                >
                  {organizations?.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                      {organization.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.organizationId && (
                  <p
                    style={{
                      color: '#d32f2f',
                      fontSize: '0.6428571428571428rem',
                      marginTop: '3px',
                      marginLeft: '14px',
                      marginRight: '14px',
                    }}
                  >
                    {errors.organizationId.message}
                  </p>
                )}
              </FormControl>
            </ThemeProvider>
          )}
        />
      )}
    </Box>
  );
};

export default observer(EditAnnouncementForm);
