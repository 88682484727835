import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField, Button, Box } from '@mui/material';
import { usePoisStore } from '../../../Stores/PoisStore';
import { error, success } from '../../../Common/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

const EditPoiCategoryForm = ({
  isEditMode,
  setIsEditMode,
  poiCategory,
  isCreateModeOn = false,
}) => {
  const poisStore = usePoisStore();
  const [poiCategoryData, setPoiCategoryData] = useState(poiCategory);
  const [updated, setUpdated] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      name: yup.string().required('Ο τίτλος είναι υποχρεωτικός'),
      description: yup.string().required('Η περιγραφή είναι υποχρεωτική'),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      name: poiCategoryData?.name || '',
      description: poiCategoryData?.description || '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
    };

    poisStore
      .updatePoiCategory(poiCategoryData.id, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        success('Η κατηγορία ενημερώθηκε επιτυχώς');
        setUpdated(true);
        setIsEditMode(false);
        setPoiCategoryData(response.result);
        navigate(-1);
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την ενημέρωση της κατηγορίας');
      });
  };

  const onCreate = (data) => {
    const payload = {
      name: data.name,
      description: data.description,
    };

    poisStore
      .createPoiCategory(payload)
      .then((response) => {
        success('Η κατηγορία δημιουργήθηκε επιτυχώς');
        navigate(-1);
      })
      .catch((err) => {
        console.error(err);
        error('Υπήρξε κάποιο πρόβλημα κατά την δημιουργία της κατηγορίας');
      });
  };

  useEffect(() => {
    isDirty && !isEditMode && !updated && reset();
  }, [isEditMode]);

  useEffect(() => {
    setPoiCategoryData(poiCategory);
  }, [poiCategory]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(isCreateModeOn ? onCreate : onSubmit)}
      noValidate
      sx={{
        mt: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Τίτλος"
            error={!!errors.name}
            helperText={errors.name?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Περιγραφή"
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
            InputProps={{
              readOnly: !isEditMode && !isCreateModeOn,
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#003375',
                },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: '#003375',
              },
            }}
          />
        )}
      />

      <div className="flex justify-center">
        {isEditMode && (
          <Button
            type="submit"
            variant="contained"
            disabled={!isDirty}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: '#003375',
              color: 'white',
              '&:hover': {
                backgroundColor: '#003375',
              },
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            Ενημέρωση
          </Button>
        )}
        {isCreateModeOn && (
          <Button
            type="submit"
            variant="contained"
            disabled={!isDirty}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: '#003375',
              color: 'white',
              '&:hover': {
                backgroundColor: '#003375',
              },
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            Δημιουργία
          </Button>
        )}
      </div>
    </Box>
  );
};

export default observer(EditPoiCategoryForm);
