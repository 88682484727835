import { decrypt, encrypt } from '../Encryption/Encryption';
// eslint-disable-next-line import/named
import {
  refreshTokenKey,
  tokenKey,
  impersonationTokenKey,
  languageKey,
} from '../../Config/constant';

export function getTokenFromSessionStorage() {
  return decrypt(sessionStorage.getItem(tokenKey));
}

export function getImpersonationTokenFromSessionStorage() {
  return decrypt(sessionStorage.getItem(impersonationTokenKey));
}

export function getRefreshTokenFromSessionStorage() {
  return decrypt(sessionStorage.getItem(refreshTokenKey));
}

export function getLanguageFromSessionStorage() {
  return decrypt(sessionStorage.getItem(languageKey));
}

export function saveTokenToSessionStorage(token) {
  sessionStorage.setItem(tokenKey, encrypt(token));
}

export function saveImpersonationTokenToSessionStorage(token) {
  sessionStorage.setItem(impersonationTokenKey, encrypt(token));
}

export function saveRefreshTokenToSessionStorage(refreshToken) {
  sessionStorage.setItem(refreshTokenKey, encrypt(refreshToken));
}

export function saveLanguageToSessionStorage(language) {
  sessionStorage.setItem(languageKey, encrypt(language));
}

export function removeTokenToSessionStorage() {
  sessionStorage.removeItem(tokenKey);
}

export function removeImpersonationTokenToSessionStorage() {
  sessionStorage.removeItem(impersonationTokenKey);
}

export function removeRefreshTokenToSessionStorage() {
  sessionStorage.removeItem(refreshTokenKey);
}

export function removeLocaleFromSessionStorage() {
  sessionStorage.removeItem(languageKey);
}
