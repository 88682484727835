import axios from 'axios';
import { warning, error } from '../Toast/Toast';
import { baseUrl } from '../../Config/constant';

axios.defaults.baseURL = baseUrl;
// eslint-disable-next-line no-return-assign
export const setBearerToken = (token) =>
  (axios.defaults.headers.common = { Authorization: `Bearer ${token}` });

axios.interceptors.response.use(undefined, (er) => {
  const { response } = er;

  if (response?.status === 400) {
    warning(response?.data);
  }

  if (response?.status === 401) {
    warning('Δεν έχετε πρόσβαση σε αυτή την ενέργεια!');
  }

  if (response?.status === 404) {
    warning('Δεν βρέθηκε η σελίδα που ζητήσατε!');
  }

  if (response?.status === 500) {
    error('Σφάλμα στον server!');
  }

  if (er.code === 'ERR_NETWORK') {
    warning('Σφάλμα στην σύνδεση με τον server!');
  }
});

export const setImpersonationTokenHeader = (token) => {
  axios.defaults.headers.common['ImpersonationToken'] = token;
};

const responseBody = (response) => response?.data;
const responseGeneral = (response) => response;

export const requests = {
  get: async (url) =>
    axios
      .get(url)
      .then(responseBody)
      .catch((err) => console.error(err)),
  getFile: (url, headers) =>
    axios.get(url, { responseType: 'blob' }).then(responseGeneral),
  post: async (url, body, config = {}) =>
    axios
      .post(url, body, config)
      .then(responseBody)
      .catch((err) => console.error(err)),
  patch: async (url, body) =>
    axios
      .patch(url, body)
      .then(responseBody)
      .catch((err) => console.error(err)),
  put: async (url, body, config) =>
    axios
      .put(url, body, config)
      .then(responseBody)
      .catch((err) => console.error(err)),
  delete: async (url, body) =>
    axios
      .delete(url, body)
      .then(responseBody)
      .catch((err) => console.error(err)),
};
