import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { useState } from 'react';
import { palette } from '../../../styles/variables/colors';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import { useSubOrganizationsStore } from '../../../Stores/SubOrganizationsStore';
import ApplicationCategoriesTable from './ApplicationCategoriesTable';
import ApplicationCategoriesCreateEditForm from './ApplicationCategoriesCreateEditForm';
import ApplicationCategoriesViewForm from './ApplicationCategoriesViewForm';
import ApplicationCategoriesDeleteModal from './ApplicationCategoriesDeleteModal';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';

export const FORM_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
  HIDDEN: 'hidden',
};

const PAGE_SIZE = 15;

const ApplicationCategories = () => {
  const { getAllOrganizations } = useSubOrganizationsStore();
  const formSectionRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [appCategories, setAppCategories] = useState();
  const [org, setOrg] = useState();
  const [formStatus, setFormStatus] = useState(FORM_STATUS.HIDDEN);
  const [focusedAppCategory, setFocusedAppCategory] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAction = useCallback(({ item, formStatus: status }) => {
    setFocusedAppCategory(item);
    setFormStatus(status);
  }, []);

  const handleDelete = useCallback(({ item }) => {
    setFocusedAppCategory(item);
    setShowDeleteModal(true);
  }, []);

  const refreshList = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      AuthSetAgent.applicationCategory
        .getAllApplicationCategories(currentPage, PAGE_SIZE)
        .then((response) => {
          const totalPages = Math.ceil(
            response.result.totalCount / response.result.pageSize
          );
          setTotalPages(totalPages);
          setAppCategories(response.result.items);
        })
        .catch((error) => {
          console.error(error);
        }),
      getAllOrganizations()
        .then((response) => {
          setOrg(response.result.items);
        })
        .catch((error) => {
          console.error(error);
        }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [currentPage]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await Promise.all([
        AuthSetAgent.applicationCategory
          .getAllApplicationCategories(currentPage, PAGE_SIZE)
          .then((response) => {
            const totalPages = Math.ceil(
              response.result.totalCount / response.result.pageSize
            );
            setTotalPages(totalPages);
            setAppCategories(response.result.items);
          })
          .catch((error) => {
            console.error(error);
          }),
        getAllOrganizations()
          .then((response) => {
            setOrg(response.result.items);
          })
          .catch((error) => {
            console.error(error);
          }),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [currentPage]);

  useEffect(() => {
    if (formStatus) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [formStatus]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <Typography
        className="pt-6 pb-5"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Κατηγορίες Υπηρεσιών
      </Typography>
      <ApplicationCategoriesTable
        focusedItem={focusedAppCategory}
        formStatus={formStatus}
        appCategories={appCategories}
        handleAction={handleAction}
        handleDelete={handleDelete}
      />
      <CustomPagination
        className={'my-4 flex justify-center items-center'}
        color={'#003375'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              setFormStatus((prev) =>
                prev === FORM_STATUS.CREATE
                  ? FORM_STATUS.HIDDEN
                  : FORM_STATUS.CREATE
              );
            }}
            variant="contained"
            type="submit"
          >
            Προσθήκη Νέας
          </Button>
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Box className="ml-4">{isLoading ? loader : content}</Box>
      {(formStatus === FORM_STATUS.CREATE ||
        formStatus === FORM_STATUS.EDIT) && (
        <ExpandableSectionContainer ref={formSectionRef}>
          <ApplicationCategoriesCreateEditForm
            isEdit={formStatus === FORM_STATUS.EDIT}
            item={focusedAppCategory}
            handleAction={handleAction}
            refreshList={refreshList}
            organizations={org}
          />
        </ExpandableSectionContainer>
      )}
      {formStatus === FORM_STATUS.VIEW && (
        <ExpandableSectionContainer ref={formSectionRef}>
          <ApplicationCategoriesViewForm
            handleAction={handleAction}
            item={focusedAppCategory}
          />
        </ExpandableSectionContainer>
      )}
      {showDeleteModal && (
        <ApplicationCategoriesDeleteModal
          item={focusedAppCategory}
          handleClose={() => setShowDeleteModal(false)}
          refreshList={refreshList}
          handleSubmit={() => {}}
        />
      )}
    </Box>
  );
};

export default observer(ApplicationCategories);
