import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import { CircularProgress, Typography } from '@mui/material';
import RantevouViewEditForm from './RantevouViewEditForm';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { ORG_NAME } from '../../../Config/constant';

const RantevouViewEdit = () => {
  const rantevouStore = useRantevouStore();
  const location = useLocation();
  const [locationUrl, setLocationUrl] = useState('/rantevou-search');
  const [rantevou, setRantevou] = useState(null);
  const { id: rantevouId } = useParams();

  useEffect(() => {
    if (location?.state?.returnToUrl) {
      setLocationUrl(location.state.returnToUrl);
    }
  }, [location]);

  useEffect(() => {
    rantevouStore
      .getRantevouById(rantevouId)
      .then((response) => {
        setRantevou(response.result);
      })
      .catch(() => {});
  }, [setRantevou, rantevouStore, rantevouId]);

  return (
    <>
      <div>
        {' '}
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
      </div>
      <div className="mt-6 ml-4">
        <Typography variant="h3" color={'#003375'} gutterBottom>
          Προβολή ραντεβού
        </Typography>
      </div>

      <div className="mt-6 ml-4">
        {rantevou ? (
          <RantevouViewEditForm
            rantevouData={rantevou}
            returnToUrl={locationUrl}
          />
        ) : (
          <div className="text-center py-10">
            <CircularProgress
              sx={{
                color: '#003375',
              }}
              size="3rem"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(RantevouViewEdit);
