import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const ReservationItemEditMode = ({ index, slot, timeFrom, timeTo, onDeleteClick, dayMode = false }) => {
    const [slotData, setSlotData] = useState({});
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (slot)
            setSlotData(slot);
    }, [slot]);

    const handleAccordionToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="flex items-center justify-between w-full">
            <Accordion expanded={expanded}
                onChange={handleAccordionToggle}
                className="w-full rounded-lg mb-2 hover:shadow-lg"
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel-content"
                    sx={{ width: "100%" }}
                >
                    <div className="flex flex-row flex-wrap">
                        <Typography sx={{ flexShrink: 0 }} className="pr-2 font-bold text-[#003375]">
                            {`${timeFrom.slice(11, 16)} - ${timeTo.slice(11, 16)} | Θέση ${index + 1}`} | {`${slotData?.isAvailable ? "Ελεύθερη" : "Δεσμευμένη"}`}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        !slotData.isAvailable ? (
                            <div>
                                <Typography variant="h6" className="mb-2 font-semibold">
                                    <span className="text-gray-600">Κωδικός κράτησης</span>: {slotData?.rantevou?.id}
                                </Typography>
                                <Typography variant="h6" className="mb-2 font-semibold">
                                    <span className="text-gray-600">Κατάσταση</span>: {slotData?.rantevou?.status}
                                </Typography>
                                <Typography variant="h6" className="mb-2 font-semibold">
                                    <span className="text-gray-600">Ονοματεπώνυμο</span>: {slotData?.rantevou?.name} {slotData?.rantevou?.surName}
                                </Typography>
                                <Typography variant="h6" className="mb-2 font-semibold">
                                    <span className="text-gray-600">Email</span>: {slotData?.rantevou?.email}
                                </Typography>
                                <Typography variant="h6" className="mb-2 font-semibold">
                                    <span className="text-gray-600">Τηλέφωνο</span>: {slotData?.rantevou?.tel}
                                </Typography>
                            </div>
                        ) : (
                            <Typography variant="h6" className="mb-2 font-semibold">
                                Δεν υπάρχει κρατημένο ραντεβού
                            </Typography>
                        )
                    }

                </AccordionDetails>
            </Accordion>
            {!dayMode && (
                <div className="ml-4">
                    <Tooltip title="Διαγραφή Θέσης">
                        <IconButton
                            size="large"
                            onClick={() => onDeleteClick(slotData)}
                        >
                            <DeleteIcon sx={{ fontSize: "1.8rem" }} />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div >
    );
};

export default observer(ReservationItemEditMode);