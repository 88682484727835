import { palette } from '../styles/variables/colors';

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 0;
export const appDrawerWidth = 320;
export const defaultLanguage = 'el';

export const encryptionKey = 'niebwfi&2e2dDW(#435';
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const tokenKey = 'token';
export const impersonationTokenKey = 'impersonation_token';
export const refreshTokenKey = 'refresh_token';
export const languageKey = 'language';
export const ORG_NAME = {
  longName: 'Περιφέρεια Δυτικής Ελλάδας',
  shortName: 'ΠΔΕ',
  referTo: 'Περιφέρειας Δυτικής Ελλάδας',
  referWithArticle: 'της Περιφέρειας Δυτικής Ελλάδας',
  referWithArticleShort: 'της ΠΔΕ',
};

// Tickets
//
export const TICKET_FORM_IDS = {
  Id: 'ticketApplicationId',
  SubmissionDate: 'dateCreated',
  CompletionDate: 'dateOfCompletion',
  CategoryId: 'ticketCategoryId',
  ServiceId: 'ticketServiceId',
  Status: 'statusId',
  Department: 'departmentId',
  Priority: 'priorityId',
  Afm: 'afmOfCitizen',
};

export const TICKET_FIELDS = {
  Afm: 'citizenUserAfm',
  DeparmentName: 'departmentName',
  SubmissionDate: 'entityCreatedAt',
  Id: 'id',
  StatusId: 'status',
  StatusText: 'statusLiteral',
  CategoryId: 'ticketCategoryId',
  CategoryText: 'ticketCategoryTitle',
  Code: 'ticketCode',
  ServiceId: 'ticketServiceId',
  ServiceText: 'ticketServiceTitle',
};

export const TICKET_STATUS = {
  UNDER_REVIEW: {
    id: 0,
    color: palette.secondary.main,
  },
  REJECTED: {
    id: 1,
    color: palette.error.main,
  },
  NEW: { id: 2, color: palette.info.main },
  IN_PROGRESS: { id: 3, color: palette.info.main },
  COMPLETED: { id: 4, color: palette.success.main },
};

export const TICKET_HIGH_PRIORITY_ID = 2;

export const statusTranslations = {
  0: 'Αποθηκευμένο',
  1: 'Υποβολή',
  2: 'Ακυρωμένο',
  3: 'Σε Εξέλιξη',
  4: 'Έκδοση',
  5: 'Σε Εκκρεμότητα',
  6: 'Απορρίφθηκε',
};

export function removeDiacritics(text) {
  return text?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
}

export function removeSingleQuotes(text) {
  return text?.replace(/'/g, '');
}

export function normalizeSearchQuery(query) {
  return removeSingleQuotes(removeDiacritics(query))?.toLowerCase()?.trim();
}
