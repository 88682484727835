import { Box, Button, Typography } from '@mui/material';
import { forwardRef } from 'react';

const NotificationItem = forwardRef(
  (
    {
      title,
      status,
      isRead,
      onClick,
      index,
      notifiableId,
      id,
      notifiableModelType,
    },
    ref
  ) => {
    const colorStatus = (status) => {
      switch (status) {
        case 'Νέο':
          return '#003375';
        case 'Τροποποιήθηκε':
          return '#003375'; // Use the same color as "Νέο"
        case 'Σε εξέλιξη':
          return '#003375'; // Use the same color as "Νέο"
        case 'Σε εκκρεμμότητα':
          return '#b60202';
        case 'Ολοκληρώθηκε':
          return '#006736';
        case 'Ακυρώθηκε':
          return '#b60202';
        case 'Διαγράφηκε':
          return '#b60202';
        case 'Δεν πραγματοποιήθηκε':
          return '#b60202'; // Use the same color as "Νέο"
        default:
          return '#003375';
      }
    };

    return (
      <div ref={ref} className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start w-full">
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={isRead ? 'normal' : 'bold'}
          >
            {index + 1}.
          </Typography>
          <Typography
            variant="h5"
            marginLeft={1}
            gutterBottom
            fontWeight={isRead ? 'normal' : 'bold'}
          >
            {title}
          </Typography>
        </div>
        <div className="ml-4 flex items-center justify-end">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              marginRight: 2,
              borderRadius: '5px',
              color: colorStatus(status),
              padding: '0.5rem',
              width: '10rem',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h5">{status}</Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              backgroundColor: '#003375',
              color: '#fff',
              '&:hover': {
                opacity: 0.8,
                backgroundColor: '#003375',
                color: '#fff',
              },
            }}
            onClick={() => onClick(id, notifiableId, notifiableModelType)}
          >
            <Typography variant="h5" padding={0.5}>
              Προβολή
            </Typography>
          </Button>
        </div>
      </div>
    );
  }
);

export default NotificationItem;
