import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useState } from 'react';
import { usePoisStore } from '../../../Stores/PoisStore';
import { Box, CircularProgress, Typography } from '@mui/material';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';
import PoiCategoryTable from './PoiCategoryTable';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useNavigate } from 'react-router-dom';
import { useOrganizationsStore } from '../../../Stores/OrganizationStore';
import { useAuthStore } from '../../../Stores/AuthStore';

const PAGE_SIZE = 15;

const PoiCategoriesOverview = () => {
  const { getPoiCategories } = usePoisStore();
  const [poiCategories, setPoiCategories] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [focusedItem, setFocusedItem] = useState(null);
  const organizationStore = useOrganizationsStore();
  const { isSuperAdmin } = useAuthStore();
  const navigate = useNavigate();

  const handleAction = useCallback(({ item }) => {
    setFocusedItem(item);
    navigate(`/poi-categories/${item.id}`);
  }, []);

  const refreshList = useCallback(async () => {
    setLoading(true);

    getPoiCategories(currentPage, PAGE_SIZE)
      .then((result) => {
        const totalPages = Math.ceil(result.totalCount / result.pageSize);
        setTotalPages(totalPages);
        setPoiCategories(result.items);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    setLoading(true);
    getPoiCategories(currentPage, PAGE_SIZE)
      .then((result) => {
        setPoiCategories(result.items);
        setTotalPages(Math.ceil(result.totalCount / result.pageSize));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getPoiCategories, currentPage]);

  useEffect(() => {
    if (isSuperAdmin && organizationStore.getOrganizations) {
      organizationStore
        .getOrganizations()
        .then((result) => {
          setOrganizations(result.items);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isSuperAdmin, organizationStore]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <PoiCategoryTable
        focusedItem={focusedItem}
        poiCategories={
          isSuperAdmin && organizations
            ? poiCategories.map((item) => ({
                ...item,
                organizationId: organizations.find(
                  (org) => org.id === item.organizationId
                )?.name,
              }))
            : poiCategories
        }
        handleAction={handleAction}
        refreshList={refreshList}
      />
      {totalPages > 0 && (
        <CustomPagination
          className={'my-4 flex justify-center items-center'}
          color={'#003375'}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <SubmitButton
            sx={{
              backgroundColor: '#003375',
              color: 'white',
              '&:hover': {
                backgroundColor: '#003375',
                color: 'white',
                opacity: 0.9,
              },
              textTransform: 'none',
            }}
            className="capitalize text-sm"
            title={'Προσθήκη Νέας'}
            onClick={() => navigate('/poi-category-create')}
            variant="contained"
            type="submit"
          />
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <Box className="min-h-[110vh]">
      <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
      <Typography
        className="pt-6 pb-5 pl-4"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Οι κατηγορίες σημείων ενδιαφέροντος
      </Typography>
      <Box className="ml-4">{loading ? loader : content}</Box>
    </Box>
  );
};

export default observer(PoiCategoriesOverview);
