import { observer } from 'mobx-react';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Autocomplete, Button, Grid, Stack, TextField } from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { t } from 'i18next';
import Accordions from '../../../Components/Accordion/Accordion';
import { format, addDays } from 'date-fns';
import RantevouSearchResults from './RantevouSearchResults';
import MyDatePicker from '../../../Components/MyDatePicker/MyDatePicker';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useRef } from 'react';
import { width } from '../../../styles/variables/width';

const RantevouSearchForm = () => {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);
  const [slotTimeFrom, setOpenTime] = useState(null);
  const [slslotslotTimeTo, setCloseTime] = useState(null);
  const [minDateForDateTo, setMinDateForDateTo] = useState(null);
  const [, setSelectedDateFrom] = useState(null);
  const [formIsSubmitted, setFormSubmited] = useState(false);
  const [tableIsVisible, setTableIsVisible] = useState(false);
  const tableRef = useRef(null);
  const [scheduleDisplayData, setScheduleDisplayData] = useState({});

  const { authUser, isAdmin } = useAuthStore();
  const rantevouStore = useRantevouStore();
  const subDepartmentStore = useSubDepartmentStore();

  useEffect(() => {
    if (subDepartmentStore.subDepartmentsList?.length === 0) {
      subDepartmentStore.getSubDepartments();
    }
    if (
      rantevouStore.rantevouSearchData?.length > 0 &&
      subDepartmentStore.subDepartmentsList?.length > 0
    ) {
      setLoading(false);
    }
  }, [
    rantevouStore,
    rantevouStore.rantevouSearchData,
    subDepartmentStore,
    subDepartmentStore.subDepartmentsList,
  ]);

  const handleDateFromChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
    setDates([date, dates[1]]);
    setValue(
      'days',
      [formattedDate, dates[1] ? format(dates[1], 'yyyy-MM-dd') : ''],
      {
        shouldValidate: true,
      }
    );

    setSelectedDateFrom(date);

    const minDateForDateTo = date ? addDays(date, 0) : null;
    setMinDateForDateTo(minDateForDateTo);
  };

  const handleDateToChange = (date) => {
    const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
    setDates([dates[0], date]);
    setValue(
      'days',
      [dates[0] ? format(dates[0], 'yyyy-MM-dd') : '', formattedDate],
      {
        shouldValidate: true,
      }
    );
  };

  const scrollPageToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const rantevouResultsSchema = yup.object().shape({
    id: yup.string().notRequired(),
    subDepartmentId: yup.string().notRequired(),
    slotTimeFrom: yup.string().trim().strict(true).notRequired(),
    slotTimeTo: yup.string().trim().strict(true).notRequired(),
    status: yup.object().notRequired(),
    afm: yup.string().notRequired(),
    days: yup
      .array()
      .of(yup.string())
      .notRequired('Το πεδίο ειναι υποχρεωτικό'),
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      id: '',
      subDepartmentId: '',
      slotTimeFrom: '',
      slotTimeTo: '',
      status: {},
      afm: '',
      dateFrom: '',
      dateTo: '',
      days: [],
    },
    resolver: yupResolver(rantevouResultsSchema),
  });

  const submitRantevouSearchFilters = (data) => {
    setLoading(true);

    const payload = {
      id: data.id || '',
      subDepartmentId:
        isAdmin === false
          ? authUser?.subDepartmentId
          : data.subDepartmentId?.id || '',
      slotTimeFrom: data.slotTimeFrom || '',
      slotTimeTo: data.slotTimeTo || '',
      dateFrom: data.days[0] || '',
      dateTo: data.days[1] || '',
      status: data.status?.value?.toString() || '',
      afm: data.afm || '',
    };

    rantevouStore
      .getRantevou(payload)
      .then((resp) => {
        setFormSubmited(true);
        setTableIsVisible(true);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          scrollPageToTable();
        }, 100);
      });
  };

  const generateTimeOptions = (start, end) => {
    const options = [];
    let current = start;

    while (current <= end) {
      let hours = Math.floor(current / 60);
      let minutes = current % 60;

      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;

      options.push(hours + ':' + minutes);
      current += 15;
    }

    return options;
  };

  const timetableOptions = useMemo(
    () => generateTimeOptions(8 * 60, 14 * 60 + 30),
    []
  );

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(submitRantevouSearchFilters)}>
        <Accordions text={'Φίλτρα αναζήτησης'} className={'mb-4'}>
          <div className="grid-cols-3 ml-6">
            <Grid container spacing={2}>
              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      type="number"
                      className="mt-14 bg-[#FFFF]"
                      fullWidth
                      label={'Κωδικός Ραντεβού'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={
                        error ? 'Το πεδίο δέχεται μόνο αριθμούς' : null
                      }
                    />
                  )}
                />
              </Grid>

              {isAdmin && (
                <Grid item lg={4} md={12} sm={12}>
                  <Controller
                    name="subDepartmentId"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        onChange={(e, value) => {
                          onChange(
                            isNaN(parseInt(value?.id))
                              ? null
                              : parseInt(value?.id)
                          );
                          setScheduleDisplayData({
                            ...scheduleDisplayData,
                            subDepartmentId: value,
                          });
                        }}
                        value={scheduleDisplayData.subDepartmentId}
                        options={subDepartmentStore?.subDepartmentsList}
                        getOptionLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="bg-[#FFFF] mt-14"
                            fullWidth
                            label={'Υποτμήμα'}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}

              <Controller
                name="days"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <>
                    <Grid item lg={2} md={12} sm={12}>
                      <MyDatePicker
                        value={dates[0] && new Date(dates[0])}
                        onChange={handleDateFromChange}
                        placeholder="Ημερομηνία Από"
                      />
                    </Grid>
                    <Grid item lg={2} md={12} sm={12}>
                      <MyDatePicker
                        value={dates[1] && new Date(dates[1])}
                        onChange={handleDateToChange}
                        minDate={minDateForDateTo}
                        placeholder="Ημερομηνία Έως"
                      />
                    </Grid>
                  </>
                )}
              />

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="slotTimeFrom"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      onChange={(e, value) => {
                        setOpenTime(value);
                        setCloseTime(null);
                        onChange(value);
                      }}
                      onClose={() => setCloseTime(null)}
                      value={value}
                      options={timetableOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-14 bg-[#FFFF]"
                          fullWidth
                          label={'Ώρα Έναρξης'}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="slotTimeTo"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      onChange={(e, value) => {
                        setCloseTime(value);
                        onChange(value);
                      }}
                      value={slslotslotTimeTo}
                      options={timetableOptions}
                      getOptionDisabled={(option) =>
                        timetableOptions.indexOf(option) <=
                        timetableOptions.indexOf(slotTimeFrom)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="mt-14 bg-[#FFFF]"
                          fullWidth
                          label={'Ώρα Λήξης'}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      onChange={(e, value) => onChange(value)}
                      value={value}
                      options={rantevouStore?.rantevouStatusesList}
                      getOptionLabel={(option) => option?.description || ''}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="bg-[#FFFF]"
                          label={'Κατάσταση'}
                          error={!!error}
                          helperText={
                            error ? t(error.message, { min: 1 }) : null
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={4} md={12} sm={12}>
                <Controller
                  name="afm"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      className="bg-[#FFFF]"
                      label={'ΑΦΜ'}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? t(error.message) : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </Accordions>

        <Grid container spacing={3} className="pr-4">
          <Grid item sm={12} className="flex justify-end">
            <Stack direction="row" justifyContent="space-between">
              <AnimateButton>
                <SubmitButton
                  sx={{
                    backgroundColor: '#003375',
                    color: '#f9f9f9',
                    textTransform: 'none',
                    width: width.button.md,
                    '&:hover': {
                      backgroundColor: '#003375',
                      color: '#f9f9f9',
                    },
                    fontSize: '1rem',
                  }}
                  variant="contained"
                  loading={loading}
                  type="submit"
                  title="Εύρεση"
                />
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>

      {formIsSubmitted && tableIsVisible && (
        <div className="pt-4" ref={tableRef}>
          <RantevouSearchResults
            appointments={rantevouStore.rantevouSearchData}
          />
        </div>
      )}
    </div>
  );
};

export default observer(RantevouSearchForm);
