import { ExpandMore, Pageview } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRantevouStore } from '../../Stores/RantevouStore';

const ReservationItem = ({ index, rantevou }) => {
  const [rantevouData, setRantevouData] = useState({});
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { rantevouStatusesList } = useRantevouStore();
  const statusValue = rantevouData?.status;
  const renderStatusName = rantevouStatusesList?.find(
    (option) => option.value === statusValue
  )?.description;

  useEffect(() => {
    if (rantevou) setRantevouData(rantevou);
  }, [rantevou]);

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionToggle}
      className="w-full rounded-lg mb-2 hover:shadow-lg"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-content"
        className="bg-gray-100 hover:bg-gray-200 cursor-pointer px-4 py-2"
      >
        <div className="flex flex-row flex-wrap">
          <Typography
            sx={{ flexShrink: 0 }}
            className="pr-2 font-bold text-[#003375]"
          >
            <b>Κωδικός κράτησης</b>: {rantevouData?.id ? rantevouData?.id : '-'}
          </Typography>
          <Typography
            sx={{ color: 'text.secondary' }}
            className="text-gray-500"
          >
            {renderStatusName ? renderStatusName : '-'}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className="px-4 pt-2">
        <div>
          <Typography variant="h6" className="mb-2 font-semibold">
            <span className="text-gray-600">Κωδικός κράτησης</span>:{' '}
            {rantevouData?.id ? rantevouData?.id : '-'}
          </Typography>
          <Typography variant="h6" className="mb-2 font-semibold">
            <span className="text-gray-600">Κατάσταση</span>:{' '}
            {renderStatusName ? renderStatusName : '-'}
          </Typography>
          <Typography variant="h6" className="mb-2 font-semibold">
            <span className="text-gray-600">Ονοματεπώνυμο</span>:{' '}
            {rantevouData?.name ? rantevouData?.name : '-'}{' '}
            {rantevouData?.surName ? rantevouData?.surName : '-'}
          </Typography>
          <Typography variant="h6" className="mb-2 font-semibold">
            <span className="text-gray-600">Email</span>:{' '}
            {rantevouData?.email ? rantevouData?.email : '-'}
          </Typography>
          <Typography variant="h6" className="mb-2 font-semibold">
            <span className="text-gray-600">Τηλέφωνο</span>:{' '}
            {rantevouData?.phoneNumber ? rantevouData?.phoneNumber : '-'}
          </Typography>
        </div>
      </AccordionDetails>
      <div className="flex justify-end pb-2 pr-2">
        <Tooltip
          title="Προβολή κράτησης"
          onClick={() => navigate(`/rantevou/${rantevou.id}`)}
        >
          <IconButton size="large">
            <Pageview fontSize="large" sx={{ color: '#003375' }} />
          </IconButton>
        </Tooltip>
      </div>
    </Accordion>
  );
};

export default observer(ReservationItem);
