import React from 'react';
import RantevouOverviewCurrentResults from './RantevouOverviewCurrentResults';
import RantevouAppointementsTable from './RantevouAppointementsTable';
import { observer } from 'mobx-react';

const RantevouTables = ({ status, appointments }) => {
  const filteredAppointments = appointments?.filter(
    (item) => item?.status === status
  );

  if (status !== null) {
    return (
      <RantevouAppointementsTable
        status={status}
        appointments={filteredAppointments}
      />
    );
  } else {
    return <RantevouOverviewCurrentResults />;
  }
};

export default observer(RantevouTables);
