import { observer } from 'mobx-react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';
import { useSubOrganizationsStore } from '../../../Stores/SubOrganizationsStore';
import OrganizationsTable from './OrganizationsTable';
import OrganizationsCreateEditForm from './OrganizationsCreateEditForm';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import { useNavigate } from 'react-router-dom';
import OrganizationsViewForm from './OrganizationsViewForm';

export const FORM_STATUS = {
  EDIT: 'edit',
  VIEW: 'view',
  HIDDEN: 'hidden',
};

const PAGE_SIZE = 15;

const Organizations = () => {
  const subOrganizationsStore = useSubOrganizationsStore();
  const formSectionRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [organizations, setOrganizations] = useState();
  const [organizationTypes, setOrganizationTypes] = useState();
  const [organizationTypesMap, setOrganizationTypesMap] = useState({});
  const [formStatus, setFormStatus] = useState(FORM_STATUS.HIDDEN);
  const [focusedOrganization, setFocusedOrganization] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAction = useCallback(({ item, formStatus: status }) => {
    setFocusedOrganization(item);
    setFormStatus(status);
  }, []);

  const refreshList = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      AuthSetAgent.organizations
        .getOrganizations(currentPage, PAGE_SIZE)
        .then((response) => {
          const totalPages = Math.ceil(
            response.result.totalCount / response.result.pageSize
          );
          setTotalPages(totalPages);
          const organizations = response.result.items;
          setOrganizations(organizations);
        })
        .catch((error) => {
          console.error(error);
        }),
      subOrganizationsStore
        .getOrganizationTypes()
        .then((response) => {
          const mapObject = {};
          const organizationTypes = response;
          organizationTypes?.forEach((item) => {
            mapObject[item?.id] = item;
          });
          setOrganizationTypesMap(mapObject);
          setOrganizationTypes(organizationTypes);
        })
        .catch((error) => {
          console.error(error);
        }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [currentPage, subOrganizationsStore]);

  const processedOrganizations = useMemo(
    () =>
      organizations?.map((item) => ({
        ...item,
        id: item?.id || null,
        name: item?.name || null,
        description: item?.description || null,
        address: item?.address || null,
        tel: item?.tel || null,
        hasERantevou: item?.hasERantevou || null,
        hasECertificates: item?.hasECertificates || null,
        hasERequests: item?.hasERequests || null,
        organizationType:
          organizationTypesMap[item?.organizationType]?.description,
        isActive: item?.isActive || null,
        cleanupSchedule: item?.cleanupSchedule || null,
      })),
    [organizations, organizationTypesMap]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await Promise.all([
        AuthSetAgent.organizations
          .getOrganizations(currentPage, PAGE_SIZE)
          .then((response) => {
            const totalPages = Math.ceil(
              response.result.totalCount / response.result.pageSize
            );
            setTotalPages(totalPages);
            const organizations = response.result.items;
            setOrganizations(organizations);
          })
          .catch((error) => {
            console.error(error);
          }),
        subOrganizationsStore
          .getOrganizationTypes()
          .then((response) => {
            const mapObject = {};
            const organizationTypes = response;
            organizationTypes?.forEach((item) => {
              mapObject[item?.id] = item;
            });
            setOrganizationTypesMap(mapObject);
            setOrganizationTypes(organizationTypes);
          })
          .catch((error) => {
            console.error(error);
          }),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [currentPage, subOrganizationsStore]);

  useEffect(() => {
    const shouldScroll = formStatus !== FORM_STATUS.HIDDEN;

    if (shouldScroll) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }
  }, [formStatus, focusedOrganization?.id]);

  const loader = (
    <Box className="flex items-center justify-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <Typography
        className="pt-6 pb-5"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Λίστα Οργανισμών
      </Typography>
      <OrganizationsTable
        focusedItem={focusedOrganization}
        formStatus={formStatus}
        organizations={processedOrganizations}
        handleAction={handleAction}
        refreshList={refreshList}
      />
      <CustomPagination
        className={'my-4 flex justify-center items-center'}
        color={'#003375'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => navigate('/org-definition')}
            variant="contained"
            type="submit"
          >
            Προσθήκη Νέου
          </Button>
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <>
      <Box className="min-h-[110vh]">
        <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
        <Box className="ml-4">{isLoading ? loader : content}</Box>
        {formStatus === FORM_STATUS.EDIT && (
          <ExpandableSectionContainer ref={formSectionRef}>
            <OrganizationsCreateEditForm
              isEdit={formStatus === FORM_STATUS.EDIT}
              item={focusedOrganization}
              handleAction={handleAction}
              refreshList={refreshList}
              organizationTypes={organizationTypes}
            />
          </ExpandableSectionContainer>
        )}
        {formStatus === FORM_STATUS.VIEW && (
          <ExpandableSectionContainer ref={formSectionRef}>
            <OrganizationsViewForm
              handleAction={handleAction}
              item={focusedOrganization}
            />
          </ExpandableSectionContainer>
        )}
      </Box>
    </>
  );
};

export default observer(Organizations);
