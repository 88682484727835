import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import {
  CircularProgress,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  Modal,
} from '@mui/material';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useAuthStore } from '../../../Stores/AuthStore';
import NoAccessCard from '../../../Components/NoAccessCard/NoAccessCard';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { error, success, warning } from '../../../Common/Toast/Toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { ORG_NAME } from '../../../Config/constant';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import MainCard from '../../../Components/Theme/Cards/MainCard';
import CancelIcon from '@mui/icons-material/Cancel';

const PAGE_SIZE = 15;

const RantevouService = () => {
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);
  const subDepartmentStore = useSubDepartmentStore();
  const appointmentServiceStore = useAppointmentServiceStore();
  const { isAdmin } = useAuthStore();
  const navigate = useNavigate();
  const [appointmentServices, setAppointmentServices] = useState(null);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  const refreshList = useCallback(async () => {
    setLoading(true);

    if (selectedSubDepartment) {
      await Promise.all([
        appointmentServiceStore
          .getAppointmentServices(
            selectedSubDepartment.id,
            currentPage,
            PAGE_SIZE
          )
          .then((res) => {
            const totalPages = Math.ceil(
              res.result.totalCount / res.result.pageSize
            );
            setTotalPages(totalPages);
            setAppointmentServices(res.result.items);
            setLoading(false);
          })
          .catch(() => {
            setAppointmentServices({});
            setLoading(false);
            warning('Η επικοινωνία με τον διακομιστή απέτυχε');
            navigate('/rantevou-service');
          }),
      ]).finally(() => {
        setLoading(false);
      });
    }
  }, [
    appointmentServiceStore,
    currentPage,
    selectedSubDepartment?.id,
    selectedSubDepartment,
  ]);

  const onViewClick = (service) => {
    navigate(`/rantevou-service/${service.id}`);
  };

  const openDeleteModal = () => {
    setIsModalDeleteOpen(true);
  };

  const renderDeleteRantevouServiceModal = (id) => {
    return (
      <Modal
        className="flex items-center justify-center "
        open={isModalDeleteOpen}
        onClose={() => setIsModalDeleteOpen(false)}
      >
        <MainCard
          sx={{ backgroundColor: 'lightgray' }}
          className="m-4 min-w-[25vw] min-h-[9vw] mb-6 mt-6 overflow-auto max-h-screen"
        >
          <div className="flex items-end justify-end">
            <CancelIcon
              className="modal-close-icon cursor-pointer"
              onClick={() => setIsModalDeleteOpen(false)}
            />
          </div>
          <div className="modal-container overflow-auto max-h-screen">
            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                Είστε σίγουροι ότι θέλετε να διαγράψετε την συγκεκριμένη
                υπηρεσία ραντεβού ?
              </Typography>
            </div>

            <div className="flex justify-center items-center">
              <Typography variant="h4" className="pt-4">
                <SubmitButton
                  onClick={() => removeRantevouService(id)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="NAI"
                />
              </Typography>

              <Typography variant="h4" className="pt-4 pl-4">
                <SubmitButton
                  onClick={() => setIsModalDeleteOpen(false)}
                  sx={{ backgroundColor: '#003375' }}
                  type="button"
                  title="ΟΧΙ"
                />
              </Typography>
            </div>
          </div>
        </MainCard>
      </Modal>
    );
  };

  const removeRantevouService = (id) => {
    setLoading(true);
    AuthSetAgent.appointmentService
      .deleteAppointmentService(id)
      .then((resp) => {
        if (resp?.success === true) {
          success('Η υπηρεσία ραντεβού διαγράφτηκε επιτυχώς');
        } else {
          error('Κάτι πήγε στραβά κατά την διαγραφή της υπηρεσίας ραντεβού');
        }
        setIsModalDeleteOpen(false);
        refreshList();
      })
      .catch((err) => {
        error('Κάτι πήγε στραβά');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAddClick = () => {
    navigate('/new-rantevou-service', {
      state: { subDepartmentId: selectedSubDepartment.id },
    });
  };

  useEffect(() => {
    if (state?.subDepartmentId) {
      subDepartmentStore.getSubDepartmentById(state?.subDepartmentId);
    }
  }, [subDepartmentStore, state]);

  useEffect(() => {
    if (state?.subDepartmentId) {
      setSelectedSubDepartment(subDepartmentStore.selectedSubDepartment);
    }
  }, [subDepartmentStore, subDepartmentStore.selectedSubDepartment]);

  useEffect(() => {
    if (
      subDepartmentStore.subDepartmentsList &&
      subDepartmentStore.subDepartmentsList?.length === 0
    ) {
      subDepartmentStore.getSubDepartments();
    }
  }, [subDepartmentStore, subDepartmentStore.subDepartmentsList]);

  useEffect(() => {
    if (selectedSubDepartment) {
      setLoading(true);
      appointmentServiceStore
        .getAppointmentServices(
          selectedSubDepartment.id,
          currentPage,
          PAGE_SIZE
        )
        .then((res) => {
          const totalPages = Math.ceil(
            res.result.totalCount / res.result.pageSize
          );
          setTotalPages(totalPages);
          setAppointmentServices(res.result.items);
          setLoading(false);
        })
        .catch(() => {
          setAppointmentServices({});
          setLoading(false);
          warning('Η επικοινωνία με τον διακομιστή απέτυχε');
          navigate('/rantevou-service');
        });
    }
  }, [appointmentServiceStore, selectedSubDepartment, currentPage]);

  return (
    <>
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>

      {isAdmin ? (
        <div className="ml-6 mr-6 mb-15">
          {loading ? (
            <div className="text-center py-10">
              <CircularProgress
                sx={{
                  color: '#003375',
                }}
                size="3rem"
              />
            </div>
          ) : (
            <>
              <div className="mt-6">
                <Typography variant="h3" color={'#003375'} gutterBottom>
                  Διαχείριση υπηρεσιών ραντεβού
                </Typography>
              </div>

              <Typography
                sx={{ fontWeight: 'medium' }}
                mt={2}
                variant="h4"
                gutterBottom
              >
                Επιλογή υποτμήματος:
                <Autocomplete
                  options={subDepartmentStore.subDepartmentsList}
                  getOptionLabel={(option) => option.name}
                  value={selectedSubDepartment}
                  onChange={(event, newValue) =>
                    setSelectedSubDepartment(newValue)
                  }
                  sx={{ width: 300, marginTop: 2 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="bg-[#FFFF] mt-14"
                      fullWidth
                      label={'Υποτμήμα'}
                    />
                  )}
                />
              </Typography>

              {selectedSubDepartment?.name && (
                <>
                  <div className="flex items-center justify-center">
                    <Typography
                      sx={{ fontWeight: 'bold' }}
                      mt={4}
                      variant="h4"
                      color={'#003375'}
                    >
                      {selectedSubDepartment?.name}
                    </Typography>
                  </div>

                  <Typography
                    sx={{ fontWeight: 'medium' }}
                    mt={6}
                    variant="h4"
                    gutterBottom
                  >
                    Προσφερόμενες υπηρεσίες ραντεβού
                  </Typography>
                  {appointmentServices?.length > 0 ? (
                    <>
                      <div className="flex flex-col mt-4 p-4 bg-[#d9f6ff]">
                        {appointmentServices?.map((service) => (
                          <div key={service.id} className="flex flex-col mt-">
                            {renderDeleteRantevouServiceModal(service?.id)}
                            <div className="flex flex-row justify-between mb-2 mt-2 items-center">
                              <Typography
                                sx={{ fontWeight: 'medium' }}
                                variant="h5"
                                gutterBottom
                              >
                                {service.title}
                              </Typography>
                              <div className="flex gap-2">
                                <SubmitButton
                                  sx={{ backgroundColor: '#003375' }}
                                  className="capitalize text-sm"
                                  title={'ΠΡΟΒΟΛΗ'}
                                  loading={loading}
                                  variant="contained"
                                  onClick={() => onViewClick(service)}
                                />
                                <SubmitButton
                                  sx={{
                                    backgroundColor: '#e87272',
                                    '&:hover': {
                                      backgroundColor: '#9e5454',
                                    },
                                  }}
                                  className="capitalize text-sm"
                                  title={'ΔΙΑΓΡΑΦΗ'}
                                  loading={loading}
                                  variant="contained"
                                  onClick={openDeleteModal}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <CustomPagination
                        className={'mt-4 flex justify-center items-center'}
                        color={'#003375'}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{ fontWeight: 'medium' }}
                      mt={6}
                      variant="h4"
                      gutterBottom
                    >
                      Δεν υπάρχουν διαθέσιμες υπηρεσίες ραντεβού αυτήν την
                      στιγμή
                    </Typography>
                  )}

                  <Stack direction="row" justifyContent="flex-end" mt={4}>
                    <AnimateButton>
                      <SubmitButton
                        sx={{ backgroundColor: '#003375' }}
                        className="capitalize text-sm"
                        title={'Προσθήκη νέας'}
                        loading={loading}
                        variant="contained"
                        onClick={() => onAddClick()}
                      />
                    </AnimateButton>
                  </Stack>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <NoAccessCard />
      )}
    </>
  );
};

export default observer(RantevouService);
