import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularSpinner from '../Spinners/CircularSpinner';

function SubmitButton({
  bgColor = '#003375', //Not used yet //TODO: to be implemented
  title,
  titleColor,
  hoverColor,
  heading = 'h4', //Not used yet //TODO: to be implemented
  elmType = '',
  buttonTitle = '',
  loading,
  size,
  type,
  variant,
  key,
  color,
  className,
  onClick,
  disabled,
  ...others
}) {
  const { t } = useTranslation();

  const RenderButtonTitle = () => {
    const { t } = useTranslation();
    if (buttonTitle === 'loginButton') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          Συνδεθειτε
        </Typography>
      );
    } else if (buttonTitle === 'forgotButton') {
      return (
        <div
          style={{
            color: '#FFFFFF',
          }}
          variant="h4"
        >
          Επαναφορα Κωδικου
        </div>
      );
    } else if (buttonTitle === 'resetPassword') {
      return (
        <div
          style={{
            color: '#FFFFFF',
          }}
          variant="h4"
        >
          Αναννεωση Κωδικου
        </div>
      );
    } else if (buttonTitle === 'addBinButton') {
      return (
        <div
          style={{
            color: '#FFFFFF',
          }}
          variant="h4"
        >
          {t('forms.Submit')}
        </div>
      );
    } else if (buttonTitle === 'addUserButton') {
      return (
        <div
          style={{
            color: '#FFFFFF',
          }}
          variant="h4"
        >
          Υποβολη
        </div>
      );
    } else if (buttonTitle === 'updateThresholdButton') {
      return (
        <div
          style={{
            color: '#FFFFFF',
          }}
          variant="h4"
        >
          {t('forms.Submit')}
        </div>
      );
    } else if (buttonTitle === 'submit') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('forms.Submit')}
        </Typography>
      );
    } else if (buttonTitle === 'export') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('forms.exportData')}
        </Typography>
      );
    } else if (buttonTitle === 'import') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('trashBins.MassImport')}
        </Typography>
      );
    } else if (buttonTitle === 'binDeviceImport') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('trashBinDevices.MassImport')}
        </Typography>
      );
    } else if (buttonTitle === 'exportToPdf') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('exportChart.exportToPdf')}
        </Typography>
      );
    } else if (buttonTitle === 'rantevouModalNo') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          οχι
        </Typography>
      );
    } else if (buttonTitle === 'addComponent') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          {t('addComponent.addComponent')}
        </Typography>
      );
    } else if (buttonTitle === 'rantevouModal') {
      return (
        <Typography
          style={{
            color: '#FFFFFF',
          }}
        >
          ναι
        </Typography>
      );
    }
    // else if (buttonTitle === "addComponent") {
    //       return (
    //         <Typography
    //           style={{
    //             color: "#FFFFFF",
    //           }}
    //         >
    //           {title}
    //         </Typography>
    //       );
    //     }
    //  }
    else {
      return (
        <Typography
          style={{
            color: titleColor ?? '#FFFFFF',
            fontSize: '1rem',
          }}
          className={className}
        >
          {title}
        </Typography>
      );
    }
  };

  //TODO: to be implemented. It is already ready to use
  const RenderButtonElm = () => {
    return (
      <div
        style={{
          color: bgColor,
        }}
        variant={heading}
      >
        <Typography
          style={{
            color: titleColor,
          }}
        >
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <Button
      className={className}
      size={size || 'large'}
      type={type || 'button'}
      onClick={onClick}
      variant={variant || 'contained'}
      color={color || 'primary'}
      key={key}
      disabled={loading || disabled}
      {...others}
    >
      {loading && (
        <CircularSpinner
          size="20px"
          color="inherit"
          sx={{ marginRight: '15px' }}
        />
      )}
      {RenderButtonTitle()}
      {/* {RenderButtonElm()} */}
    </Button>
  );
}

export default SubmitButton;
