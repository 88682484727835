import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { palette } from '../../../styles/variables/colors';

const StatusTile = ({ title, counter, onClick, isActive }) => {
  const mainColor = palette.info.darker;

  return (
    <Button>
      <Box
        sx={{
          width: 200,
          height: 175,
          bgcolor: isActive ? palette.info.main : mainColor,
          // borderRadius: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: isActive ? mainColor : '#fff',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <div
          className={`bg-[${mainColor}] w-full flex items-center justify-center`}
        >
          <Typography
            className="normal-case"
            variant="h3"
            color={isActive ? 'black' : 'white'}
            gutterBottom
            paddingY={1}
          >
            {title}
          </Typography>
        </div>
        <div
          className={`bg-gray-100 text-[${mainColor}] w-full h-full flex items-center justify-center`}
        >
          <Typography
            variant="h1"
            color={mainColor}
            gutterBottom
            fontWeight="bold"
            fontSize={'3rem'}
          >
            {counter}
          </Typography>
        </div>
      </Box>
    </Button>
  );
};

export default StatusTile;
