import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { palette } from '../../../styles/variables/colors';
import { FORM_STATUS } from './Users';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorOutline } from '@mui/icons-material';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { error, success } from '../../../Common/Toast/Toast';
import PasswordInput from '../../../Components/Theme/Inputs/PasswordInput';
import { passwordRegex } from '../../../Common/Regex';
import { observer } from 'mobx-react';

const OrganizationsList = ({
  value,
  error,
  onChange,
  organizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="organizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον οργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="organizations-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {organizations?.map((item) => (
            <FormControlLabel
              key={item?.id}
              value={item?.id}
              control={<Radio disabled={isEdit} value={item?.id} />}
              label={item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SubOrganizationsList = ({
  value,
  error,
  onChange,
  subOrganizations,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="subOrganizations-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε τον Υποοργανισμό που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!subOrganizations?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένοι Υποοργανισμοί για αυτόν τον Οργανισμό
            </Typography>
          ) : (
            subOrganizations?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const DepartmentsList = ({
  value,
  error,
  onChange,
  departments,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel className="mb-2" id="departments-radio-buttons-group-label">
          <Typography variant="h5">
            Επιλέξτε το Τμήμα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="departments-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!departments?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένα Τμήματα για αυτόν τον Υποοργανισμό
            </Typography>
          ) : (
            departments?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SubDepartmentsList = ({
  value,
  error,
  onChange,
  subDepartments,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel
          className="mb-2"
          id="subDepartments-radio-buttons-group-label"
        >
          <Typography variant="h5">
            Επιλέξτε το Υποτμήμα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!subDepartments?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένα Υποτμήματα για αυτό το Τμήμα
            </Typography>
          ) : (
            subDepartments?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const SectorsList = ({
  value,
  error,
  onChange,
  sectors,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel className="mb-2" id="sectors-radio-buttons-group-label">
          <Typography variant="h5">
            Επιλέξτε τον Τομέα που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {!sectors?.length ? (
            <Typography className="mt-2">
              Δεν υπάρχουν καταχωρημένοι Τομείς για αυτό το Υποτμήμα
            </Typography>
          ) : (
            sectors?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.id}
                control={<Radio disabled={isEdit} value={item?.id} />}
                label={item?.name}
              />
            ))
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const RolesList = ({
  value,
  error,
  onChange,
  roles,
  required = false,
  isEdit = false,
}) => {
  return (
    <Box className="mt-5">
      <FormControl error={error} required={required}>
        <FormLabel className="mb-2" id="sectors-radio-buttons-group-label">
          <Typography variant="h5">
            Επιλέξτε τον Ρόλο που ανήκει:
            {error && (
              <ErrorOutline
                color={palette.error.main}
                sx={{
                  fontSize: 18,
                  marginBottom: '1px',
                  marginLeft: 1,
                  color: palette.error.main,
                }}
              />
            )}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="sectors-radio-buttons-group-label"
          value={value}
          onChange={onChange}
          className="ml-3"
        >
          {roles
            ?.filter((item) => item?.name !== 'Citizen')
            ?.map((item) => (
              <FormControlLabel
                key={item?.id}
                value={item?.name}
                control={<Radio disabled={isEdit} value={item?.name} />}
                label={item?.name}
              />
            ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export const USER_FIELD = {
  UserName: 'username',
  Name: 'name',
  SurName: 'surname',
  Email: 'email',
  PhoneNumber: 'phoneNumber',
  SubOrganizationId: 'subOrganizationId',
  OrganizationId: 'organizationId',
  SectorId: 'sectorId',
  DepartmentId: 'departmentId',
  SubDepartmentId: 'subDepartmentId',
  Role: 'role',
  Password: 'password',
};

const defaultValues = {
  [USER_FIELD.UserName]: '',
  [USER_FIELD.Name]: '',
  [USER_FIELD.SurName]: '',
  [USER_FIELD.Email]: '',
  [USER_FIELD.PhoneNumber]: '',
  [USER_FIELD.SubOrganizationId]: null,
  [USER_FIELD.OrganizationId]: null,
  [USER_FIELD.SectorId]: null,
  [USER_FIELD.DepartmentId]: null,
  [USER_FIELD.SubDepartmentId]: null,
  [USER_FIELD.Role]: '',
  [USER_FIELD.Password]: '',
};

const UsersCreateEditForm = ({
  isEdit,
  item,
  handleAction,
  refreshList,
  organizations,
  subOrganizations,
  departments,
  subDepartments,
  sectors,
  roles,
  claims,
}) => {
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [userClaims, setUserClaims] = useState([]);

  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  const checkUserClaim = (claimName) => {
    const userClaimsArray = userClaims;
    return userClaimsArray?.includes(claimName);
  };

  const onClaimChange = (claim, isChecked) => {
    let params = {
      userId: item?.id,
      claimId: claim.id,
    };
    if (isChecked) {
      AuthSetAgent.authSetup.RemoveClaimFromUser(params).then((resp) => {
        success('Το δικαίωμα αφαιρέθηκε');
        AuthSetAgent.authSetup
          .getUserClaims(item?.id)
          .then((resp) => {
            setUserClaims(resp?.claims);
          })
          .catch(() => {})
          .finally(() => {});
      });
    } else {
      AuthSetAgent.authSetup.AddClaimToUser(params).then((resp) => {
        success('Το δικαίωμα προσθέθηκε');
        AuthSetAgent.authSetup
          .getUserClaims(item?.id)
          .then((resp) => {
            setUserClaims(resp?.claims);
          })
          .catch(() => {})
          .finally(() => {});
      });
    }
  };

  const UserSchema = yup.object().shape({
    [USER_FIELD.UserName]: isEdit
      ? yup.string().notRequired()
      : yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.Name]: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.SurName]: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.Email]: isEdit
      ? yup
          .string()
          .email('To πεδίο πρέπει να είναι τύπου email')
          .notRequired('Το πεδίο ειναι υποχρεωτικό')
      : yup
          .string()
          .email('To πεδίο πρέπει να είναι τύπου email')
          .required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.PhoneNumber]: yup
      .string()
      .trim()
      .min(10, 'Το τηλέφωνο πρέπει να έχει τουλάχιστον 10 χαρακτήρες')
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.SubOrganizationId]: isEdit
      ? yup.string().notRequired()
      : selectedRole === 'SubOrganizationSupervisor' ||
        selectedRole === 'SectorSupervisor' ||
        selectedRole === 'DepartmentAdmin' ||
        selectedRole === 'User'
      ? yup.string().required('Το πεδίο είναι υποχρεωτικό')
      : yup.string().notRequired(),
    [USER_FIELD.OrganizationId]: isEdit
      ? yup.string().notRequired()
      : selectedRole === 'OrganizationSupervisor' ||
        selectedRole === 'SubOrganizationSupervisor' ||
        selectedRole === 'SectorSupervisor' ||
        selectedRole === 'DepartmentAdmin' ||
        selectedRole === 'User' ||
        selectedRole === 'Moderator' ||
        selectedRole === 'ContentManager'
      ? yup.string().required('Το πεδίο είναι υποχρεωτικό')
      : yup.string().notRequired(),
    [USER_FIELD.SectorId]: isEdit
      ? yup.string().notRequired()
      : selectedRole === 'SectorSupervisor' ||
        selectedRole === 'User' ||
        selectedRole === 'DepartmentAdmin'
      ? yup.string().required('Το πεδίο είναι υποχρεωτικό')
      : yup.string().notRequired(),
    [USER_FIELD.DepartmentId]: isEdit
      ? yup.string().notRequired()
      : selectedRole === 'DepartmentAdmin' || selectedRole === 'User'
      ? yup.string().required('Το πεδίο είναι υποχρεωτικό')
      : yup.string().notRequired(),
    [USER_FIELD.SubDepartmentId]: isEdit
      ? yup.string().notRequired()
      : selectedRole === 'User'
      ? yup.string().required('Το πεδίο είναι υποχρεωτικό')
      : yup.string().notRequired(),
    [USER_FIELD.Role]: isEdit
      ? yup.string().notRequired()
      : yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
    [USER_FIELD.Password]: isEdit
      ? yup.string().trim().strict(true).notRequired()
      : yup
          .string()
          .trim()
          .strict(true)
          .matches(
            passwordRegex,
            'Ο κωδικός πρόσβασης πρέπει να περιέχει ένα μικρό γράμμα, ένα κεφαλαίο και έναν τυχαίο χαρακτήρα επίσης πρέπει να έχει minimum 8 χαρακτήρες'
          )
          .required('Το πεδίο ειναι υποχρεωτικό'),
  });

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: isEdit
      ? {
          [USER_FIELD.UserName]: item?.[USER_FIELD.UserName],
          [USER_FIELD.Name]: item?.[USER_FIELD.Name],
          [USER_FIELD.SurName]: item?.[USER_FIELD.SurName],
          [USER_FIELD.Email]: item?.[USER_FIELD.Email],
          [USER_FIELD.PhoneNumber]: item?.[USER_FIELD.PhoneNumber],
          [USER_FIELD.OrganizationId]: item?.[USER_FIELD.OrganizationId],
          [USER_FIELD.SubOrganizationId]: item?.[USER_FIELD.SubOrganizationId],
          [USER_FIELD.SectorId]: item?.[USER_FIELD.SectorId],
          [USER_FIELD.DepartmentId]: item?.[USER_FIELD.DepartmentId],
          [USER_FIELD.SubDepartmentId]: item?.[USER_FIELD.SubDepartmentId],
          [USER_FIELD.Role]: item?.[USER_FIELD.Role],
          [USER_FIELD.Password]: item?.[USER_FIELD.Password],
        }
      : defaultValues,
    resolver: yupResolver(UserSchema),
  });

  const selectedOrganizationId = watch(USER_FIELD.OrganizationId);
  const selectedSubOrganizationId = watch(USER_FIELD.SubOrganizationId);
  const selectedSectorId = watch(USER_FIELD.SectorId);
  const selectedDepartmentId = watch(USER_FIELD.DepartmentId);

  const filteredSubOrganizations = subOrganizations?.filter(
    (subOrg) =>
      subOrg?.organizationId?.toString() === selectedOrganizationId?.toString()
  );

  const filteredSectors = sectors?.filter(
    (sector) =>
      sector?.subOrganizationId?.toString() ===
      selectedSubOrganizationId?.toString()
  );

  const filteredDepartments = departments?.filter(
    (dep) => dep?.sectorId?.toString() === selectedSectorId?.toString()
  );

  const filteredSubDepartments = subDepartments?.filter(
    (subDep) =>
      subDep?.departmentId?.toString() === selectedDepartmentId?.toString()
  );

  const submitCreate = async () => {
    if (actionLoading) {
      return;
    }
    setActionLoading(true);

    const formValues = watch();

    const formattedBody = {
      ...watch(),
      [USER_FIELD.OrganizationId]: parseInt(
        formValues?.[USER_FIELD.OrganizationId] || null
      ),
      [USER_FIELD.SubOrganizationId]: parseInt(
        formValues?.[USER_FIELD.SubOrganizationId] || null
      ),
      [USER_FIELD.SectorId]: parseInt(
        formValues?.[USER_FIELD.SectorId] || null
      ),
      [USER_FIELD.DepartmentId]: parseInt(
        formValues?.[USER_FIELD.DepartmentId] || null
      ),
      [USER_FIELD.SubDepartmentId]: parseInt(
        formValues?.[USER_FIELD.SubDepartmentId] || null
      ),
    };
    await AuthSetAgent.users
      .createUserBySuperAdmin(formattedBody)
      .then(async (resp) => {
        if (resp === undefined) {
          error('Κάτι πήγε στραβά κατα την δημιουργία του χρήστη');
        } else {
          success('Ο χρήστης δημιουργήθηκε με επιτυχία');
        }
        reset(defaultValues);
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά τη δημιουργία Χρήστη!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const submitEdit = async () => {
    if (actionLoading) {
      return;
    }

    setActionLoading(true);

    const formValues = watch();
    const formattedBody = {
      [USER_FIELD.Name]: formValues?.[USER_FIELD.Name] || null,
      [USER_FIELD.SurName]: formValues?.[USER_FIELD.SurName] || null,
      [USER_FIELD.PhoneNumber]: formValues?.[USER_FIELD.PhoneNumber] || null,
    };
    await AuthSetAgent.users
      .updateUser(item?.id, formattedBody)
      .then(async (resp) => {
        if (resp === undefined) {
          error('Κάτι πήγε στραβά κατα την επεξεργασία του χρήστη');
        } else {
          success('Ο χρήστης ανανεώθηκε με επιτυχία');
        }
        handleAction({ formStatus: FORM_STATUS.HIDDEN });
        await refreshList();
      })
      .catch((e) => {
        error('Παρουσιάστηκε σφάλμα κατά την επεξεργασία Χρήστη!');
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const onSubmit = async () => {
    isEdit ? await submitEdit() : await submitCreate();
  };

  useEffect(() => {
    if (!isEdit) {
      reset(defaultValues);
    } else {
      reset({
        [USER_FIELD.UserName]: item?.[USER_FIELD.UserName] || '',
        [USER_FIELD.Name]: item?.[USER_FIELD.Name] || '',
        [USER_FIELD.SurName]: item?.[USER_FIELD.SurName] || '',
        [USER_FIELD.Email]: item?.[USER_FIELD.Email] || '',
        [USER_FIELD.PhoneNumber]: item?.[USER_FIELD.PhoneNumber] || '',
        [USER_FIELD.SubOrganizationId]:
          item?.[USER_FIELD.SubOrganizationId] || null,
        [USER_FIELD.OrganizationId]: item?.[USER_FIELD.OrganizationId] || null,
        [USER_FIELD.SectorId]: item?.[USER_FIELD.SectorId] || null,
        [USER_FIELD.DepartmentId]: item?.[USER_FIELD.DepartmentId] || null,
        [USER_FIELD.SubDepartmentId]:
          item?.[USER_FIELD.SubDepartmentId] || null,
        [USER_FIELD.Role]: item?.[USER_FIELD.Role] || '',
        [USER_FIELD.Password]: item?.[USER_FIELD.Password] || '',
      });
    }
  }, [isEdit, item, reset]);

  useEffect(() => {
    if (isEdit && item) {
      setSelectedRole(item?.[USER_FIELD.Role]);
    }
  }, [isEdit, item, selectedRole]);

  useEffect(() => {
    if (!isEdit) {
      const resetFieldValues = () => {
        setValue(USER_FIELD.OrganizationId, null);
        setValue(USER_FIELD.SubOrganizationId, null);
        setValue(USER_FIELD.SectorId, null);
        setValue(USER_FIELD.DepartmentId, null);
        setValue(USER_FIELD.SubDepartmentId, null);
      };
      resetFieldValues();
    }
  }, [selectedRole, isEdit]);

  useEffect(() => {
    if (isEdit) {
      setActionLoading(true);
      AuthSetAgent.authSetup
        .getUserClaims(item?.id)
        .then((resp) => {
          setUserClaims(resp?.claims);
        })
        .catch(() => {})
        .finally(() => {
          setActionLoading(false);
        });
    }
  }, [isEdit, item?.id]);

  return (
    <Box className="mr-5">
      <Typography className="pb-5" variant="h3" color={'#003375'} gutterBottom>
        {isEdit ? 'Επεξεργασία Χρήστη' : 'Προσθήκη Χρήστη'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="grid gap-3">
          <Controller
            name={USER_FIELD.UserName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2">
                <TextField
                  className="w-[50%]"
                  label="Ονομασία"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  disabled={isEdit}
                  helperText={
                    error && 'Το πεδίο Ονομασία Χρήστη είναι υποχρεωτικό!'
                  }
                />
              </Box>
            )}
          />
          <Controller
            name={USER_FIELD.Name}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2">
                <TextField
                  className="w-[50%]"
                  label="Ονομα"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Το πεδίο Ονομα είναι υποχρεωτικό!'}
                />
              </Box>
            )}
          />
          <Controller
            name={USER_FIELD.SurName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2">
                <TextField
                  className="w-[50%]"
                  label="Επώνυμο"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Το πεδίο Επώνυμο είναι υποχρεωτικό!'}
                />
              </Box>
            )}
          />
          <Controller
            name={USER_FIELD.Email}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2">
                <TextField
                  className="w-[50%]"
                  label="Email"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Εισάγετε έγκυρο email'}
                  disabled={isEdit}
                />
              </Box>
            )}
          />
          {isEdit ? null : (
            <Controller
              name={USER_FIELD.Password}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Box className="flex mb-2">
                  <PasswordInput
                    className="w-[50%]"
                    label="Κωδικός"
                    type="password"
                    onChange={onChange}
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : null}
                    disabled={isEdit}
                  />
                </Box>
              )}
            />
          )}

          <Controller
            name={USER_FIELD.PhoneNumber}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box className="flex mb-2">
                <TextField
                  className="w-[50%]"
                  label="Τηλέφωνο"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error && 'Το πεδίο Τηλέφωνο είναι υποχρεωτικό!'}
                />
              </Box>
            )}
          />

          <div>
            {isEdit &&
              claims
                ?.filter((claim) => claim?.name !== 'can_manage_thresholds')
                ?.map((claim, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        value={checkUserClaim(claim?.name)}
                        checked={checkUserClaim(claim?.name)}
                        onChange={() => {
                          onClaimChange(claim, checkUserClaim(claim?.name));
                        }}
                      />
                    }
                    label={
                      claim?.name === 'ManageTickets'
                        ? 'Δικαίωμα Αίτησης'
                        : claim?.name === 'ManageRantevou'
                        ? 'Δικαίωμα Ραντεβού'
                        : claim?.name === 'ManageCertificates'
                        ? 'Δικαίωμα Πιστοποιητικών'
                        : null || '-'
                    }
                  />
                ))}
          </div>

          <div className="flex">
            <Controller
              name={USER_FIELD.Role}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <div className={`${isEdit ? 'ml-1' : 'ml-2'}`}>
                  <RolesList
                    error={!!error}
                    onChange={(event) => {
                      onChange(event);
                      handleRoleChange(event.target.value);
                    }}
                    value={value}
                    roles={roles}
                    isEdit={isEdit}
                  />
                </div>
              )}
            />

            {(selectedRole === 'OrganizationSupervisor' ||
              selectedRole === 'SubOrganizationSupervisor' ||
              selectedRole === 'SectorSupervisor' ||
              selectedRole === 'DepartmentAdmin' ||
              selectedRole === 'User' ||
              selectedRole === 'Moderator' ||
              selectedRole === 'ContentManager') && (
              <Controller
                name={USER_FIELD.OrganizationId}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="ml-12">
                    <OrganizationsList
                      error={!!error}
                      onChange={onChange}
                      organizations={organizations}
                      value={value}
                      isEdit={isEdit}
                    />
                  </div>
                )}
              />
            )}

            {watch(USER_FIELD.OrganizationId) &&
              (selectedRole === 'SubOrganizationSupervisor' ||
                selectedRole === 'SectorSupervisor' ||
                selectedRole === 'DepartmentAdmin' ||
                selectedRole === 'User') && (
                <Controller
                  name={USER_FIELD.SubOrganizationId}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Box className="ml-20">
                      <SubOrganizationsList
                        error={!!error}
                        onChange={onChange}
                        subOrganizations={filteredSubOrganizations}
                        value={value}
                        isEdit={isEdit}
                      />
                    </Box>
                  )}
                />
              )}

            {watch(USER_FIELD.SubOrganizationId) &&
              (selectedRole === 'SectorSupervisor' ||
                selectedRole === 'DepartmentAdmin' ||
                selectedRole === 'User') && (
                <Controller
                  name={USER_FIELD.SectorId}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Box className="ml-20">
                      <SectorsList
                        error={!!error}
                        onChange={onChange}
                        value={value}
                        sectors={filteredSectors}
                        isEdit={isEdit}
                      />
                    </Box>
                  )}
                />
              )}

            {watch(USER_FIELD.SectorId) &&
              (selectedRole === 'DepartmentAdmin' ||
                selectedRole === 'User') && (
                <Controller
                  name={USER_FIELD.DepartmentId}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Box className="ml-20">
                      <DepartmentsList
                        error={!!error}
                        onChange={onChange}
                        departments={filteredDepartments}
                        value={value}
                        isEdit={isEdit}
                      />
                    </Box>
                  )}
                />
              )}

            {watch(USER_FIELD.DepartmentId) && selectedRole === 'User' && (
              <Controller
                name={USER_FIELD.SubDepartmentId}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Box className="ml-20">
                    <SubDepartmentsList
                      error={!!error}
                      onChange={onChange}
                      subDepartments={filteredSubDepartments}
                      value={value}
                      isEdit={isEdit}
                    />
                  </Box>
                )}
              />
            )}
          </div>
        </Box>
        <Box className="flex justify-end pt-5">
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              onClick={() => {
                handleAction({ formStatus: FORM_STATUS.HIDDEN });
              }}
              variant="contained"
              title="Ακύρωση"
            />
          </AnimateButton>
          <AnimateButton>
            <SubmitButton
              sx={{
                backgroundColor: '#184682',
                color: '#f9f9f9',
                textTransform: 'none',
                marginRight: '15px',
                '&:hover': {
                  backgroundColor: '#184682',
                  color: '#f9f9f9',
                },
                fontSize: '1rem',
              }}
              loading={actionLoading}
              onClick={() => {}}
              variant="contained"
              type="submit"
              title="Υποβολή"
            />
          </AnimateButton>
        </Box>
      </form>
    </Box>
  );
};

export default observer(UsersCreateEditForm);
