import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import AuthSetAgent from '../Api/AuthSetAgent';

class SectorsStore {
  sectorsList = [];

  constructor() {
    makeObservable(this, {
      sectorsList: observable,
      getAllSectors: action,
    });
  }

  getAllSectors = () => {
    return AuthSetAgent.sectors
      .getSectors()
      .then((res) => {
        this.sectorsList = res.result.items;
        return res;
      })
      .catch((err) => {
        console.error('err', err);
      });
  };
}

export const sectorsStore = new SectorsStore();
export const SectorsStoreContext = createContext(sectorsStore);
export const SectorsStoreProvider = ({ children, store }) => (
  <SectorsStoreContext.Provider value={store}>
    {children}
  </SectorsStoreContext.Provider>
);
export const useSectorsStore = () => useContext(SectorsStoreContext);
