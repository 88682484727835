import { observer } from 'mobx-react';
import Topbar from '../../../Components/Topbar/Topbar';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { useEffect, useRef, useState } from 'react';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useUserStore } from '../../../Stores/UserStore';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import Accordions from '../../../Components/Accordion/Accordion';
import { useAuthStore } from '../../../Stores/AuthStore';
import { useSubOrganizationsStore } from '../../../Stores/SubOrganizationsStore';
import { useSectorsStore } from '../../../Stores/SectorsStore';

const ImpersonationAccess = () => {
  const [selectedDepId, setSelectedDepId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chooseButtonClicked, setChooseButtonClicked] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedSubOrgId, setSelectedSubOrgId] = useState(null);
  const [selectedSectorId, setSelectedSectorId] = useState(null);
  const chooseButtonRef = useRef();
  const departmentStore = useDepartmentStore();
  const usersStore = useUserStore();
  const authStore = useAuthStore();
  const subOrganizationStore = useSubOrganizationsStore();
  const sectorsStore = useSectorsStore();

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const handleOrgChange = (orgId) => {
    setSelectedOrgId(orgId);
    setSelectedSubOrgId(null);
    setSelectedSectorId(null);
  };

  const handleSubOrgChange = (subOrgId) => {
    setSelectedSubOrgId(subOrgId);
    setSelectedSectorId(null);
  };

  const handleSectorChange = (sectorId) => {
    setSelectedSectorId(sectorId);
  };

  const handleRadioChange = (event) => {
    setSelectedDepId(event.target.value);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleUserSelect = (email) => {
    setEmail(email);
  };

  const handleSubmit = () => {
    if (selectedDepId) {
      setLoading(true);
      usersStore
        .getUsersByDepartmentId(selectedDepId)
        .then(() => {
          setChooseButtonClicked(true);
        })
        .catch((err) => {
          console.error('err', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const finalSubmit = () => {
    setLoading(true);
    authStore.getImpersonatedToken(email).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (chooseButtonClicked) {
      chooseButtonRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [chooseButtonClicked]);

  useEffect(() => {
    if (chooseButtonClicked) {
      usersStore.getAllRoles();
    }
  }, [usersStore.roles?.length, usersStore, chooseButtonClicked]);

  useEffect(() => {
    setLoading(true);
    subOrganizationStore.getAllOrganizations();
    subOrganizationStore.getAllSubOrganizations();
    sectorsStore.getAllSectors();
    departmentStore.getDepartments().finally(() => {
      setLoading(false);
    });
  }, [
    subOrganizationStore.organizationsList?.length,
    subOrganizationStore.subOrganizationsList?.length,
    subOrganizationStore,
    sectorsStore.sectorsList?.length,
    sectorsStore,
    departmentStore.departmentList?.length,
    departmentStore,
  ]);

  return (
    <>
      {loading ? (
        loader
      ) : (
        <Box className="min-h-[110vh]">
          <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
          <Box className="ml-4">
            <Typography
              className="pt-8 pb-5 pl-1"
              variant="h3"
              color={'#003375'}
              gutterBottom
            >
              Πρόσβαση σε υπηρεσίες Τμήματος
            </Typography>

            <Typography className="pl-1" variant="h4" gutterBottom>
              Επιλέξτε <b>Οργανισμό</b>
            </Typography>

            <div className="pt-1 pl-1">
              {subOrganizationStore.organizationsList?.map((organization) => (
                <div className="mt-3" key={organization?.id}>
                  <RadioGroup
                    row
                    name="organization"
                    value={selectedOrgId}
                    onChange={() => handleOrgChange(organization?.id)}
                  >
                    <FormControlLabel
                      value={organization?.id.toString()}
                      control={<Radio />}
                      label={
                        <div
                          style={{ fontSize: '18px' }}
                          className="flex items-center"
                        >
                          <span>{organization?.name}</span>
                        </div>
                      }
                    />
                  </RadioGroup>
                </div>
              ))}
            </div>

            {selectedOrgId && (
              <div className="pt-6 pl-1">
                <Typography className="pl-1" variant="h4" gutterBottom>
                  Επιλέξτε <b>Υποοργανισμό</b>
                </Typography>
                {subOrganizationStore.subOrganizationsList
                  ?.filter(
                    (subOrg) =>
                      subOrg?.organizationId.toString() ===
                      selectedOrgId.toString()
                  )
                  .map((subOrganization) => (
                    <div className="mt-3" key={subOrganization?.id}>
                      <RadioGroup
                        row
                        name="subOrganization"
                        value={selectedSubOrgId}
                        onChange={() => handleSubOrgChange(subOrganization?.id)}
                      >
                        <FormControlLabel
                          value={subOrganization?.id.toString()}
                          control={<Radio />}
                          label={
                            <div
                              style={{ fontSize: '18px' }}
                              className="flex items-center"
                            >
                              <span>{subOrganization?.name}</span>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </div>
                  ))}
              </div>
            )}

            {selectedSubOrgId && (
              <div className="pt-6  pl-1">
                <Typography className="pl-1" variant="h4" gutterBottom>
                  Επιλέξτε <b>Τομέα</b>
                </Typography>
                {sectorsStore.sectorsList
                  ?.filter(
                    (sector) =>
                      sector.subOrganizationId.toString() ===
                      selectedSubOrgId.toString()
                  )
                  .map((sector) => (
                    <div className="mt-3" key={sector?.id}>
                      <RadioGroup
                        row
                        name="sector"
                        value={selectedSectorId}
                        onChange={() => handleSectorChange(sector?.id)}
                      >
                        <FormControlLabel
                          value={sector?.id.toString()}
                          control={<Radio />}
                          label={
                            <div
                              style={{ fontSize: '18px' }}
                              className="flex items-center"
                            >
                              <span>{sector?.name}</span>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </div>
                  ))}
              </div>
            )}
            {selectedSectorId && (
              <div className="pt-6  pl-1">
                <Typography className="pl-1" variant="h4" gutterBottom>
                  Επιλέξτε <b>Τμήμα</b> για πρόσβαση στις προσφερόμενες
                  υπηρεσίες του
                </Typography>
                {departmentStore.departmentList
                  ?.filter(
                    (department) =>
                      department?.sectorId.toString() ===
                      selectedSectorId.toString()
                  )
                  .map((department) => (
                    <div className="mt-3" key={department?.id}>
                      <RadioGroup
                        row
                        name="department"
                        value={selectedDepId}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value={department?.id.toString()}
                          control={<Radio />}
                          label={
                            <div
                              style={{ fontSize: '18px' }}
                              className="flex items-center"
                            >
                              <span>{department?.name}</span>
                              <span className="ml-2">
                                {department?.sectorLocationName}
                              </span>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </div>
                  ))}
                <div className="flex justify-end items-end">
                  <Button
                    sx={{
                      backgroundColor: '#184682',
                      color: '#f9f9f9',
                      textTransform: 'none',
                      marginRight: '15px',
                      '&:hover': {
                        backgroundColor: '#184682',
                        color: '#f9f9f9',
                      },
                      fontSize: '1rem',
                    }}
                    loading={loading}
                    type={'button'}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Επιλογή
                  </Button>
                </div>
              </div>
            )}
            {chooseButtonClicked ? (
              usersStore.usersByDepIdList?.length ? (
                <ExpandableSectionContainer ref={chooseButtonRef}>
                  <Accordions
                    textClassName="text-[#003375]"
                    textSuperAdminPages={'Επιλέξτε τύπο χρήστη'}
                  >
                    <ul className="w-[100%] sm:w-[61%] md:w-[53%] lg:w-[38%] xl:w-[33%]">
                      {usersStore.roles
                        ?.filter((role) =>
                          ['DepartmentAdmin', 'User', 'Moderator'].includes(
                            role?.name
                          )
                        )
                        ?.map((role, index) => (
                          <li
                            onClick={() => handleRoleSelect(role?.name)}
                            key={index}
                            style={{
                              backgroundColor:
                                selectedRole === role?.name
                                  ? '#E8E8E9'
                                  : 'transparent',
                              cursor: 'pointer',
                              padding: '3px',
                            }}
                          >
                            <Typography component="span" variant="h4">
                              {role?.name}
                            </Typography>
                          </li>
                        ))}
                    </ul>
                  </Accordions>
                  {selectedRole && (
                    <Accordions
                      className="mt-2"
                      textClassName="text-[#003375]"
                      textSuperAdminPages={'Επιλέξτε χρήστη'}
                    >
                      {usersStore.usersByDepIdList?.some(
                        (user) => user?.role === selectedRole
                      ) ? (
                        <ul className="w-[100%] sm:w-[61%] md:w-[53%] lg:w-[38%] xl:w-[33%]">
                          {usersStore.usersByDepIdList
                            ?.filter((user) => user?.role === selectedRole)
                            ?.map((user, index) => (
                              <li
                                onClick={() => handleUserSelect(user?.email)}
                                style={{
                                  backgroundColor:
                                    email === user?.email
                                      ? '#E8E8E9'
                                      : 'transparent',
                                  cursor: 'pointer',
                                  padding: '3px',
                                }}
                                key={index}
                              >
                                <Typography component="span" variant="h4">
                                  {user?.username}
                                </Typography>
                              </li>
                            ))}
                        </ul>
                      ) : (
                        <Typography className="pl-1" variant="h4" gutterBottom>
                          Δεν υπάρχουν χρήστες για το ρόλο που επιλέξατε σε αυτό
                          το τμήμα
                        </Typography>
                      )}
                    </Accordions>
                  )}
                  <div className="flex justify-end items-end">
                    <Button
                      sx={{
                        backgroundColor: '#184682',
                        color: '#f9f9f9',
                        textTransform: 'none',
                        marginRight: '15px',
                        '&:hover': {
                          backgroundColor: '#184682',
                          color: '#f9f9f9',
                        },
                        fontSize: '1rem',
                      }}
                      loading={loading}
                      type={'button'}
                      variant="contained"
                      onClick={finalSubmit}
                    >
                      Πρόσβαση
                    </Button>
                  </div>
                </ExpandableSectionContainer>
              ) : (
                <ExpandableSectionContainer ref={chooseButtonRef}>
                  <Typography className="pl-1" variant="h4" gutterBottom>
                    Δεν υπάρχουν χρήστες για το τμήμα που επιλέξατε
                  </Typography>
                </ExpandableSectionContainer>
              )
            ) : null}
          </Box>
        </Box>
      )}
    </>
  );
};

export default observer(ImpersonationAccess);
