import { observer } from 'mobx-react';
import certificatesBlueIcon from '../../../assets/certificates-blue.png';
import rantevouBlueIcon from '../../../assets/rantevou-blue.png';
import ticketsIcon from '../../../assets/aitimata.png';
import CertificateOverviewCurrentResults from '../CertificatesOverview/CertificateOverviewCurrentResults';
import RantevouOverviewCurrentResults from '../RantevouOverview/RantevouOverviewCurrentResults';
import DashBoardTicketsTable from './DashBoardTicketsTable';
import DashBoardImageTitle from './DashBoardImageTitle';

const DashBoardTables = () => {
  return (
    <>
      <DashBoardImageTitle
        width={52}
        src={certificatesBlueIcon}
        alt={'pistopiitika'}
        text={'Πιστοποιητικά'}
      />
      <div className="pt-2">
        <CertificateOverviewCurrentResults />
      </div>

      <div className="pt-6">
        <DashBoardImageTitle
          width={52}
          src={rantevouBlueIcon}
          alt={'rantevou'}
          text={'Ραντεβού'}
        />
        <div className="pt-4">
          <RantevouOverviewCurrentResults />
        </div>
      </div>

      <div className="pt-6">
        <div className="pl-2">
          <DashBoardImageTitle
            width={45}
            src={ticketsIcon}
            alt={'aitimata'}
            text={'Αιτήματα/ παράπονα πολιτών'}
          />
        </div>
        <div className="pt-4">
          <DashBoardTicketsTable />
        </div>
      </div>
    </>
  );
};

export default observer(DashBoardTables);
