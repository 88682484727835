import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const maxCharsPerLine = 22;

const StatsGroupedBarChart = ({
  data,
  title = null,
  horizontal = false,
  yAxisTitle = null,
  xAxisTitle = null,
  showLegend = true,
  tooltipLabelCallback = null,
  maxHeight = 400,
  charsPerLine = maxCharsPerLine,
  limitLabelsInOneLine = false,
}) => {
  const [wrappedData, setWrappedData] = useState(null);

  const isEmpty = data?.labels.length === 0;

  const wrapLabels = (labels, charsPerLine) => {
    return labels?.map((label) => {
      if (limitLabelsInOneLine) {
        return label.length > charsPerLine
          ? `${label.substring(0, charsPerLine - 3)}...`
          : label;
      } else {
        const words = label.split(' ').filter((word) => word.length > 0);
        let lines = [];
        let currentLine = '';
        words.forEach((word) => {
          if (currentLine.length + word.length + 1 <= charsPerLine) {
            currentLine =
              currentLine.length > 0 ? `${currentLine} ${word}` : word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        });
        lines.push(currentLine);
        return lines;
      }
    });
  };

  const options = {
    indexAxis: horizontal ? 'y' : 'x',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: showLegend && !isEmpty,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const label = data?.labels[context[0].dataIndex];
            return label;
          },
          label: (context) => {
            if (tooltipLabelCallback) {
              return tooltipLabelCallback(context);
            }
            const label = context.dataset.label || '';
            if (label) {
              return `${label}: ${context.formattedValue}`;
            }
            return '';
          },
        },
      },
    },
    scales: {
      y: {
        title: {
          display: !!yAxisTitle,
          text: yAxisTitle,
        },
      },
      x: {
        title: {
          display: !!xAxisTitle,
          text: xAxisTitle,
        },
      },
    },
  };

  useEffect(() => {
    if (Array.isArray(data?.labels) && data?.labels.length > 0) {
      const modifiedData = {
        ...data,
        labels: wrapLabels(data?.labels, charsPerLine),
      };
      setWrappedData(modifiedData);
    } else if (isEmpty) {
      setWrappedData({
        labels: ['Δεν υπάρχουν δεδομένα'],
        datasets: [
          {
            label: '',
            data: [1],
            backgroundColor: ['#d3d3d3'],
          },
        ],
      });
    } else {
      setWrappedData(null);
    }
  }, [data]);

  return (
    <div
      style={{
        width: '100%',
        maxHeight: maxHeight,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <h3
          style={{
            color: isEmpty ? '#666666' : '#003375',
            fontSize: 18,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {title}
        </h3>
      </div>
      {wrappedData && <Bar data={wrappedData} options={options} />}
    </div>
  );
};

export default StatsGroupedBarChart;
