import { useEffect, useState } from 'react';
import Topbar from '../../../Components/Topbar/Topbar';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { useUserStore } from '../../../Stores/UserStore';
import { Typography } from '@mui/material';
import CounterBox from './CounterBox';
import NotificationsSection from './NotificationsSection';
import certificatesBlueIcon from '../../../../src/assets/certificates-blue.png';
import rantevouBlueIcon from '../../../../src/assets/rantevou-blue.png';
import aitimataBlueIcon from '../../../../src/assets/aitimata-blue.png';
import { observer } from 'mobx-react';
import { useNotificationsStore } from '../../../Stores/NotificationsStore';
import { useNavigate } from 'react-router-dom';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';

const Notifications = () => {
  const departmentStore = useSubDepartmentStore();
  const certificatesStore = useCertificatesStore();
  const userStore = useUserStore();
  const notificationsStore = useNotificationsStore();
  const navigate = useNavigate();
  const [counterList, setCounterList] = useState([
    {
      title: 'Πιστοποιητικά',
      counter: 0,
      borderColor: 'green',
    },
    {
      title: 'Ραντεβού',
      counter: 0,
      borderColor: 'purple',
    },
    {
      title: 'Αιτήματα / παράπονα πολιτών',
      counter: 0,
      borderColor: '#F24C3D',
    },
  ]);

  const viewCertificateSubmission = (id) => {
    navigate(`/certificates-submissions-view/${id}`);
  };

  const onCertificateClick = async (id, notifiableId) => {
    notificationsStore.setNotificationAsRead(id);
    viewCertificateSubmission(notifiableId);
  };

  const onTicketsClick = (id, notifiableId, notifiableModelType) => {
    notificationsStore.setNotificationAsRead(id);
    if (notifiableModelType === 'TicketApplication') {
      navigate(`/ticket/${notifiableId}`, {
        state: { returnToUrl: `/` },
      });
    } else {
      return null;
    }
  };

  const onRantevouClick = (id, notifiableId, notifiableModelType) => {
    notificationsStore.setNotificationAsRead(id);

    if (notifiableModelType === 'Schedule') {
      navigate(`/schedule-history/${notifiableId}`, {
        state: { returnToUrl: `/` },
      });
    } else if (notifiableModelType === 'Rantevou') {
      navigate(`/rantevou/${notifiableId}`, {
        state: { returnToUrl: `/` },
      });
    }
  };

  useEffect(() => {
    departmentStore.getSubDepartments();
  }, [departmentStore]);

  useEffect(() => {
    certificatesStore.getCertificatesSubmissionStatuses();
  }, [
    certificatesStore.certificatesSubmissionStatusesList?.length,
    certificatesStore,
  ]);

  useEffect(() => {
    userStore.getAllUsers();
  }, [userStore]);

  useEffect(() => {
    notificationsStore.getStatusCount().then((result) => {
      setCounterList((prev) =>
        prev.map((item) => {
          if (item?.title === 'Πιστοποιητικά') {
            return {
              ...item,
              counter: result?.find((item) => item?.status === 'Certificates')
                ?.count,
            };
          }
          if (item?.title === 'Ραντεβού') {
            return {
              ...item,
              counter: result?.find((item) => item?.status === 'Rantevou')
                ?.count,
            };
          }
          if (item?.title === 'Αιτήματα / παράπονα πολιτών') {
            return {
              ...item,
              counter: result?.find((item) => item?.status === 'Requests')
                ?.count,
            };
          }
          return item;
        })
      );
    });
  }, [notificationsStore]);

  return (
    <div>
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />

      <div className="mt-6 ml-6 mb-15">
        <Typography variant="h3" color={'#003375'} gutterBottom>
          Οι ειδοποιήσεις μου
        </Typography>

        <div>
          <div className="flex flex-row flex-wrap justify-evenly">
            {counterList?.map((item) => (
              <CounterBox key={item.title} {...item} />
            ))}
          </div>
          <div className="mt-6 w-full flex flex-col items-center">
            <NotificationsSection
              sectionTitle="Πιστοποιητικά"
              project={'Certificates'}
              icon={certificatesBlueIcon}
              onItemClick={(id, notifiableId) =>
                onCertificateClick(id, notifiableId)
              }
            />
            <NotificationsSection
              sectionTitle="Ραντεβού"
              project={'Rantevou'}
              icon={rantevouBlueIcon}
              onItemClick={(id, notifiableId, notifiableModelType) =>
                onRantevouClick(id, notifiableId, notifiableModelType)
              }
            />
            <NotificationsSection
              sectionTitle="Αιτήματα / παράπονα πολιτών"
              project={'Requests'}
              icon={aitimataBlueIcon}
              onItemClick={(id, notifiableId, notifiableModelType) => {
                onTicketsClick(id, notifiableId, notifiableModelType);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Notifications);
