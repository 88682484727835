import Topbar from "../../../Components/Topbar/Topbar";
import { observer } from "mobx-react";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAppointmentServiceStore } from "../../../Stores/AppointmentServiceStore";
import RantevouServiceViewEditForm from "./RantevouServiceViewEditForm";
import NoAccessCard from "../../../Components/NoAccessCard/NoAccessCard";
import { useAuthStore } from "../../../Stores/AuthStore";
import { warning } from "../../../Common/Toast/Toast";
import { useSubDepartmentStore } from "../../../Stores/SubDepartmentStore";
import { ORG_NAME } from "../../../Config/constant";

const RantevouServiceViewEdit = ({ subDepartmentId }) => {
    const appointmentServiceStore = useAppointmentServiceStore();
    const subDepartentStore = useSubDepartmentStore();
    const { isAdmin } = useAuthStore();
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id: serviceId } = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (subDepartmentId)
    //         subDepartentStore.getSubDepartmentById(subDepartmentId);
    // }, [subDepartentStore]);

    useEffect(() => {
        appointmentServiceStore
            .getAppointmentServiceById(serviceId)
            .then((response) => {
                setService(response.result);
                setLoading(false);
            })
            .catch(() => {
                setService({});
                setLoading(false);
                warning("Η υπηρεσία δεν βρέθηκε");
                navigate("/rantevou-service");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setService, appointmentServiceStore, serviceId]);

    return (
        <>
            <div>
                {" "}
                <Topbar title={`Ηλεκτρονικές Υπηρεσίες ${ORG_NAME.shortName}`} />
            </div>

            {isAdmin ? (
                <>
                    <div className="mt-6 ml-4">
                        <Typography variant="h3" color={"#003375"} gutterBottom>
                            Προβολή υπηρεσίας ραντεβού
                        </Typography>
                    </div>

                    {loading ? (
                        <div className="text-center py-10">
                            <CircularProgress sx={{
                                color: '#003375',
                            }} size="3rem" />
                        </div>
                    ) : (
                        <>
                            <div className="flex items-center justify-center">
                                <Typography sx={{ fontWeight: 'bold' }} mt={4} variant="h4" color={"#003375"}>
                                    {subDepartentStore.selectedSubDepartment?.name}
                                </Typography>
                            </div>

                            <div className="mt-6 ml-4">
                                {service ? (
                                    <RantevouServiceViewEditForm rantevouService={service} />
                                ) : (
                                    <div className="text-center py-10">
                                        <CircularProgress sx={{
                                            color: '#003375',
                                        }} size="3rem" />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <NoAccessCard />
            )}
        </>
    );
};

export default observer(RantevouServiceViewEdit);
