import { Button, FormControl, Grid } from '@mui/material';
import { gridSpacing } from '../../../Config/constant';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import SubCard from '../../../Components/Theme/Cards/SubCard';
import { authStore } from '../../../Stores/AuthStore';
import PasswordInput from '../../../Components/Theme/Inputs/PasswordInput';
import { passwordRegex } from '../../../Common/Regex';

const ChangePassword = () => {
  const { t } = useTranslation();

  const changePasswordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .min(8)
      .max(32)
      .matches(passwordRegex, t('validation.InvalidPassword'))
      .required('Το πεδίο είναι υποχρεωτικό'),
    newPassword: yup
      .string()
      .min(8)
      .max(32)
      .matches(passwordRegex, t('validation.InvalidPassword'))
      .required('Το πεδίο είναι υποχρεωτικό'),
    confirmNewPassword: yup
      .string()
      .min(8)
      .max(32)
      .matches(passwordRegex, t('validation.InvalidPassword'))
      .required('Το πεδίο είναι υποχρεωτικό')
      .oneOf([yup.ref('newPassword'), null], t('forms.passwordsMustMatch')),
  });

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = ({ currentPassword, newPassword, confirmNewPassword }) => {
    authStore.updatePassword(currentPassword, newPassword, confirmNewPassword);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={gridSpacing}>
        <Grid
          item
          container
          direction="column"
          marginTop="1rem"
          alignItems="center"
          xs={12}
          alignContent="space-between"
        >
          {/* <SubCard title={t('forms.changePassword')} > */}
          <div className="flex flex-wrap pt-2 w-full">
            <div className="w-full mb-4 pr-1">
              <FormControl className="w-1/2">
                <Controller
                  name="currentPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t('forms.currentPassword')}
                      onChange={onChange}
                      value={value}
                      error={error}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="w-1/2 pr-1">
              <FormControl className="w-full">
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t('forms.changePassword')}
                      onChange={onChange}
                      value={value}
                      error={error}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="w-1/2 pl-4">
              <FormControl className="w-full">
                <Controller
                  name="confirmNewPassword"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <PasswordInput
                      label={t('forms.confirmPassword')}
                      onChange={onChange}
                      value={value}
                      error={error}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          {/* </SubCard> */}
        </Grid>
      </Grid>

      <div className="text-right mt-6">
        <Button
          className="capitalize text-sm"
          sx={{ backgroundColor: '#003375' }}
          type="submit"
          variant="contained"
        >
          Αλλαγη Κωδικου Προσβασης
        </Button>
      </div>
    </form>
  );
};

export default observer(ChangePassword);
