import { action, makeObservable, observable, toJS } from 'mobx';
import AuthSetAgent from '../Api/AuthSetAgent';
import { createContext, useContext } from 'react';

const StatusOptions = {
  New: 0,
  Modified: 1,
  InProgress: 2,
  Pending: 3,
  Completed: 4,
  Cancelled: 5,
  Deleted: 6,
  Unrealized: 7,
};

const StatusDescriptions = [
  'Νέο',
  'Τροποποιήθηκε',
  'Σε εξέλιξη',
  'Σε εκκρεμμότητα',
  'Ολοκληρώθηκε',
  'Ακυρώθηκε',
  'Διαγράφηκε',
  'Δεν πραγματοποιήθηκε',
];

class NotificatiosStore {
  notifications = [];

  constructor() {
    makeObservable(this, {
      notifications: observable,
      getNotifications: action,
      setNotificationAsRead: action,
      reset: action,
    });
  }

  mapToNotification = (data) => {
    const {
      description,
      id,
      state,
      isRead,
      notifiableId,
      notifiableModelType,
    } = data;

    const notification = {
      id,
      notifiableId,
      title: description,
      status: StatusDescriptions[state],
      isRead,
      notifiableModelType:
        notifiableModelType.split('.').pop() ?? notifiableModelType,
    };

    return notification;
  };

  getNotifications = (pageNumber = null, pageSize = null, project = null) => {
    return AuthSetAgent.notifications
      .getNotifications(pageNumber, pageSize, project)
      .then((res) => {
        this.notifications = res.result.items
          .map((item) => this.mapToNotification(item))
          ?.reverse();
        return res.result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getStatusCount = () => {
    return AuthSetAgent.notifications
      .getStatusCount()
      .then((res) => {
        return res.result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setNotificationAsRead = (id) => {
    AuthSetAgent.notifications
      .setNotificationAsRead(id)
      .then(() => {
        this.getNotifications();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  reset = () => {
    this.notifications = [];
  };
}

export const notificationsStore = new NotificatiosStore();
export const NotificationsStoreContext = createContext(notificationsStore);
export const NotificationsStoreProvider = ({ children, store }) => (
  <NotificationsStoreContext.Provider value={store}>
    {children}
  </NotificationsStoreContext.Provider>
);
export const useNotificationsStore = () =>
  useContext(NotificationsStoreContext);
