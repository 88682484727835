import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import { useEffect } from 'react';
import CertificatesSearchForm from './CertificatesSearchForm';
import { ORG_NAME } from '../../../Config/constant';

const CertificatesSearch = () => {
  const departmentStore = useDepartmentStore();

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length]);

  return (
    <>
      <div>
        <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      </div>
      <div className="mt-6 ml-4">
        <Typography variant="h3" color={'#003375'} gutterBottom>
          Αναζήτηση πιστοποιητικού
        </Typography>
      </div>
      <div className="mt-12">
        <CertificatesSearchForm />
      </div>
    </>
  );
};

export default observer(CertificatesSearch);
