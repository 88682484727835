import { Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react';
import StatusTile from '../RantevouOverview/StatusTile';
import { useState, useEffect } from 'react';
import { useCertificatesStore } from '../../../Stores/CertificatesStore';
import CircularSpinner from '../../../Components/Theme/Spinners/CircularSpinner';
import { useAuthStore } from '../../../Stores/AuthStore';
import { ORG_NAME } from '../../../Config/constant';
import { useDepartmentStore } from '../../../Stores/DepartmentStore';
import CertificatesTable from '../CertificatesTable/CertificatesTable';

const CertificatesOverview = () => {
  const {
    getAllCertificateSubmission,
    allCertificateSubmissionData,
    getStatusCount,
  } = useCertificatesStore();
  const { authUser } = useAuthStore();
  const departmentStore = useDepartmentStore();
  const [loading, setLoading] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusCount, setStatusCount] = useState({});
  const departmentId = authUser.departmentId;

  const handleStatusTileClick = (status) => {
    if (selectedStatus === status) {
      setSelectedStatus(null);
    } else {
      setSelectedStatus(status);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    const departmentIdToUse = departmentId || authUser.departmentId;
    getAllCertificateSubmission(departmentIdToUse)
      .then((res) => {
        setCertificates(res.result.items);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    authUser.departmentId,
    departmentId,
    getAllCertificateSubmission,
    certificates?.length,
    allCertificateSubmissionData?.length,
  ]);

  useEffect(() => {
    departmentStore.getDepartments();
  }, [departmentStore.departmentList?.length, departmentStore]);

  useEffect(() => {
    getStatusCount()
      .then((res) => {
        setStatusCount({
          Draft: res.result.find((item) => item.status === 'Draft')?.count,
          Submitted: res.result.find((item) => item.status === 'Submitted')
            ?.count,
          Approved: res.result.find((item) => item.status === 'Approved')
            ?.count,
          Ongoing: res.result.find((item) => item.status === 'Ongoing')?.count,
          Cancelled: res.result.find((item) => item.status === 'Cancelled')
            ?.count,
          ActionRequired: res.result.find(
            (item) => item.status === 'ActionRequired'
          )?.count,
          Declined: res.result.find((item) => item.status === 'Declined')
            ?.count,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="w-full">
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <div className="mt-6 ml-6 mb-15">
        <Typography variant="h3" color={'#003375'} gutterBottom>
          Επισκόπηση αιτήσεων πιστοποιητικών
        </Typography>
        <div className="flex justify-center items-center mt-16 flex-wrap gap-4">
          <StatusTile
            title="Υποβληθείσες"
            counter={statusCount?.Submitted}
            isActive={selectedStatus === 1}
            onClick={() => handleStatusTileClick(1)}
          />
          <StatusTile
            title="Σε εξέλιξη"
            counter={statusCount?.Ongoing}
            isActive={selectedStatus === 3}
            onClick={() => handleStatusTileClick(3)}
          />
          <StatusTile
            title="Ολοκληρωμένες"
            counter={statusCount?.Approved}
            isActive={selectedStatus === 4}
            onClick={() => handleStatusTileClick(4)}
          />
          <StatusTile
            title="Σε εκκρεμότητα"
            counter={statusCount?.ActionRequired}
            isActive={selectedStatus === 5}
            onClick={() => handleStatusTileClick(5)}
          />
          <StatusTile
            title="Απορριφθείσες"
            counter={statusCount?.Declined}
            isActive={selectedStatus === 6}
            onClick={() => handleStatusTileClick(6)}
          />
        </div>
        <hr className="mt-16 border-1 border-[#003375] w-2/3" />
        {loading ? (
          <div className="w-full mt-16 flex justify-center">
            <CircularSpinner />
          </div>
        ) : (
          <div className="mt-16">
            <CertificatesTable
              status={selectedStatus}
              certificates={certificates}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(CertificatesOverview);
