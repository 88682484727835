import { Button, Typography, styled } from '@mui/material';
import ShowOnlyDatePicker from '../Theme/Inputs/ShowOnlyDatePicker';
import dayjs from 'dayjs';

const DateFilterBar = ({ dateFrom, setDateFrom, dateTo, setDateTo }) => {
  const getPredefinedPeriod = (period) => {
    switch (period) {
      case 'thisMonth':
        return { start: dayjs().startOf('month'), end: dayjs() };
      case 'lastMonth':
        return {
          start: dayjs().subtract(1, 'month').startOf('month'),
          end: dayjs().subtract(1, 'month').endOf('month'),
        };
      case 'thisWeek':
        return { start: dayjs().startOf('week'), end: dayjs() };
      case 'lastWeek':
        return {
          start: dayjs().subtract(1, 'week').startOf('week'),
          end: dayjs().subtract(1, 'week').endOf('week'),
        };
      default:
        return { start: dayjs(), end: dayjs() };
    }
  };

  const updatePeriod = (period) => {
    const { start, end } = getPredefinedPeriod(period);
    setDateFrom(start);
    setDateTo(end);
  };

  const CustomButton = styled(Button)({
    '&:hover': {
      backgroundColor: '#003375',
    },
    backgroundColor: '#0052b4',
    color: 'white',
    padding: '6px 8px',
    borderRadius: '8px',
    fontSize: '14px',
    textTransform: 'none',
  });

  return (
    <div className="flex flex-row justify-between items-center flex-wrap gap-4">
      <div className="flex flex-row items-center justify-center gap-4 md:flex-row flex-col">
        <div className="flex items-center justify-center gap-4">
          {/* <Typography variant="h6" color={'#003375'} gutterBottom>
            Από
          </Typography> */}
          <ShowOnlyDatePicker
            value={dateFrom}
            onChange={(date) => setDateFrom(date)}
            format="DD-MM-YYYY"
            disableFuture
            size="small"
            placeholder="Από"
          />
        </div>

        <div className="flex items-center justify-center gap-4">
          {/* <Typography variant="h6" color={'#003375'} gutterBottom>
            Έως
          </Typography> */}
          <ShowOnlyDatePicker
            value={dateTo}
            onChange={(date) => setDateTo(date)}
            format="DD-MM-YYYY"
            disableFuture
            size="small"
            placeholder="Έως"
          />
        </div>
      </div>

      <div className="flex flex-row items-start justify-start gap-2 md:flex-row flex-col flex-wrap">
        <CustomButton onClick={() => updatePeriod('thisMonth')}>
          Τρέχων Μήνας
        </CustomButton>
        <CustomButton onClick={() => updatePeriod('lastMonth')}>
          Προηγούμενος Μήνας
        </CustomButton>
        <CustomButton onClick={() => updatePeriod('thisWeek')}>
          Τρέχουσα Εβδομάδα
        </CustomButton>
        <CustomButton onClick={() => updatePeriod('lastWeek')}>
          Προηγούμενη Εβδομάδα
        </CustomButton>
      </div>
    </div>
  );
};

export default DateFilterBar;
