// material ui
import {
  Box,
  CircularProgress,
  IconButton,
  // Modal,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

// assets;
import { useEffect, useRef, useState } from 'react';
import { Edit } from '@mui/icons-material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useTheme } from '@mui/material/styles';
import { palette } from '../../../styles/variables/colors';

// project imports
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../../Stores/UserStore';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
// import ConfirmDialogSlide from '../../../Components/Theme/Dialogs/ConfirmDialogSlide';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback } from 'react';
import { error, success } from '../../../Common/Toast/Toast';
import clsx from 'clsx';
import { toJS } from 'mobx';
import AuthSetAgent from '../../../Api/AuthSetAgent';

const UsersTable = () => {
  // const confirmDialog = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const subDepartentStore = useSubDepartmentStore();
  const usersStore = useUserStore();
  // const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(true);

  const loader = (
    <Box className="flex justify-center items-center pl-8">
      <CircularProgress size={20} />
    </Box>
  );

  const handleChange = (userId, status) => {
    setLoading(true);

    const newValue = status === 1 ? 0 : 1;

    const lockOrUnlockUser =
      newValue === 1
        ? AuthSetAgent.users.unlockUser
        : AuthSetAgent.users.lockUser;

    lockOrUnlockUser(userId)
      .then((resp) => {
        if (resp?.data.success === true) {
          const successMessage =
            newValue === 1
              ? 'Ο Χρήστης ενεργοποιήθηκε'
              : 'Ο Χρήστης απενεργοποιήθηκε';
          success(successMessage);
        } else {
          error('Κάτι πήγε στραβά');
        }
        setLoading(false);
        refreshList();
      })
      .catch((error) => {
        setLoading(false);
        error('Κάτι πήγε στραβά');
      });
  };

  const editUser = (userId) => {
    navigate(`/user/${userId}/edit`);
  };

  const refreshList = useCallback(async () => {
    setLoading(true);

    await Promise.all([
      usersStore.getAllUsers(),
      subDepartentStore.getSubDepartments(),
    ]).finally(() => {
      setLoading(false);
    });
  }, [subDepartentStore, usersStore]);

  const tableTitleClasses = clsx([
    'ml-4 my-2 pt-2',
    !usersStore.users?.length && 'pb-2',
  ]);

  // const confirmDelete = useCallback(
  //   (user) => {
  //     setUserToDelete(user);
  //     confirmDialog.current.open();
  //   },
  //   [setUserToDelete, confirmDialog]
  // );

  // const onDeleteConfirm = useCallback(() => {
  //   usersStore
  //     .deleteUser(userToDelete.id)
  //     .then(() => {
  //       success('Ο χρήστης διαγράφτηκε επιτυχώς');
  //     })
  //     .catch(() => {});
  // }, [usersStore, userToDelete]);

  useEffect(() => {
    if (usersStore.users?.length === 0) {
      setLoading(true);
      usersStore.getAllUsers();
    }
    if (subDepartentStore.subDepartmentsList?.length === 0) {
      setLoading(true);
      subDepartentStore.getSubDepartments();
    }
    if (
      usersStore.users?.length > 0 &&
      subDepartentStore.subDepartmentsList?.length > 0
    ) {
      setLoading(false);
    }
  }, [
    usersStore.users,
    usersStore.users?.length,
    subDepartentStore.subDepartmentsList,
    usersStore,
    subDepartentStore,
  ]);

  useEffect(() => {
    refreshList();
  }, [usersStore.users?.length]);

  const columns = [
    {
      field: 'username',
      headerName: 'Ονομα Χρήστη',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'center',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] pr-16"
        >
          {params?.value}
        </Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'email',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {params?.value ? params?.value : '-'}
        </Typography>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: 'Τηλέφωνο',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {params?.value ? params?.value : '-'}
        </Typography>
      ),
    },
    {
      field: 'name',
      headerName: 'Ονομα',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {params?.value ? params?.value : '-'}
        </Typography>
      ),
    },
    {
      field: 'surname',
      headerName: 'Επώνυμο',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {params?.value ? params?.value : '-'}
        </Typography>
      ),
    },
    {
      field: 'departmentId',
      headerName: 'Υποτμήμα Χρήστη',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.8,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {
            subDepartentStore.subDepartmentsList?.find(
              (c) => c.departmentId === params?.row?.departmentId
            )?.name
          }
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Κατάσταση',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px] ml-8"
        >
          {params?.row?.status === 1 ? 'Ενεργός' : 'Ανενεργός'}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Ενέργειες',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="pr-4">
          <Stack
            className="mr-36"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              loader
            ) : (
              <>
                <Tooltip
                  placement="top"
                  title={t('misc.Edit')}
                  onClick={() => editUser(params.row.id)}
                >
                  <IconButton
                    color="#333333B2"
                    aria-label="delete"
                    size="large"
                  >
                    <Edit sx={{ fontSize: '1.1rem' }} />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip placement="top" title="Διαγραφή">
              <IconButton
                color="#333333B2"
                size="large"
                onClick={() => confirmDelete(params.row)}
              >
                <DeleteIcon sx={{ fontSize: '1.1rem' }} />
              </IconButton>
            </Tooltip> */}
                <Tooltip placement="top" title={'Αλλαγή Κατάστασης'}>
                  <Switch
                    checked={params?.row.status === 1}
                    onChange={() =>
                      handleChange(params?.row.id, params?.row.status)
                    }
                    sx={{
                      cursor: 'pointer',
                      marginLeft: '0.4rem',
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: palette.info.main,
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: palette.info.main,
                        },
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className="bg-[#003375] text-[#FFFFFF] text-[1.4rem]"
        style={{ width: '99%' }}
      >
        <div className={tableTitleClasses}> Χρήστες</div>
        {!!usersStore.users?.length && (
          <>
            {/* <ConfirmDialogSlide
              ref={confirmDialog}
              title={'Διαγραφή χρήστη'}
              body={
                'Είστε σίγουροι πως θέλετε να διαγράψετε το συγκεκριμένο χρήστη;'
              }
              onConfirm={onDeleteConfirm}
            /> */}
            <DataGrid
              sx={{
                '& .MuiDataGrid-root': {
                  borderWidth: 0,
                },
                '& .certificates-table-theme--header': {
                  backgroundColor: '#FFFFFF',
                  fontSize: 15,
                  borderBottom: '1px solid black',
                },
                '& .certificates-table-theme--row': {
                  backgroundColor: '#FFFFFF',
                  border: 'none',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                ' & .MuiDataGrid-footerContainer': {
                  backgroundColor: '#FFFFFF',
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
                // sorting: {
                //   sortModel: [{ field: 'id', sort: 'desc' }],
                // },
              }}
              disableColumnSelector
              columns={columns}
              rows={usersStore.users || []}
              loading={!usersStore.users}
              autoHeight
              disableSelectionOnClick
              disableColumnMenu
            />
          </>
        )}
      </div>
      {!usersStore.users?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default observer(UsersTable);
