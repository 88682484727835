import React from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Typography, Stack, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubDepartmentStore } from '../../../Stores/SubDepartmentStore';
import { DataGrid } from '@mui/x-data-grid';
import { Visibility } from '@mui/icons-material';
import clsx from 'clsx';
import { palette } from '../../../styles/variables/colors';

const RantevouAppointmentsTable = ({ appointments = [] }) => {
  const subDepartmentStore = useSubDepartmentStore();
  const navigate = useNavigate();

  function renderStatusName(status) {
    switch (status) {
      case 1:
        return 'Δεσμευμένα ραντεβού';
      case 2:
        return 'Ολοκληρωμένα ραντεβού';
      case 3:
        return 'Μη προσέλευση';
      case 4:
        return 'Ακυρωμένα ραντεβού';
      default:
        return 'Δεν βρέθηκαν ραντεβού για την κατάσταση που επιλέξατε';
    }
  }

  useEffect(() => {
    subDepartmentStore.getSubDepartments();
  }, [subDepartmentStore.subDepartmentsList?.splice]);

  const viewRantevou = (id) => {
    navigate(`/rantevou/${id}`, {
      state: { returnToUrl: `/rantevou-overview` },
    });
  };

  const tableTitleClasses = clsx([
    'ml-4 my-2 pt-2',
    !appointments?.length && 'pb-2',
  ]);

  const columns = [
    {
      field: 'id',
      headerName: 'Κωδ. ραντεβού',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      width: 200,
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="h5"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params.row?.id ? params.row?.id : '-'}
        </Typography>
      ),
    },
    {
      field: 'subDepartment',
      headerName: 'Υποτμήμα',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="h5"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params.row?.subDepartment ? params.row?.subDepartment : '-'}
        </Typography>
      ),
    },
    {
      field: 'date',
      headerName: 'Ημερομηνία',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="h5"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params.row?.timeFrom
            ? format(new Date(params.row?.timeFrom), 'dd/MM/yyyy')
            : '-'}
        </Typography>
      ),
    },
    {
      field: 'timeFrom',
      headerName: 'Ώρα',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="h5"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params.row?.timeFrom ? params.row?.timeFrom?.slice(11, 16) : '-'} -{' '}
          {params.row?.timeTo ? params.row?.timeTo?.slice(11, 16) : '-'}
        </Typography>
      ),
    },
    {
      field: 'afm',
      headerName: 'ΑΦΜ Πολίτη',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="h5"
          component="div"
          className="flex items-center text-[14px] ml-1"
        >
          {params.row?.afm ? params.row?.afm : '-'}
        </Typography>
      ),
    },
    {
      field: 'view',
      headerName: 'Προβολή',
      headerClassName: 'certificates-table-theme--header',
      cellClassName: 'certificates-table-theme--row',
      flex: 0.5,
      align: 'left',
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Stack
          className="mr-36"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip
            onClick={() => viewRantevou(params.row?.id)}
            placement="top"
            title={'Προβολή'}
          >
            <Visibility
              sx={{
                cursor: 'pointer',
                fontSize: '1.1rem',
                marginLeft: '1.8rem',
                color: '#333333B2',
              }}
            />
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <div
        className="bg-[#003375] text-[#FFFFFF] text-[1.4rem]"
        style={{ width: '99%' }}
      >
        <div className={tableTitleClasses}>
          {renderStatusName(appointments[0]?.status)}
        </div>
        {!!appointments?.length && (
          <DataGrid
            sx={{
              '& .certificates-table-theme--header': {
                backgroundColor: '#FFFFFF',
                borderBottom: '2px solid black',
              },
              '& .certificates-table-theme--row': {
                backgroundColor: '#FFFFFF',
                overflowWrap: 'break-word',
                minHeight: '40px !important',
                borderBottom: `1px solid ${palette.gray.light}`,
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#FFFFFF',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                fontSize: '1rem',
              },
              ' & .MuiTypography-root': {
                textWrap: 'wrap',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              // sorting: {
              //   sortModel: [
              //     { field: 'id', sort: 'desc' },
              //     { field: 'date', sort: 'desc' },
              //   ],
              // },
            }}
            getRowHeight={() => 'auto'}
            rows={appointments || []}
            columns={columns}
            loading={!appointments}
            pageSize={10}
            rowsPerPageOptions={[10]}
            autoHeight
            disableSelectionOnClick
            disableColumnSelector
            disableColumnMenu
          />
        )}
      </div>
      {!appointments?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default observer(RantevouAppointmentsTable);
