import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationItem from './NotificationItem';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useNotificationsStore } from '../../../Stores/NotificationsStore';
import { observer } from 'mobx-react';

const PAGE_SIZE = 15;

const PROJECT_DICTIONARY = {
  Rantevou: '0',
  Certificates: '1',
  Requests: '2',
};

const NotificationsSection = ({ sectionTitle, icon, onItemClick, project }) => {
  const [expanded, setExpanded] = useState(true);
  const notificationsStore = useNotificationsStore();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const handleAccordionChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const ref = useRef();
  const lastNotificationRef = useCallback(
    (node) => {
      if (loading) return;
      if (ref.current) ref.current.disconnect();
      ref.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) ref.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setLoading(true);
    notificationsStore
      .getNotifications(currentPage, PAGE_SIZE, PROJECT_DICTIONARY[project])
      .then((result) => {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...result.items.map((item) =>
            notificationsStore.mapToNotification(item)
          ),
        ]);
        setHasMore(
          (currentPage - 1) * PAGE_SIZE + result.items?.length <
            result.totalCount
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentPage]);

  return (
    <div className="w-full mt-6">
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Box display="flex" alignItems="center">
            {icon && (
              <img
                src={icon}
                alt={sectionTitle}
                width={'40px'}
                style={{ marginRight: '10px' }}
              />
            )}
            <Typography variant="h4" color={'#003375'}>
              {sectionTitle}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {!loading ? (
            <div
              className="flex flex-col w-full gap-2 overflow-y-auto pr-2"
              style={{ maxHeight: '300px' }}
            >
              {notifications?.length > 0 ? (
                <>
                  {notifications.map((item, index) => (
                    <NotificationItem
                      ref={
                        index === notifications.length - 1
                          ? lastNotificationRef
                          : null
                      }
                      key={item.id}
                      index={index}
                      onClick={onItemClick}
                      {...item}
                    />
                  ))}
                </>
              ) : (
                <Typography
                  variant="h6"
                  gutterBottom
                  display={'flex'}
                  justifyContent={'center'}
                >
                  Δεν υπάρχουν ειδοποιήσεις
                </Typography>
              )}
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <CircularProgress
                sx={{
                  color: '#003375',
                }}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default observer(NotificationsSection);
