import { Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { observer } from 'mobx-react-lite';
import StatusTile from './StatusTile';
import RantevouTables from './RantevouTables';
import { useState, useEffect } from 'react';
import { useRantevouStore } from '../../../Stores/RantevouStore';
import CircularSpinner from '../../../Components/Theme/Spinners/CircularSpinner';
import { useAuthStore } from '../../../Stores/AuthStore';
import { ORG_NAME } from '../../../Config/constant';

const RantevouOverview = () => {
  const { allRantevouData, getAllRantevou, getStatusCount } =
    useRantevouStore();
  const { authUser, isAdmin } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [statusCount, setStatusCount] = useState({});

  const handleStatusTileClick = (status) => {
    setSelectedStatus(selectedStatus === status ? null : status);
  };

  useEffect(() => {
    setLoading(true);
    getAllRantevou(isAdmin ? null : authUser?.subDepartmentId);
  }, []);

  useEffect(() => {
    getStatusCount()
      .then((res) => {
        setStatusCount({
          Available: res.result.find((item) => item.status === 'Available')
            ?.count,
          Occupied: res.result.find((item) => item.status === 'Occupied')
            ?.count,
          Completed: res.result.find((item) => item.status === 'Completed')
            ?.count,
          Unrealized: res.result.find((item) => item.status === 'Unrealized')
            ?.count,
          Cancelled: res.result.find((item) => item.status === 'Cancelled')
            ?.count,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (allRantevouData?.length > 0) {
      setAppointments(allRantevouData);
    }
    setLoading(false);
  }, [allRantevouData]);

  return (
    <div className="w-full">
      <Topbar title={`Ηλεκτρονικές Υπηρεσίες`} />
      <div className="ml-6 mb-15">
        <div className="mt-6">
          <Typography variant="h3" color={'#003375'} gutterBottom>
            Επισκόπηση αιτημάτων ραντεβού
          </Typography>
          <div className="flex justify-center items-center mt-16 flex-wrap gap-4">
            <StatusTile
              title="Δεσμευμένα"
              counter={statusCount?.Occupied}
              isActive={selectedStatus === 1}
              onClick={() => handleStatusTileClick(1)}
            />
            <StatusTile
              title="Ακυρώθηκαν"
              counter={statusCount?.Cancelled}
              isActive={selectedStatus === 4}
              onClick={() => handleStatusTileClick(4)}
            />
            <StatusTile
              title="Ολοκληρωμένα"
              counter={statusCount?.Completed}
              isActive={selectedStatus === 2}
              onClick={() => handleStatusTileClick(2)}
            />
            <StatusTile
              title="Μη προσέλευση"
              counter={statusCount?.Unrealized}
              isActive={selectedStatus === 3}
              onClick={() => handleStatusTileClick(3)}
            />
          </div>
          <hr className="mt-16 border-1 border-[#003375] w-2/3" />
          {loading ? (
            <div className="w-full mt-16 flex justify-center">
              <CircularSpinner />
            </div>
          ) : (
            <div className="mt-16">
              <RantevouTables
                appointments={appointments}
                status={selectedStatus}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(RantevouOverview);
