import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React, { Component } from "react";
import { themeColors } from "../../Config/Themes/theme";

const PageTitle = ({ title, children }) => {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  return (
    <div className="flex items-center justify-between">
      <Typography
        // className="font-bold text-[32px]"
        color={colors.greyAccent.dark2}
        gutterBottom
        variant="h3"
      >
        {title}
      </Typography>

      {children}
    </div>
  );
};

export default PageTitle;
