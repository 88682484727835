import { Box, Button, CircularProgress, Typography } from '@mui/material';
import Topbar from '../../../Components/Topbar/Topbar';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import AuthSetAgent from '../../../Api/AuthSetAgent';
import { useState } from 'react';
import SectorsTable from './SectorsTable';
import { palette } from '../../../styles/variables/colors';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SectorDeleteModal from './SectorDeleteModal';
import SectorViewForm from './SectorViewForm';
import SectorCreateEditForm from './SectorCreateEditForm';
import ExpandableSectionContainer from '../../../Components/ExpandableSectionContainer/ExpandableSectionContainer';
import CustomPagination from '../../../Components/CustomPagination/CustomPagination';

export const FORM_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  // DELETE: 'delete',
  VIEW: 'view',
  HIDDEN: 'hidden',
};

const PAGE_SIZE = 15;

const Sectors = () => {
  const formSectionRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setSectors] = useState();
  const [subOrganizations, setSubOrganizations] = useState();
  const [organizations, setOrganizations] = useState();
  const [municipalityUnits, setMunicipalityUnits] = useState();
  const [regionUnits, setRegionUnits] = useState();
  const [formStatus, setFormStatus] = useState(FORM_STATUS.HIDDEN);
  const [focusedSector, setFocusedSector] = useState();
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handleAction = useCallback(({ item, formStatus: status }) => {
    setFocusedSector(item);
    setFormStatus(status);
  }, []);

  // const handleDelete = useCallback(({ item }) => {
  //   setFocusedSector(item);
  //   setShowDeleteModal(true);
  // }, []);

  const refreshList = useCallback(async () => {
    setIsLoading(true);

    await Promise.all([
      // AuthSetAgent.regionUnits
      //   .getRegionUnitsByRegionId(4)
      //   .then((response) => {
      //     setRegionUnits(response.result.items);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   }),
      AuthSetAgent.regionUnit
        .getRegionUnits()
        .then((response) => {
          const regionUnits = response?.result?.items;
          setRegionUnits(regionUnits);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.municipalityUnit
        .getMunicipalityUnit()
        .then((response) => {
          const municipalityUnits = response?.result?.items;
          setMunicipalityUnits(municipalityUnits);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.organizations
        .getOrganizations()
        .then((response) => {
          const organizations = response?.result?.items;
          setOrganizations(organizations);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.sectors
        .getSectors(currentPage, PAGE_SIZE)
        .then((response) => {
          const totalPages = Math.ceil(
            response.result.totalCount / response.result.pageSize
          );
          setTotalPages(totalPages);
          setSectors(response.result.items);
        })
        .catch((error) => {
          console.error(error);
        }),
      AuthSetAgent.subOrganizations
        .getSubOrganizations()
        .then((response) => {
          const subOrganizations = response.result.items;
          setSubOrganizations(subOrganizations);
        })
        .catch((error) => {
          console.error(error);
        }),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [currentPage]);

  const processedSectors = useMemo(
    () =>
      sectors?.map((item) => ({
        ...item,
        organizationName: item?.subOrganization?.organization?.name,
        organizationId: item?.subOrganization?.organization?.id,
        subOrganizationName: item?.subOrganization?.name,
        subOrganizationId: item?.subOrganization?.id,
        regionUnitName: item?.regionUnit?.name || null,
        regionUnitId: item?.regionUnit?.id || null,
        municipalityUnitName: item?.municipalityUnit?.name || null,
        municipalityUnitId: item?.municipalityUnit?.id || null,
        isActive: item?.isActive || null,
      })),
    [sectors]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await Promise.all([
        // AuthSetAgent.regionUnits
        //   .getRegionUnitsByRegionId(4)
        //   .then((response) => {
        //     setRegionUnits(response.result.items);
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   }),
        AuthSetAgent.regionUnit
          .getRegionUnits()
          .then((response) => {
            const regionUnits = response?.result?.items;
            setRegionUnits(regionUnits);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.municipalityUnit
          .getMunicipalityUnit()
          .then((response) => {
            const municipalityUnits = response?.result?.items;
            setMunicipalityUnits(municipalityUnits);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.organizations
          .getOrganizations()
          .then((response) => {
            const organizations = response?.result?.items;
            setOrganizations(organizations);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.sectors
          .getSectors(currentPage, PAGE_SIZE)
          .then((response) => {
            const totalPages = Math.ceil(
              response.result.totalCount / response.result.pageSize
            );
            setTotalPages(totalPages);
            setSectors(response.result.items);
          })
          .catch((error) => {
            console.error(error);
          }),
        AuthSetAgent.subOrganizations
          .getSubOrganizations()
          .then((response) => {
            const subOrganizations = response.result.items;
            setSubOrganizations(subOrganizations);
          })
          .catch((error) => {
            console.error(error);
          }),
      ]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, [currentPage]);

  useEffect(() => {
    const shouldScroll = formStatus !== FORM_STATUS.HIDDEN;

    if (shouldScroll) {
      formSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        alignToTop: true,
      });
    }
  }, [formStatus, focusedSector?.id]);

  const loader = (
    <Box className="flex justify-center items-center p-20">
      <CircularProgress size={30} />
    </Box>
  );

  const content = (
    <>
      <Typography
        className="pt-6 pb-5"
        variant="h3"
        color={'#003375'}
        gutterBottom
      >
        Λίστα Διευθύνσεων
      </Typography>
      <SectorsTable
        focusedItem={focusedSector}
        formStatus={formStatus}
        sectors={processedSectors}
        handleAction={handleAction}
        refreshList={refreshList}
        // handleDelete={handleDelete}
      />
      <CustomPagination
        className={'my-4 flex justify-center items-center'}
        color={'#003375'}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <Box className="flex justify-end mt-5 mr-5">
        <AnimateButton>
          <Button
            sx={{
              backgroundColor: '#184682',
              color: '#f9f9f9',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#184682',
                color: '#f9f9f9',
              },
              fontSize: '1rem',
            }}
            onClick={() => {
              setFormStatus((prev) =>
                prev === FORM_STATUS.CREATE
                  ? FORM_STATUS.HIDDEN
                  : FORM_STATUS.CREATE
              );
            }}
            variant="contained"
            type="submit"
          >
            Προσθήκη Νέας
          </Button>
        </AnimateButton>
      </Box>
    </>
  );

  return (
    <>
      <Box className="min-h-[110vh]">
        <Topbar title="Ηλεκτρονικές Υπηρεσίες" />
        <Box className="ml-4">
          {isLoading ? loader : content}
          {(formStatus === FORM_STATUS.CREATE ||
            formStatus === FORM_STATUS.EDIT) && (
            <ExpandableSectionContainer ref={formSectionRef}>
              <SectorCreateEditForm
                isEdit={formStatus === FORM_STATUS.EDIT}
                item={focusedSector}
                handleAction={handleAction}
                refreshList={refreshList}
                organizations={organizations}
                subOrganizations={subOrganizations}
                municipalityUnits={municipalityUnits}
                regionUnits={regionUnits}
              />
            </ExpandableSectionContainer>
          )}
          {formStatus === FORM_STATUS.VIEW && (
            <ExpandableSectionContainer ref={formSectionRef}>
              <SectorViewForm
                handleAction={handleAction}
                item={focusedSector}
              />
            </ExpandableSectionContainer>
          )}

          {/* {showDeleteModal && (
            <SectorDeleteModal
              item={focusedSector}
              handleClose={() => setShowDeleteModal(false)}
              refreshList={refreshList}
              handleSubmit={() => {}}
            />
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default Sectors;
