import React, { useState } from 'react';
import DataTable from '../../../Components/DataTable/DataTable';
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { palette } from '../../../styles/variables/colors';
import { VisibilityOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useAuthStore } from '../../../Stores/AuthStore';
import { observer } from 'mobx-react';

const EVENT_FIELDS = {
  Title: {
    id: 'title',
  },
  Date: {
    id: 'dateTime',
  },
  Address: {
    id: 'address',
  },
  Organization: {
    id: 'organizationId',
  },
  Actions: {
    id: 'actions',
  },
};

const loader = (
  <Box className="flex justify-center items-center pl-8">
    <CircularProgress size={20} />
  </Box>
);

const ActionButtons = ({
  className,
  item,
  focusedItem,
  handleAction,
  refreshList,
}) => {
  const isFocused = focusedItem?.id === item?.id;
  const [loading, setLoading] = useState(false);

  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      {loading ? (
        loader
      ) : (
        <Tooltip
          onClick={() => {
            handleAction({ item });
          }}
          placement="top"
          title={'Προβολή'}
        >
          <VisibilityOutlined
            sx={{
              cursor: 'pointer',
              fontSize: '1.4rem',
              marginLeft: '0.4rem',
              color: isFocused ? palette.info.main : '#333333B2',
            }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const EventTable = ({
  focusedItem,
  isLoading,
  handleAction,
  refreshList,
  events,
}) => {
  const { isSuperAdmin } = useAuthStore();
  const columns = [
    {
      field: EVENT_FIELDS?.Title?.id,
      headerName: 'Τίτλος',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[EVENT_FIELDS?.Title?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: EVENT_FIELDS?.Organization?.id,
      headerName: 'Οργανισμός',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[EVENT_FIELDS?.Organization?.id] || '-'}
        </Typography>
      ),
    },
    {
      field: EVENT_FIELDS?.Date?.id,
      headerName: 'Ημερομηνία',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {dayjs(params?.row?.[EVENT_FIELDS?.Date?.id]).format(
            'DD/MM/YYYY HH:mm'
          ) || '-'}
        </Typography>
      ),
    },
    {
      field: EVENT_FIELDS?.Address?.id,
      headerName: 'Διεύθυνση',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => (
        <Typography
          align="left"
          variant="subtitle1"
          component="div"
          className="flex items-center text-[14px]"
        >
          {params?.row?.[EVENT_FIELDS?.Address?.id] ?? '-'}
        </Typography>
      ),
    },
    {
      field: EVENT_FIELDS?.Actions?.id,
      headerName: 'Ενέργειες',
      headerClassName: 'table-theme--header',
      cellClassName: 'table-theme--row',
      align: 'left',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params) => (
        <ActionButtons
          focusedItem={focusedItem}
          item={params?.row}
          handleAction={handleAction}
          className="ml-1"
          refreshList={refreshList}
        />
      ),
    },
  ].filter((column) =>
    isSuperAdmin ? true : column.field !== EVENT_FIELDS?.Organization?.id
  );

  return (
    <>
      <>
        {!!events?.length && (
          <DataTable
            columns={columns}
            rows={events || []}
            loading={isLoading}
            backgroundColor="#f9f9f9"
          />
        )}
      </>
      {!events?.length && (
        <div className="flex justify-center items-center p-10">
          Δεν υπάρχουν αποτελέσματα
        </div>
      )}
    </>
  );
};

export default observer(EventTable);
