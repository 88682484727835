import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { IconButton } from '@mui/material';

const CustomPagination = ({
  currentPage,
  totalPages,
  onPageChange,
  className,
  color,
  hideFirstAndLastButtons = false,
}) => {
  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleLastPage = () => {
    onPageChange(totalPages);
  };

  const handleChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      className={className}
    >
      {!hideFirstAndLastButtons && (
        <IconButton
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
      )}
      <Pagination
        count={totalPages}
        page={currentPage}
        boundaryCount={0}
        siblingCount={1}
        onChange={handleChange}
        style={{ color: color }}
      />
      {!hideFirstAndLastButtons && (
        <IconButton
          onClick={handleLastPage}
          disabled={currentPage === totalPages || totalPages === 0}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default CustomPagination;
