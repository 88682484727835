import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AnimateButton from '../../../Components/Theme/Extended/AnimateButton';
import SubmitButton from '../../../Components/Theme/Extended/SubmitButton';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAuthStore } from '../../../Stores/AuthStore';
import Link from '../../../Components/Theme/Link/Link';
import { useAppointmentServiceStore } from '../../../Stores/AppointmentServiceStore';
import { error, success } from '../../../Common/Toast/Toast';

const RantevouServiceViewEditForm = ({ rantevouService }) => {
  const {
    applicationCategories,
    getApplicationCategories,
    extraFieldTypes,
    updateAppointmentService,
  } = useAppointmentServiceStore();
  const { authUser } = useAuthStore();
  const [loading, setLoading] = useState(true);
  const [formEnabled, setFormEnabled] = useState(false);
  const serviceSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .strict(true)
      .required('Το πεδίο ειναι υποχρεωτικό'),
    subDepartmentId: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
    description: yup.string().strict(true),
    applicationCategoryId: yup
      .object()
      .shape({
        value: yup.number().required('Το πεδίο ειναι υποχρεωτικό'),
        description: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
      })
      .required('Το πεδίο ειναι υποχρεωτικό'),
    extraFields: yup.array().of(
      yup
        .object()
        .shape({
          name: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
          type: yup.string().required('Το πεδίο ειναι υποχρεωτικό'),
          required: yup.boolean().required('Το πεδίο ειναι υποχρεωτικό'),
        })
        .nullable()
    ),
  });
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      title: rantevouService?.title,
      subDepartmentId: rantevouService?.subDepartmentId,
      description: rantevouService?.description ?? '',
      applicationCategoryId: {
        value: rantevouService?.applicationCategoryId,
        description: applicationCategories.find(
          (x) => x.id === rantevouService?.applicationCategoryId
        )?.title,
      },
      extraFields: rantevouService?.extraFields
        ? JSON.parse(rantevouService?.extraFields)
        : [],
    },
    resolver: yupResolver(serviceSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extraFields',
  });

  const initializeFormData = () => {
    setValue('title', rantevouService?.title ?? '');
    setValue('subDepartmentId', rantevouService?.subDepartmentId);
    setValue('description', rantevouService?.description ?? '');
    setValue('applicationCategoryId', {
      value: rantevouService?.applicationCategoryId,
      description: applicationCategories.find(
        (x) => x.id === rantevouService?.applicationCategoryId
      )?.title,
    });
    setValue(
      'extraFields',
      rantevouService?.extraFields
        ? JSON.parse(rantevouService?.extraFields)
        : []
    );
  };

  const updateExistingRantevou = (data) => {
    setLoading(true);
    const payload = {
      subDepartmentId: data.subDepartmentId,
      title: data.title,
      description: data.description,
      applicationCategoryId: data.applicationCategoryId.value,
      extraFields: JSON.stringify(data.extraFields),
    };

    updateAppointmentService(rantevouService.id, payload)
      .then((response) => {
        success('Η υπηρεσία ενημερώθηκε επιτυχώς');
        setLoading(false);
        setFormEnabled(false);
      })
      .catch((err) => {
        error('Η υπηρεσία δεν ενημερώθηκε');
        error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getApplicationCategories(authUser?.organizationId);
  }, [getApplicationCategories, authUser]);

  useEffect(() => {
    if (applicationCategories.length > 0) {
      initializeFormData();
      setLoading(false);
    }
  }, [applicationCategories]);

  return (
    <div className="pb-5 relative">
      <div>
        <form
          onSubmit={handleSubmit(updateExistingRantevou)}
          className="p-4 bg-white rounded-lg shadow-md"
        >
          <div className="flex items-center mb-2 flex-wrap md:flex-nowrap">
            <Typography
              sx={{ fontWeight: 'medium' }}
              variant="h5"
              mr={2}
              color={'#003375'}
              className={'min-w-fit'}
            >
              Τίτλος Υπηρεσίας
            </Typography>
            <Controller
              name="title"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value ?? ''}
                  onChange={onChange}
                  // disabled={!formEnabled}
                  inputProps={{ readOnly: !formEnabled }}
                  className="mt-2 w-full justify-start"
                  placeholder="Εισαγωγή τίτλου παρεχόμενης υπηρεσίας"
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </div>

          <div className="flex items-center mt-4 mb-2 flex-wrap md:flex-nowrap">
            <Typography
              sx={{ fontWeight: 'medium' }}
              variant="h5"
              mr={2}
              color={'#003375'}
              className={'min-w-fit'}
            >
              Επιλογή κατηγορίας Υπηρεσίας
            </Typography>
            <Controller
              name="applicationCategoryId"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  className="w-full mt-2"
                  value={
                    value ?? {
                      value: '',
                      description: '',
                    }
                  }
                  onChange={(_, data) => onChange(data ?? null)}
                  options={applicationCategories.map((option) => {
                    return {
                      value: option.id,
                      description: option.title,
                    };
                  })}
                  getOptionLabel={(option) => option?.description || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  disabled={true}
                  readOnly={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Επιλογή κατηγορίας"
                      className="bg-[#FFFF]"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </div>

          <div className="flex-column items-center mt-4 mb-2 flex-wrap md:flex-nowrap">
            <Typography
              sx={{ fontWeight: 'medium' }}
              variant="h5"
              mr={2}
              mb={2}
              color={'#003375'}
              className={'min-w-fit'}
            >
              Οδηγίες ραντεβού
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  multiline
                  defaultValue={value ?? ''}
                  // disabled={!formEnabled}
                  inputProps={{ readOnly: !formEnabled }}
                  onChange={onChange}
                  rows={4}
                  className="w-full mt-2"
                  placeholder="Συμπλήρωση οδηγιών προς τους πολίτες για την προσέλευση στο ραντεβού"
                  error={!!error}
                  helperText={error ? t(error.message) : null}
                />
              )}
            />
          </div>

          <div className="flex-column items-center mt-4 mb-2 flex-wrap lg:flex-nowrap">
            <Typography
              sx={{ fontWeight: 'medium' }}
              variant="h5"
              mr={2}
              mb={2}
              color={'#003375'}
              className={'min-w-fit'}
            >
              Απαίτηση συμπλήρωσης επιπλέον υποχρεωτικών πεδίων
            </Typography>
            {fields.map((field, index) => (
              <div key={field.id} className="flex items-start mb-4">
                <div className="w-1/4">
                  <Controller
                    control={control}
                    name={`extraFields.${index}.name`}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        inputProps={{ readOnly: !formEnabled }}
                        className="mr-4 mt-2"
                        placeholder="Τίτλος πεδίου"
                        label="Τίτλος πεδίου"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name={`extraFields.${index}.type`}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      className="w-1/4 ml-8 mr-4"
                      onChange={(_, data) => onChange(data?.value || '')}
                      defaultValue={
                        extraFieldTypes.find((x) => x.value === value) ?? {
                          value: '',
                          description: '',
                        }
                      }
                      options={extraFieldTypes}
                      getOptionLabel={(option) => option.description}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      readOnly={!formEnabled}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          label="Τύπος πεδίου"
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`extraFields.${index}.required`}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      className="mt-2"
                      label="Υποχρεωτικό"
                      control={
                        <Checkbox
                          checked={field.value}
                          disabled={!formEnabled}
                          readOnly={!formEnabled}
                          onChange={(e) => field.onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
                <AnimateButton>
                  <SubmitButton
                    sx={{ backgroundColor: '#cc0000', marginTop: '0.5rem' }}
                    title={'ΑΦΑΙΡΕΣΗ ΠΕΔΙΟΥ'}
                    loading={loading}
                    type="button"
                    disabled={!formEnabled}
                    onClick={() => remove(index)}
                  />
                </AnimateButton>
              </div>
            ))}

            <AnimateButton>
              <SubmitButton
                sx={{ backgroundColor: '#008000' }}
                className="capitalize text-sm"
                title={'ΠΡΟΣΘΗΚΗ ΠΕΔΙΟΥ'}
                loading={loading}
                variant="contained"
                type="button"
                disabled={!formEnabled}
                onClick={() => append({ name: '', type: '', required: false })}
              />
            </AnimateButton>
          </div>

          <div className="flex justify-end items-end my-6">
            {!formEnabled ? (
              <>
                <Grid item className="flex justify-end items-end pr-6">
                  <div className="mr-4">
                    <Link
                      className="ml-2 capitalize text-sm"
                      to="/rantevou-service"
                      state={{
                        subDepartmentId: rantevouService?.subDepartmentId,
                      }}
                    >
                      Επιστροφη
                    </Link>
                  </div>

                  <AnimateButton>
                    <SubmitButton
                      onClick={(e) => {
                        e.preventDefault();
                        setFormEnabled(true);
                      }}
                      type={'button'}
                      sx={{ backgroundColor: '#b3e7f1' }}
                      className="capitalize text-sm"
                      title={'Επεξεργασια'}
                      loading={loading}
                      variant="contained"
                    />
                  </AnimateButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item className="flex justify-end items-end pr-6">
                  <div className="mr-4">
                    <Link
                      className="ml-2 capitalize text-sm"
                      to="/rantevou-service"
                      state={{
                        subDepartmentId: rantevouService?.subDepartmentId,
                      }}
                    >
                      Επιστροφη
                    </Link>
                  </div>
                  <Grid item className="flex justify-end items-end pr-6">
                    <AnimateButton>
                      <SubmitButton
                        sx={{ backgroundColor: '#b3e7f1' }}
                        className="capitalize text-sm"
                        title={'Ακυρωση'}
                        loading={loading}
                        variant="contained"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFormEnabled(false);
                          initializeFormData();
                        }}
                      />
                    </AnimateButton>
                  </Grid>
                  <AnimateButton>
                    <SubmitButton
                      sx={{ backgroundColor: '#b3e7f1' }}
                      className="capitalize text-sm"
                      title={'Αποθηκευση'}
                      loading={loading}
                      variant="contained"
                      type="submit"
                    />
                  </AnimateButton>
                </Grid>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

RantevouServiceViewEditForm.propTypes = {
  rantevouService: PropTypes.object,
};

export default observer(RantevouServiceViewEditForm);
