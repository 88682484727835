import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { themeColors } from '../../Config/Themes/theme';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useAuthStore } from '../../Stores/AuthStore';
import { observer } from 'mobx-react';
import rantevouWhiteIcon from '../../assets/rantevou-white.png';
import settingsWhiteIcon from '../../assets/settings-white.png';
import statisticsIcon from '../../assets/statistics.png';
import certificatesWhiteIcon from '../../assets/certificates-white.png';
import ticketsWhiteIcon from '../../assets/tickets-white.svg';
import notificationsWhiteIcon from '../../assets/notifications-white.png';
import dashBoardIcon from '../../assets/dashboard.png';
import organizationIcon from '../../assets/organization.png';
import applicationCategoriesIcon from '../../assets/applicationCategories.png';
import accessIcon from '../../assets/access.png';
import announcementIcon from '../../assets/announcement.png';
import eventIcon from '../../assets/event.png';
import poiIcon from '../../assets/poi.png';
import usersIcon from '../../assets/users.png';
import { useOrganizationsStore } from '../../Stores/OrganizationStore';

const MENU_ITEM_IDS = {
  CertificatesManagement: {
    id: 'CertificatesManagement',
  },
  CertificatesOverview: { id: 'CertificatesOverview' },
  CertificatesSearch: { id: 'CertificatesSearch' },
  RantevouManagement: { id: 'RantevouManagement' },
  RantevouOverview: { id: 'RantevouOverview' },
  RantevouSearch: { id: 'CertificRantevouSearchatesOverview' },
  ActiveSchedules: { id: 'ActiveSchedules' },
  NewSchedule: { id: 'NewSchedule' },
  ScheduleHistory: { id: 'ScheduleHistory' },
  TicketsOverview: { id: 'TicketsOverview' },
  TicketsSearch: { id: 'TicketsSearch' },
  Users: { id: 'Users' },
  UserCreate: { id: 'UserCreate' },
  HomePage: { id: 'HomePage' },
  Organization: { id: 'Organization' },
  // OrganizationDefinition: { id: 'OrganizationDefinition' },
  OrganizationList: { id: 'OrganizationList' },
  OrganizationChart: { id: 'OrganizationChart' },
  OrganizationManagement: { id: 'OrganizationManagement' },
  OrganizationManagementGeneral: { id: 'OrganizationManagementGeneral' },
  OrganizationManagementSectors: { id: 'OrganizationManagementSectors' },
  OrganizationManagementDepartments: {
    id: 'OrganizationManagementDepartments',
  },
  OrganizationManagementSubDepartments: {
    id: 'OrganizationManagementSubDepartments',
  },
  ApplicationCategories: { id: 'ApplicationCategories' },
  Access: { id: 'Access' },
  Notifications: { id: 'Notifications' },
  Certificates: { id: 'Certificates' },
  Announcements: { id: 'Announcements' },
  AnnouncementList: { id: 'AnnouncementList' },
  AnnouncementCategories: { id: 'AnnouncementCategories' },
  Events: { id: 'Events' },
  Pois: { id: 'Pois' },
  PoiList: { id: 'PoiList' },
  PoiCategories: { id: 'PoiCategories' },
};

const Item = ({ title, to, icon, selected, setSelected, className, name }) => {
  const location = useLocation();
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);

  useEffect(() => {
    if (location.pathname === to) {
      setSelected(name);
    }
  }, [location, name, setSelected, to]);

  return (
    <MenuItem
      active={selected === name}
      style={{
        color: colors.whiteAccent.main,
        background: selected === name ? colors.bg.whiteTransp['01'] : null,
        marginRight: selected === name ? '10px' : null,
        borderRadius: selected === name ? '4px' : null,
      }}
      onClick={() => setSelected(name)}
      icon={icon}
      className={className}
    >
      <div className="flex items-center justify-between">
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <Link to={to} />
    </MenuItem>
  );
};

const SidebarMenu = () => {
  const theme = useTheme();
  const authStore = useAuthStore();
  const organizationStore = useOrganizationsStore();
  const colors = themeColors(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');
  const [certificatesMenuOpen, setCertificatesMenuOpen] = useState(false);
  const [rantevouMenuOpen, setRantevouMenuOpen] = useState(false);
  const [ticketsMenuOpen, setTicketsMenuOpen] = useState(false);
  const [usersMenuOpen, setUsersMenuOpen] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [scheduleMenuOpen, setScheduleMenuOpen] = useState(false);
  const [organizationMenuOpen, setOrganizationMenuOpen] = useState(false);
  const [poiMenuOpen, setPoiMenuOpen] = useState(false);
  const [announcementMenuOpen, setAnnouncementMenuOpen] = useState(false);
  const [organizationManagementMenuOpen, setOrganizationManagementMenuOpen] =
    useState(false);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const isCertificatesItem =
      selected === MENU_ITEM_IDS.CertificatesManagement.id ||
      selected === MENU_ITEM_IDS.CertificatesOverview.id ||
      selected === MENU_ITEM_IDS.CertificatesSearch.id;
    setCertificatesMenuOpen(isCertificatesItem);
  }, [selected]);

  useEffect(() => {
    const isOrganizationItem =
      // selected === MENU_ITEM_IDS.OrganizationDefinition.id ||
      selected === MENU_ITEM_IDS.OrganizationList.id ||
      selected === MENU_ITEM_IDS.OrganizationChart.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementGeneral.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementSectors.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementDepartments.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementSubDepartments.id;
    setOrganizationMenuOpen(isOrganizationItem);
  }, [selected]);

  useEffect(() => {
    const isOrganizationManagementMenuItem =
      selected === MENU_ITEM_IDS.OrganizationManagementGeneral.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementSectors.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementDepartments.id ||
      selected === MENU_ITEM_IDS.OrganizationManagementSubDepartments.id;
    setOrganizationManagementMenuOpen(isOrganizationManagementMenuItem);
  }, [selected]);

  useEffect(() => {
    const isRantevouItem =
      selected === 'RantevouManagement' ||
      selected === 'RantevouOverview' ||
      selected === 'RantevouSearch' ||
      selected === 'ActiveSchedules' ||
      selected === 'NewSchedule' ||
      selected === 'ScheduleHistory';
    setRantevouMenuOpen(isRantevouItem);
  }, [selected]);

  useEffect(() => {
    const isUsersSuperAdmin =
      selected === 'SettingsSuperAdmin' || selected === 'UsersList';
    setUserManagement(isUsersSuperAdmin);
  }, [selected]);

  useEffect(() => {
    const isTicketsItem =
      selected === 'TicketsOverview' ||
      selected === 'TicketsSearch' ||
      selected === 'TicketsCreate';
    setTicketsMenuOpen(isTicketsItem);
  }, [selected]);

  useEffect(() => {
    const isUsersItem = selected === 'Users' || selected === 'UserCreate';
    setUsersMenuOpen(isUsersItem);
  }, [selected]);

  useEffect(() => {
    const isScheduleItem =
      selected === MENU_ITEM_IDS.ActiveSchedules.id ||
      selected === MENU_ITEM_IDS.NewSchedule.id ||
      selected === MENU_ITEM_IDS.ScheduleHistory.id;
    setScheduleMenuOpen(isScheduleItem);
  }, [selected]);

  useEffect(() => {
    const isPoiItem =
      selected === MENU_ITEM_IDS.PoiList.id ||
      selected === MENU_ITEM_IDS.PoiCategories.id;
    setPoiMenuOpen(isPoiItem);
  }, [selected]);

  useEffect(() => {
    const isAnnouncementItem =
      selected === MENU_ITEM_IDS.AnnouncementList.id ||
      selected === MENU_ITEM_IDS.AnnouncementCategories.id;
    setAnnouncementMenuOpen(isAnnouncementItem);
  }, [selected]);

  useEffect(() => {
    if (authStore.authUser?.organizationId) {
      organizationStore.getOrganizationById(authStore.authUser?.organizationId);
    }
  }, [
    authStore.authUser?.organizationId,
    organizationStore,
    authStore.authUser,
  ]);

  const renderMenuHeader = () => {
    return (
      <>
        <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={
            isCollapsed ? <MenuOutlinedIcon htmlColor="white" /> : undefined
          }
          style={{
            margin: '10px 0 20px 0',
            color: colors.blackAccent.main,
          }}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <MenuOutlinedIcon htmlColor="white" />
              </IconButton>
            </Box>
          )}
        </MenuItem>
        {/* {!isCollapsed && (
          <Typography
            className="pl-5 pb-4"
            variant="h5"
            style={{ color: colors.whiteAccent.main }}
          >
            Μενού
          </Typography>
        )} */}
      </>
    );
  };

  const renderStatisticsMenuItems = () => {
    return (
      <Item
        className="text-[#FFFF]"
        title={'Στατιστικά'}
        to="/"
        selected={selected}
        setSelected={setSelected}
        name={'OrganizationSupervisorStatistics'}
        icon={
          <img
            style={{ minWidth: '30px' }}
            src={statisticsIcon}
            alt="statistics"
          />
        }
      />
    );
  };

  const renderAdminMenuItems = () => {
    return (
      <>
        <Item
          className="text-[#FFFF]"
          title={'Ειδοποιήσεις'}
          to="/"
          selected={selected}
          setSelected={setSelected}
          name={'Notifications'}
          icon={
            <img
              style={{ minWidth: '30px' }}
              src={notificationsWhiteIcon}
              alt="notifications"
            />
          }
        />

        <Item
          className="text-[#FFFF] mt-4"
          title={'Dashboard'}
          to="/dashboard"
          selected={selected}
          setSelected={setSelected}
          name={'Dashboard'}
          icon={
            <img
              style={{ minWidth: '30px' }}
              src={dashBoardIcon}
              alt="dashboard"
            />
          }
        />

        {organizationStore.organizationById?.hasECertificates ? (
          <SubMenu
            icon={
              <img
                src={certificatesWhiteIcon}
                style={{ minWidth: '45px' }}
                alt="pistopiitika"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Πιστοποιητικά'}
            name={'Certificates'}
            open={certificatesMenuOpen}
            onOpenChange={(newOpenState) =>
              setCertificatesMenuOpen(newOpenState)
            }
          >
            <Item
              title="● Διαχείριση"
              to="/certificates"
              selected={selected}
              setSelected={setSelected}
              name={'CertificatesManagement'}
            />
            <Item
              title="● Επισκόπηση"
              to="/certificates-overview"
              selected={selected}
              setSelected={setSelected}
              name={'CertificatesOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/certificates-search"
              selected={selected}
              setSelected={setSelected}
              name={'CertificatesSearch'}
            />
          </SubMenu>
        ) : null}

        {organizationStore.organizationById?.hasERantevou ? (
          <SubMenu
            icon={
              <img
                style={{ minWidth: '45px' }}
                src={rantevouWhiteIcon}
                alt="rantevou"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Ραντεβού'}
            name={'Rantevou'}
            open={rantevouMenuOpen}
            onOpenChange={(newOpenState) => setRantevouMenuOpen(newOpenState)}
          >
            <Item
              title="● Διαχείριση"
              to="/rantevou-service"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'RantevouManagement'}
            />
            <SubMenu
              open={scheduleMenuOpen}
              onOpenChange={(newOpenState) => setScheduleMenuOpen(newOpenState)}
              title={'● Πρόγραμμα'}
              className="pl-0"
            >
              <Item
                title="Ενεργά"
                to="/active-schedules"
                selected={selected}
                setSelected={setSelected}
                name={'ActiveSchedules'}
              />
              <Item
                title="Δημιουργία νέου"
                to="/new-schedule"
                selected={selected}
                setSelected={setSelected}
                name={'NewSchedule'}
              />
              <Item
                title="Ιστορικότητα"
                to="/schedule-history"
                selected={selected}
                setSelected={setSelected}
                name={'ScheduleHistory'}
              />
            </SubMenu>
            <Item
              title="● Επισκόπηση"
              to="/rantevou-overview"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'RantevouOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/rantevou-search"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'RantevouSearch'}
            />
          </SubMenu>
        ) : null}

        {organizationStore.organizationById?.hasERequests ? (
          <SubMenu
            icon={
              <img
                style={{ fill: 'white', minWidth: '50px' }}
                src={ticketsWhiteIcon}
                alt="tickets"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Αιτήματα'}
            name={'Tickets'}
            open={ticketsMenuOpen}
            onOpenChange={(newOpenState) => setTicketsMenuOpen(newOpenState)}
          >
            <Item
              title="● Επισκόπηση"
              to="/tickets-overview"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'TicketsOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/tickets-search"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'TicketsSearch'}
            />
          </SubMenu>
        ) : null}

        {/* <SubMenu
          icon={
            <img
              style={{ minWidth: '35px' }}
              src={settingsWhiteIcon}
              alt="settings"
            />
          }
          className="text-[#FFFF] mt-2"
          title={'Ρυθμίσεις'}
          name={'Settings'}
          open={usersMenuOpen}
          onOpenChange={(newOpenState) => setUsersMenuOpen(newOpenState)}
        >
          <Item
            title="● Χρήστες"
            to="/users"
            selected={selected}
            setSelected={setSelected}
            className="pl-0"
            name={'Users'}
          />
          <Item
            title="● Δημιουργία Χρήστη"
            to="/user-create"
            selected={selected}
            setSelected={setSelected}
            className="pl-0"
            name={'UserCreate'}
          />
        </SubMenu> */}

        <Item
          title="Χρήστες"
          to="/users"
          selected={selected}
          setSelected={setSelected}
          name={'Users'}
          icon={<img src={usersIcon} alt="users" />}
        />
      </>
    );
  };

  const renderModeratorMenuItems = () => {
    return (
      <>
        {organizationStore.organizationById?.hasERequests ? (
          <SubMenu
            icon={
              <img
                style={{ fill: 'white', minWidth: '50px' }}
                src={ticketsWhiteIcon}
                alt="tickets"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Αιτήματα'}
            name={'Tickets'}
            open={ticketsMenuOpen}
            onOpenChange={(newOpenState) => setTicketsMenuOpen(newOpenState)}
          >
            <Item
              title="● Επισκόπηση"
              to="/"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'TicketsOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/tickets-search"
              selected={selected}
              setSelected={setSelected}
              className="pl-0"
              name={'TicketsSearch'}
            />
          </SubMenu>
        ) : null}
      </>
    );
  };

  const renderSuperAdminItems = () => {
    return (
      <>
        {/* <Item
          title="Αρχική"
          to="/"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.HomePage.id}
        /> */}

        <SubMenu
          icon={
            <img
              style={{ minWidth: '35px' }}
              src={organizationIcon}
              alt={MENU_ITEM_IDS.Organization.id}
            />
          }
          className="text-[#FFFF] mt-4"
          title={'Οργανισμός'}
          name={MENU_ITEM_IDS.Organization.id}
          open={organizationMenuOpen}
          onOpenChange={(newOpenState) => setOrganizationMenuOpen(newOpenState)}
        >
          {/* <Item
            title="Ορισμός"
            to="/org-definition"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.OrganizationDefinition.id}
          /> */}

          {/* <Item
            title="Λίστα Οργανισμών"
            to="/org-list"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.OrganizationList.id}
          /> */}

          <Item
            title="Λίστα Οργανισμών"
            to="/"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.OrganizationList.id}
          />

          <Item
            title="Οργανόγραμμα"
            to="/org-chart"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.OrganizationChart.id}
          />
          <SubMenu
            title={'Διαχείριση'}
            name={MENU_ITEM_IDS.OrganizationManagement.id}
            open={organizationManagementMenuOpen}
            onOpenChange={(newOpenState) =>
              setOrganizationManagementMenuOpen(newOpenState)
            }
          >
            <Item
              title="Γενικές Διευθύνσεις"
              to="/org-general"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.OrganizationManagementGeneral.id}
            />
            <Item
              title="Διευθύνσεις"
              to="/org-sectors"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.OrganizationManagementSectors.id}
            />
            <Item
              title="Τμήματα"
              to="/org-departments"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.OrganizationManagementDepartments.id}
            />
            <Item
              title="Υποτμήματα"
              to="/org-subdepartments"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.OrganizationManagementSubDepartments.id}
            />
          </SubMenu>
        </SubMenu>

        <SubMenu
          icon={
            <img
              style={{ minWidth: '35px' }}
              src={announcementIcon}
              alt={MENU_ITEM_IDS.Announcements.id}
            />
          }
          className="text-[#FFFF] mt-4"
          title={'Ανακοινώσεις'}
          name={MENU_ITEM_IDS.Announcements.id}
          open={announcementMenuOpen}
          onOpenChange={(newOpenState) => setAnnouncementMenuOpen(newOpenState)}
        >
          <Item
            title="Λίστα<br /> ανακοινώσεων"
            to="/announcements"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.AnnouncementList.id}
          />
          <Item
            title="Κατηγορίες<br /> ανακοινώσεων"
            to="/announcement-categories"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.AnnouncementCategories.id}
          />
        </SubMenu>

        <Item
          title="Εκδηλώσεις"
          to="/events"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.Events.id}
          icon={<img src={eventIcon} alt={MENU_ITEM_IDS.Events.id} />}
          className="mt-4"
        />
        <SubMenu
          icon={
            <img
              style={{ minWidth: '35px' }}
              src={poiIcon}
              alt={MENU_ITEM_IDS.Pois.id}
            />
          }
          className="text-[#FFFF] mt-4"
          title={'Σημεία ενδιαφέροντος'}
          name={MENU_ITEM_IDS.Pois.id}
          open={poiMenuOpen}
          onOpenChange={(newOpenState) => setPoiMenuOpen(newOpenState)}
        >
          <Item
            title="Λίστα σημείων<br /> ενδιαφέροντος"
            to="/pois"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.PoiList.id}
          />
          <Item
            title="Κατηγορίες<br /> σημείων ενδιαφέροντος"
            to="/poi-categories"
            selected={selected}
            setSelected={setSelected}
            name={MENU_ITEM_IDS.PoiCategories.id}
          />
        </SubMenu>

        <Item
          className="text-[#FFFF] mt-4"
          title="Κατηγορίες<br /> υπηρεσιών"
          to="/application-categories"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.ApplicationCategories.id}
          icon={
            <img
              src={applicationCategoriesIcon}
              alt={MENU_ITEM_IDS.ApplicationCategories.id}
            />
          }
        />

        {/* <SubMenu
          icon={
            <img
              style={{ minWidth: '35px' }}
              src={settingsWhiteIcon}
              alt="SettingsIcon"
            />
          }
          className="text-[#FFFF] mt-4"
          title={'Ρυθμίσεις'}
          name={'SettingsSuperAdmin'}
          open={userManagement}
          onOpenChange={(newOpenState) => setUserManagement(newOpenState)}
        >
          <Item
            title="● Χρήστες"
            to="/users-list"
            selected={selected}
            setSelected={setSelected}
            className="pl-0"
            name={'UsersList'}
          />
        </SubMenu> */}

        <Item
          title="Χρήστες"
          to="/users-list"
          selected={selected}
          setSelected={setSelected}
          name={'UsersList'}
          icon={<img src={usersIcon} alt="users" />}
        />

        <Item
          className="text-[#FFFF] mt-4"
          title="Πρόσβαση σε <br /> τμήμα"
          to="/access"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.Access.id}
          icon={<img src={accessIcon} alt={MENU_ITEM_IDS.Access.id} />}
        />
      </>
    );
  };

  const renderUserMenuItems = () => {
    return (
      <>
        <Item
          className="text-[#FFFF]"
          title={'Ειδοποιήσεις'}
          to="/"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.Notifications.id}
          icon={
            <img
              style={{ minWidth: '30px' }}
              src={notificationsWhiteIcon}
              alt="notifications"
            />
          }
        />

        {organizationStore.organizationById?.hasECertificates &&
        authStore.authUser?.claims?.includes('ManageCertificates') ? (
          <SubMenu
            icon={
              <img
                style={{ minWidth: '45px' }}
                src={certificatesWhiteIcon}
                alt="pistopiitika"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Πιστοποιητικά'}
            name={MENU_ITEM_IDS.Certificates.id}
            open={certificatesMenuOpen}
            onOpenChange={(newOpenState) =>
              setCertificatesMenuOpen(newOpenState)
            }
          >
            <Item
              title="● Επισκόπηση"
              to="/certificates-overview"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.CertificatesOverview.id}
            />
            <Item
              title="● Αναζήτηση"
              to="/certificates-search"
              selected={selected}
              setSelected={setSelected}
              name={MENU_ITEM_IDS.CertificatesSearch.id}
            />
          </SubMenu>
        ) : null}

        {organizationStore.organizationById?.hasERantevou &&
        authStore.authUser?.claims?.includes('ManageRantevou') ? (
          <SubMenu
            icon={
              <img
                style={{ minWidth: '45px' }}
                src={rantevouWhiteIcon}
                alt="rantevou"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Ραντεβού'}
            name={'Rantevou'}
            open={rantevouMenuOpen}
            onOpenChange={(newOpenState) => setRantevouMenuOpen(newOpenState)}
          >
            <SubMenu
              open={scheduleMenuOpen}
              onOpenChange={(newOpenState) => setScheduleMenuOpen(newOpenState)}
              title={'● Πρόγραμμα'}
              className="pl-2"
            >
              <Item
                title="Ενεργά"
                to="/active-schedules"
                selected={selected}
                setSelected={setSelected}
                name={'ActiveSchedules'}
              />
              <Item
                title="Ιστορικότητα"
                to="/schedule-history"
                selected={selected}
                setSelected={setSelected}
                name={'ScheduleHistory'}
              />
            </SubMenu>
            <Item
              title="● Επισκόπηση"
              to="/rantevou-overview"
              selected={selected}
              setSelected={setSelected}
              className="pl-2"
              name={'RantevouOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/rantevou-search"
              selected={selected}
              setSelected={setSelected}
              className="pl-2"
              name={'RantevouSearch'}
            />
          </SubMenu>
        ) : null}

        {organizationStore.organizationById?.hasERequests &&
        authStore.authUser?.claims?.includes('ManageTickets') ? (
          <SubMenu
            icon={
              <img
                style={{ fill: 'white', minWidth: '50px' }}
                src={ticketsWhiteIcon}
                alt="tickets"
              />
            }
            className="text-[#FFFF] mt-4"
            title={'Αιτήματα'}
            name={'Tickets'}
            open={ticketsMenuOpen}
            onOpenChange={(newOpenState) => setTicketsMenuOpen(newOpenState)}
          >
            <Item
              title="● Επισκόπηση"
              to="/tickets-overview"
              selected={selected}
              setSelected={setSelected}
              className="pl-2"
              name={'TicketsOverview'}
            />
            <Item
              title="● Αναζήτηση"
              to="/tickets-search"
              selected={selected}
              setSelected={setSelected}
              className="pl-2"
              name={'TicketsSearch'}
            />
            <Item
              title="● Καταχώρηση"
              to="/ticket-create"
              selected={selected}
              setSelected={setSelected}
              className="pl-2"
              name={'TicketsCreate'}
            />
          </SubMenu>
        ) : null}
      </>
    );
  };

  const renderContentManagerMenuItems = () => {
    return (
      <>
        <Item
          title="Αρχική"
          to="/"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.HomePage.id}
        />
        <Item
          className="mt-4"
          title="Ανακοινώσεις"
          to="/announcements"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.Announcements.id}
          icon={
            <img src={announcementIcon} alt={MENU_ITEM_IDS.Announcements.id} />
          }
        />
        <Item
          className="mt-4"
          title="Εκδηλώσεις"
          to="/events"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.Events.id}
          icon={<img src={eventIcon} alt={MENU_ITEM_IDS.Events.id} />}
        />
        <Item
          className="mt-4"
          title="Σημεία ενδιαφέροντος"
          to="/pois"
          selected={selected}
          setSelected={setSelected}
          name={MENU_ITEM_IDS.PoiList.id}
          icon={<img src={poiIcon} alt={MENU_ITEM_IDS.PoiList.id} />}
        />
      </>
    );
  };

  return (
    <Box
      className="sticky top-0 h-screen"
      sx={{
        '& .pro-sidebar': {
          width: isCollapsed ? '80px' : '300px',
        },
        '& .pro-sidebar-inner': {
          // background: `${colors.primary[400]} !important`,
          background: `${'#003375'}!important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          // color : "#868dfb !important",
          color: '#F2F2F2 !important',
        },
        '& .pro-menu-item.active': {
          // color : "#6870fa !important",
          color: '#FFFFFF !important',
        },
      }}
      style={{ height: screenHeight }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {renderMenuHeader()}

          {/* MENU ITEMS */}
          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            {authStore.isAdmin && !isCollapsed && renderAdminMenuItems()}
            {authStore.isModerator &&
              !isCollapsed &&
              renderModeratorMenuItems()}
            {authStore.isUser && !isCollapsed && renderUserMenuItems()}
            {authStore.isSuperAdmin && !isCollapsed && renderSuperAdminItems()}
            {authStore.isContentManager &&
              !isCollapsed &&
              renderContentManagerMenuItems()}
            {(authStore.isOrganizationSupervisor ||
              authStore.isSubOrganizationSupervisor ||
              authStore.isSectorSupervisor) &&
              !isCollapsed &&
              renderStatisticsMenuItems()}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default observer(SidebarMenu);
